import { ApiClient, IApiClientConfiguration } from '@sparelabs/api-client'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { LanguageHelper } from 'src/locales/LanguageHelper'

export class ApiClientBuilder {
  public static build(paramInput?: Partial<IApiClientConfiguration>): ApiClient {
    return new ApiClient({
      host: AuthenticatorHelper.getRegionalHost(),
      token: AuthenticatorHelper.getUserOrgToken(),
      locale: LanguageHelper.getCurrentLanguageCodeFull(),
      ...paramInput,
    })
  }
}
