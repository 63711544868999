import { HOUR } from '@sparelabs/time'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { SafeAreaView, ScrollView, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { ButtonWrapper } from 'src/components/buttons/ButtonWrapper'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { IRadioOption, RadioButtonGroup } from 'src/components/RadioButtonGroup'
import { updateEstimateInput } from 'src/helpers/EstimateHelper'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    backgroundColor: colors.blue10,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 21,
    color: colors.gray90,
  },
  titleWrapper: {
    paddingTop: 16,
    paddingBottom: 8,
    paddingHorizontal: 16,
    backgroundColor: colors.blue10,
  },
  optionsWrapper: {
    backgroundColor: colors.white,
  },
})

type DstFallBackChoiceProps = ParamsListRoot[ScreenName.DstFallBackChoice] & {
  handleDismiss: () => void
}

export const DstFallBackChoiceView = observer((props: DstFallBackChoiceProps) => {
  const tsBeforeTimeSetBack = props.date.unix()
  const tsAfterTimeSetBack = props.date.unix() + HOUR

  const [selectedTs, setSelectedTs] = useState(tsBeforeTimeSetBack)

  const formatTime = (ts: number): string =>
    `(${moment.unix(ts).format('hh:mm A')}/${moment.unix(ts).utc().format('hh:mm A')} UTC)`

  const getDstFallBackOptions = (beforeTs: number, afterTs: number): IRadioOption[] => [
    {
      key: beforeTs,
      label: `${st.components.scheduleEstimate.dst.fallBack.beforeTimeSetBack()} ${formatTime(beforeTs)}`,
    },
    {
      key: afterTs,
      label: `${st.components.scheduleEstimate.dst.fallBack.afterTimeSetBack()} ${formatTime(afterTs)}`,
    },
  ]

  const handleConfirm = () => {
    const { estimateInputStore, selectedEstimateType, handleDismiss } = props
    updateEstimateInput(selectedTs, estimateInputStore, selectedEstimateType, handleDismiss)
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView pointerEvents='box-none' style={styles.content}>
        <View style={styles.titleWrapper}>
          <Text style={styles.title}>{st.components.scheduleEstimate.dst.fallBack.subtitle()}</Text>
        </View>
        <View>
          <View style={styles.optionsWrapper}>
            <RadioButtonGroup
              selected={selectedTs}
              onHandleSelected={(newTs) => setSelectedTs(newTs as number)}
              options={getDstFallBackOptions(tsBeforeTimeSetBack, tsAfterTimeSetBack)}
            />
          </View>
        </View>
      </ScrollView>
      <ButtonWrapper>
        <PrimaryButton
          testID='dstFallbackConfirm'
          title={st.components.scheduleEstimate.dst.fallBack.title()}
          onPress={handleConfirm}
        />
      </ButtonWrapper>
    </SafeAreaView>
  )
})

export const DstFallBackChoice = (props: ScreenPropsRoot<ScreenName.DstFallBackChoice>): JSX.Element => (
  <DstFallBackChoiceView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
)
