import { IEstimateService } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { getNextAvailableTimeRange, requestedTsIsWithinNextAvailableServiceHours } from 'src/helpers/EstimateHelper'
import { moment } from 'src/helpers/Moment'
import { timeRangeToString } from 'src/helpers/TimeHelper'
import { st } from 'src/locales'
import { IEstimatesUserInputParsed } from 'src/types'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: colors.white,
    marginBottom: 0,
    marginTop: 16,
    paddingHorizontal: 16,
  },
  serviceHoursContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'stretch',
    justifyContent: 'space-between',
    marginTop: 8,
    paddingHorizontal: 16,
  },
  serviceText: {
    color: colors.gray90,
    fontSize: 14,
  },
  title: {
    color: colors.gray90,
    fontSize: 16,
    lineHeight: 20,
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 16,
  },
  description: {
    textAlign: 'center',
    padding: 30,
    paddingTop: 0,
    fontSize: 16,
    color: colors.gray70,
  },
})

interface IProps {
  estimatesUserInput: IEstimatesUserInputParsed
  estimateServices: IEstimateService[]
}

const t = st.components.estimatesCardNoTimes

@observer
export class ServiceHours extends Component<IProps> {
  public renderOutsideServiceHours(services: IEstimateService[], requestedTs: number) {
    return (
      <>
        {services.map((s, i) => (
          <View style={styles.serviceHoursContainer} key={i}>
            <Text style={styles.serviceText}>{`${s.serviceName}: `}</Text>
            <Text style={styles.serviceText}>
              {timeRangeToString(getNextAvailableTimeRange(s.serviceTimeRules, requestedTs))}
            </Text>
          </View>
        ))}
      </>
    )
  }

  public renderInsideServiceHours() {
    return (
      <View style={styles.container}>
        <Text style={styles.title}>{t.insideServiceHoursTitle()}</Text>
        <Text style={styles.description}>{t.insideServiceHours()}</Text>
      </View>
    )
  }

  public render() {
    const { estimatesUserInput, estimateServices } = this.props
    const requestedTs = estimatesUserInput.requestedPickupTs || moment().unix()

    if (estimateServices.every((s) => !requestedTsIsWithinNextAvailableServiceHours(requestedTs, s))) {
      return (
        <>
          <View style={styles.container}>
            <Text style={styles.title}>{t.outsideServiceHoursTitle()}</Text>
            <Text style={styles.description}>{t.outsideServiceHours()}</Text>
          </View>
          {this.renderOutsideServiceHours(estimateServices, requestedTs)}
        </>
      )
    }

    return this.renderInsideServiceHours()
  }
}
