import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import React, { useState } from 'react'
import { useAsync } from 'react-async-hook'
import { StyleSheet, View } from 'react-native'
import { ApiClientBuilder } from 'src/api/ApiClientBuilder'
import { colors } from 'src/assets/colors'
import { ErrorView } from 'src/components/error/ErrorView'
import { CheckoutFormWeb } from 'src/screens/payments/stripeSessionsView/CheckoutFormWeb'
import { StripeSessionLoadingIndicator } from 'src/screens/payments/stripeSessionsView/StripeSessionLoadingIndicator'
import { IStripeSessionView, IStripeSessionViewProps } from './StripeSessionViewTypes'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: colors.gray4,
  },
})

export const StripeSessionView: IStripeSessionView = (props: IStripeSessionViewProps) => {
  const [stripe, setStripe] = useState<Stripe | null>(null)
  const [isStripeFormReady, setIsStripeFormReady] = useState<boolean>(false)
  const [clientSecret, setClientSecret] = useState<string | null>(null)

  const asyncResult = useAsync(
    async () =>
      Promise.all([
        // Make sure to call `loadStripe` outside of a component’s render to avoid
        // recreating the `Stripe` object on every render.
        (async () => {
          setStripe(await loadStripe(props.publishableKey))
        })(),
        (async () => {
          setClientSecret(
            (
              await ApiClientBuilder.build().connectedAccounts.postStripeSetupIntent({
                paymentMethodTypes: [props.stripeMethodType],
              })
            ).clientSecret
          )
        })(),
      ]),
    []
  )
  if (asyncResult.error) {
    return <ErrorView />
  }
  return (
    <View style={styles.container}>
      {!stripe || !isStripeFormReady ? <StripeSessionLoadingIndicator /> : null}
      {/* show loading spinner while the form from stripe is loading. We need to do this
          because the Stripe form loads in an iframe and that takes some time to finish loading */}
      <View style={isStripeFormReady ? { width: '100%' } : { opacity: 0, height: 0 }}>
        {/* only render the form after the client secret is fetched from the API */}
        {clientSecret && (
          <Elements stripe={stripe} options={{ clientSecret }}>
            <CheckoutFormWeb onSuccess={props.onSuccess} onReady={() => setIsStripeFormReady(true)} />
          </Elements>
        )}
      </View>
    </View>
  )
}
