import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { View } from 'react-native'
import { InitState, useInitHook } from 'src/hooks/useInitHook'
import { NavigationStackRoot } from 'src/navigation/stacks/NavigationStackRoot'
import { NoConnection } from 'src/screens/login/NoConnection'
import { StorybookStore } from 'src/stores/StorybookStore'
import { StorybookScreen } from './storybook/StorybookScreen'
import 'src/styles/bootstrap/bootstrap'

const FORCE_STORYBOOK_LOAD = false

export const AppChild: FC = observer(() => {
  const { initState, retry, isLoading } = useInitHook()
  if ((__DEV__ && FORCE_STORYBOOK_LOAD) || StorybookStore.isStoryBookEnabled) {
    return <StorybookScreen />
  }
  if (initState === InitState.Loading) {
    return <View />
  }
  if (initState === InitState.NoConnection) {
    return <NoConnection handleRetryPress={retry} isLoading={isLoading} />
  }
  return <NavigationStackRoot isLoggedIn={initState === InitState.LoggedIn} />
})
