import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { ImageMarker } from 'src/components/mapMarkers/ImageMarker'
import { IMarkerProps } from 'src/components/mapMarkers/MapMarkerProps'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { ANDROID_ANCHOR, mapMarkerStyles, START_PIN_OFFSET, START_PIN_SIZE } from 'src/helpers/MapMarkerHelper'
import { IMarker, MarkerTypes } from 'src/types'

@observer
export class StartPinMarker extends Component<IMarkerProps> {
  public render() {
    const startPinMarker: IMarker = {
      name: MarkerTypes.StartPin,
      coordinate: this.props.location,
      anchor: ANDROID_ANCHOR,
      imageStyle: mapMarkerStyles.startPin,
      webCenterOffset: { offsetLeft: -START_PIN_OFFSET, offsetTop: -START_PIN_OFFSET },
      child: <SvgIconWrapper icon={SvgIcon.StartPin} widthFixed={START_PIN_SIZE} heightFixed={START_PIN_SIZE} />,
    }
    return <ImageMarker {...startPinMarker} />
  }
}
