import React, { Component, ComponentType } from 'react'
import { NetworkErrorCard } from 'src/components/cards/NetworkErrorCard'
import { ApiStore } from 'src/stores/ApiStore'

interface IProps<C> {
  child: ComponentType<C>
  childProps: C
}

class WithNetworkDownWrapperView<C> extends Component<IProps<C>> {
  public render() {
    const { childProps } = this.props
    if (!ApiStore.connected) {
      return <NetworkErrorCard />
    }
    return <this.props.child {...childProps} />
  }
}

export const withNetworkDownView = <C extends {}>(Child: ComponentType<C>): ComponentType<C> => {
  const NewComponent = (props: C) => <WithNetworkDownWrapperView<C> child={Child} childProps={props} />
  NewComponent.displayName = Child.displayName

  return NewComponent
}
