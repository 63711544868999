import { FavoriteLocationType, IFavoriteLocationResponse } from '@sparelabs/api-client'
import { GeometryType, IPoint } from '@sparelabs/geography'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { AlertButton, SafeAreaView, ScrollView, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { CancelButton } from 'src/components/buttons/CancelButton'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { FavoriteTypeSelector } from 'src/components/favorites/FavoriteTypeSelector'
import { TextField } from 'src/components/textField/TextField'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { st } from 'src/locales'
import { FavoriteScreenStateType, ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { EditMode, FavoriteLocationEditStore } from 'src/stores/FavoriteLocationEditStore'
import { FavoriteLocationStore } from 'src/stores/FavoriteLocationStore'
import { SetLocationStore } from 'src/stores/SetLocationStore'
import { AlertButtonStyle } from 'src/util/types'

const styles = StyleSheet.create({
  safeAreaContainer: {
    flex: 1,
    backgroundColor: colors.gray4,
  },
  container: {
    backgroundColor: 'white',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  address: {
    color: colors.radioLabel,
    fontSize: 17,
    paddingBottom: 24,
    textAlign: 'center',
  },
  buttonContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  textInputContainer: {
    fontSize: 17,
  },
  cancelStyle: {
    marginBottom: 16,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  textContainer: {
    flex: 1,
    width: '100%',
    paddingVertical: 24,
  },
})

type Props = ParamsListRoot[ScreenName.EditFavoriteScreen] & {
  handleDismiss: () => void
}

interface IState {
  textLabel: string
  setLocationStore: SetLocationStore
  favoriteLocationEditStore: FavoriteLocationEditStore
}

@observer
export class EditFavoriteScreenView extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      textLabel: '',
      setLocationStore: new SetLocationStore(),
      favoriteLocationEditStore: new FavoriteLocationEditStore(),
    }
  }

  public componentDidMount() {
    const params = this.props
    let favoriteData: Partial<IFavoriteLocationResponse> = {}
    if (params.state === FavoriteScreenStateType.Edit) {
      favoriteData = FavoriteLocationStore.findById(params.favoriteId) || {}
    } else {
      const location: IPoint = {
        type: GeometryType.Point,
        coordinates: [params.longitude, params.latitude],
      }
      favoriteData = {
        location,
        address: params.address,
        type: params.type ? params.type : FavoriteLocationType.Other,
        name: this.getName(params.type) ?? params.address,
      }
    }

    this.state.favoriteLocationEditStore.init(favoriteData)
    this.setState({ textLabel: this.getLabel() })
  }

  private readonly getName = (type: FavoriteLocationType) => {
    if (type === FavoriteLocationType.Home) {
      return st.screens.favorites.homeTitle()
    } else if (type === FavoriteLocationType.Work) {
      return st.screens.favorites.workTitle()
    }
    return undefined
  }

  public getLabel = () => {
    // If name is unset, label === address otherwise label === st.screens.favorites.editNameLabel()
    const hasName = Boolean(
      this.state.favoriteLocationEditStore.data.name && this.state.favoriteLocationEditStore.data.name !== ''
    )
    const addressLabel = this.state.favoriteLocationEditStore.data.address || st.screens.favorites.editNameLabel()
    return hasName ? st.screens.favorites.editNameLabel() : addressLabel
  }

  public handlePressSave = async () => {
    await this.state.favoriteLocationEditStore.save()
    this.props.handleDismiss()
    // if this is a new favorite then go back two times.
    // because we would have rendered the modal for setting location just now
    if (this.props.state === FavoriteScreenStateType.New) {
      this.props.handleDismiss()
    }
  }

  public handlePressDelete = async () => {
    AlertHelper.alert(
      st.screens.favorites.deleteFavorite(),
      st.screens.favorites.deleteAlertText(),
      [this.cancelAlertButton(), this.deleteAlertButton()],
      {
        cancelable: false,
      }
    )
  }

  private readonly deleteAlertButton = (): AlertButton => ({
    text: st.screens.favorites.deleteButton(),
    onPress: async () => {
      await this.state.favoriteLocationEditStore.delete()
      this.props.handleDismiss()
    },
    style: AlertButtonStyle.Destructive,
  })

  private readonly cancelAlertButton = (): AlertButton => ({
    text: st.common.buttonCancel(),
    style: AlertButtonStyle.Cancel,
  })

  public renderButtons() {
    const deleteButton = this.state.favoriteLocationEditStore.data.id ? (
      <View style={styles.cancelStyle}>
        <CancelButton
          loading={this.state.favoriteLocationEditStore.isDeleting}
          title={st.screens.favorites.deleteFavorite()}
          onPress={this.handlePressDelete}
          testID='favoriteDelete'
        />
      </View>
    ) : null
    const buttonConfirmText =
      this.state.favoriteLocationEditStore.editMode === EditMode.EDIT
        ? st.screens.favorites.save()
        : st.common.buttonConfirm()
    return (
      <View style={styles.buttonContainer}>
        {deleteButton}
        <PrimaryButton
          loading={this.state.favoriteLocationEditStore.isSaving}
          title={buttonConfirmText}
          onPress={this.handlePressSave}
          testID='favoriteConfirm'
        />
      </View>
    )
  }

  public onChangeText = (name: string) => {
    this.state.favoriteLocationEditStore.set({ name })
  }

  public onChangeIcon = (type: FavoriteLocationType) => {
    this.state.favoriteLocationEditStore.set({ type })
  }

  public onFocus = () => {
    this.setState({
      textLabel: st.screens.favorites.editNameLabel(),
    })
  }

  public onBlur = () => {
    this.setState({
      textLabel: this.getLabel(),
    })
  }

  public render() {
    return (
      <SafeAreaView style={styles.safeAreaContainer}>
        <ScrollView>
          <View style={styles.container} testID='favoritesView'>
            <Text style={styles.address}>{this.state.favoriteLocationEditStore.data.address}</Text>
            <FavoriteTypeSelector
              selected={this.state.favoriteLocationEditStore.data.type}
              onPress={this.onChangeIcon}
            />
            <View style={styles.textContainer}>
              <TextField
                style={styles.textInputContainer}
                label={this.state.textLabel}
                value={this.state.favoriteLocationEditStore.data.name || ''}
                tintColor={colors.primaryColor}
                onChangeText={this.onChangeText}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
              />
            </View>
            {this.renderButtons()}
          </View>
        </ScrollView>
      </SafeAreaView>
    )
  }
}

export const EditFavoriteScreen = (props: ScreenPropsRoot<ScreenName.EditFavoriteScreen>) => (
  <EditFavoriteScreenView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
)
