import { FavoriteLocationType, IFavoriteLocationResponse, Resources } from '@sparelabs/api-client'
import { action, observable } from 'mobx'
import { LegacyApiClient } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { FavoriteLocationStore } from './FavoriteLocationStore'

export enum EditMode {
  NEW = 'new',
  EDIT = 'edit',
}

export class FavoriteLocationEditStore {
  @observable
  public editMode: EditMode = EditMode.NEW

  @observable
  public isSaving: boolean = false

  @observable
  public isDeleting: boolean = false

  @observable
  public data: Partial<IFavoriteLocationResponse>

  constructor() {
    this.data = this.getDefaultData()
  }

  @action
  public init = (data?: Partial<IFavoriteLocationResponse>) => {
    if (!data || (data && !data.id)) {
      this.editMode = EditMode.NEW
    } else {
      this.editMode = EditMode.EDIT
    }

    this.data = { ...this.getDefaultData(), ...data }
  }

  @action
  public setAndSaveLocation = async (data: Partial<IFavoriteLocationResponse>) => {
    this.set(data)
    await this.save()
  }

  @action
  public set = (data: Partial<IFavoriteLocationResponse>) => {
    this.data = { ...this.data, ...data }
  }

  @action public save = async () => {
    this.isSaving = true
    let res
    const token = AuthenticatorHelper.getUserOrgToken()
    if (this.editMode === EditMode.EDIT) {
      res = await LegacyApiClient.patch(token, `${Resources.FavoriteLocations}/${this.data.id}`, {
        ...this.data,
      })
    } else {
      res = await LegacyApiClient.post(token, Resources.FavoriteLocations, {
        ...this.data,
      })
    }

    FavoriteLocationStore.updateWithNewFavorite(res.body)
    this.isSaving = false
  }

  @action public delete = async () => {
    this.isDeleting = true
    const token = AuthenticatorHelper.getUserOrgToken()
    if (this.data && this.data.id) {
      await LegacyApiClient.delete(token, `${Resources.FavoriteLocations}/${this.data.id}`)
      const deletedFavorite = FavoriteLocationStore.findById(this.data.id)
      if (deletedFavorite) {
        FavoriteLocationStore.remove(this.data.id)
      }
    }
    this.isDeleting = false
  }

  private readonly getDefaultData = (): Partial<IFavoriteLocationResponse> => ({
    name: undefined,
    location: undefined,
    address: undefined,
    type: FavoriteLocationType.Other,
  })
}
