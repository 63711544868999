import { IFareStub, IRequestResponse, ITipResponse } from '@sparelabs/api-client'
import { CurrencyHelper } from '@sparelabs/currency'
import { FareSemantics } from '@sparelabs/domain-logic'
import { toJS } from 'mobx'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { TextButton } from 'src/components/buttons/TextButton'
import { IFontAwesomeWrapperProps } from 'src/components/FontAwesomeIcon'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { ITipStore } from 'src/stores/TipStore'

const styles = StyleSheet.create({
  dateText: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 20,
    color: colors.gray90,
  },
  timeText: {
    paddingTop: 4,
    fontSize: 16,
    lineHeight: 20,
    color: colors.gray70,
  },
  priceText: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingRight: 4,
    fontSize: 16,
    lineHeight: 20,
    color: colors.gray70,
  },
  fareContainer: {
    paddingTop: 4,
    flexDirection: 'row',
    alignItems: 'center',
    height: 36,
  },
})

interface IProps {
  request: IRequestResponse
  showFare: boolean
  handleNavigateDiscountDetails: (params: ParamsListRoot[ScreenName.DiscountDetails]) => void
  tipStore: ITipStore
}

interface IState {
  tip: ITipResponse | null
}

@observer
export class PastTripText extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      tip: null,
    }
  }

  public async componentDidMount() {
    this.setState({
      tip: await this.props.tipStore.getTipResponse(this.props.request.id),
    })
  }

  private readonly handleFareInfoPress = (request: IRequestResponse) => {
    const { fare, fareRedemptions } = request
    this.props.handleNavigateDiscountDetails({
      fare: toJS(fare),
      fareRedemptions: toJS(fareRedemptions),
    })
  }

  private readonly renderFareWithDiscount = (request: IRequestResponse) => {
    if (FareSemantics.hasDiscount(request)) {
      const iconProps: IFontAwesomeWrapperProps = {
        icon: 'info-circle',
        size: 16,
        color: colors.gray70,
      }
      return (
        <TextButton
          onPress={() => this.handleFareInfoPress(request)}
          containerStyle={styles.fareContainer}
          text={this.renderFare(request.fare)}
          textStyle={styles.priceText}
          rightIconProps={iconProps}
        />
      )
    }

    return (
      <View style={styles.fareContainer}>
        <Text style={styles.priceText}>{this.renderFare(request.fare)}</Text>
      </View>
    )
  }

  private readonly renderFare = (fare: IFareStub) =>
    `${CurrencyHelper.format(fare.total, fare.currency)} ${st.screens.pastTrip.fare()}${
      this.state.tip ? this.renderTip(this.state.tip) : ''
    }`

  public renderTip = (tip: ITipResponse) =>
    ` • ${CurrencyHelper.format(tip.amount, tip.currency)} ${st.screens.pastTrip.tip()}`

  public render() {
    const { request, showFare } = this.props
    const m = moment.unix(request.scheduledPickupTs ?? request.requestedPickupTs)

    return (
      <View style={{ flexDirection: 'column' }}>
        <Text style={styles.dateText}>{m.format('ddd, LL')}</Text>
        <Text style={styles.timeText}>{m.format('LT')}</Text>
        {showFare && this.renderFareWithDiscount(request)}
      </View>
    )
  }
}
