import { IOrganizationResponse, IRequestResponse, IVehicleResponse } from '@sparelabs/api-client'
import { toJS } from 'mobx'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { BackButton } from 'src/components/buttons/BackButton'
import { BottomSheetWrapper } from 'src/components/cards/BottomSheetWrapper'
import { CardWrapper } from 'src/components/cards/CardWrapper'
import { NetworkErrorPill } from 'src/components/NetworkErrorPill'
import { ActiveRequestCompletionObserver } from 'src/components/request/ActiveRequestCompletionObserver'
import { RequestProcessingCard } from 'src/components/request/processing/RequestProcessingCard'
import { CLOSED_CARD_HEIGHT, RequestCard } from 'src/components/request/RequestCard'
import { RequestCompletionAlerts } from 'src/components/request/RequestCompletionAlerts'
import { VehicleStatusPill } from 'src/components/request/VehicleStatusPill'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { COLLAPSED_ESTIMATE_TOP_PADDING_WEB } from 'src/helpers/RideOptionsCardHelper'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { MODAL_MAX_WIDTH } from 'src/navigation/consts/NavigationHelper'
import { ActiveRequestVehicleStore } from 'src/stores/ActiveRequestVehicleStore'
import { ApiStore } from 'src/stores/ApiStore'
import { IRequestStore } from 'src/stores/RequestStore'
import { RouterStore } from 'src/stores/RouterStore'
import { UIStateStore } from 'src/stores/UIStore'
import { Pathname } from 'src/types/homeRoot'
import { RequestStatus } from 'src/util/types'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backButtonWrapper: {
    position: 'absolute',
    zIndex: 2,
    bottom: CLOSED_CARD_HEIGHT,
    width: '100%',
  },
  pill: {
    alignItems: 'center',
    marginBottom: 45,
    width: '100%',
    position: 'absolute',
    bottom: CLOSED_CARD_HEIGHT,
  },
})

export interface IRequestScreenProps {
  requestStore: IRequestStore
  selectedEstimateId: string | null
  handleNavigateHome: () => void
  handleNavigateDiscountDetails: (params: ParamsListRoot[ScreenName.DiscountDetails]) => void
  handleNavigateRequestCancellation: (params: ParamsListRoot[ScreenName.RequestCancellation]) => void
  handleNavigateRequestRiderOptions: (
    params: Pick<ParamsListRoot[ScreenName.RequestRiderOptions], 'requestId' | 'serviceId'>
  ) => void
  handleNavigateRequestAccessibilityOptions: (
    params: Pick<ParamsListRoot[ScreenName.RequestAccessibilityOptions], 'requestId' | 'serviceId'>
  ) => void
}

@observer
export class RequestScreen extends Component<IRequestScreenProps> {
  // TODO Make this private.
  public static readonly executeOnNDA = async (request: IRequestResponse, selectedEstimateId: string | null) => {
    if (selectedEstimateId === request.estimateId) {
      await RouterStore.returnToPreviousScreen()
      RequestCompletionAlerts.showImmediateNDAAlert()
    } else {
      await RouterStore.returnHome()
      RequestCompletionAlerts.showNDAAlert(request.scheduledPickupTs || request.requestedPickupTs)
    }
  }

  private readonly executeOnCancelled = async (request: IRequestResponse) => {
    this.props.handleNavigateHome()
    if (request.cancellationDetails) {
      RequestCompletionAlerts.showCancelledAlert(
        request.scheduledPickupTs || request.requestedPickupTs,
        request.cancellationDetails.reason
      )
    }
  }

  // TODO Make this private.
  public static readonly executeOnCompleted = async () => {
    await RouterStore.goToScreen({ pathname: Pathname.RequestLastCompleted })
  }

  public renderCard(request: IRequestResponse | null, vehicle: IVehicleResponse | null) {
    if (!request || request.status === RequestStatus.Completed || request.status === RequestStatus.Cancelled) {
      return null
    }

    const handleNavigateRequestCancellation = () =>
      this.props.handleNavigateRequestCancellation({
        requestId: request.id,
        serviceBrandPhotoUrl: request.serviceBrand.photoUrl,
        onSuccessCallback: this.props.handleNavigateHome,
      })

    if (AuthenticatorHelper.organization) {
      if (this.isRequestOrVehicleLoading(request, vehicle)) {
        return this.renderRequestProcessingCard(request, handleNavigateRequestCancellation)
      }
      return this.renderRequestDetails(
        AuthenticatorHelper.organization,
        request,
        vehicle,
        handleNavigateRequestCancellation
      )
    }
    return null
  }

  public renderRequestProcessingCard = (request: IRequestResponse, handleNavigateRequestCancellation: () => void) => (
    <CardWrapper containerStyle={{ bottom: 0 }}>
      <BackButton onPress={this.props.handleNavigateHome} />
      <RequestProcessingCard request={request} handleNavigateRequestCancellation={handleNavigateRequestCancellation} />
    </CardWrapper>
  )

  public renderRequestDetails = (
    organization: IOrganizationResponse,
    request: IRequestResponse,
    vehicle: IVehicleResponse | null,
    handleNavigateRequestCancellation: () => void
  ) => (
    <>
      {this.renderVehicleStatusPill(request)}
      <BottomSheetWrapper>
        <RequestCard
          organization={organization}
          request={request}
          vehicle={vehicle}
          handleNavigateRequestCancellation={handleNavigateRequestCancellation}
          handleNavigateRequestAccessibilityOptions={() =>
            this.props.handleNavigateRequestAccessibilityOptions({
              requestId: request.id,
              serviceId: request.serviceId,
            })
          }
          handleNavigateRequestRiderOptions={() =>
            this.props.handleNavigateRequestRiderOptions({
              requestId: request.id,
              serviceId: request.serviceId,
            })
          }
          handleNavigateDiscountDetails={() =>
            this.props.handleNavigateDiscountDetails({
              fare: toJS(request.fare),
              fareRedemptions: toJS(request.fareRedemptions),
            })
          }
          handleBackPress={this.props.handleNavigateHome}
        />
      </BottomSheetWrapper>
    </>
  )

  public renderVehicleStatusPill = (request: IRequestResponse) => (
    <View style={this.getPillStyle(UIStateStore.shouldShowLandscapeWeb)} pointerEvents='box-none'>
      {ApiStore.connected ? <VehicleStatusPill request={request} /> : <NetworkErrorPill />}
    </View>
  )

  public render() {
    const { request, vehicle } = this.getRequestObjects()
    return (
      <View key='route-container' style={styles.container} pointerEvents='box-none' testID='requestView'>
        <ActiveRequestCompletionObserver
          effectIfCancelled={this.executeOnCancelled}
          effectIfNda={(request: IRequestResponse) =>
            RequestScreen.executeOnNDA(request, this.props.selectedEstimateId)
          }
          effectIfCompleted={RequestScreen.executeOnCompleted}
        />
        {request && this.renderCard(request, vehicle)}
      </View>
    )
  }

  public isRequestOrVehicleLoading(request: IRequestResponse, vehicle: IVehicleResponse | null) {
    return (
      !vehicle ||
      request.status === RequestStatus.Processing ||
      // we want to continue showing the spinner for no drivers available so that
      // we can fetch the updated estimate in the background
      request.status === RequestStatus.NoDriversAvailable
    )
  }

  private readonly getRequestObjects = (): { request: IRequestResponse | null; vehicle: IVehicleResponse | null } => ({
    request: this.props.requestStore.activeRequest,
    vehicle: ActiveRequestVehicleStore.vehicle,
  })

  private readonly getPillStyle = (shouldShowLandscapeWeb: boolean): StyleProp<ViewStyle> => {
    if (shouldShowLandscapeWeb) {
      return [
        styles.pill,
        {
          bottom: UIStateStore.screenHeight - COLLAPSED_ESTIMATE_TOP_PADDING_WEB,
          width: MODAL_MAX_WIDTH,
        },
      ]
    }

    return styles.pill
  }
}
