import DateTimePicker from '@react-native-community/datetimepicker'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { Platform, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { ButtonWrapper } from 'src/components/buttons/ButtonWrapper'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { DateTimeInput } from 'src/components/datepicker/DateTimeInput'
import { DateTimeDisplay, DateTimePickerMode } from 'src/components/datepicker/DateTimeInputTypes'
import { DstSprintForwardHint } from 'src/components/datepicker/DstSpringForwardHint'
import { EstimateRadioButtonGroup, EstimateScheduleOptions } from 'src/components/rideOptions/EstimateRadioButtonGroup'
import { updateEstimateInput } from 'src/helpers/EstimateHelper'
import { Moment, moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { LanguageHelper } from 'src/locales/LanguageHelper'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { OsType } from 'src/util/types'
import { Header } from '../../components/headers/Header'

const getStyles = () =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    content: {
      flex: 1,
      backgroundColor: colors.blue10,
    },
    titleWrapper: {
      paddingTop: 16,
      paddingBottom: 8,
      paddingHorizontal: 16,
      backgroundColor: colors.blue10,
    },
    optionsWrapper: {
      backgroundColor: colors.white,
    },
    datePickerWrapper: {
      backgroundColor: colors.white,
      borderBottomWidth: 1,
      borderColor: colors.borderBlue,
      borderRadius: 4,
      paddingHorizontal: 16,
      paddingVertical: 24,
    },
  })

export type ScheduleEstimateViewProps = ParamsListRoot[ScreenName.ScheduleEstimate] & {
  handleDismiss: () => void
  handleNavigateDstFallBackChoice: (params: ParamsListRoot[ScreenName.DstFallBackChoice]) => void
}

interface IState {
  requestedMoment: Moment
  selectedEstimateType: EstimateScheduleOptions
}

@observer
export class ScheduleEstimateView extends Component<ScheduleEstimateViewProps, IState> {
  constructor(props: ScheduleEstimateViewProps) {
    super(props)
    const { requestedPickupTs, requestedDropoffTs } = props.estimateInputStore.getEstimateInput()
    if (requestedDropoffTs) {
      this.state = {
        requestedMoment: moment.unix(requestedDropoffTs),
        selectedEstimateType: EstimateScheduleOptions.Arrive,
      }
    } else if (requestedPickupTs) {
      this.state = {
        requestedMoment: moment.unix(requestedPickupTs),
        selectedEstimateType: EstimateScheduleOptions.Leave,
      }
    } else {
      this.state = {
        requestedMoment: moment(),
        selectedEstimateType: EstimateScheduleOptions.Now,
      }
    }
  }

  public onHandleConfirm = async () => {
    const { selectedEstimateType, requestedMoment } = this.state

    /**
     * Due to DST times can occur twice in a day (during the switch from DST to Non-DST)
     * on IOS, requestedMoment is the pre-DST switch moment
     * on Android, requestedMoment is the post-DST switch moment
     * Compare requestedMoment to both lastHour and nextHour to detect DST change
     */
    const nextHour = requestedMoment.clone().add(1, 'hour')
    const lastHour = requestedMoment.clone().subtract(1, 'hour')

    const isDstFallBack =
      (requestedMoment.isDST() && !nextHour.isDST()) || (lastHour.isDST() && !requestedMoment.isDST())

    if (isDstFallBack) {
      this.props.handleDismiss()
      this.props.handleNavigateDstFallBackChoice({
        estimateInputStore: this.props.estimateInputStore,
        selectedEstimateType: this.state.selectedEstimateType,
        date: requestedMoment,
      })
    } else {
      const { estimateInputStore, handleDismiss } = this.props
      updateEstimateInput(requestedMoment.unix(), estimateInputStore, selectedEstimateType, handleDismiss)
    }
  }

  public onHandleDateChange = (requestedMoment: Moment) => {
    // Change radio button if we switch timer off of current time
    if (this.state.selectedEstimateType === EstimateScheduleOptions.Now) {
      this.setState({ selectedEstimateType: EstimateScheduleOptions.Leave })
    }
    this.setState({ requestedMoment })
  }

  public onRadioChange = (selectedEstimateType: EstimateScheduleOptions) => {
    if (selectedEstimateType === EstimateScheduleOptions.Now) {
      this.setState({ requestedMoment: moment() })
    }
    this.setState({ selectedEstimateType })
  }

  public render() {
    const { estimateStore } = this.props

    const styles = getStyles()
    const scheduledArriveEnabledServices =
      estimateStore.estimateServices &&
      estimateStore.estimateServices.filter((s) => s.serviceAllowScheduledArriveByBooking)
    const scheduledLeaveEnabledServices =
      estimateStore.estimateServices &&
      estimateStore.estimateServices.filter((s) => s.serviceAllowScheduledLeaveAtBooking)

    const jsDate = this.state.requestedMoment.toDate()
    const jsMinDate = moment().toDate()

    return (
      <SafeAreaView style={styles.container}>
        <ScrollView pointerEvents='box-none' style={styles.content}>
          <View style={styles.titleWrapper}>
            <Header
              headerText={st.components.scheduleEstimate.subtitle()}
              accessibilityHint={st.components.scheduleEstimate.header.accessibilityHint()}
            />
          </View>
          <View style={styles.optionsWrapper}>
            <EstimateRadioButtonGroup
              onHandleChange={(changes) => this.onRadioChange(changes)}
              selected={this.state.selectedEstimateType}
              scheduledLeaveEnabledServices={scheduledLeaveEnabledServices}
              scheduledArriveEnabledServices={scheduledArriveEnabledServices}
            />
          </View>

          <View style={styles.datePickerWrapper}>
            {
              /**
               * For iOS we do not want to show the year picker for scheduling requests, therefore
               * we call the DateTimePicker directly, whereas for Android we use our DateTimeInput
               */
              Platform.OS === OsType.iOS ? (
                <DateTimePicker
                  value={jsDate}
                  mode={DateTimePickerMode.DateTime}
                  display={DateTimeDisplay.Spinner}
                  minimumDate={jsMinDate}
                  onChange={(event, date) => this.onHandleDateChange(moment(date))}
                  locale={LanguageHelper.getCurrentLanguageCode()}
                />
              ) : (
                <>
                  <DateTimeInput
                    showTitle={true}
                    date={this.state.requestedMoment}
                    display={DateTimeDisplay.Calendar}
                    onHandleDateChange={(changes) => this.onHandleDateChange(changes)}
                    minimumDate={moment()}
                    overrideTimeLabel={st.components.dateTimePicker.time()}
                    mode={DateTimePickerMode.DateTime}
                  />
                  <DstSprintForwardHint time={this.state.requestedMoment} />
                </>
              )
            }
          </View>
        </ScrollView>
        <ButtonWrapper>
          <PrimaryButton
            testID='scheduleEstimateConfirm'
            title={st.components.scheduleEstimate.buttonTitle()}
            onPress={this.onHandleConfirm}
          />
        </ButtonWrapper>
      </SafeAreaView>
    )
  }
}

export const ScheduleEstimate = (props: ScreenPropsRoot<ScreenName.ScheduleEstimate>) => (
  <ScheduleEstimateView
    {...props.route.params}
    handleDismiss={() => props.navigation.goBack()}
    handleNavigateDstFallBackChoice={(params) => props.navigation.navigate(ScreenName.DstFallBackChoice, params)}
  />
)
