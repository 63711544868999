import { NavigationProp } from '@react-navigation/native'
import { ISurveyResponse, SurveyStatus } from '@sparelabs/api-client'
import { delay } from 'bluebird'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Animations } from 'src/assets/Animations'
import { colors } from 'src/assets/colors'
import { LottieAnimation } from 'src/components/lottieAnimation/LottieAnimation'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { SurveyForm } from 'src/screens/survey/SurveyForm'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.blue10,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  description: {
    color: colors.gray90,
    fontWeight: 'bold',
    fontSize: 24,
    textAlign: 'center',
  },
  missedBusContainer: {
    top: 0,
    height: 240,
    marginTop: 20,
    marginBottom: 40,
  },
})

type Props = ParamsListRoot[ScreenName.Survey] & {
  // only provided to be used with forms, other navigation actions should be explicitly defined
  navigation: NavigationProp<ParamsListRoot>
  handleDismiss: () => void
}

interface IState {
  showCompleted: boolean
}

export class SurveyView extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showCompleted: false,
    }
  }

  public render() {
    const { showCompleted } = this.state
    if (this.props.survey.status === SurveyStatus.Archived) {
      return this.renderExpiredSurvey()
    }

    if (showCompleted) {
      return this.renderCompleted()
    }

    return this.renderSurveyContent(this.props.survey)
  }

  private readonly renderSurveyContent = (survey: ISurveyResponse) => (
    <SurveyForm navigation={this.props.navigation} survey={survey} onSubmit={this.handleSubmit} />
  )

  private readonly renderCompleted = () => (
    <View style={styles.container}>
      <LottieAnimation source={Animations.confirmation} autoPlay={true} loop={false} width='60%' maxHeight='100%' />
      <Text style={styles.description}>{st.screens.surveys.completedMessage()}</Text>
    </View>
  )

  private readonly renderExpiredSurvey = () => (
    <View style={styles.container}>
      <View style={styles.missedBusContainer}>
        <LottieAnimation source={Animations.missedBus} autoPlay={true} loop={false} height={240} />
      </View>
      <Text style={styles.description}>{st.screens.surveys.surveyExpiredMessage()}</Text>
    </View>
  )

  private readonly handleSubmit = async () => {
    // show completed screen
    this.setState({ showCompleted: true })
    // wait for animation to play
    await delay(2000)
    // dismiss the modal
    this.props.handleDismiss()
  }
}

export const Survey = (props: ScreenPropsRoot<ScreenName.Survey>) => (
  <SurveyView {...props.route.params} handleDismiss={() => props.navigation.goBack()} navigation={props.navigation} />
)
