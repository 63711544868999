import { action, observable } from 'mobx'
import { AlertButton } from 'react-native'

class WebAlertStoreClass {
  @observable public showAlert: boolean = false
  @observable public title: string | undefined
  @observable public message: string | undefined
  @observable public buttons: AlertButton[] | undefined

  @action
  public setAlert = (title: string, message?: string, buttons?: AlertButton[]) => {
    this.showAlert = true
    this.title = title
    this.message = message
    this.buttons = buttons
  }

  @action
  public clearAlert = () => {
    this.showAlert = false
    this.title = undefined
    this.message = undefined
    this.buttons = undefined
  }
}

export const WebAlertStore = new WebAlertStoreClass()
