import {
  FontAwesomeIconStyle,
  IEstimateOutput,
  IEstimateService,
  IFareRedemptionStub,
  IFareStub,
} from '@sparelabs/api-client'
import { CurrencyHelper } from '@sparelabs/currency'
import { FareSemantics } from '@sparelabs/domain-logic'
import { toJS } from 'mobx'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { BackButton } from 'src/components/buttons/BackButton'
import { TextButton } from 'src/components/buttons/TextButton'
import { CardWrapper } from 'src/components/cards/CardWrapper'
import { FloatingCard } from 'src/components/cards/FloatingCard'
import { IFontAwesomeWrapperProps } from 'src/components/FontAwesomeIcon'
import { Header } from 'src/components/headers/Header'
import { CreateRequestButton } from 'src/components/rideOptions/CreateRequestButton'
import { EstimateCardButtonWrapper } from 'src/components/rideOptions/EstimateCardButtonWrapper'
import { EstimateCardWalkingDirections } from 'src/components/rideOptions/EstimateCardWalkingDirections'
import { onPressRequestCreation } from 'src/helpers/EstimateConfirmServiceCardHelper'
import {
  calculateDropoffTime,
  calculatePickupTime,
  getDropoffTimeString,
  getPickupTimeString,
} from 'src/helpers/EstimateHelper'
import { FareRenderer } from 'src/helpers/FareRenderer'
import { shouldShowRequestCustomFields } from 'src/helpers/RideOptionsCardHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ParamsListScheduledTripList, ScreenName } from 'src/navigation'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { IEstimateStore } from 'src/stores/EstimateStore'
import { RouterStore } from 'src/stores/RouterStore'
import { EstimatesUserInputParsed } from 'src/types'

const ICON_SIZE = 64
export const CARD_HEIGHT = 228

const getStyles = () =>
  StyleSheet.create({
    body: {
      flex: 1,
      padding: 16,
      marginBottom: 2,
    },
    infoContainer: {
      flexDirection: 'column',
      paddingTop: 8,
    },
    serviceInfo: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'space-between',
      alignItems: 'center',
    },
    buttonWrapper: {
      paddingHorizontal: 16,
      marginBottom: 16,
    },
    fareText: {
      fontSize: 16,
      color: colors.gray90,
      lineHeight: 20,
    },
    discount: {
      alignItems: 'flex-end',
      flexBasis: 70,
      lineHeight: 20,
    },
    cost: {
      color: colors.gray70,
      textDecorationLine: 'line-through',
      fontSize: 16,
      marginLeft: 4,
    },
    iconWrapper: {
      alignItems: 'center',
      marginTop: 16,
    },
    serviceIcon: {
      height: ICON_SIZE,
      width: ICON_SIZE,
      backgroundColor: 'white',
      borderRadius: ICON_SIZE / 2,
    },
    text: {
      color: colors.gray70,
      fontSize: 16,
      lineHeight: 20,
    },
    description: {
      color: colors.gray70,
      fontSize: 16,
      marginTop: 4,
      marginBottom: 16,
      marginRight: 4,
      flex: 1,
      lineHeight: 20,
    },
    disabled: {
      color: colors.gray50,
      fontSize: 16,
    },
  })

interface IProps {
  handleNavigateDiscountDetails: (params: ParamsListRoot[ScreenName.DiscountDetails]) => void
  handleNavigateScheduleTrip: (params: ParamsListScheduledTripList[ScreenName.ScheduledTrip]) => void
  handleNavigateRequestCustomFields: (params: ParamsListRoot[ScreenName.RequestCustomFields]) => void
  selectedRideOptionId: string | null
  estimateStore: IEstimateStore
  estimateInput: EstimatesUserInputParsed
  estimateInputStore: IEstimateInputStore
  confirmServicePathname: string
}

@observer
export class EstimateConfirmServiceCard extends Component<IProps> {
  public interval: NodeJS.Timeout | null = null

  public async componentDidMount() {
    this.startRefreshingEstimates()
  }

  public componentWillUnmount() {
    this.stopRefreshingEstimates()
  }

  public startRefreshingEstimates() {
    this.interval = setInterval(() => {
      // TODO: Change this to only refetch estimate for this service
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.props.estimateStore.fetchEstimates(this.props.estimateInput)
    }, 20000)
  }

  public stopRefreshingEstimates() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  public getService = () => this.props.estimateStore.getEstimateServiceById(this.props.selectedRideOptionId)

  public renderDropoffRange(estimate: IEstimateOutput) {
    const styles = getStyles()
    const dropoffTimes = calculateDropoffTime(estimate)
    if (!dropoffTimes) {
      return null
    }
    const { dropoffTimeHigh, dropoffTimeLow, dropoffTime } = dropoffTimes
    return (
      <Text style={styles.text}>
        {getDropoffTimeString(
          dropoffTimeLow,
          dropoffTimeHigh,
          dropoffTime,
          this.getService()?.serviceShowPickupDropoffWindows ?? false
        )}
      </Text>
    )
  }

  public renderPickupRange(estimate: IEstimateOutput) {
    const styles = getStyles()
    const pickupTimes = calculatePickupTime(estimate)
    if (!pickupTimes) {
      return null
    }
    const { pickupTimeLow, pickupTimeHigh, pickupTime } = pickupTimes
    return (
      <Text style={styles.text}>
        {getPickupTimeString(
          pickupTimeLow,
          pickupTimeHigh,
          pickupTime,
          this.getService()?.serviceShowPickupDropoffWindows ?? false
        )}
      </Text>
    )
  }

  public renderServiceInfoContainer(service: IEstimateService, estimate: IEstimateOutput | null | undefined) {
    const styles = getStyles()
    return (
      <View style={styles.infoContainer}>
        <Header headerText={service.serviceName} style={[estimate ? null : { color: colors.gray50 }]} />
        <Text style={styles.description} numberOfLines={2}>
          {service.serviceDescription}
        </Text>

        {estimate ? (
          <>
            <View style={[styles.serviceInfo, { marginBottom: 4 }]}>
              {this.renderPickupRange(estimate)}
              {this.renderPrice(estimate)}
            </View>

            <View style={styles.serviceInfo}>
              {this.renderDropoffRange(estimate)}
              {FareSemantics.hasDiscount(estimate) && this.renderDiscountInfo(estimate)}
            </View>
          </>
        ) : (
          <View style={styles.serviceInfo}>
            <Text style={styles.disabled}>{st.components.rideOptionsCard.noRidesAvailable()}</Text>
          </View>
        )}
      </View>
    )
  }

  public renderCardBody(service: IEstimateService | null, estimate: IEstimateOutput | null | undefined) {
    const styles = getStyles()
    const isEstimateUpdating = (service && this.props.estimateStore.loadingStore.isLoading(service.serviceId)) ?? false
    return (
      <FloatingCard informationBanner={estimate ? <EstimateCardWalkingDirections estimate={estimate} /> : undefined}>
        <View style={styles.body}>
          <View style={styles.iconWrapper}>
            <Image style={styles.serviceIcon} source={{ uri: service?.serviceBrand.photoUrl ?? '' }} />
          </View>
          {service && this.renderServiceInfoContainer(service, estimate)}
        </View>
        <EstimateCardButtonWrapper>
          <CreateRequestButton
            isEstimateUpdating={isEstimateUpdating}
            service={this.getService()}
            handleOnPressRequest={() =>
              onPressRequestCreation(
                this.props.selectedRideOptionId,
                this.props.estimateStore,
                this.props.estimateInputStore,
                this.props.handleNavigateScheduleTrip
              )
            }
            selectedEstimate={estimate ?? null}
            estimateInput={this.props.estimateInput}
          />
        </EstimateCardButtonWrapper>
      </FloatingCard>
    )
  }

  public render() {
    const service = this.getService()

    const { estimateStore, selectedRideOptionId } = this.props
    const estimate = selectedRideOptionId ? estimateStore.estimateResponseMap.get(selectedRideOptionId) : null
    const onPressGoBack = async () => {
      if (await shouldShowRequestCustomFields()) {
        this.props.handleNavigateRequestCustomFields({
          estimateInputStore: this.props.estimateInputStore,
          estimateStore: this.props.estimateStore,
          confirmServicePathname: this.props.confirmServicePathname,
        })
      }
      await RouterStore.returnToPreviousScreen()
    }

    return (
      <CardWrapper containerStyle={{ bottom: 0 }}>
        <View pointerEvents='box-none'>
          <BackButton onPress={onPressGoBack} />
        </View>
        {this.renderCardBody(service, estimate)}
      </CardWrapper>
    )
  }

  private renderPrice(estimate: IEstimateOutput) {
    const styles = getStyles()
    return (
      <Text style={styles.fareText}>
        {FareSemantics.requiresPayment(estimate)
          ? FareRenderer.renderPriceAmount(estimate)
          : FareRenderer.renderZeroFare(estimate)}
      </Text>
    )
  }

  private renderDiscountInfo({ fare, fareRedemptions }: IEstimateOutput) {
    const styles = getStyles()
    const iconProps: IFontAwesomeWrapperProps = {
      icon: [FontAwesomeIconStyle.Solid, 'info-circle'],
      size: 16,
      color: colors.gray70,
    }

    return (
      <View style={styles.discount}>
        <TextButton
          onPress={() => this.handleOnPressDiscountInfo(fare, fareRedemptions)}
          containerStyle={{ flexDirection: 'row', alignItems: 'center' }}
          text={CurrencyHelper.format(fare.cost, fare.currency)}
          textStyle={styles.cost}
          leftIconProps={iconProps}
        />
      </View>
    )
  }

  private readonly handleOnPressDiscountInfo = (fare: IFareStub, fareRedemptions: IFareRedemptionStub[]) => {
    this.props.handleNavigateDiscountDetails({
      fare: toJS(fare),
      fareRedemptions: toJS(fareRedemptions),
    })
  }
}
