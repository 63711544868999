import { logEvent } from 'expo-firebase-analytics'
import { getApps } from 'firebase/app'
import { Mixpanel } from 'src/helpers/Mixpanel'
import { IEventProps } from 'src/lib/ExpoMixpanelAnalytics'

// Follow Mixpanel event naming conventions: https://www.notion.so/sparelabs/Mixpanel-Naming-Conventions-ff32a1723bcd42aba4ae46bf2542763b
export enum EventName {
  AppLoad = 'app_load',
  ExpoUpdateStatus = 'expo_update_status',
  ScreenView = 'screen_view',
  ContinueJourney = '*Press* RiderApp | ContinueJourney-Button',
  JourneyRequestRide = '*Press* RiderApp | JourneyRequestRide-Button',
}

export class AnalyticsHelper {
  public static track = (name: EventName, props: IEventProps = {}): void => {
    Mixpanel.track(name, props)
  }

  public static setScreen = async (screen: string): Promise<void> => {
    /**
     * Check if Firebase has been initialized by Expo
     */
    if (getApps().length) {
      await logEvent(EventName.ScreenView, { screen_name: screen, page_title: screen })
    }
    AnalyticsHelper.track(EventName.ScreenView, { screen })
  }
}
