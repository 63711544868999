import { IRequestResponse, ITipPolicyResponse } from '@sparelabs/api-client'
import { CurrencyHelper } from '@sparelabs/currency'
import { isNumber } from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { ActivityIndicator, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Animations } from 'src/assets/Animations'
import { colors } from 'src/assets/colors'
import { ButtonWrapper } from 'src/components/buttons/ButtonWrapper'
import { OptionButton, OptionButtonPosition } from 'src/components/buttons/OptionButton'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import { LottieAnimation } from 'src/components/lottieAnimation/LottieAnimation'
import { ProfileImage } from 'src/components/ProfileImage'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { handleError } from 'src/helpers/ErrorHelpers'
import { TipHelper } from 'src/helpers/TipHelper'
import { useNavigationPreventBackButton } from 'src/hooks/useNavigationPreventBackButton'
import { st } from 'src/locales'
import { ParamsListReview, ScreenName, ScreenPropsReview } from 'src/navigation'
import { TipOptionButton } from 'src/screens/tip/TipOptionButton'
import { TipProgressBar } from 'src/screens/tip/TipProgressBar'
import { LoadingStore } from 'src/stores/LoadingStore'
import { ITipPolicyStore, TipPolicyStore } from 'src/stores/TipPolicyStore'
import { ITipStore, TipStore } from 'src/stores/TipStore'

const styles = StyleSheet.create({
  safeAreaContainer: {
    flex: 1,
    backgroundColor: colors.white,
  },
  tipBackground: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: colors.blue10,
  },
  container: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 16,
    paddingBottom: 24,
  },
  tipButtonsWrapper: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  tipOptionsWrapper: {
    flexDirection: 'row',
  },
  title: {
    fontSize: 21,
    fontWeight: '700',
    color: colors.gray90,
    paddingTop: 16,
  },
  subtitle: {
    fontSize: 16,
    color: colors.gray70,
    paddingTop: 8,
    paddingBottom: 40,
  },
  selected: {
    backgroundColor: colors.blue50,
    color: 'white',
  },
  custom: {
    color: colors.blue50,
    fontSize: 16,
    textAlign: 'center',
    paddingTop: 16,
  },
  sendTipButton: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.textLight,
    paddingTop: 10,
  },
  thankYouContainer: {
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingVertical: 20,
  },
  thankYouText: {
    color: colors.textMedium,
    fontSize: 22,
  },
  customContainer: {
    backgroundColor: colors.blue10,
    width: '100%',
    flexDirection: 'row',
    padding: 16,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  amount: {
    color: colors.gray90,
    fontSize: 16,
  },
  tapArea: {
    padding: 8,
  },
})

interface ITipState {
  tipAmount: number | null
  isCustom: boolean
  displayThankMessage: boolean
  tipPolicy: ITipPolicyResponse | null
  selectedButton: TipButtonKey | null
}

export enum TipButtonKey {
  SmallestSuggestion = 'smallest',
  MediumSuggestion = 'medium',
  LargestSuggestion = 'largest',
  NoTip = 'no-tip',
}

type Props = ParamsListReview[ScreenName.AddTip] & {
  tipStore: ITipStore
  tipPolicyStore: ITipPolicyStore
  handleNavigateRootHome: () => void
  handleNavigateCustomTip: (params: ParamsListReview[ScreenName.CustomTipScreen]) => void
}

@observer
export class AddTipView extends Component<Props, ITipState> {
  private readonly loadingStore = new LoadingStore()

  constructor(props: Props) {
    super(props)

    this.state = {
      tipAmount: null,
      isCustom: false,
      displayThankMessage: false,
      tipPolicy: null,
      selectedButton: TipButtonKey.SmallestSuggestion,
    }
  }

  public async componentDidMount() {
    const { request, tipPolicyStore } = this.props

    const tipPolicy: ITipPolicyResponse | null = await this.loadingStore.execute(
      tipPolicyStore.getTipPolicyResponse(request),
      'get-tip-policy'
    )

    if (tipPolicy) {
      this.setState({
        tipPolicy,
        // Set the default tipAmount to be the smallest value, as this is the button selected initially
        tipAmount: TipHelper.getButtonTipValue(
          request.fare,
          tipPolicy,
          tipPolicy.smallestTipAmountSuggestion,
          tipPolicy.smallestTipPercentageSuggestion
        ),
      })
    }
  }

  public handleTipButtonSelect = (key: TipButtonKey, amount: number) => {
    this.setState({
      tipAmount: amount,
      isCustom: false,
      selectedButton: key,
    })
  }

  public handleTipSubmit = async () => {
    const { request, tipStore } = this.props

    if (!isNumber(this.state.tipAmount)) {
      return
    }

    try {
      const query = {
        amount: this.state.tipAmount,
        currency: request.fare.currency,
      }
      await this.loadingStore.execute(tipStore.createTip(request.id, query))
      this.setState({
        displayThankMessage: true,
      })
      // We add a timeout so allow time for Promise to resolve and render the thank you message,
      // similar pattern as in ReviewScreen
      await new Promise((resolve) => setTimeout(resolve, 2500))
      this.props.handleNavigateRootHome()
    } catch (error) {
      handleError({ error, silent: true })
      AlertHelper.alert(
        st.screens.tip.tipErrorTitle(),
        error.message ?? st.screens.tip.tipErrorMessage(),
        [
          {
            text: st.common.alertOk(),
          },
        ],
        { cancelable: false }
      )
    }
  }

  public handleCustomAmount = async () => {
    const { request } = this.props
    this.props.handleNavigateCustomTip({
      fare: toJS(request.fare),
      handleAddTip: this.handleCustomTip,
      title: TipHelper.getTipScreenTitle(request, true),
      canRiderTip: true,
    })
  }

  public handleCustomTip = async (amount: number) => {
    this.setState({
      tipAmount: amount,
      isCustom: true,
      selectedButton: null,
    })
  }

  public renderCustomAmount = (amount: number) => (
    <View style={styles.customContainer}>
      <Text style={styles.amount}>{CurrencyHelper.format(amount, this.props.request.fare.currency)}</Text>
      <TouchableOpacity onPress={this.dismissCustom} style={styles.tapArea}>
        <FontAwesomeIconWrapper icon='times' color={colors.gray90} size={20} />
      </TouchableOpacity>
    </View>
  )

  public renderTipButtons = (tipPolicy: ITipPolicyResponse | null, request: IRequestResponse) => {
    if (this.loadingStore.isLoading('get-tip-policy') || !tipPolicy) {
      return <ActivityIndicator color='black' />
    }

    return (
      <View style={styles.tipButtonsWrapper}>
        <View style={styles.tipOptionsWrapper} testID='tipOptionsWrapper'>
          <TipOptionButton
            onHandleSelected={this.handleTipButtonSelect}
            tipAmount={TipHelper.getButtonTipValue(
              request.fare,
              tipPolicy,
              tipPolicy.smallestTipAmountSuggestion,
              tipPolicy.smallestTipPercentageSuggestion
            )}
            percentage={
              TipHelper.shouldShowPercentage(request.fare.cost, tipPolicy)
                ? tipPolicy.smallestTipPercentageSuggestion
                : null
            }
            position={OptionButtonPosition.First}
            buttonKey={TipButtonKey.SmallestSuggestion}
            currency={request.fare.currency}
            selected={this.state.selectedButton === TipButtonKey.SmallestSuggestion}
          />
          <TipOptionButton
            onHandleSelected={this.handleTipButtonSelect}
            tipAmount={TipHelper.getButtonTipValue(
              request.fare,
              tipPolicy,
              tipPolicy.mediumTipAmountSuggestion,
              tipPolicy.mediumTipPercentageSuggestion
            )}
            percentage={
              TipHelper.shouldShowPercentage(request.fare.cost, tipPolicy)
                ? tipPolicy.mediumTipPercentageSuggestion
                : null
            }
            position={OptionButtonPosition.Middle}
            buttonKey={TipButtonKey.MediumSuggestion}
            currency={request.fare.currency}
            selected={this.state.selectedButton === TipButtonKey.MediumSuggestion}
          />
          <TipOptionButton
            onHandleSelected={this.handleTipButtonSelect}
            tipAmount={TipHelper.getButtonTipValue(
              request.fare,
              tipPolicy,
              tipPolicy.largestTipAmountSuggestion,
              tipPolicy.largestTipPercentageSuggestion
            )}
            percentage={
              TipHelper.shouldShowPercentage(request.fare.cost, tipPolicy)
                ? tipPolicy.largestTipPercentageSuggestion
                : null
            }
            position={OptionButtonPosition.Middle}
            buttonKey={TipButtonKey.LargestSuggestion}
            currency={request.fare.currency}
            selected={this.state.selectedButton === TipButtonKey.LargestSuggestion}
          />
          <OptionButton
            title={st.screens.tip.noTip()}
            onHandleSelected={() => this.handleTipButtonSelect(TipButtonKey.NoTip, 0)}
            position={OptionButtonPosition.Last}
            buttonKey={TipButtonKey.NoTip}
            selected={this.state.selectedButton === TipButtonKey.NoTip}
          />
        </View>
        <TouchableOpacity onPress={this.handleCustomAmount}>
          <Text style={styles.custom}>{st.screens.tip.customAmount()}</Text>
        </TouchableOpacity>
      </View>
    )
  }

  public dismissCustom = () => {
    this.setState({
      tipAmount: 0,
      isCustom: false,
    })
  }

  public render() {
    return this.state.displayThankMessage ? this.renderThankYouMessage() : this.renderTipOptions()
  }

  public renderTipOptions() {
    const { request } = this.props

    return (
      <SafeAreaView style={styles.safeAreaContainer}>
        <View style={styles.container}>
          {request.driver && <ProfileImage height={160} user={request.driver} />}
          <Text style={styles.title}>
            {st.screens.tip.addATipForDriver({ driver: request.driver?.firstName ?? st.screens.tip.yourDriver() })}
          </Text>
          <Text style={styles.subtitle}>{st.screens.tip.showYourAppreciation()}</Text>
          {this.state.isCustom && this.state.tipAmount
            ? this.renderCustomAmount(this.state.tipAmount)
            : this.renderTipButtons(this.state.tipPolicy, request)}
        </View>

        <ButtonWrapper>
          <TipProgressBar firstStep={false} />
          <PrimaryButton
            title={st.common.done()}
            onPress={this.handleTipSubmit}
            disabled={
              (!this.state.isCustom && this.state.selectedButton === null && this.state.tipAmount !== null) ||
              this.loadingStore.isLoading()
            }
            loading={this.loadingStore.isLoading()}
          />
        </ButtonWrapper>
      </SafeAreaView>
    )
  }

  private readonly renderThankYouMessage = () => (
    <View style={styles.thankYouContainer}>
      <LottieAnimation source={Animations.confirmation} autoPlay={true} loop={false} width='60%' maxWidth={400} />
      <Text style={styles.thankYouText}>{st.screens.tip.thankYouMessage()}</Text>
    </View>
  )
}

export const AddTip = (props: ScreenPropsReview<ScreenName.AddTip>) => {
  useNavigationPreventBackButton(() => true, props.navigation, [])
  return (
    <AddTipView
      {...props.route.params}
      tipStore={TipStore}
      tipPolicyStore={TipPolicyStore}
      handleNavigateRootHome={() => {
        props.navigation.navigate(ScreenName.RootHome, {})
      }}
      handleNavigateCustomTip={(params) => {
        props.navigation.navigate(ScreenName.CustomTipScreen, params)
      }}
    />
  )
}
