import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Region } from 'react-native-maps'
import { colors } from 'src/assets/colors'
import { BackButton } from 'src/components/buttons/BackButton'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { CardWrapper } from 'src/components/cards/CardWrapper'
import { FloatingCard } from 'src/components/cards/FloatingCard'
import { Header } from 'src/components/headers/Header'
import { Map } from 'src/components/map/Map'
import { MapServiceAreas } from 'src/components/mapMarkers/MapServiceAreas'
import { EstimateCardButtonWrapper } from 'src/components/rideOptions/EstimateCardButtonWrapper'
import { SetLocationPin } from 'src/components/SetLocationPin'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { fetchAddress } from 'src/helpers/EstimateHelper'
import { ParseEstimateHelper } from 'src/helpers/ParseEstimateHelper'
import { st } from 'src/locales'
import { ROUTER_CONTENT_PADDING_WEB } from 'src/screens/HomeRootHelper'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { LoadingStore } from 'src/stores/LoadingStore'
import { LocationStore } from 'src/stores/LocationStore'
import { MapStoreClass } from 'src/stores/MapStore'
import { RouterStore } from 'src/stores/RouterStore'
import { SearchFieldType, SearchScreenStore } from 'src/stores/SearchScreenStore'
import { SetLocationStore } from 'src/stores/SetLocationStore'
import { UIStateStore } from 'src/stores/UIStore'
import { IEstimatesUserInput } from 'src/types'
import { Pathname } from 'src/types/homeRoot'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cardTextContent: {
    paddingHorizontal: 16,
  },
  subtitle: {
    color: colors.gray70,
    fontSize: 16,
  },
  locationContainer: {
    paddingVertical: 16,
    marginRight: 32,
    flexDirection: 'row',
  },
  dot: {
    width: 8,
    height: 8,
    margin: 6,
    marginRight: 12,
  },
  location: {
    color: colors.gray90,
    fontSize: 16,
    fontWeight: 'bold',
    flexWrap: 'wrap',
    height: 40,
  },
})

interface IProps {
  estimateUserInput: IEstimatesUserInput
  estimateInputStore: IEstimateInputStore
  handleClearEstimateServices: () => void
}

interface IState {
  mapStore: MapStoreClass
  setLocationStore: SetLocationStore
  loadingSuggestion: boolean
  isDragging: boolean
  addressName: string | null
}

@observer
export class ConfirmOriginScreen extends Component<IProps, IState> {
  public loadingStore = new LoadingStore()

  constructor(props: IProps) {
    super(props)
    this.state = {
      mapStore: new MapStoreClass(),
      setLocationStore: new SetLocationStore(),
      loadingSuggestion: false,
      isDragging: false,
      addressName: this.props.estimateUserInput.requestedPickupAddress,
    }
  }

  public async componentDidMount() {
    if (!this.state.addressName) {
      const geocodedAddress = await fetchAddress(this.props.estimateUserInput.requestedPickupLocation)
      this.setState({
        addressName: geocodedAddress,
      })
    }
  }

  public setMapDragging = async () => {
    this.setState({ loadingSuggestion: true, isDragging: true })
  }

  public onRegionChangeComplete = async (region: Region) => {
    if (this.state.isDragging) {
      this.setState({
        isDragging: false,
      })
    } else {
      return
    }

    this.state.mapStore.setMapRegion(region)
    await this.state.setLocationStore.setMapRegionSuggestion(region)
    this.setState({
      loadingSuggestion: false,
      addressName: this.state.setLocationStore.autoSuggestion.mapRegionSuggestion?.name ?? null,
    })
  }

  public getLocationValue = () => {
    if (this.state.loadingSuggestion) {
      return st.screens.search.loading()
    }
    return this.state.addressName
  }

  public getUpdatedEstimateInput = () => {
    const updatedEstimateInput = this.state.setLocationStore.autoSuggestion.mapRegionSuggestion
      ? {
          ...this.props.estimateUserInput,
          requestedPickupAddress: this.state.setLocationStore.autoSuggestion.mapRegionSuggestion.name,
          requestedPickupLocation: this.state.setLocationStore.autoSuggestion.mapRegionSuggestion.location,
        }
      : this.props.estimateUserInput
    return updatedEstimateInput
  }

  public handlePressConfirm = async () => {
    this.props.handleClearEstimateServices()

    // get updated estimate input in the case of user moving origin pin
    const estimateInput = this.getUpdatedEstimateInput()
    const parsedEstimateInput = await ParseEstimateHelper.parseEstimate(estimateInput)
    if (parsedEstimateInput) {
      this.props.estimateInputStore.updateEstimateInput(parsedEstimateInput)
    }

    await RouterStore.replaceScreen({ pathname: Pathname.RideOptions })
  }

  public renderCard() {
    return (
      <CardWrapper
        containerStyle={{ bottom: 0, marginLeft: UIStateStore.shouldShowLandscapeWeb ? ROUTER_CONTENT_PADDING_WEB : 0 }}
      >
        <BackButton onPress={this.goBackFromConfirmPickup} />
        <FloatingCard>
          <View style={styles.cardTextContent}>
            <Header headerText={st.screens.confirmLocation.confirmStartLocation()} />
            <Text accessible={false} importantForAccessibility='no' style={styles.subtitle}>
              {st.screens.confirmLocation.dragMap()}
            </Text>
            <View style={styles.locationContainer}>
              <View style={styles.dot}>
                <SvgIconWrapper icon={SvgIcon.StartPin} widthFixed={8} heightFixed={8} />
              </View>
              <Text style={styles.location} numberOfLines={2}>
                {this.getLocationValue()}
              </Text>
            </View>
          </View>
          <EstimateCardButtonWrapper>
            <PrimaryButton
              title={st.screens.confirmLocation.confirmButton()}
              onPress={this.handlePressConfirm}
              disabled={this.state.loadingSuggestion}
            />
          </EstimateCardButtonWrapper>
        </FloatingCard>
      </CardWrapper>
    )
  }

  public goBackFromConfirmPickup = async () => {
    SearchScreenStore.clearField(SearchFieldType.Destination)
    SearchScreenStore.setCurrentSearchField(SearchFieldType.Destination)
    await RouterStore.returnToPreviousScreen()
  }

  public render() {
    const latitude = this.props.estimateUserInput.requestedPickupLocation?.coordinates
      ? this.props.estimateUserInput.requestedPickupLocation.coordinates[1]
      : null
    const longitude = this.props.estimateUserInput.requestedPickupLocation?.coordinates
      ? this.props.estimateUserInput.requestedPickupLocation.coordinates[0]
      : null

    return (
      <View key='route-container' style={styles.container} pointerEvents='box-none'>
        {latitude && longitude && (
          <>
            <View
              style={styles.container}
              accessibilityElementsHidden={true}
              importantForAccessibility='no-hide-descendants'
            >
              <Map
                key='confirm-origin-map'
                latitude={latitude}
                longitude={longitude}
                isLocationPermissionGranted={LocationStore.isGranted()}
                onPanDrag={this.setMapDragging}
                onRegionChangeComplete={this.onRegionChangeComplete}
                latitudeDelta={0.0031} // We set this value to a lower number of the default, so we render the map zoomed in
              >
                <MapServiceAreas mapRegion={this.state.mapStore.mapRegion} hideStops={false} />
              </Map>
            </View>
            {this.renderCard()}
            <SetLocationPin />
          </>
        )}
      </View>
    )
  }
}
