import {
  IRequestAccessibilityFeature,
  IRequestResponse,
  IRequestRiderType,
  IVehicleResponse,
} from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { colors } from 'src/assets/colors'
import { RequestPaymentInfo } from 'src/components/request/RequestPaymentInfo'
import { TripTimeline } from 'src/components/request/TripTimeline'
import { RequestHelper } from 'src/helpers/RequestHelper'
import { st } from 'src/locales'
import { LanguageHelper } from 'src/locales/LanguageHelper'
import { RequestCancelButton } from 'src/screens/request/RequestCancelButton'
import { PRE_PICKUP_REQUEST_STATUSES } from 'src/stores/RequestStore'
import { ServiceStore } from 'src/stores/ServiceStore'

const getStyles = () =>
  StyleSheet.create({
    cancelContainer: {
      padding: 16,
    },
    divider: {
      borderTopWidth: 1,
      borderTopColor: colors.borderGray,
    },
    container: {
      paddingTop: 16,
      paddingBottom: 8,
      paddingHorizontal: 16,
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderTopWidth: 1,
      borderTopColor: colors.borderGray,
    },
    infoTitle: {
      textTransform: 'uppercase',
      fontSize: 14,
      fontWeight: 'bold',
      color: colors.gray70,
    },
    infoContent: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    text: {
      flex: 1,
      paddingVertical: 8,
      color: colors.gray90,
    },
    link: {
      flex: 1,
      padding: 16,
      paddingRight: 0,
      color: colors.blue50,
    },
  })

interface IProps {
  request: IRequestResponse
  vehicle: IVehicleResponse | null
  handleNavigateDiscountDetails: () => void
  handleNavigateRequestCancellation: () => void
  handleNavigateRequestAccessibilityOptions: () => void
  handleNavigateRequestRiderOptions: () => void
}

@observer
export class RequestCardContent extends Component<IProps> {
  public renderRiders = (riders: IRequestRiderType[]): string =>
    riders
      .map((rider) => {
        const riderType = LanguageHelper.getLocalizedNoun(RequestHelper.riderTypeToString(rider))
        return LanguageHelper.getFormattedCount(riderType, rider.count)
      })
      .join(', ')

  public renderRiderContainer(request: IRequestResponse) {
    const styles = getStyles()
    return (
      <View style={styles.container}>
        <Text style={styles.infoTitle}>{st.components.requestCard.ridersInfo.ridersLabel()}</Text>
        {request.riders && (
          <View style={styles.infoContent}>
            <Text style={styles.text}>{this.renderRiders(request.riders)}</Text>
            {PRE_PICKUP_REQUEST_STATUSES.includes(request.status) && (
              <TouchableOpacity onPress={this.props.handleNavigateRequestRiderOptions}>
                <Text style={styles.link}>{st.components.requestCard.ridersInfo.editLabel()}</Text>
              </TouchableOpacity>
            )}
          </View>
        )}
      </View>
    )
  }

  public renderAccessibilityRequirements = (features: IRequestAccessibilityFeature[]): string => {
    if (features.length) {
      return features
        .map((feature) => {
          const accessibilityFeature = LanguageHelper.getLocalizedNoun(RequestHelper.accessibilityTypeToString(feature))
          return LanguageHelper.getFormattedCount(accessibilityFeature, feature.count)
        })
        .join(', ')
    }
    return st.components.requestCard.accessibilityInfo.noRequirements()
  }

  public renderAccessibilityContainer(request: IRequestResponse) {
    const styles = getStyles()
    return (
      <View style={styles.container}>
        <Text style={styles.infoTitle}>{st.components.requestCard.accessibilityInfo.accessibilityLabel()}</Text>
        <View style={styles.infoContent}>
          <Text style={styles.text}>{this.renderAccessibilityRequirements(request.accessibilityFeatures)}</Text>
          {PRE_PICKUP_REQUEST_STATUSES.includes(request.status) && (
            <TouchableOpacity onPress={this.props.handleNavigateRequestAccessibilityOptions}>
              <Text style={styles.link}>{st.components.requestCard.accessibilityInfo.editLabel()}</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    )
  }

  public renderInfoPanel() {
    const styles = getStyles()
    const { request } = this.props
    const service = ServiceStore.servicesMap.get(request.serviceId)
    return (
      <View>
        <View style={styles.divider} />
        <TripTimeline request={request} />
        {this.renderRiderContainer(request)}
        {service && service.accessibilityFeatures?.length > 0 ? this.renderAccessibilityContainer(request) : null}
        <RequestPaymentInfo
          request={request}
          handleNavigateDiscountDetails={this.props.handleNavigateDiscountDetails}
        />
      </View>
    )
  }

  public renderCancelButton() {
    const styles = getStyles()
    const { request } = this.props
    if (PRE_PICKUP_REQUEST_STATUSES.includes(request.status)) {
      return (
        <>
          <View style={styles.divider} />
          <View style={styles.cancelContainer}>
            <RequestCancelButton
              handleNavigateRequestCancellation={() => this.props.handleNavigateRequestCancellation()}
            />
          </View>
        </>
      )
    }
    return null
  }

  public render() {
    return (
      <SafeAreaView edges={['bottom']}>
        <View style={{ marginBottom: 16 }}>
          {this.renderInfoPanel()}
          {this.props.children}
          {this.renderCancelButton()}
        </View>
      </SafeAreaView>
    )
  }
}
