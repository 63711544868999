import { BottomSheetScrollView } from '@gorhom/bottom-sheet'
import { IOrganizationResponse, IRequestResponse, IVehicleResponse, RequestStatus } from '@sparelabs/api-client'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Image, Linking, Platform, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import {
  AccessibleBottomSheetPosition,
  AccessibleBottomSheetView,
} from 'src/components/accessibleBottomSheet/AccessibleBottomSheetView'
import { AbstractButton } from 'src/components/buttons/AbstractButton'
import { BackButton } from 'src/components/buttons/BackButton'
import { ProfileImage } from 'src/components/ProfileImage'
import { RequestCardContent } from 'src/components/request/RequestCardContent'
import { WalkingDirections } from 'src/components/request/WalkingDirections'
import { TouchableListItemWrapper } from 'src/components/touchableListItemWrapper/TouchableListItemWrapper'
import { MapMarkerHelper } from 'src/helpers/MapMarkerHelper'
import { RequestHelper } from 'src/helpers/RequestHelper'
import { st } from 'src/locales'
import { ROUTER_CONTENT_MAX_WIDTH } from 'src/screens/HomeRootHelper'
import { AboutRide } from 'src/screens/request/AboutRide'
import { ServiceStore } from 'src/stores/ServiceStore'
import { UIStateStore } from 'src/stores/UIStore'
import { OsType } from 'src/util/types'
import { RequestCardHeader } from './RequestCardHeader'

const IMAGE_DIAMETER = 64
export const TOP_OFFSET = 16
export const CLOSED_CARD_HEIGHT = 170

const getStyles = () =>
  StyleSheet.create({
    card: {
      backgroundColor: colors.white,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      shadowColor: colors.darkShadowColor,
      shadowOffset: {
        width: 0,
        height: 3,
      },
      shadowOpacity: 0.34,
      shadowRadius: 6.27,
      elevation: 4,
    },
    handleIndicatorStyle: {
      width: 50,
    },
    container: {
      paddingHorizontal: 16,
    },
    requestBodyContainer: {
      paddingVertical: 12,
      width: '100%',
      height: 'auto',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
    },
    infoContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    title: {
      color: colors.gray90,
      fontWeight: '600',
      fontSize: 16,
    },
    paddedTitle: {
      color: colors.gray90,
      fontWeight: '600',
      fontSize: 16,
      paddingBottom: 5,
    },
    subtitle: {
      color: colors.textMedium,
      fontSize: 14,
    },
    serviceIcon: {
      marginLeft: 10,
      height: IMAGE_DIAMETER,
      width: IMAGE_DIAMETER,
      borderRadius: IMAGE_DIAMETER / 2,
    },
    divider: {
      borderBottomWidth: 1,
      borderBottomColor: colors.borderGray,
    },
    scroll: {
      paddingBottom: 24,
    },
    webOverride: {
      maxWidth: ROUTER_CONTENT_MAX_WIDTH,
      borderRadius: 8,
    },
    backButtonWrapper: {
      position: 'absolute',
      width: '100%',
      marginBottom: -8,
    },
    lyftLogo: {
      height: 24,
      width: 34,
      marginRight: 16,
    },
    aboutRide: {
      paddingVertical: 16,
    },
    rightArrow: {
      margin: 16,
      marginLeft: 'auto',
      ...(Platform.OS === OsType.Web && { width: 8, height: 13 }),
    },
    cell: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexBasis: 'auto',
      alignItems: 'center',
      paddingTop: 16,
    },
    infoBox: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })

enum RequestCardSnapPoints {
  Initial = '25%',
  Top = '90%',
}

interface IProps {
  organization: IOrganizationResponse
  request: IRequestResponse
  vehicle: IVehicleResponse | null
  handleNavigateDiscountDetails: () => void
  handleNavigateRequestCancellation: () => void
  handleNavigateRequestAccessibilityOptions: () => void
  handleNavigateRequestRiderOptions: () => void
  handleBackPress: () => void
}

export const RequestCard = observer((props: IProps) => {
  const [backButtonHeight, setBackButtonHeight] = useState<string>(RequestCardSnapPoints.Initial)
  const externalURL = props.request.externalUrl
  const styles = getStyles()

  const renderVehicleInfo = () => {
    const vehicle: IVehicleResponse | null = props.vehicle
    const request: IRequestResponse = props.request
    let title: string | null = null

    switch (request.status) {
      case RequestStatus.Accepted:
      case RequestStatus.Arriving:
      case RequestStatus.InProgress:
        if (vehicle) {
          title = `${vehicle.color} ${vehicle.make} ${vehicle.model}`
        }
        break
      case RequestStatus.Completed:
      case RequestStatus.Cancelled:
      default:
        break
    }
    if (title) {
      return (
        <View style={styles.requestBodyContainer}>
          <View style={styles.infoContainer}>
            <>
              <Text style={[styles.title, styles.paddedTitle]}>{title}</Text>
              <Text style={styles.subtitle}>{vehicle?.licensePlate}</Text>
            </>
          </View>
          <Image style={styles.serviceIcon} source={{ uri: request.serviceBrand.photoUrl }} />
        </View>
      )
    }
    return null
  }

  const renderDriverInfo = () => {
    const vehicle: IVehicleResponse | null = props.vehicle
    const request: IRequestResponse = props.request
    let title: string | null = null
    let vehicleInfo: string | null = null

    switch (request.status) {
      case RequestStatus.Accepted:
      case RequestStatus.Arriving:
      case RequestStatus.InProgress:
        if (request.driver && vehicle) {
          title = request.driver.firstName
          vehicleInfo = `${vehicle.color} ${vehicle.make} ${vehicle.model}`
        }
        break
      case RequestStatus.Completed:
      case RequestStatus.Cancelled:
      default:
        break
    }
    if (title) {
      return (
        <View style={styles.requestBodyContainer}>
          <View style={styles.infoContainer}>
            <>
              <Text style={[styles.title, styles.paddedTitle]}>{title}</Text>
              <Text style={styles.subtitle}>{vehicleInfo}</Text>
              <Text style={styles.subtitle}>{vehicle?.licensePlate}</Text>
            </>
          </View>
          {request.driver && <ProfileImage height={80} user={request.driver} />}
        </View>
      )
    }
    return null
  }

  const renderClosedCardContent = () => {
    const request: IRequestResponse = props.request
    const showDriverProfileToRiders: boolean =
      ServiceStore.servicesMap.get(props.request.serviceId)?.showDriverProfileToRiders ||
      request.isExternallyDispatched ||
      false

    const dropoffTime: string | null = RequestHelper.getEstimatedArrival(request)
    const dropoffTimeLatest: string | null = RequestHelper.getLatestArrival(request)
    if (dropoffTime !== null && dropoffTimeLatest !== null) {
      return showDriverProfileToRiders ? renderDriverInfo() : renderVehicleInfo()
    }

    return null
  }

  const renderHeader = () => (
    <View testID='requestCard'>
      <RequestCardHeader request={props.request} />
    </View>
  )

  const handleOnChangePosition = (position: AccessibleBottomSheetPosition) => {
    setBackButtonHeight(
      position === AccessibleBottomSheetPosition.Top ? RequestCardSnapPoints.Top : RequestCardSnapPoints.Initial
    )
  }

  const renderLyftInfoBox = () => (
    <View style={styles.container}>
      <View style={styles.infoBox}>
        <Image style={styles.lyftLogo} source={Images.lyftLogoPink} />
        <View style={{ flex: 1 }}>
          <Text>{st.components.requestCard.lyftRide.providerMsg()}</Text>
        </View>
      </View>
      {externalURL && (
        <View style={{ paddingVertical: 16 }}>
          <AbstractButton
            textColor={colors.pink70}
            title={st.components.requestCard.lyftRide.viewRideBtn()}
            backgroundColor={colors.pink10}
            onPress={() => Linking.openURL(externalURL)}
          />
        </View>
      )}
    </View>
  )

  const isLyftRequest = RequestHelper.isLyftRequest(props.request)

  return (
    <>
      <View
        style={[
          styles.backButtonWrapper,
          {
            bottom: backButtonHeight,
          },
        ]}
        pointerEvents='box-none'
      >
        <BackButton onPress={props.handleBackPress} />
      </View>
      <AccessibleBottomSheetView
        style={[styles.card, UIStateStore.shouldShowLandscapeWeb ? styles.webOverride : {}]}
        handleIndicatorStyle={[styles.handleIndicatorStyle]}
        enableContentPanningGesture={true}
        enableOverDrag={true}
        onChangePosition={handleOnChangePosition}
        snapPoints={[RequestCardSnapPoints.Initial, RequestCardSnapPoints.Top]}
      >
        {renderHeader()}
        <BottomSheetScrollView>
          {MapMarkerHelper.showStartLocation(props.request) && (
            <View style={styles.divider}>
              <WalkingDirections request={props.request} />
            </View>
          )}

          <View style={styles.container}>{renderClosedCardContent()}</View>

          {isLyftRequest && renderLyftInfoBox()}

          <RequestCardContent
            request={props.request}
            vehicle={props.vehicle}
            handleNavigateDiscountDetails={props.handleNavigateDiscountDetails}
            handleNavigateRequestCancellation={props.handleNavigateRequestCancellation}
            handleNavigateRequestAccessibilityOptions={props.handleNavigateRequestAccessibilityOptions}
            handleNavigateRequestRiderOptions={props.handleNavigateRequestRiderOptions}
          >
            {isLyftRequest && (
              <>
                <View style={[styles.divider, { paddingTop: 16 }]} />
                <View style={[styles.container, styles.aboutRide]}>
                  <AboutRide provider='Lyft' serviceName={props.request.serviceBrand.name} />
                  {externalURL && (
                    <TouchableListItemWrapper onPress={() => Linking.openURL(externalURL)} style={styles.cell}>
                      <View>
                        <Text style={{ fontSize: 16 }}>{st.components.requestCard.lyftRide.contactDriver()}</Text>
                      </View>
                      <Image style={styles.rightArrow} resizeMode='contain' source={Images.rightArrow} />
                    </TouchableListItemWrapper>
                  )}
                </View>
                <View style={styles.divider} />
              </>
            )}
          </RequestCardContent>
        </BottomSheetScrollView>
      </AccessibleBottomSheetView>
    </>
  )
})
