export enum AuthType {
  STANDARD = 'standard',
  SSO = 'sso',
}

/**
 * Note that whenever you change these, expo bundler needs to be restarted
 * to pick up the changes from app.json and these need to be defined inside of generate.app.config.ts
 */
export interface IExpoConstants {
  APP_NAME: string
  MOBILE_APP_ID: string
  API_HOST: string
  APP_LOGO_NAME: string
  SHOW_ALL_ORGANIZATIONS: boolean
  AUTH_TYPE: AuthType
  SSO_PROVIDER_NAME?: string
  SSO_DOMAIN?: string
  TRACK_MIXPANEL_EVENTS: boolean
  APP_STORE_TITLE: string
  PRIVACY_POLICY: string
  eas: {
    projectId: string | undefined
  }
}
