import { NavigationProp } from '@react-navigation/native'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { SecondaryButton } from 'src/components/buttons/SecondaryButton'
import { TextField } from 'src/components/textField/TextField'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'
import { ParamsListDeveloperMenu, ParamsListRoot, ScreenName, ScreenPropsDeveloperMenu } from 'src/navigation'
import { NavigationStateHelper } from 'src/navigation/consts/NavigationStateHelper'
import { HostStore } from '../../api/HostStore'
import { colors } from '../../assets/colors'
import { st } from '../../locales'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  containerCell: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 20,
  },
  description: {
    fontSize: 15,
    color: colors.textDark,
    paddingLeft: 20,
    paddingRight: 20,
    paddingVertical: 20,
  },
  buttonContainer: {
    marginTop: 10,
  },
})

type Props = ParamsListDeveloperMenu[ScreenName.DeveloperHostSelect] & {
  handleNavigateRootLogin: () => void
}

interface IState {
  hostUrl: string
}

@observer
export class DeveloperHostSelectView extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hostUrl: HostStore.getHost(),
    }
  }

  public render() {
    return (
      <ScrollView keyboardShouldPersistTaps='always' keyboardDismissMode='on-drag' style={styles.container}>
        {this.renderSetHost()}
      </ScrollView>
    )
  }

  private renderSetHost() {
    return (
      <View>
        <Text style={styles.description}>{st.screens.developerHostSelect.setHostDescription()}</Text>
        <View style={styles.containerCell}>
          <TextField
            label={st.screens.developerHostSelect.hostFieldLabel()}
            onChangeText={(setHostUrl) => this.setState({ hostUrl: setHostUrl.toLocaleLowerCase() })}
            tintColor={colors.primaryColor}
            keyboardType='url'
            placeholder={HostStore.getHost()}
            value={this.state.hostUrl}
          />
          <PrimaryButton
            title={st.common.buttonConfirm()}
            onPress={async () => {
              await this.setHost(this.state.hostUrl)
            }}
          />
          {HostStore.getPossibleHosts().map((host) => (
            <View key={host} style={styles.buttonContainer}>
              <SecondaryButton
                title={HostStore.isDefaultHost(host) ? st.screens.developerHostSelect.defaultHost({ host }) : host}
                onPress={() => this.setState({ hostUrl: host.toLocaleLowerCase() })}
              />
            </View>
          ))}
        </View>
      </View>
    )
  }

  private async setHost(host: string) {
    const currentHost = HostStore.getHost()
    try {
      HostStore.setHost(host)
      await AuthenticatorHelper.fetchGlobalApp()
      AuthenticatorHelper.clearOrLoginDataList()
      this.props.handleNavigateRootLogin()
    } catch (error) {
      HostStore.setHost(currentHost)
      handleError({ error: error as IErrorWithResponse })
    }
  }
}

export const DeveloperHostSelect = (props: ScreenPropsDeveloperMenu<ScreenName.DeveloperHostSelect>) => (
  <DeveloperHostSelectView
    {...props.route.params}
    handleNavigateRootLogin={() =>
      props.navigation
        .getParent<NavigationProp<ParamsListRoot>>()
        .reset({ routes: [NavigationStateHelper.getRootLoginRoutes()] })
    }
  />
)
