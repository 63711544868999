import { IEstimateOutput, SlotType } from '@sparelabs/api-client'
import { autorun, IReactionDisposer } from 'mobx'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { View } from 'react-native'
import { EdgePadding } from 'react-native-maps'
import { ErrorBoundary } from 'src/components/error/ErrorBoundary'
import { DrivingPolyline } from 'src/components/mapMarkers/DrivingPolyline'
import { DropoffLabelMarker } from 'src/components/mapMarkers/DropoffLabelMarker'
import { DropoffPinMarker } from 'src/components/mapMarkers/DropoffPinMarker'
import { DropoffRadius } from 'src/components/mapMarkers/DropoffRadius'
import { EndLabelMarker } from 'src/components/mapMarkers/EndLabelMarker'
import { EndPinMarker } from 'src/components/mapMarkers/EndPinMarker'
import { MapServiceAreas } from 'src/components/mapMarkers/MapServiceAreas'
import { PickupAreaPill } from 'src/components/mapMarkers/PickupAreaPill'
import { PickupLabelMarker } from 'src/components/mapMarkers/PickupLabelMarker'
import { PickupPinMarker } from 'src/components/mapMarkers/PickupPinMarker'
import { PickupRadius } from 'src/components/mapMarkers/PickupRadius'
import { StartPinMarker } from 'src/components/mapMarkers/StartPinMarker'
import { WalkingPolyline } from 'src/components/mapMarkers/WalkingPolyline'
import { WalkingPolylineInfoBuilder } from 'src/components/mapMarkers/WalkingPolylineInfoBuilder'
import { CARD_HEIGHT } from 'src/components/rideOptions/EstimateConfirmServiceCard'
import { MapMarkerHelper } from 'src/helpers/MapMarkerHelper'
import { MapStore } from 'src/stores/MapStore'
import { UIStateStore } from 'src/stores/UIStore'
import { EstimatesUserInputParsed } from 'src/types'

interface IProps {
  isLoading: boolean
  selectedEstimate: IEstimateOutput | null
  estimateInput: EstimatesUserInputParsed | null
  forceRenderPolyLine?: boolean
}

@observer
export class EstimateConfirmServiceMap extends Component<IProps> {
  private estimateAutorun: IReactionDisposer | null = null
  public componentDidMount() {
    MapStore.disableMovement = true
    MapStore.latitudeDeltaValue = 0.002
    this.estimateAutorun = autorun(() => {
      // When loading is set to false
      if (!this.props.isLoading) {
        const estimate = this.getActiveEstimate()
        if (estimate) {
          this.fitMapToMarkers(estimate)
        }
      }
    })
  }

  public componentWillUnmount() {
    if (this.estimateAutorun) {
      this.estimateAutorun()
    }
    MapStore.resetMap()
  }

  public getActiveEstimate(): IEstimateOutput | EstimatesUserInputParsed | null {
    return this.props.selectedEstimate ?? this.props.estimateInput
  }

  public getScheduledPickupTime(): number | null {
    if (this.props.selectedEstimate) {
      return this.props.selectedEstimate.estimatedPickupTime.ts
    }
    return null
  }

  public renderMapPolylineAndMarkers() {
    const estimate = this.getActiveEstimate()
    if (estimate === null) {
      return null
    }
    const locations = MapMarkerHelper.getAllCoordinates(estimate)
    const scheduledPickupTime = this.getScheduledPickupTime()
    const pickupLocation = MapMarkerHelper.getPickupLocation(estimate)
    const dropoffLocation = MapMarkerHelper.getDropoffLocation(estimate)
    const showEndLocation: boolean = MapMarkerHelper.showEndLocation(estimate)
    const forceRenderPolyLine = this.props.forceRenderPolyLine || false
    /* On web, the order of polylines and markers determine which gets rendered on top of one another */
    return (
      <>
        <ErrorBoundary>
          <DrivingPolyline
            pickupLocation={pickupLocation}
            dropoffLocation={dropoffLocation}
            renderPolyline={
              estimate.scheduledDropoffLocation !== null ||
              estimate.scheduledPickupLocation !== null ||
              forceRenderPolyLine
            }
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <WalkingPolyline walkingInfo={WalkingPolylineInfoBuilder.build(estimate, SlotType.Pickup, false)} />
        </ErrorBoundary>
        <ErrorBoundary>
          <WalkingPolyline walkingInfo={WalkingPolylineInfoBuilder.build(estimate, SlotType.Dropoff, false)} />
        </ErrorBoundary>
        <ErrorBoundary>
          <PickupRadius mappable={estimate} />
        </ErrorBoundary>
        <ErrorBoundary>
          <PickupAreaPill mappable={estimate} />
        </ErrorBoundary>
        <ErrorBoundary>
          {MapMarkerHelper.showStartLocation(estimate) && (
            <StartPinMarker location={estimate.requestedPickupLocation} />
          )}
        </ErrorBoundary>
        <ErrorBoundary>
          <PickupPinMarker location={pickupLocation} />
        </ErrorBoundary>
        <ErrorBoundary>
          <DropoffPinMarker location={dropoffLocation} />
        </ErrorBoundary>
        <ErrorBoundary>
          {showEndLocation && <EndPinMarker location={estimate.requestedDropoffLocation} />}
        </ErrorBoundary>
        <ErrorBoundary>
          <DropoffRadius mappable={estimate} />
        </ErrorBoundary>
        <ErrorBoundary>
          {!showEndLocation && !MapMarkerHelper.showDropoffRadius(estimate) && (
            <DropoffLabelMarker coordinates={locations} location={dropoffLocation} />
          )}
        </ErrorBoundary>
        <ErrorBoundary>
          {!MapMarkerHelper.showPickupRadius(estimate) && (
            <PickupLabelMarker
              location={pickupLocation}
              coordinates={locations}
              scheduledPickupTime={scheduledPickupTime}
            />
          )}
        </ErrorBoundary>
        <ErrorBoundary>
          {showEndLocation && (
            <EndLabelMarker
              location={estimate.requestedDropoffLocation}
              coordinates={MapMarkerHelper.getAllCoordinates(estimate)}
            />
          )}
        </ErrorBoundary>
      </>
    )
  }

  public render() {
    return (
      <View>
        <ErrorBoundary>
          <MapServiceAreas hideStops={true} />
        </ErrorBoundary>
        {this.renderMapPolylineAndMarkers()}
      </View>
    )
  }

  private fitMapToMarkers(estimate: IEstimateOutput | EstimatesUserInputParsed) {
    if (!MapMarkerHelper.showPickupRadius(estimate)) {
      const mapPadding: EdgePadding = {
        top: 0,
        bottom: (UIStateStore.screenHeight - CARD_HEIGHT) / 3,
        left: 0,
        right: 0,
      }
      MapStore.mapPadding = mapPadding
    }
    // with a larger screen on web, we want to show the whole trip for a user
    const coordinates = UIStateStore.shouldShowLandscapeWeb
      ? MapMarkerHelper.getAllCoordinates(estimate)
      : MapMarkerHelper.getPickupMarkerCoordinates(estimate)
    MapStore.fitMapToCoordinates(coordinates, {
      edgePadding: MapMarkerHelper.getEdgePadding(estimate),
      animated: true,
    })
  }
}
