import {
  AccessibilityFeature,
  IPostRequestFareEstimateResponse,
  IRequestAccessibilityFeature,
} from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { getAuthedApi } from 'src/api'
import { PurchaseInput } from 'src/helpers/payments/PaymentFlowHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { RequestOptions } from 'src/screens/request/RequestOptions'
import { IRequestStore, PRE_PICKUP_REQUEST_STATUSES, RequestStore } from 'src/stores/RequestStore'
import { IServiceStore, ServiceStore } from 'src/stores/ServiceStore'

type Props = ParamsListRoot[ScreenName.RequestAccessibilityOptions] & {
  requestStore: IRequestStore
  serviceStore: IServiceStore
  handleDismiss: () => void
  handleNavigateDiscountDetails: (params: ParamsListRoot[ScreenName.DiscountDetails]) => void
  getFareEstimate: (updatedValues: IRequestAccessibilityFeature[]) => Promise<IPostRequestFareEstimateResponse>
}

@observer
export class RequestAccessibilityOptionsView extends Component<Props> {
  public UNSAFE_componentWillMount = async () => {
    const { handleDismiss, requestId, serviceId, serviceStore, requestStore } = this.props
    const request = requestStore.requestMap.get(requestId)
    const service = serviceStore.servicesMap.get(serviceId)
    if (!request || !service || !PRE_PICKUP_REQUEST_STATUSES.includes(request.status)) {
      handleDismiss()
    }
  }

  public handleConfirmUpdate = async (updatedValues: IRequestAccessibilityFeature[], purchaseInput?: PurchaseInput) => {
    const { handleDismiss, requestId, requestStore } = this.props
    await requestStore.updateRequest(requestId, {
      accessibilityFeatures: updatedValues,
      ...purchaseInput,
    })
    handleDismiss()
  }

  public render() {
    const { serviceId, requestId, handleNavigateDiscountDetails, serviceStore, requestStore, getFareEstimate } =
      this.props
    const service = serviceStore.servicesMap.get(serviceId)
    const request = requestStore.requestMap.get(requestId)
    if (request && service) {
      return (
        <RequestOptions<AccessibilityFeature>
          requestStore={requestStore}
          handleNavigateDiscountDetails={handleNavigateDiscountDetails}
          requestId={request.id}
          initialFare={request.fare}
          showFareChange={false}
          initialRequestOptionsCount={request.accessibilityFeatures}
          availableServiceOptions={service.accessibilityFeatures.map((feature) => feature.type)}
          getFareEstimate={getFareEstimate}
          handleConfirmUpdate={this.handleConfirmUpdate}
          question={st.components.editAccessibility.subtitle()}
          buttonTitle={st.components.editAccessibility.buttonTitle()}
          translationFunction={st.enums.accessibilityFeature}
          allowZeroTotal={true}
        />
      )
    }
    return null
  }
}

export const RequestAccessibilityOptions = (props: ScreenPropsRoot<ScreenName.RequestAccessibilityOptions>) => (
  <RequestAccessibilityOptionsView
    {...props.route.params}
    requestStore={RequestStore}
    serviceStore={ServiceStore}
    handleDismiss={() => props.navigation.goBack()}
    handleNavigateDiscountDetails={(params) => props.navigation.navigate(ScreenName.DiscountDetails, params)}
    getFareEstimate={(updatedValues) =>
      getAuthedApi().requests.createFareEstimate(props.route.params.requestId, { accessibilityFeatures: updatedValues })
    }
  />
)
