import { ErrorName } from '@sparelabs/error-types'
import { size } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { useAsync } from 'react-async-hook'
import { StyleSheet, View } from 'react-native'
import { BackButton } from 'src/components/buttons/BackButton'
import { BottomSheetWrapper } from 'src/components/cards/BottomSheetWrapper'
import { CardWrapper } from 'src/components/cards/CardWrapper'
import { OriginDestinationPill } from 'src/components/rideOptions/OriginDestinationPill'
import { RideOptionsCard } from 'src/components/rideOptions/RideOptionsCard'
import { RideOptionsLoadingCard } from 'src/components/rideOptions/RideOptionsLoadingCard'
import { areAllEstimatesNull, isLyftEstimateAvailable, isLyftPassLinkEnabled } from 'src/helpers/EstimateHelper'
import { multimodalEnabled } from 'src/helpers/RideOptionsCardHelper'
import { ParamsListRoot, ParamsListScheduledTripList, ScreenName } from 'src/navigation'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { EstimateServicesLoading, IEstimateStore } from 'src/stores/EstimateStore'
import { IJourneyStore } from 'src/stores/JourneyStore'
import { RouterStore } from 'src/stores/RouterStore'
import { SearchFieldType, SearchScreenStore } from 'src/stores/SearchScreenStore'
import { IUserFleetAgreementStore } from 'src/stores/UserFleetAgreementStore'
import { EstimatesUserInputParsed } from 'src/types/estimate'
import { Pathname } from 'src/types/homeRoot'
import { ExternalRideOptions } from 'src/types/rideOptions'
import { useInterval } from '../../hooks/useInterval'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  originDestination: {
    marginBottom: 8,
  },
})

export interface IRideOptionsProps {
  preselectedRideOptionId: string | null
  selectedRideOptionId: string | null
  setRideOption: (rideOptionId: string) => void
  clearRideOption: () => void
  shouldFetchJourneys: boolean
  showOriginDestinationPill: boolean
  confirmServicePathname: string
  handleNavigateScheduleTrip: (params: ParamsListScheduledTripList[ScreenName.ScheduledTrip]) => void
  handleNavigateEstimateAccessibilityOptions: (params: ParamsListRoot[ScreenName.EstimateAccessibilityOptions]) => void
  handleNavigateEstimateRiderOptions: (params: ParamsListRoot[ScreenName.EstimateRiderOptions]) => void
  handleNavigateSelectPaymentMethod: (props: ParamsListRoot[ScreenName.SelectPaymentMethod]) => void
  handleNavigateScheduleEstimate: (params: ParamsListRoot[ScreenName.ScheduleEstimate]) => void
  handleNavigateOnboarding: (params: ParamsListRoot[ScreenName.Onboarding]) => void
  handleNavigateRequestCustomFields: (params: ParamsListRoot[ScreenName.RequestCustomFields]) => void
  handleNavigateConfirmFleetAgreements: (params: ParamsListRoot[ScreenName.FleetAgreementModal]) => void
}

interface IRideOptionsStoreProps {
  journeyStore: IJourneyStore | null
  estimateStore: IEstimateStore
  estimateInputStore: IEstimateInputStore
  userFleetAgreementStore: IUserFleetAgreementStore
}

const goBackFromRideOptions = async () => {
  // Populate the data on the origin and destination in search screen if necessary
  SearchScreenStore.populateOriginDestinationFromPrevious()
  SearchScreenStore.clearField(SearchFieldType.Destination)
  SearchScreenStore.setCurrentSearchField(SearchFieldType.Destination)

  await RouterStore.returnToPreviousScreen()
}

const setDefaultRideOption = (
  estimateStore: IEstimateStore,
  journeyStore: IJourneyStore | null,
  selectedRideOptionId: string | null,
  setRideOption: (rideOption: string) => void
) => {
  // if previous journey or estimate no longer exists, get a new default
  if (
    !journeyStore?.getJourneyFromId(selectedRideOptionId) &&
    !estimateStore.getSelectedEstimate(selectedRideOptionId)
  ) {
    if (
      estimateStore.estimateServices &&
      estimateStore.estimateServices.length &&
      !areAllEstimatesNull(estimateStore.estimateServices, estimateStore.estimateResponseMap)
    ) {
      setRideOption(estimateStore.estimateServices[0].serviceId)
    } else if (multimodalEnabled() && journeyStore && journeyStore.journeys?.length) {
      // Set it to the first journey based on the available section(s)
      if (journeyStore.mixedJourneys.length) {
        setRideOption(journeyStore.mixedJourneys[0].id)
      } else if (journeyStore.transitJourneys.length) {
        setRideOption(journeyStore.transitJourneys[0].id)
      } else if (journeyStore.walkingOnlyJourney.length) {
        setRideOption(journeyStore.walkingOnlyJourney[0].id)
      }
    }
    if (isLyftPassLinkEnabled() && isLyftEstimateAvailable(estimateStore)) {
      setRideOption(ExternalRideOptions.Lyft)
    }
  }
}

export const RideOptions = observer((props: IRideOptionsProps & IRideOptionsStoreProps) => {
  const [fleetPromptShown, setFleetPromptShown] = useState<boolean>(false)

  const { journeyStore, estimateStore, estimateInputStore, userFleetAgreementStore } = props

  const updateEstimates = async (input?: EstimatesUserInputParsed | null): Promise<void> => {
    await estimateStore.fetchEstimates(input ?? estimateInputStore.getEstimateInput())
  }

  const updateJourneys = async (input?: EstimatesUserInputParsed | null) => {
    if (props.shouldFetchJourneys && journeyStore && input) {
      await journeyStore.fetchJourneys(input)
    }
  }

  const updateRideOptions = () => {
    setDefaultRideOption(estimateStore, journeyStore, props.selectedRideOptionId, props.setRideOption)
  }

  //load an updated list of fleet agreements
  useAsync(async () => userFleetAgreementStore.refreshStoreData(), [])

  // we want to use useMemo() so it doesn't change values during rerender and cause the prompt to rerender every refresh
  const fleets = useMemo(
    () =>
      estimateStore.estimateServices && !userFleetAgreementStore.loadingStore.isLoading()
        ? userFleetAgreementStore.restrictedFleets(estimateStore.estimateServices)
        : {},
    [estimateStore.estimateServices, userFleetAgreementStore]
  )

  // if we find fleets that need an agreement we bring up the fleet agreement prompt
  // we want to be careful to only call this once unless new fleets appear
  useEffect(() => {
    if (size(fleets) && !fleetPromptShown) {
      setFleetPromptShown(true)
      props.handleNavigateConfirmFleetAgreements({
        fleets,
        userFleetAgreementStore,
        progress: { index: 0, length: size(fleets) },
        estimateStore,
      })
    }
  }, [fleets])

  // update estimates when our input changes or we have new agreements
  useEffect(() => {
    const fetchEstimateAndJourney = async () => {
      // Fetch estimate services first if estimate services has not already been fetched for this request
      if (!estimateStore.estimateServices) {
        await estimateStore.fetchEstimateServices(estimateInputStore.getEstimateInput())
      }
      await updateEstimates(estimateInputStore.estimateInput)
      await updateJourneys(estimateInputStore.estimateInput)
      updateRideOptions()
    }

    if (props.preselectedRideOptionId) {
      props.setRideOption(props.preselectedRideOptionId)
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchEstimateAndJourney()
  }, [estimateInputStore.estimateInput, userFleetAgreementStore.userFleetAgreements])

  // updates our estimates every 20 seconds but omits updating journeys since journey IDs are randomly generated on the backend
  useInterval(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateEstimates()
    updateRideOptions()
  }, 20000)

  const isLoading = () => {
    const estimateIsLoading =
      estimateStore.loadingStore.isLoading(EstimateServicesLoading.EstimateServices) ||
      !estimateInputStore.estimateInput ||
      !estimateStore.estimatesAreReady

    const journeyIsLoading = Boolean(
      multimodalEnabled() && journeyStore && journeyStore.loadingStore.isLoading() && !journeyStore.journeys
    )

    const fleetAgreementIsLoading = userFleetAgreementStore.loadingStore.isLoading()

    return estimateIsLoading || journeyIsLoading || fleetAgreementIsLoading
  }

  const renderRideOptionsCard = () => {
    if (isLoading()) {
      return (
        <CardWrapper>
          <BackButton onPress={goBackFromRideOptions} />
          <RideOptionsLoadingCard />
        </CardWrapper>
      )
    }

    // If the user is banned we want to show an error here to prevent them from booking a ride
    if (estimateStore.estimateError && estimateStore.estimateError.name === ErrorName.UserBannedError) {
      return (
        <CardWrapper>
          <BackButton onPress={goBackFromRideOptions} />
        </CardWrapper>
      )
    }

    return (
      <BottomSheetWrapper>
        <RideOptionsCard
          selectedRideOptionId={props.selectedRideOptionId}
          setRideOptions={props.setRideOption}
          journeyStore={journeyStore}
          estimateStore={estimateStore}
          estimateInputStore={estimateInputStore}
          shouldShowJourneys={props.shouldFetchJourneys}
          confirmServicePathname={props.confirmServicePathname}
          handleBackPress={goBackFromRideOptions}
          handleNavigateScheduleEstimate={props.handleNavigateScheduleEstimate}
          handleNavigateSelectPaymentMethod={props.handleNavigateSelectPaymentMethod}
          handleNavigateEstimateAccessibilityOptions={props.handleNavigateEstimateAccessibilityOptions}
          handleNavigateEstimateRiderOptions={props.handleNavigateEstimateRiderOptions}
          handleNavigateRequestCustomFields={props.handleNavigateRequestCustomFields}
        />
      </BottomSheetWrapper>
    )
  }

  return (
    <View key='route-container' style={styles.container} pointerEvents='box-none'>
      {props.showOriginDestinationPill && (
        <View key='origin-destination-container' style={styles.originDestination}>
          <OriginDestinationPill
            requestedPickupAddress={estimateInputStore.estimateInput?.requestedPickupAddress ?? ''}
            requestedDropoffAddress={estimateInputStore.estimateInput?.requestedDropoffAddress ?? ''}
            handlePressPill={async (searchField: SearchFieldType) => {
              SearchScreenStore.populateOriginDestinationFromPrevious()
              SearchScreenStore.setCurrentSearchField(searchField)
              await RouterStore.goToScreen({ pathname: Pathname.Search })
            }}
          />
        </View>
      )}
      {renderRideOptionsCard()}
    </View>
  )
})
