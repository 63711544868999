import { CreatedInterface, IFleetStub } from '@sparelabs/api-client'
import React from 'react'
import { AlertButton, Image, StyleSheet, Text, View } from 'react-native'
import { ApiClientBuilder } from 'src/api/ApiClientBuilder'
import { colors } from 'src/assets/colors'
import { CancelButton } from 'src/components/buttons/CancelButton'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError } from 'src/helpers/ErrorHelpers'
import { WebViewHelper } from 'src/helpers/WebViewHelper'
import { st } from 'src/locales'
import { ParamsListAccount, ParamsListRoot, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { LoadingStore } from 'src/stores/LoadingStore'
import { AlertButtonStyle } from 'src/util/types'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  containerCell: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  textContainer: {
    paddingBottom: 32,
  },
  termsAndConditionsContainer: {
    paddingTop: 16,
  },
  termsAndConditionsText: {
    fontSize: 16,
    lineHeight: 20,
    color: colors.blue50,
  },
  contentContainer: {
    paddingBottom: 16,
  },
  sectionTitle: {
    paddingTop: 16,
    fontSize: 24,
    color: colors.textDark,
    fontWeight: 'bold',
  },
  sectionText: {
    fontSize: 16,
    lineHeight: 20,
    color: colors.gray70,
  },
  image: {
    width: 80,
    height: 80,
    borderRadius: 20,
  },
  initials: {
    fontSize: 24,
    backgroundColor: 'transparent',
    textAlign: 'center',
    color: colors.gray1,
  },
  initialsWrapper: {
    height: 80,
    width: 80,
    borderRadius: 20,
    backgroundColor: '#DCDCDC',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  cancelStyle: {
    marginBottom: 16,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
})

type Props = ParamsListAccount[ScreenName.FleetAgreementsDetails] & {
  handleDismiss: () => void
  handleNavigateWebView: (params: ParamsListRoot[ScreenName.WebViewModal]) => void
}

export const FleetAgreementDetailsView = (props: Props) => {
  const loadingStore: LoadingStore = new LoadingStore()
  const { fleet } = props
  const termsUrl = fleet.data.termsUrl

  const removeAgreement = async () => {
    await loadingStore.execute(deleteAgreement())
    props.updateFleetAgreementsListCallback()
    props.handleDismiss()
  }

  const renderLogo = (logoUrl: string | null): JSX.Element | null =>
    logoUrl ? (
      <View>
        <Image source={{ uri: logoUrl }} style={styles.image} />
      </View>
    ) : null

  const renderIcon = (fleet: IFleetStub): JSX.Element | null =>
    fleet.logoUrl ? null : (
      <View>
        <SvgIconWrapper heightFixed={80} widthFixed={133} icon={SvgIcon.FleetAgreementIcon} />
      </View>
    )

  const handlePressRemoveAgreement = () => {
    AlertHelper.alert(
      st.screens.fleetAgreementList.endAgreementAlertTitle({ fleetName: props.fleet.data.name }),
      st.screens.fleetAgreementList.endAgreementAlertText({ fleetName: props.fleet.data.name }),
      [cancelAlertButton(), confirmAlertButton()],
      {
        cancelable: false,
      }
    )
  }

  const deleteAgreement = async () => {
    try {
      if (AuthenticatorHelper.userOrgToken && AuthenticatorHelper.userId) {
        await ApiClientBuilder.build().userFleetAgreements.post({
          createdInterface: CreatedInterface.Rider,
          agreed: false,
          fleetId: props.fleet.data.id,
          userId: AuthenticatorHelper.userId,
        })
      }
    } catch (error) {
      handleError({ error: error as Error, silent: true })
    }
  }

  const confirmAlertButton = (): AlertButton => ({
    text: st.screens.fleetAgreementList.endAgreementButton(),
    onPress: async () => {
      await removeAgreement()
    },
    style: AlertButtonStyle.Destructive,
  })

  const cancelAlertButton = (): AlertButton => ({
    text: st.common.buttonCancel(),
    style: AlertButtonStyle.Cancel,
  })

  return (
    <View style={styles.container}>
      <View style={styles.containerCell}>
        {renderLogo(fleet.data.logoUrl)}
        {renderIcon(fleet.data)}
        <View style={styles.contentContainer}>
          <Text style={styles.sectionTitle}>{fleet.data.name}</Text>
        </View>
        <View style={styles.textContainer}>
          <Text style={styles.sectionText}>
            {st.screens.fleetAgreementList.fleetDescription({
              orgName: AuthenticatorHelper.getOrganization().name,
              fleetName: fleet.data.name,
            })}
          </Text>
          {termsUrl && (
            <View style={styles.termsAndConditionsContainer}>
              <Text
                style={styles.termsAndConditionsText}
                onPress={() => props.handleNavigateWebView({ uri: termsUrl })}
              >
                {st.screens.fleetAgreementList.termsAndConditions({
                  fleetName: fleet.data.name,
                })}
              </Text>
            </View>
          )}
        </View>
        <View style={styles.cancelStyle}>
          <CancelButton
            loading={loadingStore.isLoading(fleet.id)}
            onPress={handlePressRemoveAgreement}
            title={st.screens.fleetAgreementList.endAgreementButton()}
          />
        </View>
      </View>
    </View>
  )
}

export const FleetAgreementDetails = (props: ScreenPropsAccount<ScreenName.FleetAgreementsDetails>) => (
  <FleetAgreementDetailsView
    {...props.route.params}
    handleDismiss={() => props.navigation.goBack()}
    handleNavigateWebView={(params) =>
      WebViewHelper.handleLink(params, props.navigation.navigate.bind(FleetAgreementDetails))
    }
  />
)
