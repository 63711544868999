import { applicationId } from 'expo-application'
import ExpoConstants from 'expo-constants'
import { difference, keys } from 'lodash'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { AuthType, IExpoConstants } from 'src/types/constants'
import config from '../../app.json'

interface IApplicationConstants {
  APP_VERSION: string
  NATIVE_APP_VERSION: string
  NATIVE_BUILD_VERSION: string
  APP_ID: string | null
}

const developmentConstants: IExpoConstants = {
  APP_NAME: 'Spare',
  MOBILE_APP_ID: 'cca2a31a-ac1b-46df-be88-75edd0098579',
  API_HOST: 'https://api.staging.sparelabs.com',
  APP_LOGO_NAME: 'defaultLogo',
  SHOW_ALL_ORGANIZATIONS: true,
  AUTH_TYPE: AuthType.STANDARD,
  SSO_PROVIDER_NAME: 'Sammy SSO',
  SSO_DOMAIN: 'sparelabs.com',
  TRACK_MIXPANEL_EVENTS: true,
  APP_STORE_TITLE: 'Spare',
  PRIVACY_POLICY: 'https://example.com',
  eas: {
    projectId: '949d3324-483d-4982-9e90-3a1275122236',
  },
}

export class RiderConstants {
  private readonly constants: IApplicationConstants & IExpoConstants

  constructor(windowObject: Window = window, expoConstantsObject = ExpoConstants) {
    this.constants = {
      // eslint-disable-next-line @typescript-eslint/no-require-imports
      APP_VERSION: require('../../package.json').version,
      APP_ID: applicationId ?? '',
      NATIVE_APP_VERSION: ExpoConstants.nativeAppVersion ?? '',
      NATIVE_BUILD_VERSION: ExpoConstants.nativeBuildVersion ?? '',
      ...this.validateAndParseExpoExtras(windowObject, expoConstantsObject),
    }
  }

  private validateAndParseExpoExtras(windowObject: Window, expoConstantsObject: typeof ExpoConstants): IExpoConstants {
    // global.ExpoConstants is only set on staging/production builds as
    // a post build step using the apply-app-config-to-web-build.js script.
    // This is an optimization so that we don't need to rebuild the entire bundle for each white label
    let constants = {} as IExpoConstants

    // apply default constants on local dev if app.json is not found
    if (__DEV__) {
      constants = developmentConstants
    }

    // TODO "manifest" field is empty in updates. After the SDK 46 update,
    // "expoConfig.extra" should be used to access the extra fields.
    if (config) {
      constants = (config as any)?.expo?.extra
    }

    if (expoConstantsObject.manifest?.extra !== undefined) {
      constants = expoConstantsObject.manifest?.extra as IExpoConstants
    }

    if ((windowObject as any).ExpoConstants !== undefined) {
      constants = (windowObject as any).ExpoConstants
    }

    let missingKeys = difference(keys(developmentConstants), keys(constants))
    if (constants.AUTH_TYPE === AuthType.STANDARD) {
      missingKeys = missingKeys.filter((key) => key !== 'SSO_PROVIDER_NAME' && key !== 'SSO_DOMAIN')
    }
    if (missingKeys.length > 0) {
      AlertHelper.alert(
        `App is not correctly configured. "${missingKeys.join(',')}" keys are missing from configuration`
      )
    }

    return constants
  }

  public get getConstants(): IApplicationConstants & IExpoConstants {
    return this.constants
  }
}

export const Constants = new RiderConstants().getConstants
