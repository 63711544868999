import { IListResponse } from '@sparelabs/api-client'
import { observable, runInAction } from 'mobx'
import { handleError } from 'src/helpers/ErrorHelpers'
import { LoadingStore } from 'src/stores/LoadingStore'

type ApiCallCallback<T> = (skip: number, limit: number) => Promise<IListResponse<T>>

/**
 * Extend this store to get paginated data from the API, with control over the speed at which we fetch more data.
 * This is useful for infinite scrolling lists of data.
 */
export class PagedDataStore<T> extends LoadingStore {
  public static PAGE_LIMIT: number = 10

  @observable
  public data: T[] = []

  @observable public apiHasMoreData: boolean = true

  @observable
  private skip: number = 0

  private readonly apiCall: ApiCallCallback<T>
  constructor(apiCall: ApiCallCallback<T>) {
    super()
    this.apiCall = apiCall
  }

  public async fetchMore() {
    if (this.apiHasMoreData) {
      try {
        await this.execute(
          (async () => {
            const res = await this.apiCall(this.skip, PagedDataStore.PAGE_LIMIT)
            runInAction(() => {
              this.data = [...this.data, ...res.data]
              this.skip += res.data.length
              this.apiHasMoreData = res.total > this.data.length && res.data.length > 0
            })
          })()
        )
      } catch (error) {
        handleError({ error, silent: true })
      }
    }
  }
}
