import { ImageSourcePropType } from 'react-native'
import { Images } from 'src/assets/Images'
import { Constants } from 'src/consts/Constants'

export class WhitelabelHelper {
  public static getLogoSource = (): ImageSourcePropType =>
    (Constants.APP_LOGO_NAME && Images[Constants.APP_LOGO_NAME as keyof typeof Images]
      ? Images[Constants.APP_LOGO_NAME as keyof typeof Images]
      : Images.defaultLogo) as ImageSourcePropType
}
