import { IPrice, LegMode } from '@sparelabs/api-client'
import { CurrencyHelper } from '@sparelabs/currency'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { LayoutChangeEvent, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { EnrouteCircle } from 'src/components/EnrouteCircle'
import { IconCircle } from 'src/components/IconCircle'
import { getModeIcon } from 'src/components/journey/JourneyHelper'
import { TimelineDash } from 'src/components/journey/TimelineDash'
import { OUTER_CIRCLE_DIAMETER, StopCircle } from 'src/components/StopCircle'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'

const BOARDING_CIRCLE_SIZE = 32

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingHorizontal: 16,
  },
  sectionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  timelineContainer: {
    flexDirection: 'row',
    flexShrink: 1,
  },
  instructionContainer: {
    flexDirection: 'column',
    paddingHorizontal: 8,
    flexShrink: 1,
    flex: -1,
  },
  instructionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.gray90,
    flexWrap: 'wrap',
    lineHeight: 20,
  },
  detailsText: {
    fontSize: 14,
    color: colors.gray70,
    paddingTop: 4,
    lineHeight: 16,
  },
  stopsInfoText: {
    fontSize: 14,
    color: colors.gray70,
    marginLeft: 8,
    lineHeight: 16,
  },
  detailsRightText: {
    fontSize: 14,
    color: colors.gray70,
    textAlign: 'right',
    lineHeight: 16,
  },
  iconContainer: {
    flexDirection: 'column',
    left: -4,
  },
  boardingLineStub: {
    opacity: 0.4,
    borderLeftWidth: 8,
    zIndex: -2,
    height: 3,
    width: 32,
    top: BOARDING_CIRCLE_SIZE - 3,
    position: 'absolute',
    left: 12,
  },
  routeLine: {
    opacity: 0.4,
    borderLeftWidth: 8,
    zIndex: -2,
    position: 'relative',
    width: 32,
    right: -12,
  },
  stopContainer: {
    width: 32,
    right: -8,
  },
  stopLine: {
    opacity: 0.4,
    borderLeftWidth: 8,
    zIndex: -1,
    position: 'relative',
    minHeight: 41,
    width: 32,
    right: -8,
  },
  stopLineStub: {
    opacity: 0.4,
    borderLeftWidth: 8,
    zIndex: -2,
    height: 3,
    width: 32,
    top: 0,
    position: 'absolute',
    left: 4,
  },
  timelineDot: {
    position: 'absolute',
    zIndex: 1,
    paddingTop: 4,
    left: 8,
  },
})

export interface IFixedRouteItem {
  legMode: LegMode
  routeName: string
  lastStopName: string
  secondLastStopName?: string
  numberOfStops: number
  rideDurationMinutes: number
  startTimestamp: number
  lastStopTimestamp: number
  price?: IPrice
  isLastLeg?: boolean
  routeColor: string
  routeTextColor: string
}

interface IProps {
  fixedRouteDetails: IFixedRouteItem
}

interface IState {
  dottedLineHeight: number
  routeLineHeight: number
}

@observer
export class JourneyFixedRouteItem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      dottedLineHeight: 0,
      routeLineHeight: 0,
    }
  }

  public render() {
    return (
      <View style={styles.container}>
        <View style={[styles.sectionContainer, { paddingTop: 4 }]}>
          <View style={styles.timelineContainer}>
            {this.renderBoardingTimeline()}
            {this.renderBoardingInstructions()}
          </View>
          {this.renderBoardingTime()}
        </View>
        <View style={styles.timelineContainer}>
          {this.renderStopsTimeline()}
          {this.renderStopsInfo()}
        </View>
        <View style={styles.sectionContainer}>
          <View style={styles.timelineContainer}>
            {this.renderExitTimeline()}
            {this.renderExitInstructions()}
          </View>
          {this.renderExitTime()}
        </View>
      </View>
    )
  }

  private readonly renderBoardingTimeline = () => {
    const { legMode, routeColor, routeTextColor } = this.props.fixedRouteDetails
    return (
      <View style={styles.iconContainer}>
        <View style={{ ...styles.boardingLineStub, borderColor: routeColor }} />
        <IconCircle
          icon={getModeIcon(legMode)}
          backgroundColor={routeColor}
          color={routeTextColor}
          size={BOARDING_CIRCLE_SIZE}
        />
        <View
          style={{
            ...styles.routeLine,
            height: this.state.routeLineHeight,
            borderColor: routeColor ?? colors.blueAlt50,
          }}
        />
      </View>
    )
  }

  private readonly renderBoardingInstructions = () => (
    <View style={[styles.instructionContainer, { paddingBottom: 24 }]} onLayout={this.setRouteLineHeight}>
      <Text style={styles.instructionTitle}>
        {st.components.journey.boardDescription({ routeName: this.props.fixedRouteDetails.routeName })}
      </Text>
    </View>
  )

  private readonly renderBoardingTime = () => {
    const startTime = moment.unix(this.props.fixedRouteDetails.startTimestamp).format('LT')
    const { price } = this.props.fixedRouteDetails
    return (
      <View>
        <Text style={styles.detailsRightText}>{startTime}</Text>
        {price && <Text style={styles.detailsRightText}>{CurrencyHelper.format(price.amount, price.currency)}</Text>}
      </View>
    )
  }

  private readonly renderStopsTimeline = () => (
    <>
      <View style={styles.timelineDot}>
        <EnrouteCircle outerColor={this.props.fixedRouteDetails.routeColor ?? colors.blueAlt50} />
      </View>
      <View style={{ ...styles.stopLine, borderColor: this.props.fixedRouteDetails.routeColor ?? colors.blueAlt50 }} />
    </>
  )

  private readonly renderStopsInfo = () => {
    const { numberOfStops, rideDurationMinutes } = this.props.fixedRouteDetails
    const infoText =
      numberOfStops > 0
        ? st.components.journey.numberOfStopsDescription({
            numberOfStops: numberOfStops.toString(),
            minutes: rideDurationMinutes.toString(),
          })
        : st.components.journey.nonstopDescription({ minutes: rideDurationMinutes.toString() })
    return (
      <View>
        <Text style={styles.stopsInfoText}>{infoText}</Text>
      </View>
    )
  }

  private readonly renderExitTimeline = () => (
    <View style={styles.iconContainer}>
      <View style={styles.stopContainer}>
        <View
          style={{ ...styles.stopLineStub, borderColor: this.props.fixedRouteDetails.routeColor ?? colors.blueAlt50 }}
        />
        <StopCircle outerColor={this.props.fixedRouteDetails.routeColor ?? colors.blueAlt50} />
      </View>
      {!this.props.fixedRouteDetails.isLastLeg && <TimelineDash dottedLineHeight={this.state.dottedLineHeight} />}
    </View>
  )

  private readonly renderExitInstructions = () => (
    <View style={[styles.instructionContainer, { paddingBottom: 40 }]} onLayout={this.setDottedLineHeight}>
      <Text style={styles.instructionTitle}>
        {st.components.journey.exitDescription({ stopName: this.props.fixedRouteDetails.lastStopName })}
      </Text>
      {this.props.fixedRouteDetails.secondLastStopName && (
        <Text style={styles.detailsText}>
          {st.components.journey.previousStopDescription({ stopName: this.props.fixedRouteDetails.secondLastStopName })}
        </Text>
      )}
    </View>
  )

  private readonly renderExitTime = () => {
    const lastStopTimestamp = moment.unix(this.props.fixedRouteDetails.lastStopTimestamp).format('LT')
    return (
      <View>
        <Text style={styles.detailsRightText}>{lastStopTimestamp}</Text>
      </View>
    )
  }

  private readonly setRouteLineHeight = (event: LayoutChangeEvent) => {
    if (this.state.routeLineHeight === 0) {
      const height = event.nativeEvent.layout.height
      this.setState({ routeLineHeight: height - BOARDING_CIRCLE_SIZE })
    }
  }

  private readonly setDottedLineHeight = (event: LayoutChangeEvent) => {
    if (this.state.dottedLineHeight === 0) {
      const height = event.nativeEvent.layout.height
      this.setState({ dottedLineHeight: height - OUTER_CIRCLE_DIAMETER })
    }
  }
}
