import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { FloatingLabel } from 'src/components/FloatingLabel'
import { ImageMarker } from 'src/components/mapMarkers/ImageMarker'
import { IPickupMarkerProps } from 'src/components/mapMarkers/MapMarkerProps'
import { getMinutesUntilPickup } from 'src/helpers/EstimateHelper'
import { getAnchorForMarker, getCenterOffsetForMarker, getWebCenterOffset } from 'src/helpers/MapHelper'
import { st } from 'src/locales'
import { IMarker, MarkerTypes } from 'src/types'

const LABEL_WIDTH_ENGLISH = 48

/**
 * Marks the scheduled pickup location if it exists
 * Otherwise, marks the requested pickup location.
 * Labeled as "Pickup"
 */
@observer
export class PickupLabelMarker extends Component<IPickupMarkerProps> {
  public render() {
    const labelMarker = this.createLabel()
    return <ImageMarker key={labelMarker.name} {...labelMarker} />
  }

  private readonly getPickupTime = () => {
    const scheduledPickupTime = this.props.scheduledPickupTime
    if (scheduledPickupTime) {
      return getMinutesUntilPickup(scheduledPickupTime)
    }
    return null
  }

  private readonly createLabel = (): IMarker => {
    const { location, coordinates } = this.props
    const pickupTime: number | null = this.getPickupTime()

    return {
      name: MarkerTypes.PickupLabel,
      coordinate: location,
      child: (
        <FloatingLabel
          title={st.helpers.mapMarkerHelpers.pickup()}
          subtitle={
            pickupTime && pickupTime < 60 ? st.helpers.mapMarkerHelpers.pickupTime({ time: pickupTime }) : undefined
          }
        />
      ),
      anchor: getAnchorForMarker(coordinates, location, LABEL_WIDTH_ENGLISH),
      centerOffset: getCenterOffsetForMarker(coordinates, location, LABEL_WIDTH_ENGLISH),
      webCenterOffset: getWebCenterOffset({ markerLocations: coordinates, location, labelWidth: LABEL_WIDTH_ENGLISH }),
      pointerEvents: 'none',
      style: { zIndex: 1 },
    }
  }
}
