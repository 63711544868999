/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { CompositeNavigationProp, NavigatorScreenParams, RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { IAddress } from '@sparelabs/address'
import {
  AccessibilityFeature,
  FavoriteLocationType,
  IAnnouncementResponse,
  ICustomFieldChoice,
  IFareRedemptionStub,
  IFareStub,
  IFleetStub,
  IGlobalLinkedOrganization,
  IPaymentProviderDefOutput,
  IRequestAccessibilityFeature,
  IRequestInsightsResponse,
  IRequestResponse,
  IRequestRiderType,
  ISurveyResponse,
  RiderType,
  StripePaymentMethodType,
} from '@sparelabs/api-client'
import { DateTimePickerMode } from 'src/components/datepicker/DateTimeInputTypes'
import { IFormFieldProps } from 'src/components/form/FormFieldTypes'
import { EstimateScheduleOptions } from 'src/components/rideOptions/EstimateRadioButtonGroup'
import { Moment } from 'src/helpers/Moment'
import { PaymentMethodType } from 'src/helpers/payments/PaymentMethodHelper'
import { ScreenName } from 'src/navigation/types/ScreenNameTypes'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { IEstimateStore } from 'src/stores/EstimateStore'
import { IUserFleetAgreementStore } from 'src/stores/UserFleetAgreementStore'

export type ParamsListSetPhoneNumber = {
  [ScreenName.Login]: { title: string; authOrganization: IGlobalLinkedOrganization }
  [ScreenName.SetPhoneNumber]: { title: string; authOrganization: IGlobalLinkedOrganization }
  [ScreenName.SetPhoneNumberConfirm]: { phoneNumber: string; authOrganization: IGlobalLinkedOrganization }
}

export type ParamsListEditPhoneNumber = {
  [ScreenName.EditPhoneNumber]: { title: string }
  [ScreenName.EditPhoneNumberConfirm]: { phoneNumber: string }
}

export type ScreenPropsSetPhoneNumber<S extends keyof ParamsListSetPhoneNumber> = {
  route: RouteProp<ParamsListSetPhoneNumber, S>
  navigation: CompositeNavigationProp<
    StackNavigationProp<ParamsListSetPhoneNumber, S>,
    StackNavigationProp<ParamsListRoot>
  >
}

export type ScreenPropsEditPhoneNumber<S extends keyof ParamsListEditPhoneNumber> = {
  route: RouteProp<ParamsListEditPhoneNumber, S>
  navigation: CompositeNavigationProp<
    StackNavigationProp<ParamsListEditPhoneNumber, S>,
    StackNavigationProp<ParamsListRoot>
  >
}

export type ParamsListDeveloperMenu = {
  [ScreenName.DeveloperMenu]: {}
  [ScreenName.DeveloperHostSelect]: {}
  [ScreenName.DeveloperReviewerLogin]: {}
}

export type ScreenPropsDeveloperMenu<S extends keyof ParamsListDeveloperMenu> = {
  route: RouteProp<ParamsListDeveloperMenu, S>
  navigation: CompositeNavigationProp<
    StackNavigationProp<ParamsListDeveloperMenu, S>,
    StackNavigationProp<ParamsListRoot>
  >
}

export type SuccessfulPaymentMethodAddHook = (paymentMethodId: string) => Promise<void> | null

export type ParamsListAddPaymentMethod = {
  [ScreenName.AddPaymentMethod]: {
    paymentMethodTypeRequired?: PaymentMethodType[] | undefined
    onPaymentMethodSuccessfullyAdded?: SuccessfulPaymentMethodAddHook
    validProviders: IPaymentProviderDefOutput[]
  }
  [ScreenName.AddNimoca]: { onPaymentMethodSuccessfullyAdded: SuccessfulPaymentMethodAddHook }
  [ScreenName.AddStripePaymentMethodSession]: {
    onPaymentMethodSuccessfullyAdded: SuccessfulPaymentMethodAddHook
    stripeMethodType: StripePaymentMethodType
  }
}

export type ScreenPropsAddPaymentMethod<S extends keyof ParamsListAddPaymentMethod> = {
  route: RouteProp<ParamsListAddPaymentMethod, S>
  navigation: CompositeNavigationProp<
    StackNavigationProp<ParamsListAddPaymentMethod, S>,
    StackNavigationProp<ParamsListRoot>
  >
}

export type ParamsListAnnouncementList = {
  [ScreenName.ListAnnouncements]: undefined
}

export type ScreenPropsAnnouncementList<S extends keyof ParamsListAnnouncementList> = {
  route: RouteProp<ParamsListAnnouncementList, S>
  navigation: CompositeNavigationProp<
    StackNavigationProp<ParamsListAnnouncementList, S>,
    StackNavigationProp<ParamsListRoot>
  >
}

export type ParamsAcknowledgeableAnnouncementList = {
  [ScreenName.AcknowledgeableAnnouncementModal]: undefined
}

export type ScreenPropsAcknowledgeableAnnouncement<S extends keyof ParamsAcknowledgeableAnnouncementList> = {
  route: RouteProp<ParamsAcknowledgeableAnnouncementList, S>
  navigation: CompositeNavigationProp<
    StackNavigationProp<ParamsAcknowledgeableAnnouncementList, S>,
    StackNavigationProp<ParamsListRoot>
  >
}

export type ParamsListReview = {
  [ScreenName.Review]: {
    canRiderTip: boolean
    request: IRequestResponse
    title: string
  }
  [ScreenName.AddTip]: {
    canRiderTip: boolean
    request: IRequestResponse
    title: string
  }
  [ScreenName.CustomTipScreen]: {
    canRiderTip: boolean
    fare: IFareStub
    handleAddTip: (amount: number) => void
    title: string
  }
}

export type ScreenPropsReview<S extends keyof ParamsListReview> = {
  route: RouteProp<ParamsListReview, S>
  navigation: CompositeNavigationProp<StackNavigationProp<ParamsListReview, S>, StackNavigationProp<ParamsListRoot>>
}

export type ParamsListScheduledTripList = {
  [ScreenName.ScheduledTripsList]: {
    /**
     * Caller opening this screen can force this screen to deeply navigate into a specific trip
     * TODO this should probably be handled by React Navigation
     */
    navigateToRequestId: string | null
  }
  [ScreenName.ScheduledTrip]: {
    request: IRequestResponse
    showConfirmation: boolean
  }
}

export type ScreenPropsScheduledTripList<S extends keyof ParamsListScheduledTripList> = {
  route: RouteProp<ParamsListScheduledTripList, S>
  navigation: CompositeNavigationProp<
    StackNavigationProp<ParamsListScheduledTripList, S>,
    StackNavigationProp<ParamsListRoot>
  >
}

export enum FarePassScreenStateType {
  FarePass,
  FarePassAllocation,
}

export enum FavoriteScreenStateType {
  New,
  Edit,
}

type EditFavoriteScreenParams =
  | { state: FavoriteScreenStateType.Edit; favoriteId: string }
  | {
      state: FavoriteScreenStateType.New
      type: FavoriteLocationType
      longitude: number
      latitude: number
      address?: string
    }

export type ParamsListAccount = {
  [ScreenName.Account]: {}
  // favorites
  [ScreenName.FavoritesListScreen]: {}
  // fare passes
  [ScreenName.FarePassList]: {}
  [ScreenName.ViewFarePass]: {
    farePassName: string
    state:
      | { type: FarePassScreenStateType.FarePass; farePassId: string }
      | { type: FarePassScreenStateType.FarePassAllocation; allocationId: string }
  }
  [ScreenName.FarePassHistoryList]: {}
  // payment methods
  [ScreenName.PaymentMethods]: {}
  [ScreenName.ViewCash]: {}
  [ScreenName.ViewNimoca]: { paymentMethodId: string }
  [ScreenName.ViewSepa]: { paymentMethodId: string }
  [ScreenName.ViewCreditCard]: { paymentMethodId: string }
  // webview for faq URL
  [ScreenName.Legal]: {}
  // groups
  [ScreenName.GroupMemberships]: {}
  [ScreenName.GroupDetails]: {
    group: {
      id: string
      name: string
      description: string | null
    }
    joinDate: string
    expiryDateTime: string
    updateGroupsListCallback: () => void
  }
  [ScreenName.FleetAgreementList]: {}
  [ScreenName.JoinGroup]: { updateGroupsListCallback: () => void }
  // past trips
  [ScreenName.PastTripsList]: {}
  [ScreenName.PastTrip]: { request: IRequestResponse }
  // promos
  [ScreenName.ListPromo]: {}
  [ScreenName.AddPromo]: {}
  [ScreenName.FleetAgreementsDetails]: {
    fleet: { id: string; data: IFleetStub }
    updateFleetAgreementsListCallback: () => void
  }
}

export type ScreenPropsAccount<S extends keyof ParamsListAccount> = {
  route: RouteProp<ParamsListAccount, S>
  navigation: CompositeNavigationProp<StackNavigationProp<ParamsListAccount, S>, StackNavigationProp<ParamsListRoot>>
}

export type ParamsListRoot = {
  // root - storybook
  [ScreenName.RootStoryBookUI]: undefined
  // root - login
  [ScreenName.RootSetOrganization]: {}
  // root - login if 1 org
  [ScreenName.RootLogin]: { title: string; authOrganization: IGlobalLinkedOrganization }
  // root - home screen after being logged in
  [ScreenName.RootHome]: {}
  // edit phone number modal
  [ScreenName.GroupEditPhoneNumber]: NavigatorScreenParams<ParamsListEditPhoneNumber>
  // set phone number modal
  [ScreenName.GroupSetPhoneNumber]: NavigatorScreenParams<ParamsListSetPhoneNumber>
  // developer menu modal that can be accessed from login or settings
  [ScreenName.GroupDeveloperMenu]: NavigatorScreenParams<ParamsListDeveloperMenu>
  // account settings modal
  [ScreenName.GroupAccount]: NavigatorScreenParams<ParamsListAccount>
  [ScreenName.GroupAddPaymentMethod]: NavigatorScreenParams<ParamsListAddPaymentMethod>
  // review modal
  [ScreenName.GroupReview]: NavigatorScreenParams<ParamsListReview>
  [ScreenName.GroupScheduledTripsList]: NavigatorScreenParams<ParamsListScheduledTripList>

  // modals
  [ScreenName.RequestCancellation]: {
    requestId: string
    serviceBrandPhotoUrl: string
    onSuccessCallback: () => void
  }
  [ScreenName.RequestRiderOptions]: {
    requestId: string
    serviceId: string
  }
  [ScreenName.RequestAccessibilityOptions]: {
    requestId: string
    serviceId: string
  }
  [ScreenName.DiscountDetails]: {
    fare: IFareStub
    fareRedemptions: IFareRedemptionStub[]
  }
  [ScreenName.EstimateRiderOptions]: {
    serviceRiderTypes: RiderType[]
    riders: IRequestRiderType[]
    maxRiders: number
    estimateInputStore: IEstimateInputStore
  }
  [ScreenName.EstimateAccessibilityOptions]: {
    serviceAccessibilityFeatures: AccessibilityFeature[]
    accessibilityFeatures: IRequestAccessibilityFeature[]
    estimateInputStore: IEstimateInputStore
    estimateStore: IEstimateStore
  }
  [ScreenName.ScheduleEstimate]: {
    estimateInputStore: IEstimateInputStore
    estimateStore: IEstimateStore
  }
  [ScreenName.RequestCustomFields]: {
    estimateInputStore: IEstimateInputStore
    estimateStore: IEstimateStore
    confirmServicePathname: string
    displayErrors?: boolean
  }
  [ScreenName.SingleChoiceModal]: {
    choices: ICustomFieldChoice[]
    selected: string | undefined
    onSubmit: (selected: string) => void
    hint?: string
    title?: string
    isRequired?: boolean
    addWrapper?: boolean
    onDismiss?: () => void
  }
  [ScreenName.MultipleChoiceModal]: {
    choices: ICustomFieldChoice[]
    selected: string[]
    onSubmit: (selected: string[]) => void
    hint?: string
    title: string
  }
  [ScreenName.DatetimeModal]: {
    initialMoment: Moment | undefined
    onSubmit: (moment: Moment) => void
    hint?: string
    mode?: DateTimePickerMode
  } & { title: string }
  [ScreenName.Address]: IFormFieldProps<Partial<IAddress>> & { title: string }
  [ScreenName.Survey]: {
    survey: ISurveyResponse
  }
  [ScreenName.EmissionsDetails]: {
    travelDistance: number
    requestInsights: IRequestInsightsResponse
  }
  [ScreenName.SetFavoriteLocation]: { type: FavoriteLocationType }
  [ScreenName.EditFavoriteScreen]: EditFavoriteScreenParams
  [ScreenName.Onboarding]: {
    onFinish?: () => void
  }
  [ScreenName.WebViewModal]: { uri: string }
  // set rider profile modal
  [ScreenName.SetProfile]: {
    isOnboarding: boolean
    onDone?: () => void
    displayErrors?: boolean
  }
  // collect location permissions modal
  [ScreenName.Permissions]: {}
  // modal for viewing a single announcement that needs to be acknowledged
  [ScreenName.AcknowledgeableAnnouncementModal]: { announcement: IAnnouncementResponse; onAcknowledge: () => void }
  // modal for viewing a list of announcements from home screen
  [ScreenName.ListAnnouncements]: {}
  // confirm purchase modal
  [ScreenName.ConfirmPurchase]: {
    title: string
    terms: string
    termsUrl: string
    getPriceSubtitle: () => string
    // returns whether or not the purchase was successful
    onPressPurchase: (paymentMethodId: string) => Promise<boolean>
  }
  // screen for selecting payment method for a purchase
  [ScreenName.SelectPaymentMethod]: {
    selectedPaymentMethodId: string | null
    onPaymentMethodPress: (paymentMethodId: string | null) => Promise<void>
    paymentMethodTypeRequired?: PaymentMethodType[]
    isCashEnabled: boolean
  }
  [ScreenName.ConfirmStripePayment]: {
    stripePublicKey: string
    clientSecret: string
    onConfirmComplete: (success: boolean) => void
  }
  [ScreenName.FleetAgreementModal]: {
    fleets: Record<string, IFleetStub>
    userFleetAgreementStore: IUserFleetAgreementStore
    estimateStore: IEstimateStore
    progress: { index: number; length: number }
  }
  [ScreenName.DstFallBackChoice]: {
    estimateInputStore: IEstimateInputStore
    selectedEstimateType: EstimateScheduleOptions
    date: Moment
  }
}

export type ScreenPropsRoot<S extends keyof ParamsListRoot> = {
  route: RouteProp<ParamsListRoot, S>
  navigation: StackNavigationProp<ParamsListRoot, S>
}
