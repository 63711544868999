import { FeatureFlag, RequestStatus } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { AbstractButton } from 'src/components/buttons/AbstractButton'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { RequestHelper } from 'src/helpers/RequestHelper'
import { st } from 'src/locales'
import { ParamsListAccount, ParamsListRoot, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { AboutRide } from 'src/screens/request/AboutRide'
import { PastTripText } from 'src/screens/request/PastTrips/PastTripText'
import { TripEmissions } from 'src/screens/request/PastTrips/TripEmissions'
import { RequestBrand } from 'src/screens/request/RequestBrand'
import { RequestStatusBar } from 'src/screens/request/RequestStatusBar'
import { RequestTimeline } from 'src/screens/request/RequestTimeline'
import { ITipStore, TipStore } from 'src/stores/TipStore'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    padding: 16,
    backgroundColor: 'white',
  },
  divider: {
    borderTopColor: colors.borderGray,
    borderTopWidth: 1,
    marginVertical: 16,
  },
  timelineContainer: {
    marginLeft: 8,
  },
  timeline: {
    height: 'auto',
    flex: 0,
  },
  accountHistoryMsg: {
    color: colors.gray70,
    fontSize: 14,
    marginVertical: 8,
  },
})

type Props = ParamsListAccount[ScreenName.PastTrip] & {
  tipStore: ITipStore
  handleNavigateEmissionDetails: (params: ParamsListRoot[ScreenName.EmissionsDetails]) => void
  handleNavigateDiscountDetails: (params: ParamsListRoot[ScreenName.DiscountDetails]) => void
}

@observer
export class PastTripView extends Component<Props> {
  public renderEmissionsData() {
    const { request, handleNavigateEmissionDetails } = this.props
    const requestInsightsEnabled = AuthenticatorHelper.organization?.featureFlags.includes(FeatureFlag.RequestInsights)
    if (requestInsightsEnabled && request.status === RequestStatus.Completed) {
      return <TripEmissions handleNavigateEmissionDetails={handleNavigateEmissionDetails} request={request} />
    }
    return null
  }

  private renderAboutRide(): JSX.Element {
    return (
      <>
        <View style={styles.divider} />
        <View style={{ paddingVertical: 8 }}>
          <AboutRide provider='Lyft' serviceName={this.props.request.serviceBrand.name} />
        </View>
        <View style={{ marginVertical: 10 }}>
          <AbstractButton
            textColor={colors.pink70}
            title={st.components.requestCard.lyftRide.btnTitle()}
            backgroundColor={colors.pink10}
            onPress={() => RequestHelper.goToLyftRides()}
          />
        </View>
        <Text style={styles.accountHistoryMsg}>{st.components.requestCard.lyftRide.accountHistoryMsg()}</Text>
      </>
    )
  }

  public render() {
    const { tipStore, request, handleNavigateDiscountDetails } = this.props
    return (
      <View style={styles.container}>
        <RequestStatusBar request={request} />
        <View style={styles.innerContainer}>
          <PastTripText
            tipStore={tipStore}
            request={request}
            showFare={true}
            handleNavigateDiscountDetails={handleNavigateDiscountDetails}
          />
          <View style={styles.divider} />
          {this.renderEmissionsData()}
          <RequestBrand request={request} />
          <View style={styles.timelineContainer}>
            <RequestTimeline request={request} containerStyle={styles.timeline} />
          </View>
          {RequestHelper.isLyftRequest(request) && this.renderAboutRide()}
        </View>
      </View>
    )
  }
}

export const PastTrip = (props: ScreenPropsAccount<ScreenName.PastTrip>) => (
  <PastTripView
    {...props.route.params}
    tipStore={TipStore}
    handleNavigateEmissionDetails={(params) => props.navigation.navigate(ScreenName.EmissionsDetails, params)}
    handleNavigateDiscountDetails={(params) => props.navigation.navigate(ScreenName.DiscountDetails, params)}
  />
)
