import { CreatedInterface } from '@sparelabs/api-client'
import React from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ApiClientBuilder } from 'src/api/ApiClientBuilder'
import { colors } from 'src/assets/colors'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { SecondaryButton } from 'src/components/buttons/SecondaryButton'
import { FormWrapper } from 'src/components/form/FormWrapper'
import { ProgressBar } from 'src/components/ProgressBar'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError } from 'src/helpers/ErrorHelpers'
import { WebViewHelper } from 'src/helpers/WebViewHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'

const styles = StyleSheet.create({
  contentWrapper: {
    padding: 16,
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderBottomColor: colors.coolGray10,
  },
  formWrapper: {
    height: '100%',
    backgroundColor: colors.blue10,
  },
  fleetLogo: {
    height: 80,
    width: 80,
    borderRadius: 20,
    backgroundColor: 'transparent',
  },
  headerText: {
    lineHeight: 28,
    fontSize: 24,
    fontWeight: '700',
    width: 344,
    marginTop: 16,
    marginBottom: 16,
    color: colors.gray90,
  },
  descriptionText: {
    lineHeight: 20,
    fontSize: 16,
    fontWeight: '400',
    color: colors.gray70,
  },
  termsAndConditionsText: {
    fontSize: 16,
    lineHeight: 20,
    color: colors.blue50,
  },
  buttonWrapper: {
    marginTop: 'auto',
    paddingTop: 16,
    paddingHorizontal: 16,
    paddingBottom: 34,
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'column',
    borderTopWidth: 1,
    borderTopColor: colors.gray1,
  },
  primaryButton: {
    marginBottom: 8,
  },
  progressBar: {
    marginBottom: 8,
    height: 4,
    display: 'flex',
  },
})

type Props = ParamsListRoot[ScreenName.FleetAgreementModal] & {
  onNext: (params: ParamsListRoot[ScreenName.FleetAgreementModal]) => void
  onComplete: () => void
  handleNavigateWebView: (params: ParamsListRoot[ScreenName.WebViewModal]) => void
}

export const FleetAgreementModalView = (props: Props) => {
  const fleetIds = Object.keys(props.fleets)
  const index = props.progress.index

  const nextFleet = async () => {
    if (index + 1 < props.progress.length) {
      props.onNext({ ...props, progress: { index: index + 1, length: props.progress.length } })
    } else {
      await props.userFleetAgreementStore.refreshStoreData()
      props.estimateStore.clearEstimates()
      props.onComplete()
    }
  }

  const createFleetAgreement = async (agreed: boolean) => {
    try {
      if (AuthenticatorHelper.userOrgToken && AuthenticatorHelper.userId) {
        await ApiClientBuilder.build().userFleetAgreements.post({
          createdInterface: CreatedInterface.Rider,
          agreed,
          fleetId: props.fleets[fleetIds[index]].id,
          userId: AuthenticatorHelper.userId,
        })
      }
    } catch (error) {
      handleError({ error: error as Error, silent: true })
    }
  }

  const handleResponse = async (agreed: boolean) => {
    await createFleetAgreement(agreed)
    await nextFleet()
  }

  const currentFleetAgreement = props.fleets[fleetIds[index]]
  const termsUrl = currentFleetAgreement.termsUrl ? currentFleetAgreement.termsUrl : null

  return (
    <SafeAreaView edges={['bottom']}>
      <FormWrapper style={styles.formWrapper}>
        <View style={styles.contentWrapper}>
          {currentFleetAgreement.logoUrl ? (
            <Image source={{ uri: currentFleetAgreement.logoUrl }} style={styles.fleetLogo} />
          ) : (
            <SvgIconWrapper heightFixed={80} widthFixed={133} icon={SvgIcon.FleetAgreementIcon} />
          )}
          <Text accessibilityRole='header' style={styles.headerText}>
            {st.screens.fleetAgreementModal.descriptionTitle({ fleetName: currentFleetAgreement.name })}
          </Text>
          <Text style={styles.descriptionText}>
            {st.screens.fleetAgreementModal.descriptionText({ fleetName: currentFleetAgreement.name })}
          </Text>
          {termsUrl && (
            <Text style={styles.descriptionText}>
              {'\n' + st.screens.fleetAgreementModal.termsAndConditionsText({ fleetName: currentFleetAgreement.name })}
              <Text
                style={styles.termsAndConditionsText}
                onPress={() => props.handleNavigateWebView({ uri: termsUrl })}
              >
                {st.screens.fleetAgreementModal.termsAndConditions({ fleetName: currentFleetAgreement.name })}
              </Text>
              .
            </Text>
          )}
        </View>
        <View style={styles.buttonWrapper}>
          <View style={styles.progressBar}>
            <ProgressBar
              progressPercent={((index + 1) / props.progress.length) * 100}
              barColor={colors.blue50}
              backgroundColor={colors.blue20}
              barHeight={4}
            />
          </View>
          <View style={styles.primaryButton}>
            <PrimaryButton title={st.screens.fleetAgreementModal.agree()} onPress={() => handleResponse(true)} />
          </View>
          <View style={styles.primaryButton}>
            <SecondaryButton
              title={st.screens.fleetAgreementModal.maybeLater()}
              onPress={() => handleResponse(false)}
            />
          </View>
        </View>
      </FormWrapper>
    </SafeAreaView>
  )
}

export const FleetAgreementModal = (props: ScreenPropsRoot<ScreenName.FleetAgreementModal>) => (
  <FleetAgreementModalView
    {...props.route.params}
    onNext={(params) => props.navigation.navigate(ScreenName.FleetAgreementModal, params)}
    onComplete={() => props.navigation.goBack()}
    handleNavigateWebView={(params) =>
      WebViewHelper.handleLink(params, props.navigation.navigate.bind(FleetAgreementModal))
    }
  />
)
