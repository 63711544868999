import { autorun, IReactionDisposer } from 'mobx'
import { observer } from 'mobx-react/native'
import React, { Component, useEffect, useState } from 'react'
import { Keyboard, StyleSheet, View } from 'react-native'
import { fetchPlaceDetails } from 'src/api'
import { DismissableSearchList } from 'src/components/DismissableSearchList'
import { LoadingShimmer } from 'src/components/LoadingShimmer'
import { SearchHeader } from 'src/components/SearchHeader'
import { AutoSuggestHelper, AutoSuggestion, AutoSuggestType, SetAutoSuggestions } from 'src/helpers/AutoSuggestHelper'
import { parseSuggestionToEstimateInput } from 'src/helpers/EstimateHelper'
import { ParseEstimateHelper } from 'src/helpers/ParseEstimateHelper'
import { ROUTER_CONTENT_MAX_WIDTH } from 'src/screens/HomeRootHelper'
import { LoadingStore } from 'src/stores/LoadingStore'
import { LocationStore } from 'src/stores/LocationStore'
import { MapStore } from 'src/stores/MapStore'
import { RouterStore } from 'src/stores/RouterStore'
import { SearchFieldType, SearchScreenStore } from 'src/stores/SearchScreenStore'
import { UIStateStore } from 'src/stores/UIStore'
import { Pathname } from 'src/types/homeRoot'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  webOverride: {
    maxWidth: ROUTER_CONTENT_MAX_WIDTH,
  },
})

interface IProps {
  currentSearchFieldType: SearchFieldType
  originGoogleSuggestions: AutoSuggestion[] | null
  destinationGoogleSuggestions: AutoSuggestion[] | null
  originSearchTerm: string
  destinationSearchTerm: string
  isMapPanning: boolean
}

const SearchScreenView = (props: IProps) => {
  const [isShowingMap, shouldShowMap] = useState(false)

  let mapSearchUpdate: IReactionDisposer | undefined

  // for componentDidMount
  useEffect(() => {
    SearchScreenStore.createSearchIndex()
    mapSearchUpdate = autorun(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadingStore.execute(SearchScreenStore.setMapRegion(MapStore.mapRegion), geocodeLoadingKey)
    })
  }, [])

  useEffect(() => {
    const previousSelectedField =
      SearchScreenStore.currentSearchFieldType === SearchFieldType.Origin
        ? SearchFieldType.Destination
        : SearchFieldType.Origin
    if (isShowingMap) {
      const mapSearchItem = SearchScreenStore.getRegionSuggestion(previousSelectedField)
      if (mapSearchItem) {
        // set the previously selected field if we've changed focus
        SearchScreenStore.setAutoSuggestion(mapSearchItem, previousSelectedField)
      }
      shouldShowMap(false)
    }
  }, [props.currentSearchFieldType])

  useEffect(
    () => () => {
      if (mapSearchUpdate) {
        // disposer for autorun for mapSearchUpdate
        mapSearchUpdate()
      }
    },
    []
  )

  const geocodeLoadingKey: string = 'geocode'
  const loadingStore = new LoadingStore()

  const setSearchTerm = (text: string) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    SearchScreenStore.setSearchTerm(text)
  }

  const handlePressBack = async () => {
    await RouterStore.returnToPreviousScreen()
  }

  const mapShowingOnSwap = () => {
    if (isShowingMap) {
      const mapSearchItem = SearchScreenStore.getRegionSuggestion()
      if (mapSearchItem) {
        // set the previously selected field if we've changed focus
        SearchScreenStore.setAutoSuggestion(mapSearchItem)
      }
      shouldShowMap(false)
    }
  }

  const moveToConfirmOriginScreen = async (originField: SetAutoSuggestions, destinationField: SetAutoSuggestions) => {
    // eslint disable-next-line no-floating-promises
    await RouterStore.goToScreen({
      pathname: Pathname.RideOptionConfirmOrigin,
      state: { estimateInput: parseSuggestionToEstimateInput(originField, destinationField) },
    })
  }

  const handlePressSearchResult = async (searchItem: SetAutoSuggestions) => {
    const permissionsDenied = await LocationStore.permissionsDenied()
    if (searchItem.type === AutoSuggestType.CurrentLocation && permissionsDenied) {
      await LocationStore.noLocationPermissionAlert()
    } else {
      SearchScreenStore.setAutoSuggestion(searchItem)
      const originField = SearchScreenStore.originSearchField.selectedSuggestion
      const destinationField = SearchScreenStore.destinationSearchField.selectedSuggestion

      if (originField && destinationField) {
        shouldShowMap(false)
        // Refresh the current location in case we've moved since opening the screen
        const currentLocation = LocationStore.getCurrentLocationPointWithPermission()

        if (currentLocation) {
          if (originField.type === AutoSuggestType.CurrentLocation) {
            originField.location = currentLocation
          }
          if (destinationField.type === AutoSuggestType.CurrentLocation) {
            destinationField.location = currentLocation
          }
        }

        if (originField.type === AutoSuggestType.PlacesPrediction) {
          const response = await fetchPlaceDetails({ placeId: originField.placeId })
          const { location, address, name } = AutoSuggestHelper.mapPlaceDetail(response)
          originField.location = location
          originField.address = ParseEstimateHelper.getCompleteAddress(name, address)
        }

        SearchScreenStore.savePreviousOriginDestination()
        await moveToConfirmOriginScreen(originField, destinationField)
      } else {
        SearchScreenStore.setCurrentSearchField(
          SearchScreenStore.currentSearchFieldType === SearchFieldType.Origin
            ? SearchFieldType.Destination
            : SearchFieldType.Origin
        )
      }
    }
  }

  const handlePressDoneOnMap = () => {
    const mapSearchItem = SearchScreenStore.getRegionSuggestion()
    if (mapSearchItem) {
      SearchScreenStore.setAutoSuggestion(mapSearchItem)
    }
    if (mapSearchItem) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handlePressSearchResult(mapSearchItem)
      shouldShowMap(false)
      const currentLocation = LocationStore.getCurrentLocationWithPermission()
      if (currentLocation) {
        MapStore.fitMapToCurrentPosition(currentLocation.coords.latitude, currentLocation.coords.longitude)
      }
    }
  }

  const handlePressSetLocationOnMap = async () => {
    Keyboard.dismiss()
    shouldShowMap(true)
    await SearchScreenStore.setMapRegion(null)
    const currentLocation = LocationStore.getCurrentLocationWithPermission()
    if (currentLocation) {
      MapStore.fitMapToCurrentPosition(currentLocation.coords.latitude, currentLocation.coords.longitude)
    }
    await loadingStore.execute(SearchScreenStore.setMapRegion(MapStore.mapRegion), geocodeLoadingKey)
  }

  const originField = SearchScreenStore.originSearchField.selectedSuggestion
  const destinationField = SearchScreenStore.destinationSearchField.selectedSuggestion
  // Do not show results that are already selected in the origin or destination
  const filteredResults = SearchScreenStore.getSearchSuggestions().filter((suggestion) => {
    // If there is nothing selected show all
    if (!originField && !destinationField) {
      return true
    }
    return (
      (originField && suggestion.key !== originField.key) ||
      (destinationField && suggestion.key !== destinationField.key)
    )
  })

  const geoCodeLoading = loadingStore.isLoading(geocodeLoadingKey) || props.isMapPanning
  return (
    <View
      pointerEvents='box-none'
      style={[styles.container, UIStateStore.shouldShowLandscapeWeb ? styles.webOverride : {}]}
      testID='searchView'
    >
      <SearchHeader
        setSearchTerm={(text) => setSearchTerm(text)}
        handleFocus={(searchType) => SearchScreenStore.handleFocusChange(searchType)}
        mapShowingOnSwap={mapShowingOnSwap}
        onPressBack={handlePressBack}
        geoCodeLoading={geoCodeLoading}
        isShowingMap={isShowingMap}
      />
      {geoCodeLoading && isShowingMap ? <LoadingShimmer /> : null}
      <DismissableSearchList
        isOpen={!isShowingMap}
        geoCodeLoading={geoCodeLoading}
        onPressSearchResult={handlePressSearchResult}
        onConfirmMapRegion={handlePressDoneOnMap}
        onPressSetLocationOnMap={handlePressSetLocationOnMap}
        searchSuggestions={filteredResults}
      />
    </View>
  )
}

@observer
export class SearchScreen extends Component {
  public render() {
    return (
      <SearchScreenView
        currentSearchFieldType={SearchScreenStore.currentSearchFieldType}
        originGoogleSuggestions={SearchScreenStore.originSearchField.placeSuggestions}
        destinationGoogleSuggestions={SearchScreenStore.destinationSearchField.placeSuggestions}
        originSearchTerm={SearchScreenStore.originSearchField.searchTerm}
        destinationSearchTerm={SearchScreenStore.destinationSearchField.searchTerm}
        isMapPanning={MapStore.isPanning}
      />
    )
  }
}
