import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { PaymentDefaultButton } from 'src/screens/payments/buttons/PaymentDefaultButton'
import { PaymentDeleteButton } from 'src/screens/payments/buttons/PaymentDeleteButton'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'

const styles = StyleSheet.create({
  buttonRow: {
    flexDirection: 'row',
    marginTop: 32,
  },
  buttonLeft: {
    flex: 1,
    paddingRight: 16,
  },
  buttonRight: {
    flex: 1,
  },
})

interface IProps {
  paymentMethodId: string
  handlePopScreen: () => void
}

@observer
export class PaymentMethodControls extends Component<IProps> {
  public render(): JSX.Element | null {
    const { paymentMethodId } = this.props
    const paymentMethod = PaymentMethodStore.getPaymentMethodById(paymentMethodId)
    if (paymentMethod) {
      return (
        <View style={styles.buttonRow}>
          <View style={styles.buttonLeft}>
            <PaymentDeleteButton handleAfterDelete={this.props.handlePopScreen} paymentMethodId={paymentMethod.id} />
          </View>
          <View style={styles.buttonRight}>
            <PaymentDefaultButton
              handleAfterSetDefault={this.props.handlePopScreen}
              paymentMethodId={paymentMethod.id}
            />
          </View>
        </View>
      )
    }
    return null
  }
}
