import { IRequestResponse } from '@sparelabs/api-client'
import { CurrencyHelper } from '@sparelabs/currency'
import { FareSemantics } from '@sparelabs/domain-logic'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import { FareRenderer } from 'src/helpers/FareRenderer'
import { PaymentMethodHelper } from 'src/helpers/payments/PaymentMethodHelper'
import { st } from 'src/locales'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'

const getStyles = () =>
  StyleSheet.create({
    wrapper: {
      paddingTop: 16,
      paddingBottom: 8,
      paddingHorizontal: 16,
      borderTopWidth: 1,
      borderTopColor: colors.borderGray,
    },
    title: {
      textTransform: 'uppercase',
      fontSize: 14,
      fontWeight: 'bold',
      color: colors.gray70,
    },
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    discount: {
      flexDirection: 'row',
      color: colors.gray70,
      textDecorationLine: 'line-through',
      marginTop: -4,
    },
    cost: {
      color: colors.gray70,
      marginRight: 8,
    },
    paymentMethod: {
      color: colors.gray70,
    },
    text: {
      paddingVertical: 8,
      color: colors.gray90,
    },
  })

interface IProps {
  request: IRequestResponse
  handleNavigateDiscountDetails: () => void
}

@observer
export class RequestPaymentInfo extends Component<IProps> {
  public renderPaymentContainer(request: IRequestResponse) {
    const styles = getStyles()

    return (
      <View>
        <View style={styles.container}>
          {FareSemantics.requiresPayment(request) ? this.renderNonZeroFare(request) : this.renderZeroFare(request)}
        </View>
        {FareSemantics.hasDiscount(request) && this.renderDiscountInfo(request)}
      </View>
    )
  }

  private renderNonZeroFare(request: IRequestResponse) {
    const styles = getStyles()
    return (
      <>
        <Text style={styles.text}>{FareRenderer.renderPriceAmount(request)}</Text>
        <Text style={styles.paymentMethod}>
          {request.paymentMethodId !== null ? this.renderPaymentMethod(request) : st.helpers.payment.payInVehicle()}
        </Text>
      </>
    )
  }

  private renderZeroFare(request: IRequestResponse) {
    const styles = getStyles()
    return <Text style={styles.text}>{FareRenderer.renderZeroFare(request)}</Text>
  }

  public render() {
    const styles = getStyles()
    const { request } = this.props
    return (
      <View style={styles.wrapper}>
        <Text style={styles.title}>{st.components.requestCard.paymentInfo.paymentLabel()}</Text>
        <View>{this.renderPaymentContainer(request)}</View>
      </View>
    )
  }

  public renderPaymentMethod(request: IRequestResponse): string | null {
    if (request.paymentMethodId) {
      const paymentMethod = PaymentMethodStore.getPaymentMethodById(request.paymentMethodId)
      if (paymentMethod) {
        return PaymentMethodHelper.getShortPaymentMethodName(paymentMethod)
      }
    }
    return null
  }

  public renderDiscountInfo({ fare }: IRequestResponse) {
    const styles = getStyles()
    return (
      <View style={styles.discount}>
        <TouchableOpacity onPress={this.props.handleNavigateDiscountDetails} style={{ flexDirection: 'row' }}>
          <Text style={[styles.cost, { textDecorationLine: 'line-through' }]} testID='discount'>
            {CurrencyHelper.format(fare.cost, fare.currency)}
          </Text>
          <FontAwesomeIconWrapper icon='info-circle' size={16} color={colors.gray70} />
        </TouchableOpacity>
      </View>
    )
  }
}
