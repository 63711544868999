import { IFareStub, IPostRequestFareEstimateResponse } from '@sparelabs/api-client'
import { FareSemantics } from '@sparelabs/domain-logic'
import { cloneDeep, isEqual, sum } from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { colors } from 'src/assets/colors'
import { ButtonWrapper } from 'src/components/buttons/ButtonWrapper'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { Counter } from 'src/components/Counter'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { PaymentFlowHelper, PurchaseInput } from 'src/helpers/payments/PaymentFlowHelper'
import { RequestHelper } from 'src/helpers/RequestHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { LoadingStore } from 'src/stores/LoadingStore'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'
import { IRequestStore } from 'src/stores/RequestStore'

const getStyles = () =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    scrollView: {
      flex: 1,
      backgroundColor: colors.blue10,
      flexDirection: 'column',
    },
    titleWrapper: {
      flex: 1,
      paddingHorizontal: 16,
      paddingTop: 16,
    },
    optionsWrapper: {
      padding: 16,
      paddingBottom: 24,
      backgroundColor: colors.white,
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderColor: colors.borderBlue,
    },
    row: {
      flexDirection: 'row',
      marginTop: 12,
      marginBottom: 12,
    },
    questionText: {
      fontWeight: 'bold',
      fontSize: 21,
      color: colors.gray90,
      paddingTop: 8,
      paddingBottom: 4,
    },
    fareChangeText: {
      fontSize: 16,
      paddingLeft: 16,
      paddingRight: 4,
      color: colors.gray70,
    },
    label: {
      alignSelf: 'flex-start',
      fontSize: 16,
      color: colors.gray90,
      paddingRight: 8,
    },
    emptyText: {
      color: colors.gray70,
    },
    leftContainer: {
      flex: 1,
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    rightContainer: {
      flex: 1,
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    fareInfo: {
      flexDirection: 'row',
      marginBottom: 16,
      alignItems: 'center',
    },
  })

const COUNTER_MINIMUM = 0
// Set to a large number, the API will disallow increments before this
const COUNTER_MAXIMUM = 99

interface IProps<T extends string> {
  handleNavigateDiscountDetails: (params: ParamsListRoot[ScreenName.DiscountDetails]) => void
  requestStore: IRequestStore
  requestId: string
  initialFare: IFareStub
  showFareChange: boolean
  availableServiceOptions: T[]
  initialRequestOptionsCount: Array<{ type: T; count: number }>
  getFareEstimate: (updatedValues: Array<{ type: T; count: number }>) => Promise<IPostRequestFareEstimateResponse>
  handleConfirmUpdate: (
    updatedValues: Array<{ type: T; count: number }>,
    purchaseInput?: PurchaseInput
  ) => Promise<void>
  question: string
  buttonTitle: string
  translationFunction: Record<T, () => string>
  allowZeroTotal: boolean
  maxTotal?: number
}

interface IState<T extends string> {
  currentRequestOptions: Array<{ type: T; count: number }>
  fareEstimate: IPostRequestFareEstimateResponse | null
}

enum LoadingKey {
  FareEstimate = 'fareEstimate',
  Confirm = 'confirm',
}

@observer
export class RequestOptions<T extends string> extends Component<IProps<T>, IState<T>> {
  private readonly loadingStore: LoadingStore = new LoadingStore()

  constructor(props: IProps<T>) {
    super(props)
    this.state = { currentRequestOptions: props.initialRequestOptionsCount, fareEstimate: null }
  }

  public handlePressCounter = async (key: T, newValue: number) => {
    try {
      let updatedRequestOptions = cloneDeep(this.state.currentRequestOptions)
      const option = updatedRequestOptions.find((option) => option.type === key)
      if (option) {
        option.count = newValue
      } else {
        updatedRequestOptions.push({ type: key, count: newValue })
      }
      // we don't want to send 0s to the API
      updatedRequestOptions = updatedRequestOptions.filter((option) => option.count > 0)

      const fareEstimate = await this.loadingStore.execute(
        this.props.getFareEstimate(updatedRequestOptions),
        LoadingKey.FareEstimate
      )
      if (fareEstimate) {
        this.setState({ currentRequestOptions: updatedRequestOptions, fareEstimate })
      }
    } catch {
      this.showAlert()
    }
  }

  public showAlert = () => {
    AlertHelper.alert(
      st.components.editRideOptions.cannotAccommodate(),
      st.components.editRideOptions.notEnoughRoom(),
      [
        {
          text: st.common.alertOk(),
        },
      ],
      { cancelable: false }
    )
  }

  public getFareChange = (): string | null => {
    if (this.state.fareEstimate) {
      const fareChange = this.state.fareEstimate.fare.total - this.props.initialFare.total
      return RequestHelper.getFareChange(fareChange, this.props.initialFare.currency)
    }
    return RequestHelper.getFareChange(0, this.props.initialFare.currency)
  }

  public renderCounter(key: T) {
    const styles = getStyles()
    const counterLabel = this.props.translationFunction[key]?.() ?? key
    const counterValue = this.state.currentRequestOptions.find((r) => r.type === key)?.count ?? 0
    const counterConfig = {
      min: COUNTER_MINIMUM,
      /**
       * If maxTotal is specified then only allow this to be incremented up to the maximum.
       * Otherwise set to a large number, the API will disallow increments before this
       */
      max: this.props.maxTotal ? this.props.maxTotal - this.getTotalCount() + counterValue : COUNTER_MAXIMUM,
    }
    return (
      <View key={key} style={styles.row}>
        <View style={styles.leftContainer}>
          <Text style={styles.label}>{counterLabel}</Text>
        </View>
        <View style={styles.rightContainer}>
          <Counter
            config={counterConfig}
            value={counterValue}
            onChange={(value) => this.handlePressCounter(key, value)}
            disabled={this.isLoading()}
            disableDecrement={
              counterValue <= counterConfig.min || (!this.props.allowZeroTotal && this.getTotalCount() <= 1)
            }
            disableIncrement={counterValue >= counterConfig.max}
            itemLabel={counterLabel}
          />
        </View>
      </View>
    )
  }

  public renderEmptyText = (text: string) => {
    const styles = getStyles()
    return (
      <View style={styles.row}>
        <Text style={styles.emptyText}>{text}</Text>
      </View>
    )
  }

  private readonly updateWithFareChange = async (fare: IFareStub) => {
    const paymentMethodId = this.props.requestStore.requestMap.get(this.props.requestId)?.paymentMethodId
    const paymentMethod = (paymentMethodId ? PaymentMethodStore.getPaymentMethodById(paymentMethodId) : null) ?? null
    // attempt creating the request through the purchase helper, which will always choose the
    // relevant payment flow
    const result = await PaymentFlowHelper.purchase(
      paymentMethod,
      {
        amount: fare.total,
        currency: fare.currency,
      },
      async (purchaseInput) => this.props.handleConfirmUpdate(this.state.currentRequestOptions, purchaseInput)
    )
    if (!result.success) {
      // notify the customer about any payment related errors
      PaymentFlowHelper.throwPaymentFailureReasonAlert(result.reason)
    }
  }

  public handleConfirmUpdate = async () => {
    await this.loadingStore.execute(
      (async () => {
        if (!this.state.fareEstimate) {
          return
        }
        if (this.state.fareEstimate.requiresNewCharge) {
          await this.updateWithFareChange(this.state.fareEstimate.fare)
        } else {
          await this.props.handleConfirmUpdate(this.state.currentRequestOptions)
        }
      })(),
      LoadingKey.Confirm
    )
  }

  private readonly handleFareInfoPress = (fareEstimate: IPostRequestFareEstimateResponse) => {
    const { fare, fareRedemptions } = fareEstimate
    this.props.handleNavigateDiscountDetails({
      fare: toJS(fare),
      fareRedemptions: toJS(fareRedemptions),
    })
  }

  private renderFareChangeWithDiscount(fareChange: string, fareEstimate: IPostRequestFareEstimateResponse) {
    const styles = getStyles()

    if (FareSemantics.hasDiscount(fareEstimate)) {
      return (
        <TouchableOpacity onPress={() => this.handleFareInfoPress(fareEstimate)} style={styles.fareInfo}>
          {this.renderFareChange(fareChange)}
          <FontAwesomeIconWrapper icon='info-circle' size={16} color={colors.gray70} />
        </TouchableOpacity>
      )
    }

    return <View style={styles.fareInfo}>{this.renderFareChange(fareChange)}</View>
  }

  private renderFareChange(fareChange: string) {
    const styles = getStyles()

    return (
      <Text numberOfLines={1} style={styles.fareChangeText}>
        {fareChange}
      </Text>
    )
  }

  private readonly getTotalCount = () => sum(this.state.currentRequestOptions.map((option) => option.count))

  private readonly isSubmitDisabled = () => {
    const isSameAsInitialValues = isEqual(this.state.currentRequestOptions, this.props.initialRequestOptionsCount)

    return isSameAsInitialValues || this.isLoading() || (!this.props.allowZeroTotal && this.getTotalCount() === 0)
  }

  private readonly isLoading = () =>
    this.loadingStore.isLoading(LoadingKey.Confirm) || this.loadingStore.isLoading(LoadingKey.FareEstimate)

  public render() {
    const styles = getStyles()
    const fareChange = this.getFareChange()

    return (
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
          <View style={styles.titleWrapper}>
            <Text style={styles.questionText}>{this.props.question}</Text>
          </View>
          <View style={{ height: 36 }}>
            {fareChange &&
              this.props.showFareChange &&
              this.state.fareEstimate &&
              this.renderFareChangeWithDiscount(fareChange, this.state.fareEstimate)}
          </View>
          <View style={styles.optionsWrapper}>
            {this.props.availableServiceOptions.map((option: T) => this.renderCounter(option))}
          </View>
        </ScrollView>
        <ButtonWrapper>
          <PrimaryButton
            title={this.props.buttonTitle}
            onPress={this.handleConfirmUpdate}
            disabled={this.isSubmitDisabled()}
            loading={this.loadingStore.isLoading(LoadingKey.Confirm)}
          />
        </ButtonWrapper>
      </SafeAreaView>
    )
  }
}
