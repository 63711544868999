import { FavoriteLocationType, IFavoriteLocationResponse } from '@sparelabs/api-client'
import Location from 'expo-location'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { AutoSuggestHelper } from 'src/helpers/AutoSuggestHelper'
import { mapFavoriteTypeToColor, mapFavoriteTypeToIcon } from 'src/helpers/FavoritesHelper'
import { st } from 'src/locales'
import { FavoriteLocationStore, MAX_NUM_FAVORITE_LOCATIONS } from 'src/stores/FavoriteLocationStore'
import { RouterStore } from 'src/stores/RouterStore'
import { SearchFieldType, SearchScreenStore } from 'src/stores/SearchScreenStore'
import { Pathname } from 'src/types/homeRoot'
import { HomeFavIconAction } from '../cards/HomeFavIconAction'

const styles = StyleSheet.create({
  iconRowContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingHorizontal: 10,
    paddingTop: 10,
  },
  iconContainer: {
    width: '25%',
    paddingHorizontal: 5,
    paddingBottom: 10,
  },
})

interface IProps {
  currentLocation: Location.LocationObject | null
  navigateToSearch: () => void
  updateSearchWithFavorite: (favorite: IFavoriteLocationResponse) => void
  handleNavigateSetFavoriteLocation: (params: { type: FavoriteLocationType }) => void
  isLocationPermissionGranted: boolean
}

const NUMBER_OF_PERMANENT_BUTTONS = 2
const NUMBER_OF_ICONS_PER_LINE = 4

@observer
export class HomeFavIconRow extends Component<IProps> {
  public onHandleActionSelect = async (
    type: FavoriteLocationType,
    favoriteLocation: IFavoriteLocationResponse | undefined,
    currentLocation: Location.LocationObject | null,
    isLocationPermissionGranted: boolean
  ) => {
    if (!favoriteLocation) {
      this.props.handleNavigateSetFavoriteLocation({ type })
    } else if (!currentLocation || !isLocationPermissionGranted) {
      this.navigateToSearch(favoriteLocation)
    } else {
      await this.navigateToConfirmOrigin(currentLocation, favoriteLocation)
    }
  }

  public render() {
    return this.renderFavorites()
  }

  private renderHomeWorkAction(type: FavoriteLocationType.Home | FavoriteLocationType.Work) {
    const hasTypeSet =
      type === FavoriteLocationType.Home ? FavoriteLocationStore.hasHomeSet : FavoriteLocationStore.hasWorkSet
    const icon = mapFavoriteTypeToIcon(type)
    const color = mapFavoriteTypeToColor(type)
    if (hasTypeSet) {
      const locations = FavoriteLocationStore.filterByType(type)
      return locations.map((location) => this.renderFavoriteAction(location))
      // if no favourite location found, create an add button
    }
    const placeholder =
      type === FavoriteLocationType.Home
        ? st.screens.favorites.homePlaceHolder()
        : st.screens.favorites.workPlaceHolder()
    return (
      <View style={[styles.iconContainer]}>
        <HomeFavIconAction
          title={placeholder}
          icon={icon}
          iconColor={color}
          onPress={() => this.props.handleNavigateSetFavoriteLocation({ type })}
        />
      </View>
    )
  }

  private renderFavoriteAction(favorite: IFavoriteLocationResponse) {
    const icon = mapFavoriteTypeToIcon(favorite.type)
    const color = mapFavoriteTypeToColor(favorite.type)
    return (
      <View key={favorite.id} style={styles.iconContainer} accessibilityLabel={favorite.name}>
        <HomeFavIconAction
          title={favorite.name || favorite.address}
          icon={icon}
          iconColor={color}
          onPress={() =>
            this.onHandleActionSelect(
              favorite.type,
              favorite,
              this.props.currentLocation,
              this.props.isLocationPermissionGranted
            )
          }
        />
      </View>
    )
  }

  private renderOtherFavorites() {
    let locations = FavoriteLocationStore.otherFavorites
    if (locations.length + NUMBER_OF_PERMANENT_BUTTONS > MAX_NUM_FAVORITE_LOCATIONS) {
      locations = locations.slice(0, MAX_NUM_FAVORITE_LOCATIONS - NUMBER_OF_PERMANENT_BUTTONS)
    }
    return locations.map((location) => this.renderFavoriteAction(location))
  }

  private renderFavorites() {
    // If there is a home or work, they will take the spot of a permanent button
    let numberOfAddButtons: number = NUMBER_OF_PERMANENT_BUTTONS
    if (FavoriteLocationStore.hasHomeSet) {
      numberOfAddButtons -= 1
    }
    if (FavoriteLocationStore.hasWorkSet) {
      numberOfAddButtons -= 1
    }

    const homeFavorites = this.renderHomeWorkAction(FavoriteLocationType.Home)
    const workFavorites = this.renderHomeWorkAction(FavoriteLocationType.Work)

    // Number of favorites + number of permanent buttons is greater than the number that hsould be on the first line
    const isWrappedContainer = FavoriteLocationStore.favorites.length + numberOfAddButtons > NUMBER_OF_ICONS_PER_LINE
    const wrapStyle: StyleProp<ViewStyle> = isWrappedContainer ? { justifyContent: 'flex-start' } : {}
    return (
      <View style={[styles.iconRowContainer, wrapStyle]}>
        {homeFavorites}
        {workFavorites}
        {this.renderOtherFavorites()}
      </View>
    )
  }

  private readonly navigateToConfirmOrigin = async (
    currentLocation: Location.LocationObject,
    favouriteLocation: IFavoriteLocationResponse
  ) => {
    const currentLocationSuggestion = AutoSuggestHelper.mapPositionToAutoSuggestion(currentLocation)
    const favoriteAutoSuggestion = AutoSuggestHelper.mapFavoriteToAutoSuggestion(favouriteLocation)
    if (currentLocationSuggestion) {
      // set searchScreen values in case we want to return to search screen through OriginDestinationPill
      SearchScreenStore.setAutoSuggestion(currentLocationSuggestion, SearchFieldType.Origin)
      SearchScreenStore.setAutoSuggestion(favoriteAutoSuggestion, SearchFieldType.Destination)
      SearchScreenStore.savePreviousOriginDestination()

      await RouterStore.goToScreen({
        pathname: Pathname.RideOptionConfirmOrigin,
        state: {
          estimateInput: {
            requestedDropoffAddress: favouriteLocation.address,
            requestedDropoffLocation: favouriteLocation.location,
            requestedPickupLocation: currentLocationSuggestion.location,
            // Do not fill in address when using current location, confirm origin will reverse geocode the address
            requestedPickupAddress: null,
          },
        },
      })
    }
  }

  private readonly navigateToSearch = (favouriteLocation: IFavoriteLocationResponse) => {
    this.props.updateSearchWithFavorite(favouriteLocation)
    this.props.navigateToSearch()
  }
}
