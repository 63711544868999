import { IconName } from '@fortawesome/pro-solid-svg-icons'
import {
  FavoriteLocationType,
  IAutocompleteSuggestionsResponse,
  IFavoriteLocationResponse,
  IPlaceDetailsResponse,
  IStopResponse,
  ServiceStatus,
  ServiceType,
} from '@sparelabs/api-client'
import { buildPoint, Geography, IPoint } from '@sparelabs/geography'
import Location from 'expo-location'
import Fuse from 'fuse.js'
import { debounce, uniqWith } from 'lodash'
import { Region } from 'react-native-maps'
import { listStops, reverseGeocode, searchPlaces } from 'src/api'
import { colors } from 'src/assets/colors'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { st } from 'src/locales'
import { FavoriteLocationStore } from 'src/stores/FavoriteLocationStore'
import { handleError } from './ErrorHelpers'
import { mapFavoriteTypeToColor, mapFavoriteTypeToIcon } from './FavoritesHelper'

export enum AutoSuggestType {
  Stop = 'stop',
  Favorite = 'favorite',
  SetLocationOnMap = 'setLocationOnMap',
  PlacesPrediction = 'placesPrediction',
  Place = 'place',
  Geocode = 'geocode',
  CurrentLocation = 'currentLocation',
}

export const searchItemIconMap: Record<AutoSuggestType, IconName> = {
  [AutoSuggestType.Stop]: 'flag',
  [AutoSuggestType.SetLocationOnMap]: 'map-pin',
  [AutoSuggestType.PlacesPrediction]: 'map-marker',
  [AutoSuggestType.Favorite]: 'star',
  [AutoSuggestType.Place]: 'map-marker',
  [AutoSuggestType.CurrentLocation]: 'location',
  [AutoSuggestType.Geocode]: 'map-pin',
}

export const searchItemColorMap: Record<AutoSuggestType, string> = {
  [AutoSuggestType.Stop]: colors.yellow,
  [AutoSuggestType.SetLocationOnMap]: colors.gray5,
  [AutoSuggestType.Geocode]: colors.gray5,
  [AutoSuggestType.PlacesPrediction]: colors.paleRed,
  [AutoSuggestType.Place]: colors.paleRed,
  [AutoSuggestType.CurrentLocation]: colors.gray5,
  [AutoSuggestType.Favorite]: colors.selectedBlue,
}

export interface IBaseAutoSuggestion<T> {
  type: T
  key: string
  name: string
  searchKey: string
}

export interface IPlainAutoSuggestion
  extends IBaseAutoSuggestion<AutoSuggestType.CurrentLocation | AutoSuggestType.Geocode> {
  address: string | null
  location: IPoint | null
}

export interface IStopAutoSuggestion extends IBaseAutoSuggestion<AutoSuggestType.Stop> {
  description: string | null
  location: IPoint
}

export interface IPlaceholderAutosuggestion extends IBaseAutoSuggestion<AutoSuggestType.SetLocationOnMap> {}

export interface IPlacePredictionAutoSuggestion extends IBaseAutoSuggestion<AutoSuggestType.PlacesPrediction> {
  placeId: string
  address: string
  location: IPoint | null
}

export interface IFavoriteAutosuggestion extends IBaseAutoSuggestion<AutoSuggestType.Favorite> {
  favoriteType: FavoriteLocationType
  location: IPoint
  address: string | null
}

export type SetAutoSuggestions =
  | IPlainAutoSuggestion
  | IPlacePredictionAutoSuggestion
  | IFavoriteAutosuggestion
  | IStopAutoSuggestion

export type AutoSuggestion = SetAutoSuggestions | IPlaceholderAutosuggestion

export class AutoSuggestHelper {
  public static getFavoriteSuggestions = (
    searchTerm: string,
    favorites: Fuse<IFavoriteAutosuggestion, Fuse.FuseOptions<IFavoriteAutosuggestion>> | null
  ): IFavoriteAutosuggestion[] => {
    if (searchTerm.length > 0 && favorites) {
      return favorites.search<IFavoriteAutosuggestion, { includeScore: false }, { includeMatches: false }>(searchTerm)
    }
    return AutoSuggestHelper.mapFavoritesArrayToAutoSuggestion(FavoriteLocationStore.favorites)
  }

  public static async getStopSuggestion(searchTerm: string): Promise<IStopAutoSuggestion[]> {
    let stopsResponse: IStopResponse[] | null = null
    let stopAutoSuggestions: IStopAutoSuggestion[] = []
    try {
      if (AuthenticatorHelper.userOrgToken) {
        const stopsQuery = {
          limit: 20,
          serviceType: ServiceType.OnDemand,
          serviceStatus: ServiceStatus.Enabled,
          isVisibleToRiderApp: true,
          search: searchTerm,
        }
        const res = await listStops(AuthenticatorHelper.userOrgToken, stopsQuery)
        if (res) {
          stopsResponse = res.body.data
        }
      }
    } catch (error) {
      handleError({ error: error as Error })
    }
    if (stopsResponse) {
      stopAutoSuggestions = AutoSuggestHelper.mapStopsToAutoSuggestion(stopsResponse)
    }

    return stopAutoSuggestions
  }

  public static mapStopsToAutoSuggestion(stops: IStopResponse[]): IStopAutoSuggestion[] {
    const uniqueStops = this.deduplicateByLocation(stops)
    return uniqueStops.map((stop: IStopResponse): IStopAutoSuggestion => {
      const name = stop.name || stop.code
      const description = stop.description
      return {
        key: stop.id,
        type: AutoSuggestType.Stop,
        name,
        description,
        searchKey: `${name} ${description}`,
        location: stop.location,
      }
    })
  }

  public static getPlacesSuggestions = debounce(
    async (searchTerm: string, referencePoint?: IPoint | null): Promise<IPlacePredictionAutoSuggestion[] | null> => {
      try {
        // Debounce Places suggestions so that we do not request useless information
        const suggestions = await searchPlaces(searchTerm, referencePoint)
        return this.formatPlacesAutocompleteResponse(suggestions)
      } catch (error) {
        handleError({ error: error as Error })
      }

      return null
    },
    200,
    { leading: true }
  )

  public static createSearchIndex<T>(list: T[]): Fuse<T, Fuse.FuseOptions<T>> {
    const options: Fuse.FuseOptions<T> = {
      shouldSort: true,
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 2,
    }
    const keys: string[] = ['searchKey']
    const fuse = new Fuse(list, { ...options, keys })
    return fuse
  }

  public static mapPositionToAutoSuggestion(position: Location.LocationObject | null): IPlainAutoSuggestion {
    const name = st.screens.search.currentLocation()
    return {
      type: AutoSuggestType.CurrentLocation,
      key: AutoSuggestType.CurrentLocation,
      address: null,
      name,
      searchKey: name,
      location: position ? buildPoint(position.coords.longitude, position.coords.latitude) : null,
    }
  }

  public static mapFavoritesArrayToAutoSuggestion(locations: IFavoriteLocationResponse[]): IFavoriteAutosuggestion[] {
    const homeWorkArray: IFavoriteAutosuggestion[] = []
    const favoriteAutoSuggestions: IFavoriteAutosuggestion[] = []
    for (const location of locations) {
      const favoriteSuggestion = this.mapFavoriteToAutoSuggestion(location)
      if (location.type === FavoriteLocationType.Home || location.type === FavoriteLocationType.Work) {
        homeWorkArray.push(favoriteSuggestion)
      } else {
        favoriteAutoSuggestions.push(favoriteSuggestion)
      }
    }
    return homeWorkArray.concat(favoriteAutoSuggestions)
  }

  public static mapFavoriteToAutoSuggestion(favorite: IFavoriteLocationResponse): IFavoriteAutosuggestion {
    const name = favorite.name || favorite.address
    const address = favorite.name ? favorite.address : null
    return {
      key: favorite.id,
      type: AutoSuggestType.Favorite,
      name,
      address,
      favoriteType: favorite.type,
      searchKey: `${name} ${address}`,
      location: favorite.location,
    }
  }

  public static mapSetOnMapToAutoSuggestion(): IPlaceholderAutosuggestion {
    return {
      key: AutoSuggestType.SetLocationOnMap,
      type: AutoSuggestType.SetLocationOnMap,
      name: st.screens.search.setLocationOnMap(),
      searchKey: st.screens.search.setLocationOnMap(),
    }
  }

  public static async mapRegionToAutoSuggestion(mapRegion: Region): Promise<IPlainAutoSuggestion | null> {
    try {
      const { latitude, longitude } = mapRegion
      if (latitude === 0 && longitude === 0) {
        return null
      }

      const res = await reverseGeocode({ latitude, longitude })
      let address: string
      if (res.address) {
        address = res.address
      } else {
        address = `${mapRegion.latitude.toFixed(2)}°, ${mapRegion.longitude.toFixed(2)}°`
      }

      return {
        type: AutoSuggestType.Geocode,
        key: 'geocodeResult',
        address: null,
        name: address,
        searchKey: address,
        location: buildPoint(longitude, latitude),
      }
    } catch (error) {
      handleError({ error: error as Error })
    }
    return null
  }

  public static formatPlacesAutocompleteResponse(
    suggestions: IAutocompleteSuggestionsResponse[]
  ): IPlacePredictionAutoSuggestion[] {
    return suggestions.map((suggestion): IPlacePredictionAutoSuggestion => {
      const name = suggestion.label
      const address = suggestion.subLabel
      const type = AutoSuggestType.PlacesPrediction
      return {
        key: suggestion.placeId,
        placeId: suggestion.placeId,
        type,
        name,
        address,
        searchKey: `${name} ${address}`,
        location: null,
      }
    })
  }

  public static mapPlaceDetail(detail: IPlaceDetailsResponse): {
    id: string
    name: string | null
    address: string | null
    location: IPoint | null
  } {
    return {
      id: detail.placeId,
      name: detail.name ?? null,
      address: detail.address ?? null,
      location: detail.location ? buildPoint(detail.location[1], detail.location[0]) : null,
    }
  }

  public static getColorForAutoSuggestType(autoSuggestion: AutoSuggestion) {
    if (autoSuggestion.type === AutoSuggestType.Favorite) {
      return mapFavoriteTypeToColor(autoSuggestion.favoriteType)
    }
    return searchItemColorMap[autoSuggestion.type] || colors.paleRed
  }

  public static getIconForAutoSuggestType(autoSuggestion: AutoSuggestion): IconName {
    if (autoSuggestion.type === AutoSuggestType.Favorite) {
      return mapFavoriteTypeToIcon(autoSuggestion.favoriteType)
    }
    return searchItemIconMap[autoSuggestion.type] || 'map-marker'
  }

  private static deduplicateByLocation(stops: IStopResponse[]): IStopResponse[] {
    return uniqWith(stops, (stop1, stop2) => Geography.arePointsEqual(stop1.location, stop2.location))
  }
}
