import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { getOriginDestinationPillMargin } from 'src/helpers/EstimateHelper'
import { ROUTER_CONTENT_MAX_WIDTH } from 'src/screens/HomeRootHelper'
import { SearchFieldType } from 'src/stores/SearchScreenStore'
import { UIStateStore } from 'src/stores/UIStore'

const styles = StyleSheet.create({
  touchable: {
    shadowColor: colors.darkShadowColor,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 6,
    shadowOpacity: 1,
    elevation: 4,
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: colors.white,
    marginHorizontal: 8,
    borderRadius: 8,
  },
  arrow: {
    tintColor: colors.gray50,
    width: 12,
    height: 12,
    marginHorizontal: 8,
    alignSelf: 'center',
  },
  textTouchableLeft: {
    flexBasis: 'auto',
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'flex-end',
    paddingLeft: 12,
  },
  textTouchableRight: {
    flexBasis: 'auto',
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'flex-start',
    paddingRight: 12,
  },
  text: {
    fontSize: 14,
    color: colors.gray90,
    paddingVertical: 12,
  },
})

interface IProps {
  requestedPickupAddress: string
  requestedDropoffAddress: string
  handlePressPill: (searchField: SearchFieldType) => void
}

@observer
export class OriginDestinationPill extends Component<IProps> {
  private pickupDropoffComponent() {
    const { requestedPickupAddress, requestedDropoffAddress, handlePressPill } = this.props
    return (
      <>
        <TouchableOpacity style={styles.textTouchableLeft} onPress={() => handlePressPill(SearchFieldType.Origin)}>
          <Text style={styles.text} numberOfLines={1}>
            {requestedPickupAddress}
          </Text>
        </TouchableOpacity>
        <Image style={styles.arrow} resizeMode='contain' source={Images.arrowRight} />
        <TouchableOpacity
          style={styles.textTouchableRight}
          onPress={() => handlePressPill(SearchFieldType.Destination)}
        >
          <Text style={styles.text} numberOfLines={1}>
            {requestedDropoffAddress}
          </Text>
        </TouchableOpacity>
      </>
    )
  }

  public render() {
    const { handlePressPill } = this.props
    return (
      <SafeAreaView edges={['top']}>
        <TouchableOpacity
          style={[
            styles.touchable,
            {
              marginTop: getOriginDestinationPillMargin(),
            },
            UIStateStore.shouldShowLandscapeWeb ? this.getWebStylesOverride() : {},
          ]}
          onPress={() => handlePressPill(SearchFieldType.Destination)}
        >
          {this.pickupDropoffComponent()}
        </TouchableOpacity>
      </SafeAreaView>
    )
  }

  private readonly getWebStylesOverride = () => ({
    maxWidth: ROUTER_CONTENT_MAX_WIDTH,
    marginHorizontal: 0,
  })
}
