import { observer } from 'mobx-react/native'
import React from 'react'
import { FlatList, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { AutoSuggestion, AutoSuggestType, SetAutoSuggestions } from 'src/helpers/AutoSuggestHelper'
import { AutoSuggestListItem } from './AutoSuggestListItem'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  footerComponent: {
    alignItems: 'center',
    padding: 25,
  },
})

const FooterComponent = () => (
  <View style={styles.footerComponent}>
    <SvgIconWrapper icon={SvgIcon.GoogleLabel} widthFixed={144} heightFixed={18} />
  </View>
)

interface IProps {
  onPressSearchResult: (suggestion: SetAutoSuggestions) => void
  onPressSetLocationOnMap: () => void
  searchSuggestions: AutoSuggestion[]
  style: StyleProp<ViewStyle>
}

export const AutoSuggestList = observer((props: IProps) => {
  const renderFooter = () => {
    const googleSuggestion = props.searchSuggestions.find(
      (suggestion) => suggestion.type === AutoSuggestType.PlacesPrediction
    )
    if (googleSuggestion) {
      return FooterComponent
    }
    return null
  }

  const handlePress = (suggestion: AutoSuggestion) => {
    if (suggestion.type === AutoSuggestType.SetLocationOnMap) {
      props.onPressSetLocationOnMap()
    } else {
      props.onPressSearchResult(suggestion)
    }
  }

  return (
    <View style={styles.container}>
      <FlatList
        renderItem={({ item }) => (
          <AutoSuggestListItem key={item.key} testID={item.name} onPress={() => handlePress(item)} searchItem={item} />
        )}
        keyboardShouldPersistTaps='always'
        data={props.searchSuggestions}
        ListFooterComponent={renderFooter()}
        keyExtractor={(item) => item.key}
        style={props.style}
      />
    </View>
  )
})
