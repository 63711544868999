import { IEstimateService } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { AccessibleBottomSheetPosition } from 'src/components/accessibleBottomSheet/AccessibleBottomSheetView'
import { LyftPassCard } from 'src/components/rideOptions/LyftPassCard'
import { RideOptionsCardServiceInfo } from 'src/components/rideOptions/RideOptionsCardServiceInfo'
import { ServiceHours } from 'src/components/rideOptions/ServiceHours'
import { isLyftPassLinkEnabled } from 'src/helpers/EstimateHelper'
import { triggerConfirmServiceScreen } from 'src/helpers/RideOptionsCardHelper'
import { st } from 'src/locales'
import { IEstimateStore } from 'src/stores/EstimateStore'
import { RouterStore } from 'src/stores/RouterStore'
import { EstimatesUserInputParsed } from 'src/types'
import { Pathname } from 'src/types/homeRoot'
import { ExternalRideOptions } from 'src/types/rideOptions'

const styles = StyleSheet.create({
  container: {
    paddingTop: 16,
  },
  sectionTitleContainer: {
    borderBottomWidth: 1,
    borderColor: colors.borderBlue,
    paddingBottom: 8,
    paddingHorizontal: 16,
  },
  sectionTitle: {
    fontSize: 14,
    color: colors.gray70,
    lineHeight: 16,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
})

interface IProps {
  position: AccessibleBottomSheetPosition
  selectedRideOptionId: string | null
  setRideOptions: (rideOptionId: string) => void
  estimateStore: IEstimateStore
  showSectionTitles: boolean
  estimateInput: EstimatesUserInputParsed
  confirmServicePathname: string
}
@observer
export class RideOptionsServicesList extends Component<IProps> {
  public render() {
    return (
      <View style={styles.container}>
        {this.props.showSectionTitles ? (
          <View style={styles.sectionTitleContainer}>
            <Text style={styles.sectionTitle}>{st.components.rideOptionsCard.onDemandOptions()}</Text>
          </View>
        ) : null}

        {isLyftPassLinkEnabled() && (
          <LyftPassCard
            estimates={this.props.estimateStore.lyftEstimates}
            selectedRideOptionId={this.props.selectedRideOptionId}
            handleLyftPress={this.handleLyftPress}
            accessibilityRole='radio'
          />
        )}

        {this.props.estimateStore.estimateServices?.map((s, i) => (
          <RideOptionsCardServiceInfo
            estimateStore={this.props.estimateStore}
            service={s}
            selected={s.serviceId === this.props.selectedRideOptionId}
            handleSelectedService={this.handleSelectedService}
            key={i}
            accessibilityRole='radio'
          />
        ))}
      </View>
    )
  }

  public handleLyftPress = async () => {
    this.props.setRideOptions(ExternalRideOptions.Lyft)
    if (this.props.position === AccessibleBottomSheetPosition.Top) {
      await RouterStore.goToScreen({
        pathname: Pathname.LyftPassLink,
      })
    }
  }

  public renderServiceHours = (estimateServices: IEstimateService[]) => (
    <ServiceHours estimatesUserInput={this.props.estimateInput} estimateServices={estimateServices} />
  )

  public handleSelectedService = async (selectedService: IEstimateService) => {
    /**
     * The selectedService is optional as we select the first service by default.
     * And we trigger the EstimateConfirmService screen when rider selects a service,
     * hence if the rider presses the Next button the service is already selected on the list.
     */
    this.props.setRideOptions(selectedService.serviceId)
    // Only trigger the confirmation screen if the modal is fully open, otherwise the rider must press next
    if (this.props.position === AccessibleBottomSheetPosition.Top) {
      await triggerConfirmServiceScreen(
        selectedService,
        this.props.estimateStore.estimateResponseMap,
        this.props.estimateInput,
        this.props.confirmServicePathname
      )
    }
  }
}
