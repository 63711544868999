import React, { lazy, Suspense } from 'react'
import { Text } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context'

const StorybookUINative = lazy(() => import('./StorybookUI'))

/**
 * Lazy loading of storybook is needed because of this issue:
 * https://github.com/storybookjs/react-native/issues/20
 *
 * This is needs to be done together with the global promise overwrite to bluebird
 */
export const StorybookScreen: React.FC = () => (
  <Suspense fallback={<Text> loading </Text>}>
    <SafeAreaProvider>
      <StorybookUINative />
    </SafeAreaProvider>
  </Suspense>
)
