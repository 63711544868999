import { IJourney, IRequestResponse } from '@sparelabs/api-client'
import { toJS } from 'mobx'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { BackButton } from 'src/components/buttons/BackButton'
import { CardWrapper } from 'src/components/cards/CardWrapper'
import { shouldShowContinueJourneyButton } from 'src/components/journey/ContinueJourneyButton'
import { LastCompletedRequestCard } from 'src/components/request/LastCompletedRequestCard'
import { TipHelper } from 'src/helpers/TipHelper'
import { ParamsListReview, ParamsListRoot, ScreenName } from 'src/navigation'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { RequestStore } from 'src/stores/RequestStore'
import { RouterStore } from 'src/stores/RouterStore'
import { EstimatesUserInputParsed } from 'src/types'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

interface IProps {
  handleNavigateReview: (params: ParamsListReview[ScreenName.Review]) => void
  handleNavigateEmissionsDetails: (params: ParamsListRoot[ScreenName.EmissionsDetails]) => void
  journey: IJourney | null
  initialEstimateInputStore: IEstimateInputStore
  journeyEstimateInput: EstimatesUserInputParsed | null
}

interface IState {
  canRiderTip: boolean
}

@observer
export class LastCompletedRequestScreen extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      canRiderTip: false,
    }
  }

  public async componentDidMount() {
    if (RequestStore.lastCompletedRequest) {
      const canRiderTip = await TipHelper.checkRiderCanTip(RequestStore.lastCompletedRequest)
      this.setState({ canRiderTip })
    }
  }

  public shouldShowCard = (
    request: IRequestResponse,
    journey: IJourney | null,
    initialEstimateInput: EstimatesUserInputParsed | null,
    journeyEstimateInput: EstimatesUserInputParsed | null
  ): boolean =>
    request &&
    (request.shouldRiderReview ||
      request.scheduledDropoffAddress !== request.requestedDropoffAddress ||
      shouldShowContinueJourneyButton(journey, initialEstimateInput, journeyEstimateInput))

  public render(): JSX.Element | null {
    const request = RequestStore.lastCompletedRequest
    const { journey, initialEstimateInputStore, journeyEstimateInput } = this.props

    if (
      !request ||
      !this.shouldShowCard(request, journey, initialEstimateInputStore.estimateInput, journeyEstimateInput)
    ) {
      void RouterStore.returnHome()
      return null
    }

    return (
      <View key='route-container' style={styles.container} pointerEvents='box-none' testID='requestView'>
        <CardWrapper containerStyle={{ bottom: 0 }}>
          {this.state.canRiderTip ? null : <BackButton onPress={() => RouterStore.returnHome()} />}
          <LastCompletedRequestCard
            handleNavigateReview={(params) => {
              this.props.handleNavigateReview({
                ...params,
                request: toJS(request),
                canRiderTip: this.state.canRiderTip,
              })
            }}
            handleNavigateEmissionsDetails={(params) => {
              this.props.handleNavigateEmissionsDetails(params)
            }}
            request={request}
            initialEstimateInputStore={initialEstimateInputStore}
            journeyEstimateInput={journeyEstimateInput}
            journey={journey}
          />
        </CardWrapper>
      </View>
    )
  }
}
