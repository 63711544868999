import { IAnnouncementResponse } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { AnnouncementHelper } from 'src/helpers/AnnouncementHelper'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { AnnouncementTitleBar } from 'src/screens/announcements/AnnouncementTitleBar'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.sectionBlue,
  },
  titleBarContainer: {
    marginTop: 15,
    paddingHorizontal: 15,
  },
  contentContainer: {
    backgroundColor: colors.white,
    paddingHorizontal: 24,
    paddingTop: 32,
    marginTop: -20,
    zIndex: -1,
    flexDirection: 'column',
  },
  title: {
    fontSize: 16,
    color: colors.gray90,
    paddingBottom: 15,
    lineHeight: 24,
  },
  subTitle: {
    fontSize: 14,
    color: colors.gray70,
    paddingBottom: 16,
  },
  divider: {
    borderTopColor: colors.borderGray,
    borderTopWidth: 1,
    marginTop: 5,
  },
  moreInfoTitle: {
    fontSize: 16,
    color: colors.blue50,
  },
  moreInfoAction: {
    paddingVertical: 20,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
})

interface IProps {
  handleActionPress: (uri: string | null) => void
  announcement: IAnnouncementResponse
}

export class AnnouncementItem extends Component<IProps> {
  public renderAction(announcement: IAnnouncementResponse) {
    if (announcement.openUrl) {
      return (
        <>
          <View style={styles.divider} />
          <TouchableOpacity
            style={styles.moreInfoAction}
            onPress={() => this.props.handleActionPress(announcement.openUrl)}
            accessibilityRole='link'
          >
            <Text style={styles.moreInfoTitle}>{st.screens.listAnnouncements.moreInfo() + ' →'}</Text>
          </TouchableOpacity>
        </>
      )
    }
    return null
  }

  public renderTimeAgo(announcement: IAnnouncementResponse) {
    const isRecurring = announcement.startDate || announcement.endDate
    const timeAgo = moment.unix(announcement.createdAt).fromNow()
    if (!isRecurring) {
      return <Text style={styles.subTitle}>{st.screens.listAnnouncements.posted({ timeAgo })}</Text>
    }
    return null
  }

  public render() {
    const { title, icon, details, color } = this.props.announcement
    return (
      <View style={styles.container}>
        <View style={styles.titleBarContainer}>
          <AnnouncementTitleBar
            title={title}
            icon={AnnouncementHelper.getAnnouncementIcon(icon)}
            backgroundColor={color}
          />
        </View>
        <View style={styles.contentContainer}>
          <Text style={styles.title}>{details}</Text>
          {this.renderTimeAgo(this.props.announcement)}
          {this.renderAction(this.props.announcement)}
        </View>
      </View>
    )
  }
}
