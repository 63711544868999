import { IUserStub } from '@sparelabs/api-client'
import { IUserData } from '@sparelabs/authenticator'
import React, { Component } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { hash } from '../helpers/StringHelper'

const styles = StyleSheet.create({
  profileImage: {
    height: 52,
    width: 52,
    borderRadius: 26,
  },
  profileTintedImage: {
    tintColor: colors.gray1,
  },
  initials: {
    fontSize: 20,
    backgroundColor: 'transparent',
    textAlign: 'center',
    color: colors.gray1,
  },
  initialsWrapper: {
    height: 52,
    width: 52,
    borderRadius: 26,
    backgroundColor: '#DCDCDC',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

interface IProps {
  user: IUserData | IUserStub
  icon?: string
  height: number
}

export class ProfileImage extends Component<IProps> {
  public renderProfile = () =>
    this.props.user && this.props.user.photoUrl && !this.props.icon ? (
      <Image
        resizeMode='cover'
        style={[
          styles.profileImage,
          this.props.height
            ? {
                height: this.props.height,
                width: this.props.height,
                borderRadius: this.props.height / 2,
              }
            : null,
        ]}
        source={{ uri: this.props.user.photoUrl }}
        key={hash(this.props.user.photoUrl)}
      />
    ) : (
      <View
        style={[
          styles.initialsWrapper,
          this.props.height
            ? {
                height: this.props.height,
                width: this.props.height,
                borderRadius: this.props.height / 2,
              }
            : null,
        ]}
      >
        {this.props.icon || !this.props.user.firstName ? (
          <Image
            resizeMode='cover'
            style={styles.profileTintedImage}
            source={this.props.icon ? this.props.icon : Images.user}
          />
        ) : (
          <Text style={[styles.initials, this.props.height ? { fontSize: this.props.height * 0.35 } : null]}>
            {this.props.user.firstName[0]}
            {this.props.user.lastName ? this.props.user.lastName[0] : null}
          </Text>
        )}
      </View>
    )

  public render() {
    return this.renderProfile()
  }
}
