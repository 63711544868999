export enum ScreenName {
  RootSetOrganization = 'SetOrganization',
  RootLogin = 'Login',
  RootHome = 'HomeRoot',
  RootStoryBookUI = 'StoryBookUI',

  GroupSetPhoneNumber = 'SetPhoneNumberGroup',
  Login = 'Login',
  GroupEditPhoneNumber = 'EditPhoneNumberGroup',
  GroupAccount = 'AccountGroup',
  GroupAddPaymentMethod = 'AddPaymentMethodGroup',
  GroupReview = 'GroupReview',
  GroupMemberships = 'GroupMemberships',
  GroupDeveloperMenu = 'DeveloperMenuGroup',
  GroupScheduledTripsList = 'GroupScheduledTripsList',

  NavigatorBackButton = 'NavigatorBackButton',
  SetPhoneNumber = 'SetPhoneNumber',
  SetPhoneNumberConfirm = 'SetPhoneNumberConfirm',
  EditPhoneNumber = 'EditPhoneNumber',
  EditPhoneNumberConfirm = 'EditPhoneNumberConfirm',
  SetProfile = 'SetProfile',
  Account = 'Account',
  FavoritesListScreen = 'FavoritesListScreen',
  FarePassList = 'FarePassList',
  ViewFarePass = 'ViewFarePass',
  FarePassHistoryList = 'FarePassHistoryList',
  PaymentMethods = 'PaymentMethods',
  AddPaymentMethod = 'AddPaymentMethod',
  Permissions = 'Permissions',
  ViewCreditCard = 'ViewCreditCard',
  ViewSepa = 'ViewSepa',
  ViewNimoca = 'ViewNimoca',
  ViewCash = 'ViewCash',
  SetFavoriteLocation = 'SetFavoriteLocation',
  Onboarding = 'Onboarding',
  CloseModalButton = 'CloseModalButton',
  WebViewModal = 'WebViewModal',
  Review = 'Review',
  AddTip = 'AddTip',
  CustomTipScreen = 'CustomTipScreen',
  GroupDetails = 'GroupDetails',
  JoinGroup = 'JoinGroup',
  AddStripePaymentMethodSession = 'AddStripePaymentMethodSession',
  ConfirmStripePayment = 'ConfirmStripePayment',
  ScheduledTripsList = 'ScheduledTripsList',
  ScheduledTrip = 'ScheduledTrip',
  PastTripsList = 'PastTripsList',
  PastTrip = 'PastTrip',
  ListAnnouncements = 'ListAnnouncements',
  EditFavoriteScreen = 'EditFavoriteScreen',
  RequestCancellation = 'RequestCancellation',
  AddNimoca = 'AddNimoca',
  ListPromo = 'ListPromo',
  AddPromo = 'AddPromo',
  SelectPaymentMethod = 'SelectPaymentMethod',
  DeveloperMenu = 'DeveloperMenu',
  DeveloperHostSelect = 'DeveloperHostSelect',
  DeveloperReviewerLogin = 'DeveloperReviewerLogin',
  ConfirmPurchase = 'ConfirmPurchase',
  RequestRiderOptions = 'RequestRiderOptions',
  RequestAccessibilityOptions = 'RequestAccessibilityOptions',
  RequestCustomFields = 'RequestCustomFields',
  DiscountDetails = 'DiscountDetails',
  EstimateRiderOptions = 'EstimateRiderOptions',
  EstimateAccessibilityOptions = 'EstimateAccessibilityOptions',
  ScheduleEstimate = 'ScheduleEstimate',
  AcknowledgeableAnnouncementModal = 'AcknowledgeableAnnouncementModal',
  SingleChoiceModal = 'SingleChoiceModal',
  MultipleChoiceModal = 'MultipleChoiceModal',
  DatetimeModal = 'DatetimeModal',
  Legal = 'Legal',
  Address = 'Address',
  Survey = 'Survey',
  EmissionsDetails = 'EmissionsDetails',
  DstFallBackChoice = 'DstFallBackChoice',
  FleetAgreementList = 'FleetAgreementsList',
  FleetAgreementsDetails = 'FleetAgreementsDetails',
  FleetAgreementModal = 'FleetAgreementModal',
}
