import { IEstimateOutput, IRequestResponse } from '@sparelabs/api-client'
import { IPoint } from '@sparelabs/geography'
import { CompassDirection } from 'geolib'
import { ImageRequireSource, ImageStyle, ImageURISource, StyleProp } from 'react-native'
import { EdgePadding, LatLng } from 'react-native-maps'
import { ISimpleJourneyRoute } from 'src/types/journey'
import { IEstimatesUserInputParsed } from './estimate'

export interface IMarkerCoordinates {
  longitude: number
  latitude: number
}

export interface IMapOptions {
  edgePadding?: EdgePadding
  animated?: boolean
}

export interface IMapState {
  coordinates: LatLng[]
  options?: IMapOptions
}

export interface IMarker {
  name: MarkerTypes
  coordinate: IPoint
  image?: ImageURISource | ImageRequireSource
  imageStyle?: StyleProp<ImageStyle>
  style?: { [key: string]: string | number }
  // react-map-gl specific (Web)
  webCenterOffset?: {
    offsetLeft: number
    offsetTop: number
  }
  centerOffset?: {
    x: number
    y: number
  }
  anchor?: {
    x: number
    y: number
  }
  child?: JSX.Element
  pointerEvents?: 'box-none' | 'none' | 'box-only' | 'auto'
  key?: string
}

export enum MarkerTypes {
  Preload = 'preload',
  DropoffPin = 'dropoff-pin',
  PickupPill = 'pickup-pill',
  PickupPin = 'pickup-pin',
  StartPin = 'start-pin',
  EndPin = 'end-pin',
  FixedLegPin = 'fixed-leg-pin',
  DropoffLabel = 'dropoff-label',
  PickupLabel = 'pickup-label',
  StartLabel = 'start-label',
  EndLabel = 'end-label',
  BoardLabel = 'board-label',
  ExitLabel = 'exit-label',
  StopPin = 'stop-pin',
  VehiclePin = 'vehicle-pin',
  SearchLocationPin = 'search-location-pin',
}

// Definition in library is wrong and library is no longer being updated
export interface ICompassDirection extends CompassDirection {
  bearing: number
}

export type Mappable = IEstimateOutput | IRequestResponse | IEstimatesUserInputParsed | ISimpleJourneyRoute

export interface IAutocompletePrediction {
  description: string
  place_id: string
  reference: string
  structured_formatting: IAutocompleteStructuredFormatting
}

export interface IAutocompleteStructuredFormatting {
  main_text: string
  secondary_text: string
}

export interface IGeocoderResult {
  address_components: IGeocoderAddressComponent[]
  formatted_address: string
  geometry: any
  partial_match: boolean
  place_id: string
  postcode_localities: string[]
  types: string[]
}

export interface IGeocoderAddressComponent {
  long_name: string
  short_name: string
  types: GeocoderAddressTypes[]
}

// TODO: incomplete types
export enum GeocoderAddressTypes {
  streetNumber = 'street_number',
  route = 'route',
  neighborhood = 'neighborhood',
  political = 'political',
}
