import { IRequestResponse, RequestCancelledBy, RequestStatus } from '@sparelabs/api-client'
import { Lambda, observe } from 'mobx'
import { Component } from 'react'
import { RequestStore } from 'src/stores/RequestStore'

interface IProps {
  effectIfNda?: (request: IRequestResponse) => void
  effectIfCancelled?: (request: IRequestResponse) => void
  effectIfCompleted?: (request: IRequestResponse) => void
}

interface IState {
  disposer: Lambda | null
}

/**
 * This class helps us observe when an active request
 * changes or becomes in-active.
 */
export class ActiveRequestCompletionObserver extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      disposer: null,
    }
  }

  public observe = () => {
    /**
     * We are observing changes on activeRequest to determine when it becomes
     * NDA / Cancelled so that we can present UI alerts / modals to riders with information
     * regarding this. We don't use an autorun here because its using observe allows us to
     * see the previous value of `activeRequest`
     */
    const disposer = observe(RequestStore, 'activeRequest', ({ newValue, oldValue }) => {
      if (oldValue) {
        // If the request is still the "active request" then its not completed so we can return early
        if (newValue && newValue.id === oldValue.id) {
          return
        }
        // This code assumes that we are keeping track of the active request
        // at least until this observer runs even if its no longer active
        const request = RequestStore.requestMap.get(oldValue.id)
        if (request) {
          if (request.status === RequestStatus.NoDriversAvailable && this.props.effectIfNda) {
            this.props.effectIfNda(request)
          }
          if (
            request.status === RequestStatus.Cancelled &&
            request.cancellationDetails &&
            request.cancellationDetails.cancelledBy !== RequestCancelledBy.Rider &&
            this.props.effectIfCancelled
          ) {
            this.props.effectIfCancelled(request)
          }
          if (request.status === RequestStatus.Completed && this.props.effectIfCompleted) {
            this.props.effectIfCompleted(request)
          }
        }
      }
    })
    this.setState({ disposer })
  }

  public stop() {
    if (this.state.disposer) {
      this.state.disposer()
      this.setState({ disposer: null })
    }
  }

  public render() {
    return null
  }

  public UNSAFE_componentWillMount() {
    this.observe()
  }

  public componentWillUnmount() {
    this.stop()
  }
}
