import { observable } from 'mobx'

export class LoadingStore {
  private static readonly DEFAULT_KEY = 'default'

  @observable
  public loadingKeys: Map<string, boolean> = observable.map()

  public async execute<T>(promise: Promise<T>, key: string = LoadingStore.DEFAULT_KEY): Promise<T> {
    this.loadingKeys.set(key, true)
    let res
    try {
      res = await promise
    } finally {
      this.loadingKeys.set(key, false)
    }
    return res
  }

  public isLoading(key: string = LoadingStore.DEFAULT_KEY) {
    return this.loadingKeys.get(key)
  }
}
