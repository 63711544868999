import { FontAwesomeIconStyle, IRequestResponse, RequestStatus } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { colors } from 'src/assets/colors'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { GenericStatusBar } from 'src/screens/request/GenericStatusBar'
import { isWithinPollingThreshold } from 'src/screens/request/ScheduledRequests/ScheduledTripHelper'

interface IProps {
  request: Pick<IRequestResponse, 'status' | 'matchCutoffTs' | 'requestedPickupTs'>
}

@observer
export class RequestStatusBar extends Component<IProps> {
  public render() {
    const { status } = this.props.request
    if (status === RequestStatus.NoDriversAvailable) {
      return (
        <GenericStatusBar
          icon={[FontAwesomeIconStyle.Solid, 'exclamation-triangle']}
          color={colors.red70}
          backgroundColor={colors.red10}
          text={st.request.noDriversAvailable()}
        />
      )
    } else if (status === RequestStatus.Cancelled) {
      return (
        <GenericStatusBar
          icon={[FontAwesomeIconStyle.Solid, 'exclamation-triangle']}
          color={colors.red70}
          backgroundColor={colors.red10}
          text={st.request.rideCancelled()}
        />
      )
    } else if (status === RequestStatus.Processing) {
      return (
        <GenericStatusBar
          icon={[FontAwesomeIconStyle.Solid, 'info-circle']}
          color={colors.blue70}
          backgroundColor={colors.blue10}
          text={this.getProcessingText()}
        />
      )
    }
    return null
  }

  private readonly getProcessingText = () => {
    const { matchCutoffTs, requestedPickupTs } = this.props.request
    if (isWithinPollingThreshold(matchCutoffTs, moment().unix())) {
      const relativeTime = moment.unix(matchCutoffTs).fromNow(true)
      return st.request.moreDetailsWithinCutoff({ relativeTime })
    }

    const relativeTime = moment.unix(matchCutoffTs).to(moment.unix(requestedPickupTs), true)
    return st.request.moreDetailsBeforePickup({ relativeTime })
  }
}
