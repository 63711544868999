import { CurrencyHelper } from '@sparelabs/currency'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FarePassCard } from 'src/components/farePasses/FarePassCard'
import { handleError } from 'src/helpers/ErrorHelpers'
import { PaymentFailureType, PaymentFlowHelper } from 'src/helpers/payments/PaymentFlowHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { FarePassStore } from 'src/stores/FarePassStore'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'
import { PrimaryButton } from '../buttons/PrimaryButton'

interface IProps {
  farePassId: string
  onPurchaseComplete: (allocationId: string) => void
  handleNavigateConfirmPurchase: (params: ParamsListRoot[ScreenName.ConfirmPurchase]) => void
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: colors.white,
  },
  contentContainer: {
    margin: 16,
  },
  title: {
    marginTop: 16,
    marginBottom: 4,
    fontSize: 24,
    color: colors.gray6,
  },
})

@observer
export class PurchaseFarePassView extends Component<IProps> {
  public handleGetPassPress = async () => {
    const { name, simpleTermsOfUse, termsOfUseUrl } = FarePassStore.getFarePass(this.props.farePassId)
    this.props.handleNavigateConfirmPurchase({
      title: name,
      terms: simpleTermsOfUse,
      termsUrl: termsOfUseUrl,
      getPriceSubtitle: (): string => {
        const farePass = FarePassStore.getFarePass(this.props.farePassId)
        const priceWithCurrency = CurrencyHelper.format(farePass.cost, farePass.currency)
        return priceWithCurrency
      },
      onPressPurchase: this.handleBuyFarePass,
    })
  }

  public handleBuyFarePass = async (paymentMethodId: string): Promise<boolean> => {
    try {
      const farePass = FarePassStore.getFarePass(this.props.farePassId)
      const paymentMethod = PaymentMethodStore.getPaymentMethodById(paymentMethodId)
      if (!paymentMethod) {
        return false
      }
      const result = await FarePassStore.buyFarePass(
        this.props.farePassId,
        farePass.cost,
        farePass.currency,
        paymentMethod
      )
      if (result.success) {
        this.props.onPurchaseComplete(result.value.id)
        return true
      } else if (result.reason.type === PaymentFailureType.PriceChange) {
        await FarePassStore.updateFarePasses()
      }
      PaymentFlowHelper.throwPaymentFailureReasonAlert(result.reason)
    } catch (error) {
      handleError({ error: error as Error })
    }
    return false
  }

  public render() {
    const farePass = FarePassStore.getFarePass(this.props.farePassId)
    const priceWithCurrency = CurrencyHelper.format(farePass.cost, farePass.currency)

    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.contentContainer}>
          <FarePassCard farePass={farePass} />
          <Text style={styles.title}>{priceWithCurrency}</Text>
        </View>
        <View style={styles.contentContainer}>
          <PrimaryButton title={st.screens.farePasses.getPass()} onPress={this.handleGetPassPress} />
        </View>
      </SafeAreaView>
    )
  }
}
