import { NavigationProp } from '@react-navigation/native'
import { ApiClient, IErrorResponse, IGlobalLinkedOrganization } from '@sparelabs/api-client'
import { AxiosError } from 'axios'
import { observer } from 'mobx-react/native'
import React from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleAuthError } from 'src/helpers/ErrorHelpers'
import { Sentry } from 'src/helpers/sentry'
import { st } from 'src/locales'
import { ParamsListDeveloperMenu, ParamsListRoot, ScreenName, ScreenPropsDeveloperMenu } from 'src/navigation'
import { DeveloperReviewerLoginForm } from 'src/screens/developer/DeveloperReviewerLoginForm'
import { IDeveloperReviewerLoginFormValues } from 'src/screens/developer/DeveloperReviewerLoginTypes'
import { LoginStyle } from 'src/screens/login/LoginStyle'
import { LoadingStore } from 'src/stores/LoadingStore'
import { colors } from '../../assets/colors'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  descriptionContainer: {
    alignSelf: 'flex-start',
  },
  description: {
    fontSize: 15,
    color: colors.textDark,
    paddingRight: 20,
    paddingVertical: 20,
  },
  cellContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
})

type Props = ParamsListDeveloperMenu[ScreenName.DeveloperHostSelect] & {
  handleNavigateReplaceRootHome: () => void
  handleNavigateSetProfile: (params: ParamsListRoot[ScreenName.SetProfile]) => void
}

const DeveloperReviewerLoginView = observer((props: Props) => {
  const loadingStore = new LoadingStore()

  const loginReviewer = async (username: string, password: string, authOrganization: IGlobalLinkedOrganization) => {
    const { userId, token } = await new ApiClient({ host: authOrganization.apiHost }).auth.testCredentialsLogin({
      username,
      password,
      organizationId: authOrganization.id,
    })
    AuthenticatorHelper.userOrgToken = token
    AuthenticatorHelper.userId = userId
    AuthenticatorHelper.regionalHost = authOrganization.apiHost
  }

  const redirectToNextScreen = () => {
    if (AuthenticatorHelper.isFullUser(AuthenticatorHelper.getUser())) {
      props.handleNavigateReplaceRootHome()
    } else {
      props.handleNavigateSetProfile({ isOnboarding: true })
    }
  }

  const handlePressConfirm = async (reviewLoginValues?: IDeveloperReviewerLoginFormValues) => {
    try {
      const authOrganization = AuthenticatorHelper.globalApp?.linkedOrganizations[0]
      if (authOrganization) {
        await loadingStore.execute(
          loginReviewer(reviewLoginValues?.username || '', reviewLoginValues?.password || '', authOrganization)
        )
        await AuthenticatorHelper.fetchUserData()
        await AuthenticatorHelper.fetchOrganizationData(authOrganization.id)
        redirectToNextScreen()
      } else {
        AlertHelper.alert('No organization was linked to the app')
      }
    } catch (error) {
      Sentry.captureMessage('Reviewer login failed')
      Sentry.setExtra('extra', error)
      handleAuthError(error as AxiosError<IErrorResponse>)
    }
  }

  return (
    <ScrollView keyboardShouldPersistTaps='always' keyboardDismissMode='on-drag' style={styles.container}>
      <View style={styles.cellContainer}>
        <View style={LoginStyle.contentContainer}>
          <View style={styles.descriptionContainer}>
            <Text style={styles.description}>{st.screens.developerReviewerLogin.description()}</Text>
          </View>
          <DeveloperReviewerLoginForm
            handlePressConfirm={handlePressConfirm}
            isLoading={loadingStore.isLoading() ?? false}
          />
        </View>
      </View>
    </ScrollView>
  )
})

export const DeveloperReviewerLogin = (props: ScreenPropsDeveloperMenu<ScreenName.DeveloperReviewerLogin>) => (
  <DeveloperReviewerLoginView
    {...props.route.params}
    handleNavigateReplaceRootHome={() =>
      props.navigation
        .getParent<NavigationProp<ParamsListRoot>>()
        .reset({ routes: [{ name: ScreenName.RootHome, params: {} }] })
    }
    handleNavigateSetProfile={(params) => props.navigation.navigate(ScreenName.SetProfile, params)}
  />
)
