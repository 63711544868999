import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { ParamsListAccount, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { PaymentMethodControls } from 'src/screens/payments/PaymentMethodControls'
import { IPaymentMethodStore, PaymentMethodStore } from 'src/stores/PaymentMethodStore'

const styles = StyleSheet.create({
  viewContainer: {
    padding: 16,
    backgroundColor: colors.sectionBlue,
    flex: 1,
  },
  viewContentContainer: {
    backgroundColor: colors.white,
    borderRadius: 16,
    paddingVertical: 20,
    paddingHorizontal: 15,
    shadowColor: colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 10,
    shadowOpacity: 1,
  },
  accountNumberText: {
    marginTop: 10,
  },
})

type Props = ParamsListAccount[ScreenName.ViewSepa] & {
  paymentMethodStore: IPaymentMethodStore
  handleDismiss: () => void
}

@observer
export class ViewSepaView extends Component<Props> {
  public render(): JSX.Element | null {
    const { paymentMethodId } = this.props
    const paymentMethod = this.props.paymentMethodStore.getPaymentMethodById(paymentMethodId)
    if (paymentMethod) {
      return (
        <View style={styles.viewContainer}>
          <View style={styles.viewContentContainer}>
            <SvgIconWrapper icon={SvgIcon.SepaIcon} widthFixed={80} heightFixed={48} />
            <Text style={styles.accountNumberText}>{`${'*'.repeat(18)}${paymentMethod.last4Digits}`}</Text>
          </View>
          <PaymentMethodControls handlePopScreen={() => this.props.handleDismiss()} paymentMethodId={paymentMethodId} />
        </View>
      )
    }
    return null
  }
}

export const ViewSepa = (props: ScreenPropsAccount<ScreenName.ViewSepa>) => (
  <ViewSepaView
    {...props.route.params}
    handleDismiss={() => props.navigation.goBack()}
    paymentMethodStore={PaymentMethodStore}
  />
)
