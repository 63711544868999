import { UpdateCheckResult, UpdateFetchResult } from 'expo-updates'
import { useEffect, useState } from 'react'
import { useAsync } from 'react-async-hook'
import { Platform } from 'react-native'
import { AnalyticsHelper, EventName } from 'src/helpers/AnalyticsHelper'
import { OsType } from 'src/util/types'

export enum UpdateState {
  Loading,
  Updating,
  NoUpdate,
  FinishedUpdate,
}

export interface IUpdateHandlers {
  checkUpdate: () => Promise<UpdateCheckResult>
  fetchUpdate: () => Promise<UpdateFetchResult>
  reload: () => Promise<void>
}

export const checkUpdate = async (checkUpdateHandler: () => Promise<UpdateCheckResult>): Promise<boolean> => {
  try {
    if (!__DEV__ && Platform.OS !== OsType.Web) {
      const updateCheckResult = await checkUpdateHandler()
      return updateCheckResult.isAvailable
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
  return false
}

export const getUpdates = async (
  updateHandlers: IUpdateHandlers,
  updatesCallback: (updateState: UpdateState) => void
): Promise<boolean> => {
  try {
    await updateHandlers.fetchUpdate()
    await updateHandlers.reload()
    return true
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  updatesCallback(UpdateState.NoUpdate)
  return false
}

export const getUpdateStatus = (
  updateHandlers: IUpdateHandlers,
  isPending: boolean,
  hasUpdate: boolean,
  updatesCallback: (updateState: UpdateState) => void
): UpdateState => {
  if (isPending) {
    return UpdateState.Loading
  }
  if (hasUpdate) {
    void getUpdates(updateHandlers, updatesCallback)
    return UpdateState.Updating
  }

  return UpdateState.NoUpdate
}

export const useUpdateHook = (updateHandlers: IUpdateHandlers): UpdateState => {
  const checkUpdateAsyncStatus = useAsync(() => checkUpdate(updateHandlers.checkUpdate), [])
  const isPending: boolean = checkUpdateAsyncStatus.status === 'loading'
  const hasUpdate: boolean = checkUpdateAsyncStatus.result ?? false

  const [status, setStatus] = useState<UpdateState>(UpdateState.Loading)
  useEffect(() => {
    const updateStatus = getUpdateStatus(updateHandlers, isPending, hasUpdate, setStatus)
    if ([UpdateState.Updating, UpdateState.FinishedUpdate].includes(updateStatus)) {
      AnalyticsHelper.track(EventName.ExpoUpdateStatus, { updateStatus })
    }
    setStatus(updateStatus)
  }, [hasUpdate, isPending])

  return status
}
