import { IPoint } from '@sparelabs/geography'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { FavoriteScreenStateType, ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { SetLocationView } from 'src/screens/SetLocation'
import { FavoriteLocationEditStore } from 'src/stores/FavoriteLocationEditStore'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
interface IState {
  isShowingMap: boolean
  favoriteEditStore: FavoriteLocationEditStore
}

type Props = ParamsListRoot[ScreenName.SetFavoriteLocation] & {
  handleNavigateEditFavoriteScreen: (params: ParamsListRoot[ScreenName.EditFavoriteScreen]) => void
  handleDismiss: () => void
}

@observer
export class SetFavoriteLocationView extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isShowingMap: false,
      favoriteEditStore: new FavoriteLocationEditStore(),
    }
  }

  public setLocationCallback = async (location: IPoint, address?: string) => {
    const { type } = this.props
    // Set favorite location in favoriteEditStore
    this.state.favoriteEditStore.set({
      type,
      location,
      address,
    })

    // Pass location and address down to the Favorites screen
    this.props.handleNavigateEditFavoriteScreen({
      state: FavoriteScreenStateType.New,
      type,
      longitude: location.coordinates[0],
      latitude: location.coordinates[1],
      address,
    })
  }

  public render() {
    return (
      <SafeAreaView style={styles.container}>
        <SetLocationView
          setLocationCallback={this.setLocationCallback}
          handlePressBack={() => this.props.handleDismiss()}
        />
      </SafeAreaView>
    )
  }
}

export const SetFavoriteLocation = (props: ScreenPropsRoot<ScreenName.SetFavoriteLocation>) => (
  <SetFavoriteLocationView
    {...props.route.params}
    handleDismiss={() => props.navigation.goBack()}
    handleNavigateEditFavoriteScreen={(params) => props.navigation.navigate(ScreenName.EditFavoriteScreen, params)}
  />
)
