import { action, observable } from 'mobx'
import { persist } from 'mobx-persist'
import { Constants } from 'src/consts/Constants'

const defaultHost: string = Constants.API_HOST
const defaultPossibleHosts: string[] = [
  'https://api.sparelabs.com',
  'https://api.us.sparelabs.com',
  'https://api.staging.sparelabs.com',
  'https://api.staging.us.sparelabs.com',
  'http://localhost:3000',
  'https://api-spare.ngrok.io',
]

export class HostStoreClass {
  public name = 'HostStore'

  @persist('object')
  @observable
  private host: string = defaultHost

  @persist('list')
  @observable
  private readonly possibleHosts: string[] = defaultPossibleHosts

  public isDefaultHost(host: string): boolean {
    return host.toLocaleLowerCase() === defaultHost
  }

  public getHost(): string {
    return this.host
  }

  public getPossibleHosts(): string[] {
    return this.possibleHosts
  }

  @action
  public reset() {
    this.host = defaultHost
  }

  @action
  public setHost(host: string) {
    const lowerCaseHost = host.toLocaleLowerCase()
    this.host = lowerCaseHost
    if (!this.possibleHosts.includes(lowerCaseHost)) {
      this.possibleHosts.push(lowerCaseHost)
    }
  }
}

export const HostStore = new HostStoreClass()
