import { SlotType } from '@sparelabs/api-client'
import { ILineString, IPoint } from '@sparelabs/geography'
import { isNumber } from 'lodash'
import { geoJsonToCoords, lineStringToCoords } from 'src/helpers/MapHelper'
import { MapMarkerHelper } from 'src/helpers/MapMarkerHelper'
import { IMarkerCoordinates } from 'src/types'

export interface IWalkable {
  requestedPickupLocation: IPoint
  requestedDropoffLocation: IPoint
  scheduledPickupLocation: IPoint | null
  scheduledDropoffLocation: IPoint | null
  pickupWalkingPolyline: ILineString | null
  dropoffWalkingPolyline: ILineString | null
  pickupWalkingDuration: number | null
  dropoffWalkingDuration: number | null
  scheduledPickupRadius?: number | null
  scheduledDropoffRadius?: number | null
}

export interface IWalkingPolylineInfo {
  startLocation: IPoint
  endLocation: IPoint
  walkingPolyline: IMarkerCoordinates[]
  walkingDuration: number
  hasRadius: boolean
}

export class WalkingPolylineInfoBuilder {
  public static build(
    walkable: IWalkable,
    slotType: SlotType | undefined,
    isWalkingOnlyRoute: boolean
  ): IWalkingPolylineInfo | null {
    if (isWalkingOnlyRoute) {
      return this.getWalkingOnlyInfo(walkable)
    } else if (slotType === SlotType.Pickup) {
      return this.getPickupPolylineInfo(walkable)
    } else if (slotType === SlotType.Dropoff) {
      return this.getDropoffPolylineInfo(walkable)
    }

    return null
  }

  public static getWalkingOnlyInfo(walkable: IWalkable): IWalkingPolylineInfo | null {
    // pickup/dropoff durations and polylines should be the same for walking only journey legs
    const walkingDuration = walkable.pickupWalkingDuration
    const polyline = walkable.pickupWalkingPolyline && lineStringToCoords(walkable.pickupWalkingPolyline)
    return this.buildPolylineInfo(
      walkable.requestedPickupLocation,
      walkable.requestedDropoffLocation,
      walkingDuration,
      polyline,
      false
    )
  }

  private static getPickupPolylineInfo(walkable: IWalkable): IWalkingPolylineInfo | null {
    const startLocation = walkable.requestedPickupLocation
    const endLocation = walkable.scheduledPickupLocation
    const walkingDuration = walkable.pickupWalkingDuration
    const polyline = walkable.pickupWalkingPolyline && lineStringToCoords(walkable.pickupWalkingPolyline)
    if (endLocation && polyline) {
      // last point in polyline should be the scheduled pickup location
      polyline.push(geoJsonToCoords(endLocation))
    }
    const hasRadius = MapMarkerHelper.showPickupRadius(walkable)
    return this.buildPolylineInfo(startLocation, endLocation, walkingDuration, polyline, hasRadius)
  }

  private static getDropoffPolylineInfo(walkable: IWalkable): IWalkingPolylineInfo | null {
    const endLocation = walkable.requestedDropoffLocation
    const startLocation = walkable.scheduledDropoffLocation
    const walkingDuration = walkable.dropoffWalkingDuration
    const polyline = walkable.dropoffWalkingPolyline && lineStringToCoords(walkable.dropoffWalkingPolyline)
    if (startLocation && polyline) {
      // first point in polyline should be the scheduled dropoff location
      polyline.unshift(geoJsonToCoords(startLocation))
    }
    const hasRadius = MapMarkerHelper.showDropoffRadius(walkable)
    return this.buildPolylineInfo(startLocation, endLocation, walkingDuration, polyline, hasRadius)
  }

  private static buildPolylineInfo(
    startLocation: IPoint | null,
    endLocation: IPoint | null,
    walkingDuration: number | null,
    walkingPolyline: IMarkerCoordinates[] | null,
    hasRadius: boolean
  ): IWalkingPolylineInfo | null {
    return startLocation && endLocation && isNumber(walkingDuration) && walkingPolyline
      ? {
          startLocation,
          endLocation,
          walkingDuration,
          walkingPolyline,
          hasRadius,
        }
      : null
  }
}
