import { FontAwesomeIconStyle, IJourney, ILeg } from '@sparelabs/api-client'
import { flatMap } from 'lodash'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import { isWalkingOnlyJourney, removeShortDistanceLegs } from 'src/components/journey/JourneyHelper'
import { JourneyLegTag } from 'src/components/journey/JourneyLegTag'
import { moment } from 'src/helpers/Moment'
import { RequestHelper } from 'src/helpers/RequestHelper'
import { st } from 'src/locales'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingVertical: 12,
    paddingHorizontal: 16,
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderBottomColor: colors.borderBlue,
    borderLeftColor: colors.white,
    borderLeftWidth: 4,
  },
  tagContainer: {
    flexDirection: 'row',
    overflow: 'hidden',
    alignContent: 'center',
    height: 20,
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 8,
  },
  details: {
    flexDirection: 'column',
  },
  detailsText: {
    color: colors.gray70,
    fontSize: 14,
    lineHeight: 16,
  },
  priceText: {
    color: colors.gray90,
    fontSize: 14,
    lineHeight: 16,
    marginBottom: 4,
    textAlign: 'right',
  },
  chevronContainer: {
    height: 10,
    width: 10,
    alignSelf: 'center',
    marginHorizontal: 2,
  },
  selected: {
    borderLeftColor: colors.blue50,
    borderLeftWidth: 4,
    backgroundColor: colors.blue10,
  },
})

interface IProps {
  journey: IJourney
  selected?: boolean
}

@observer
export class JourneyRoute extends Component<IProps> {
  public render() {
    const { legs } = this.props.journey
    if (legs.length > 0) {
      return (
        <View style={[styles.container, this.props.selected ? styles.selected : null]}>
          {this.renderLegTags(legs)}
          <View style={styles.detailsContainer}>
            <View style={styles.details}>
              {this.renderLeaveText(legs)}
              {this.renderArrivalRange(legs)}
            </View>
            {!isWalkingOnlyJourney(this.props.journey) && (
              <View style={styles.details}>{this.renderPriceDescription()}</View>
            )}
          </View>
        </View>
      )
    }
    return null
  }

  private readonly renderArrivalRange = (legs: ILeg[]) => {
    const lastLeg = legs[legs.length - 1]
    const minTime = moment.unix(lastLeg.endTime.minTs).format('LT')
    const maxTime = moment.unix(lastLeg.endTime.maxTs).format('LT')

    if (minTime !== maxTime) {
      return <Text style={styles.detailsText}>{st.components.journey.arrivalDescription({ minTime, maxTime })}</Text>
    }
    return <Text style={styles.detailsText}>{st.components.journey.arrivalDescriptionSingle({ minTime })}</Text>
  }

  private readonly renderLeaveText = (legs: ILeg[]) => {
    const firstLeg = legs[0]
    return (
      <Text style={{ ...styles.detailsText, marginBottom: 4 }}>
        {st.components.journey.leaveDescription({
          minutes: RequestHelper.getMinutesUntilPickup(firstLeg.startTime.ts).toString(),
        })}
      </Text>
    )
  }

  private readonly renderLegTags = (legs: ILeg[]) => {
    const prunedLegs = removeShortDistanceLegs(legs)
    return (
      <View style={styles.tagContainer}>
        {this.addSeparators(
          prunedLegs.map((leg, index) => (
            <JourneyLegTag
              index={index}
              key={`${leg.mode}-${leg.startTime.ts}-${leg.polyline}-${leg.endTime.ts}`}
              leg={leg}
              routeSelected={this.props.selected ?? false}
            />
          )),
          this.renderSeparator()
        )}
      </View>
    )
  }

  private readonly renderSeparator = () => (
    <View
      style={styles.chevronContainer}
      accessible={false}
      accessibilityLabel={st.components.journey.separatorAccessibilityLabel()}
    >
      <FontAwesomeIconWrapper icon={[FontAwesomeIconStyle.Solid, 'chevron-right']} size={10} color={colors.gray50} />
    </View>
  )

  private readonly addSeparators = (legItems: JSX.Element[], separator: JSX.Element) =>
    flatMap(legItems, (leg, index) => (index !== legItems.length - 1 ? [leg, separator] : [leg]))

  // TODO: When Journey API has fare information, this should display discounted price if available
  private readonly renderPriceDescription = () => (
    <Text style={{ ...styles.detailsText, textAlign: 'right' }}>{st.components.journey.payTransitAgency()}</Text>
  )
}
