import { IGlobalLinkedOrganization, ITermsResponse } from '@sparelabs/api-client'
import { observer } from 'mobx-react-lite'
import { ParsedQuery } from 'query-string'
import React, { useEffect } from 'react'
import { ActivityIndicator, FlatList, Image, Platform, SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { LoginTerms, SPARE_PRIVACY_POLICY, SPARE_TERMS_OF_USE } from 'src/components/login/LoginTerms'
import { RetryButton } from 'src/components/login/RetryButton'
import { SettingsListItem } from 'src/components/settings/SettingsListItem'
import { Constants } from 'src/consts/Constants'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { AuthenticatorHelper, IOrgLoginData } from 'src/helpers/AuthenticatorHelper'
import { DeepLinkAction, DeepLinkHelper, IDeepLink } from 'src/helpers/DeepLinkHelper'
import { WhitelabelHelper } from 'src/helpers/WhitelabelHelper'
import { usePreLoginChecks } from 'src/hooks/usePreLoginChecks'
import { st } from 'src/locales'
import { ScreenName, ScreenPropsRoot } from 'src/navigation'
import { DeveloperMenuTouchable } from 'src/screens/developer/components/DeveloperMenuTouchable'
import { IDeepLinkUrl } from 'src/types/deepLink'
import { OsType } from 'src/util/types'

const styles = StyleSheet.create({
  loginContainer: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'white',
    justifyContent: 'space-between',
  },
  container: {
    flex: 1,
    paddingBottom: 40,
  },
  bottomContainer: {
    flex: 1,
    width: '100%',
    paddingVertical: 16,
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 16,
  },
  logoContainer: {
    marginTop: 80,
    width: 250,
    height: 200,
  },
  headerText: {
    fontWeight: '700',
    fontSize: 24,
    color: colors.gray90,
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  separator: {
    backgroundColor: colors.gray30,
    height: 1,
  },
})

export interface ILoginDeepLinkParams extends ParsedQuery<string> {
  apiHost: string
  id: string
  name: string
}
interface IProps {
  organizations: IGlobalLinkedOrganization[]
  terms: Pick<ITermsResponse, 'privacyPolicyUrl' | 'termsOfUseUrl'>
  handleOrganizationClick: (id: string) => Promise<void>
  handleNavigateDeveloperMenu: () => void
  handleDeepLinkLoginNavigate: (authOrganization: IGlobalLinkedOrganization) => void
}

export const SetOrganizationView = observer(
  ({
    organizations,
    terms,
    handleOrganizationClick,
    handleNavigateDeveloperMenu,
    handleDeepLinkLoginNavigate,
  }: IProps) => {
    const { isLoading, isConnected, updateAppAndLogout } = usePreLoginChecks()

    useEffect(() => {
      const deepLinkHelperInstance = new DeepLinkHelper(deepLinkAction)
      void deepLinkHelperInstance.startListening()
      return () => {
        deepLinkHelperInstance.stopListening()
      }
    }, [])

    const deepLinkAction = async ({ url }: IDeepLinkUrl) => {
      const parsedUrl = DeepLinkHelper.parseDeepLink(url)
      if (parsedUrl) {
        await handleLink(parsedUrl)
      }
    }

    const handleLink = async (parsedUrl: IDeepLink) => {
      const query = parsedUrl.query as ILoginDeepLinkParams
      switch (parsedUrl.url) {
        case DeepLinkAction.Login:
          await AuthenticatorHelper.logout()
          handleDeepLinkLoginNavigate({
            termsOfUseUrl: SPARE_TERMS_OF_USE,
            privacyPolicyUrl: SPARE_PRIVACY_POLICY,
            ...query,
          })
          break
      }
    }

    const getSeparatorComponent = () => {
      if (Platform.OS !== OsType.Android) {
        // eslint-disable-next-line react/display-name, react/prop-types
        return ({ highlighted }) => <View style={[styles.separator, highlighted && { marginLeft: 0 }]} />
      }
      return null
    }

    const renderTestingAlert = () => (
      <View style={{ marginHorizontal: 16, marginTop: 16 }}>
        <Text style={{ ...styles.headerText, marginBottom: 8, paddingHorizontal: 0 }}>
          {st.screens.login.spareRiderTestingTitle()}
        </Text>
        <Text>{st.screens.login.spareRiderTestingContent()}</Text>
      </View>
    )

    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.loginContainer}>
          <View style={{ alignItems: 'center' }}>
            <DeveloperMenuTouchable handleNavigateDeveloperMenu={handleNavigateDeveloperMenu}>
              <Image resizeMode='contain' style={styles.logoContainer} source={WhitelabelHelper.getLogoSource()} />
            </DeveloperMenuTouchable>
          </View>
          <View style={{ flex: 1 }}>
            {isLoading ? (
              <ActivityIndicator color={colors.black} size='small' />
            ) : (
              <View>
                {isConnected ? (
                  <>
                    {organizations.length > 0 && (
                      <>
                        <Text style={styles.headerText}>{st.screens.login.multiOrganizationSelectionQuestion()}</Text>
                        <LoginTerms
                          terms={terms}
                          containerStyle={{
                            justifyContent: 'flex-start',
                            marginHorizontal: 16,
                            marginVertical: 0,
                            marginBottom: 16,
                          }}
                        />
                        <FlatList
                          style={{ flex: 1 }}
                          data={organizations}
                          renderItem={({ item }: { item: IGlobalLinkedOrganization }) => (
                            <SettingsListItem
                              id={item.id}
                              onPressItem={handleOrganizationClick}
                              title={item.name}
                              titleColor={colors.gray90}
                              subTitle={
                                AuthenticatorHelper.getOrgLoginDataList().find(
                                  ({ organizationId }) => item.id === organizationId
                                )
                                  ? st.screens.login.loggedIn()
                                  : undefined
                              }
                            />
                          )}
                          ItemSeparatorComponent={getSeparatorComponent()}
                          ListHeaderComponent={getSeparatorComponent()}
                          ListFooterComponent={getSeparatorComponent()}
                        />
                      </>
                    )}
                    {Constants.SHOW_ALL_ORGANIZATIONS && renderTestingAlert()}
                    {organizations.length === 0 && (
                      <LoginTerms
                        terms={terms}
                        containerStyle={{
                          justifyContent: 'flex-start',
                          marginHorizontal: 16,
                          marginVertical: 8,
                        }}
                      />
                    )}
                  </>
                ) : (
                  <RetryButton onRetryPress={updateAppAndLogout} isLoading={isLoading} />
                )}
              </View>
            )}
          </View>
        </View>
      </SafeAreaView>
    )
  }
)

export const SetOrganization = observer(
  ({ navigation }: ScreenPropsRoot<ScreenName.RootSetOrganization>): JSX.Element => {
    const organizations = AuthenticatorHelper.globalApp?.linkedOrganizations || []
    const terms = AuthenticatorHelper.globalApp?.terms || {
      termsOfUseUrl: SPARE_TERMS_OF_USE,
      privacyPolicyUrl: SPARE_PRIVACY_POLICY,
    }

    const handleLoggedOrganizationClick = async (orgLoginData: IOrgLoginData, apiHost: string) => {
      AuthenticatorHelper.regionalHost = apiHost
      AuthenticatorHelper.userOrgToken = orgLoginData.userOrgToken
      await AuthenticatorHelper.fetchUserData()
      await AuthenticatorHelper.fetchOrganizationData(orgLoginData.organizationId)
      navigation.reset({ routes: [{ name: ScreenName.RootHome, params: {} }] })
    }

    const handleOrganizationClick = async (id: string) => {
      const mobileAppOrganization = organizations.find((org) => org.id === id)
      const orgLoginData = AuthenticatorHelper.getOrgLoginDataList().find((data) => data.organizationId === id)
      if (mobileAppOrganization && orgLoginData) {
        await handleLoggedOrganizationClick(orgLoginData, mobileAppOrganization.apiHost)
      } else if (mobileAppOrganization) {
        navigation.navigate(ScreenName.GroupSetPhoneNumber, {
          screen: ScreenName.Login,
          params: { title: ScreenName.Login, authOrganization: mobileAppOrganization },
        })
      } else {
        AlertHelper.alert(st.screens.login.organizationNotConnectedLabel())
      }
    }

    return (
      <SetOrganizationView
        organizations={organizations}
        terms={terms}
        handleOrganizationClick={handleOrganizationClick}
        handleNavigateDeveloperMenu={() =>
          navigation.navigate(ScreenName.GroupDeveloperMenu, {
            screen: ScreenName.DeveloperMenu,
            params: {},
          })
        }
        handleDeepLinkLoginNavigate={(authOrganization: IGlobalLinkedOrganization) => {
          navigation.reset({
            routes: [{ name: ScreenName.RootLogin, params: { title: ScreenName.RootLogin, authOrganization } }],
          })
        }}
      />
    )
  }
)
