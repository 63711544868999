import { AccessibilityFeature, RiderType, ReviewTag as Tag } from '@sparelabs/api-client'
import { ITranslationStrings } from '@sparelabs/translate'
import { ILocaleTranslationKeys } from './keys'

const en: ITranslationStrings<ILocaleTranslationKeys> = {
  common: {
    alertOk: 'OK',
    buttonConfirm: 'Confirm',
    buttonCancel: 'Cancel',
    acceptButton: 'Accept',
    done: 'Done',
    conjunction: 'and',
    uploading: 'Uploading...',
    viewImage: 'View File',
    updating: 'Updating...',
    accessibilityBackButton: 'Back Button',
    accessibilityBackButtonHint: 'Navigates to the previous screen',
    error: 'Error',
    inputText: 'Text input',
    outOf: 'Out of',
  },
  recaptcha: {
    loading: 'Verifying identity please wait.',
    title: 'Security Check',
    captchaError:
      'Please try again later. If you’re still having trouble logging in, please contact {{appName}} support on their website.',
  },
  appVersion: {
    alertTitle: 'Update Needed',
    alertMessage: 'Please update the {{appName}} app to continue using it.',
    alertMessageAlternative:
      'This version of {{appName}} is out of date. Please update the app to avoid experiencing bugs.',
    update: 'Update',
  },
  errors: {
    errorOccurred: 'There was an unexpected error, please try again in a few seconds.',
    networkError: 'Cannot reach network. Please try again.',
    noConnection: 'No connection',
    ssoLoginError: 'There was an issue getting your identity provider, please try again or contact support.',
  },
  enums: {
    riderType: {
      [RiderType.Adult]: 'Adult',
      [RiderType.Infant]: 'Infant',
      [RiderType.Child]: 'Child',
      [RiderType.Youth]: 'Youth',
      [RiderType.Senior]: 'Senior',
      [RiderType.Reduced]: 'Reduced',
      [RiderType.Disabled]: 'Person with disabilities',
      [RiderType.Veteran]: 'Veteran',
      [RiderType.Student]: 'Student',
      [RiderType.PersonalCareAttendant]: 'Personal care attendant',
      [RiderType.Other]: 'Other rider',
    },
    riderTypePlural: {
      [RiderType.Adult]: 'Adults',
      [RiderType.Infant]: 'Infants',
      [RiderType.Child]: 'Children',
      [RiderType.Youth]: 'Youths',
      [RiderType.Senior]: 'Seniors',
      [RiderType.Reduced]: 'Reduced',
      [RiderType.Disabled]: 'People with disabilities',
      [RiderType.Veteran]: 'Veterans',
      [RiderType.Student]: 'Students',
      [RiderType.PersonalCareAttendant]: 'Personal care attendants',
      [RiderType.Other]: 'Other riders',
    },
    accessibilityFeature: {
      [AccessibilityFeature.Wheelchair]: 'Wheelchair',
      [AccessibilityFeature.ChildSeatBaby]: 'Child Seat (0-3 yr.)',
      [AccessibilityFeature.ChildSeatYoung]: 'Child Seat (4-10 yr.)',
      [AccessibilityFeature.Stroller]: 'Stroller',
      [AccessibilityFeature.ServiceAnimal]: 'Service Animal',
      [AccessibilityFeature.Bike]: 'Bike',
      [AccessibilityFeature.OversizedWheelchair]: 'Oversized Wheelchair',
      [AccessibilityFeature.Lift]: 'Lift',
      [AccessibilityFeature.Walker]: 'Walker',
      [AccessibilityFeature.WhiteCane]: 'White Cane',
      [AccessibilityFeature.Stretcher]: 'Stretcher',
      [AccessibilityFeature.ActualDoorToDoor]: 'Actual Door to Door',
      [AccessibilityFeature.FoldingWheelchair]: 'Folding Wheelchair',
    },
    accessibilityFeaturePlural: {
      [AccessibilityFeature.Wheelchair]: 'Wheelchairs',
      [AccessibilityFeature.ChildSeatBaby]: 'Child Seats (0-3 yr.)',
      [AccessibilityFeature.ChildSeatYoung]: 'Child Seats (4-10 yr.)',
      [AccessibilityFeature.Stroller]: 'Strollers',
      [AccessibilityFeature.ServiceAnimal]: 'Service Animals',
      [AccessibilityFeature.Bike]: 'Bikes',
      [AccessibilityFeature.OversizedWheelchair]: 'Oversized Wheelchairs',
      [AccessibilityFeature.Lift]: 'Lifts',
      [AccessibilityFeature.Walker]: 'Walkers',
      [AccessibilityFeature.WhiteCane]: 'White Canes',
      [AccessibilityFeature.Stretcher]: 'Stretchers',
      [AccessibilityFeature.ActualDoorToDoor]: 'Actual Door to Door',
      [AccessibilityFeature.FoldingWheelchair]: 'Folding Wheelchairs',
    },
    tagType: {
      [Tag.AboveAndBeyond]: 'Above & Beyond',
      [Tag.AppExperience]: 'App Experience',
      [Tag.BusyService]: 'Busy Service',
      [Tag.Comfort]: 'Comfort',
      [Tag.Driving]: 'Driving',
      [Tag.Dropoff]: 'Dropoff',
      [Tag.LateDriver]: 'Late Driver',
      [Tag.NoShow]: 'No Show',
      [Tag.OnTime]: 'On Time',
      [Tag.Pickup]: 'Pickup', // TODO: remove after new card is implemented
      [Tag.Professionalism]: 'Professionalism',
      [Tag.Traffic]: 'Traffic',
      [Tag.StopLocation]: 'Stop Location',
      [Tag.Price]: 'Price',
      [Tag.TransitConnection]: 'Transit Connection',
    },
    transitModes: {
      bus: 'Bus',
      train: 'Train',
      onDemand: 'On Demand',
      tram: 'Tram',
      walk: 'Walk',
    },
  },
  request: {
    addressUnknown: 'Address Unknown',
    requestedPickupTime: 'Requested pickup {{time}}',
    estimatedPickupTime: 'Estimated pickup {{time}}',
    latestTime: '{{time}} latest',
    moreDetailsBeforePickup: 'More details {{relativeTime}} before pickup',
    moreDetailsWithinCutoff: 'More details within {{relativeTime}}',
    additionalRideDetails: 'Additional Ride Details',
    rideCancelled: 'Ride cancelled',
    noDriversAvailable: 'No drivers available',
    processingText: 'Confirming your ride',
    processingWait: {
      findingARide: 'Finding a ride • {{timer}}',
      moreDetailsAtFuture: 'More details by {{futureTime}}',
    },
  },
  helpers: {
    mapMarkerHelpers: {
      pickup: 'Pickup',
      pickupTime: '{{time}} min',
      dropoff: 'Dropoff',
      destination: 'Destination',
      pickupArea: 'Pickup Area',
      hiddenVehicle: 'See vehicle on map in {{minutes}} min',
    },
    timeHelpers: {
      today: 'Today',
      tomorrow: 'Tomorrow',
      lastWeek: '[Last] {{dayOfWeek}}',
      yesterday: 'Yesterday',
    },
    errorHelpers: {
      generalAlertTitle: 'Something went wrong',
      authAlertTitle: 'You are no longer logged in',
      authAlertContent: 'You have been redirected to the login screen',
    },
    payment: {
      noCharge: 'No charge',
      free: 'Free',
      payInVehicle: 'Pay in vehicle',
    },
  },
  authentication: {
    verificationAlertTitle: 'Problem with verification code',
    verificationAlertContent: 'Try entering it again, or request a new one',
  },
  payments: {
    paymentErrorTitle: 'Payment Error',
    paymentErrorPriceChange: 'Purchase price has changed, please re-confirm payment.',
    paymentErrorPaymentMethod:
      'Payment was unsuccessful. You have not been charged. Please try a different payment method.',
    paymentErrorHandleAction:
      'Payment was unsuccessful. You did not complete the required step for authenticating this purchase.',
    stripeError: 'Failed to load Stripe.',
  },
  screens: {
    scheduledTrip: {
      title: 'Scheduled Trip',
      confirmation: 'Ride Scheduled!',
    },
    scheduledTrips: {
      title: 'Scheduled Trips',
      noScheduledTrips: 'No Trips Found',
    },
    pastTrip: {
      title: 'Past Trip',
      tip: 'tip',
      fare: 'fare',
    },
    pastTrips: {
      title: 'Past Trips',
      cancelled: 'Cancelled',
      noDriversAvailable: 'No Drivers Available',
      noPastTrips: 'No Trips Found',
    },
    login: {
      multiOrganizationSelectionQuestion: 'Which organization do you want to travel with?',
      spareRiderTestingTitle: 'Testing with Spare Rider',
      spareRiderTestingContent:
        "To test your service using this app, open your organization's secret link. This can be found in Spare Platform Settings → In-app Testing → Spare Rider App Testing Link.",
      standardTerms: 'By continuing, you agree to the',
      termsOfUse: 'Terms of Use',
      privacyPolicy: 'Privacy Policy',
      noConnection: 'No connection. Check that you are connected to the internet and try again.',
      loginLabel: 'Log In',
      ssoLoginLabel: 'Log In with {{ssoProvider}}',
      registerLabel: 'Register',
      retryLabel: 'Retry',
      organizationNotConnectedLabel: 'Organization is not linked to this app',
      loggedIn: 'Logged in',
    },
    setPhoneNumber: {
      titleLogin: 'Log In With Phone Number',
      titleLoginSso: 'Set Phone Number',
      titleRegister: 'Register With Phone Number',
      phoneAlertTitle: 'Phone number not valid',
      phoneAlertContent: 'Please check your phone number and try again',
      phoneNumberLabel: 'Phone Number',
      phoneNumberDescription: 'Enter your phone number.',
    },
    setPhoneNumberConfirm: {
      title: 'Confirm Phone Number',
      verificationCodeWarning: 'Verification Code is required',
      verificationCode: 'Verification Code',
      verificationCodeDescription: 'A verification code has been sent to your phone.',
    },
    setProfile: {
      title: 'Update Your Profile',
      actionSheetTitle: 'Select Profile Picture',
      actionCancel: 'Cancel',
      actionPhoto: 'Take photo',
      actionLibrary: 'Select from Library',
      buttonText: 'Change Profile Photo',
      firstNameLabel: 'First Name',
      firstNameWarning: 'First Name is required',
      lastNameLabel: 'Last Name',
      lastNameWarning: 'Last Name is required',
      emailWarning: 'Email is required',
      emailValid: 'Your email is not valid',
      emailLabel: 'Email',
      phoneNumberLabel: 'Phone Number',
      fieldRequired: 'This field is required',
      validateNumber: 'Please enter a valid number',
    },
    account: {
      buttonLabel: 'Account Settings',
      title: 'Account',
      organization: 'Riding with {{organizationName}}',
      header: 'Edit Profile',
      pastTrips: 'Past Trips',
      scheduledTrips: 'Scheduled Trips',
      groupMemberships: 'Group Memberships',
      fleetAgreements: 'Fleet Agreements',
      payments: 'Payments',
      farePasses: 'Fare Passes',
      favoriteLocations: 'Favorite Locations',
      help: 'Help',
      legal: 'Legal',
      signout: 'Sign Out',
      deleteAccount: 'Delete Account',
    },
    permissions: {
      title: 'Location Access',
      authorizedTitle: 'Please allow {{appName}} to use your location',
      authorizedContent:
        'We use your location to show your position on the map and to help you choose a nearby pickup spot.',
      authorizedButton: 'Next',
      alertTitle: 'No Location Access',
      deniedAlertMessage:
        "Without your location, {{appName}} won't be able to show where you are on the map or use current location when booking rides.",
      featurePermissionMessage:
        'This feature needs to know your location to work.\n\nTo use it, go to {{appName}} in Settings and allow location access.',
      openSettings: 'Open Settings',
      cameraAndPhotoAccess: 'Sorry, we require camera roll permissions to make this work.',
    },
    paymentMethods: {
      title: 'Payment Methods',
      defaultSubtitle: 'Default',
      notAvailable: 'Not Available',
      setDefaultTitle: 'Set As Default?',
      setDefaultText: 'Would you like to set this as your default payment method?',
      setDefaultOk: 'Set as default',
      setDefaultCancel: 'Keep previous default',
    },
    addPaymentMethod: {
      title: 'Add Payment Method',
      creditCard: 'Credit Card',
      sepaDebit: 'SEPA Direct Debit',
      nimoca: 'nimoca',
    },
    addNimoca: {
      creditRewardDescription:
        'Enter your nimoca card details and receive credit when you transfer with another Nishitetsu bus or train.',
      nimocaArchiveDisclaimer: 'Adding this card will remove all other nimoca cards from your KnowRoute account.',
      body: 'Save by using nimoca!',
      buttonTitle: 'Add Card',
      placeholder: 'nimoca ID',
      title: 'nimoca',
      alertTitle: 'Problem with nimoca',
      alertDescription: 'Try entering your nimoca ID again',
      lengthWarning: 'nimoca ID must be 15 characters',
    },
    addCreditCard: {
      title: 'Add Credit Card',
    },
    viewPaymentMethod: {
      remove: 'Remove',
      makeDefault: 'Make Default',
    },
    viewNimoca: {
      title: 'nimoca',
      description:
        'Pay onboard using your nimoca card and receive credit when you transfer with another Nishitetsu bus or train.',
    },
    viewCreditCard: {
      title: 'Credit Card',
    },
    viewSepa: {
      title: 'SEPA Direct Debit',
    },
    viewCash: {
      title: 'Cash',
      description: 'Pay using any other methods offered by the service provider.',
    },
    confirmPayment: {
      title: 'Confirm Payment',
    },
    onBoarding: {
      title: 'Onboarding',
      p1Title: 'Welcome to {{brandName}}',
      p1Description: 'A convenient way for you to get around by sharing on-demand rides with others.',
      p1DescriptionNoPooling: 'A convenient way for you to get around.',
      p2Title: 'Book a Ride in Seconds',
      p2Description:
        'It’s easy! Just tell us where you’re trying to go, and we will find the best possible trip for you.',
      p3Title: 'Walk to your Pickup',
      p3Description:
        'Once your ride is confirmed, we will display your pickup location. Then, walk a short distance to meet your driver.',
      p4Title: 'Share your ride',
      p4Description:
        'You may share the vehicle with others going in a similar direction, making a few stops along the way.',
      p5Title: 'Walk to your Destination',
      p5Description:
        '{{brandName}} analyzes the most efficient route and will drop you off close to your destination. After a quick walk from your dropoff location, you will arrive at your destination.',
      nextButton: 'Next',
      finishButton: 'Ready to start',
    },
    legal: {
      termsOfUse: 'Terms of Use',
      privacyPolicy: 'Privacy Policy',
      noTerms: 'No connection. Check that you are connected to the internet and try again.',
    },
    favorites: {
      title: 'Favorites',
      homeTitle: 'Home',
      workTitle: 'Work',
      homePlaceHolder: 'Set Home',
      workPlaceHolder: 'Set Work',
      addFavorite: 'Add Favorite',
      deleteFavorite: 'Delete Favorite',
      deleteAlertText: 'Are you sure you want to delete this favorite?',
      deleteButton: 'Delete',
      save: 'Save changes',
      editNameLabel: 'Name',
    },
    group: {
      title: 'Group',
      groupConnectDescription: 'Please enter the secret code you were provided for your group.',
      secretCode: 'Secret Code',
      groupMembershipsTitle: 'Group Memberships',
      joinGroup: 'Join a group',
      joinedTitle: 'Joined',
      joinErrorTitle: "Couldn't Join Group",
      joinErrorText: "That code doesn't match any groups. Try again?",
      enterButton: 'Enter Code',
      leaveButton: 'Leave Group',
      leaveAlertTitle: 'Leave Group?',
      leaveAlertText:
        'Are you sure you want to leave the group "{{groupName}}"? You might lose access to services or features associated with the group',
      joinDate: 'Join Date',
      expiryDateTime: 'Valid Until',
      noExpiry: 'Forever',
    },
    fleetAgreementList: {
      title: 'Fleet Agreements',
      description: 'You’ve agreed to ride with the following vehicle providers:',
      descriptionNoFleets: 'You haven’t agreed to ride with any fleets.',
      fleetDescription:
        'Some vehicles in the {{orgName}} system are provided by {{fleetName}}. This means shorter wait times and better service. Since you’ve agreed to ride with {{fleetName}}, you may sometimes be sent {{fleetName}} vehicles.',
      termsAndConditions: '{{fleetName}} Terms and Conditions',
      endAgreementButton: 'End Agreement',
      endAgreementAlertTitle: 'Stop riding with {{fleetName}}?',
      endAgreementAlertText:
        'We won’t send you {{fleetName}} vehicles anymore. This may result in longer pickup times.',
    },
    fleetAgreementModal: {
      title: 'Ride with {{fleetName}}',
      descriptionTitle: 'Ride with {{fleetName}} for no extra charge',
      descriptionText:
        'Some vehicles in this service are provided by {{fleetName}}. This means shorter wait times and a better experience without any changes to your fare.',
      termsAndConditionsText: 'If that’s ok with you, agree to ride with {{fleetName}} and accept the ',
      termsAndConditions: '{{fleetName}} Terms and Conditions',
      agree: 'Agree and Continue',
      maybeLater: 'Maybe Later',
    },
    developerMenu: {
      title: 'Developer Menu',
    },
    developerReviewerLogin: {
      title: 'Reviewer Login',
      description: 'Enter username and password to login as an app reviewer',
      usernameWarning: 'Username is required',
      username: 'Username',
      passwordWarning: 'Password is required',
      password: 'Password',
    },
    developerHostSelect: {
      title: 'Host Select',
      hostFieldLabel: 'Host (HTTPS)',
      setHostDescription: 'Enter the Secure Host you would like configure as the API endpoint.',
      defaultHost: '{{host}} (default)',
    },
    review: {
      reviewTitle: 'How was your ride?',
      title: 'Review Ride',
      rideDateAndAddress: '{{ date }} to {{ address }}',
      leaveAReview: 'Leave a review',
      accessibilityThumbsUp: 'Thumbs up',
      accessibilityThumbsDown: 'Thumbs down',
      alertTitle: 'Review not complete',
      alertContent: 'You must provide a 👍 or 👎 in order to complete your review.',
      reviewDescription: 'Tell us about your trip.',
      reviewPlaceholder: 'Write an optional comment to {{organization}} support',
      positiveDescription: 'Great! What did you like?',
      negativeDescription: 'What issues did you experience?',
      thankYouMessage: 'Thank you for your feedback',
      sendFeedback: 'Send Feedback',
    },
    tip: {
      customTipTitle: 'Enter Custom Tip',
      addATipTitle: 'Add A Tip',
      tipAmount: 'Tip Amount',
      lowAmountValidation: 'Tip cannot be less than {{ minAmount }}',
      tipPercentage: 'About {{ tipPercentage }}% of your {{ cost }} fare',
      addTipButton: 'Add Tip',
      addATipForDriver: 'Add a tip for {{ driver }}',
      showYourAppreciation: 'Show your appreciation',
      customAmount: 'or enter custom amount',
      noTip: 'No Tip',
      yourDriver: 'your driver',
      thankYouMessage: 'Thank you for your feedback and tip!',
      tipErrorTitle: 'Something went wrong',
      tipErrorMessage: "We couldn't send your tip, you haven't been charged.",
      tipLastTrip: 'Tip your last trip',
    },
    estimates: {
      selectPaymentMethod: 'Please select a payment method in order to request this ride.',
      selectPaymentMethodDiscountedFare: 'Please select a payment method',
      selectPaymentMethodDiscountedFareBody:
        'Because this ride is free due to a discount, a payment method is still needed to request a ride.',
    },
    confirmLocation: {
      confirmStartLocation: "Confirm your trip's start location",
      dragMap: 'Drag map to adjust location',
      confirmButton: 'Confirm Location',
    },
    search: {
      currentLocation: 'Current Location',
      setLocationOnMap: 'Set location on map',
      loading: 'Loading...',
      whereTo: 'Where to?',
      whereFrom: 'Where from?',
    },
    request: {
      rideNoLongerAvailableTitle: 'Ride no longer available',
      rideNoLongerAvailableContent: 'Unfortunately the requested ride is no longer available. Please try again.',
      rideNDATitle: 'No Drivers Available',
      rideNDAContent:
        'We couldn’t find a driver for your ride {{ rideTime }}. Please try booking again with similar settings.',
      rideCancelledTitle: 'Ride Cancelled',
      rideCancelledContent: 'Your ride {{ rideTime }} was cancelled. Please try booking again with similar settings.',
      rideCancelledNoShowContent: 'Your ride {{ rideTime }} was cancelled because the driver marked you as late.',
    },
    listAnnouncements: {
      title: 'Announcements',
      noListAnnouncements: 'No Announcements Found',
      moreInfo: 'More Information',
      posted: 'Posted {{timeAgo}}',
    },
    requestCancellation: {
      title: 'Cancel Ride',
      description: 'Why do you want to cancel?',
      commentPlaceholder: 'Optional Comment to {{organization}} Support',
      alertTitle: 'Why do you want to cancel?',
      alertContent: 'You must select a reason in order to cancel your trip.',
      confirmCancellation: 'Confirm Cancellation',
    },
    listPromoScreen: {
      menuTitle: 'Promos',
      title: 'Applied Promos',
      amountRemaining: '- You have maximum {{amount}} remaining',
      ridesRemaining: '- You have {{count}} ride remaining',
      ridesRemaining_plural: '- You have {{count}} rides remaining',
      expiryDate: '- Valid until {{expiry}}',
      referralTitle: 'Referral Code',
      addPromoCode: 'Enter promo code',
      noPromos: 'Add and view your promo codes here',
    },
    addPromo: {
      title: 'Enter Promo Code',
      codePlaceholder: 'Promo Code',
      addError: 'Promotion Code is not valid',
    },
    discountDetails: {
      title: 'Discount Details',
      discountApplied: 'A discount has been applied to your fare.',
      fullFare: 'Full Fare',
      promo: 'Promo',
      storedValuePass: 'Stored Value Pass',
      timeBasedPass: 'Time Based Pass',
      discount: 'Discount',
      total: 'Total',
      creditRemaining: '{{ amount }} remaining',
      ridesRemaining: '{{ numOfRides }} remaining',
      expiryDate: 'Expires {{ date }} {{ time }}',
    },
    selectPaymentMethod: {
      title: 'Payment Methods',
      addPaymentMethod: 'Add Payment Method',
      cashTitle: 'Cash',
      default: 'Default',
      missing: 'Add Payment',
      select: 'Select',
    },
    farePasses: {
      title: 'Fare Passes',
      yourPasses: 'Your Passes',
      noPasses: "You don't have any passes",
      purchasePass: 'Purchase A Pass',
      noPassesToPurchase: 'There are no passes available to purchase',
      getPass: 'Get Pass',
      passHistory: 'History',
    },
    farePassHistoryList: {
      title: 'Pass History',
      expired: 'Expired',
      deactivated: 'Deactivated',
      depleted: 'Depleted',
    },
    viewFarePass: {
      expiry: 'Expires {{date}} at {{time}}',
      expired: 'Expired {{date}} at {{time}}',
      deactivated: 'Deactivated',
      deactivatedExplanation: 'Deactivated by admin',
      creditRemaining: '{{amount}} remaining',
      expiryDateTitle: 'Expiry Date',
      creditRemainingTitle: 'Credit Remaining',
      purchaseDateTitle: 'Purchase Date',
      purchasePriceTitle: 'Purchase Price',
      paymentMethodTitle: 'Payment Method',
      unknownPaymentMethod: 'Unknown payment method',
      termsTitle: 'Terms',
      termsLinkText: 'Full Terms →',
    },
    rideOptionsFilters: {
      title: 'Filter Ride Options',
      buttonText: 'Apply Choices',
      transitModesTitle: 'Transit Modes',
      transitModeDescription: 'Choose which modes of travel to include in trips',
    },
    surveys: {
      title: 'Survey',
      completeButton: 'Complete',
      numberOfQuestions: '{{amount}} questions in total',
      dataDisclaimer: 'All data collected is anonymous',
      completedMessage: 'Thanks for completing\nour survey!',
      surveyExpiredMessage: 'You just missed this bus!\nThe survey has expired.',
      connectionError: 'No connection. Check that you are connected to the internet and try again.',
    },
    emissionsDetails: {
      emissionsDetailsTitle: 'Emission Detail',
      emissionsYouProduced: 'You produced',
      emissionsDetailsHeaderExplanation:
        'fewer CO₂ emissions on this trip, compared to driving a fossil fuel-powered vehicle',
      emissionsPercentSaved: ' {{savings}}% ',
      emissionsReductionMessage: 'The carbon impact of your trip was reduced by sharing part your ride with others.',
      emissionsReductionValues:
        'Along your {{distance}}km trip the vehicle emitted {{totalEmissions}}g of CO₂, of which {{riderEmissions}}g is attributed to you.',
      otherRiders: 'Other Riders',
      yourTrip: 'Your Trip',
      gasVehicle: 'Fossil Fuel Vehicle',
      gramsEmitted: '{{emissions}}g CO₂',
      emissionsErrorTitle: 'Something Went Wrong',
      emissionsErrorMessage: 'We could not calculate your emissions data',
    },
  },
  components: {
    searchBar: {
      searchPlaceholder: 'Where to?',
      searchAccessibilityLabel: 'Search button. Double tap to search your trip destination!',
    },
    requestCard: {
      driverArrives: 'Driver arrives in {{ minutes }} min',
      driverArriving: 'Driver is arriving',
      driverIsHere: 'Driver is Here',
      driverLeaves: 'Leaves in {{ minutes }} min',
      dropoffEtaNow: 'Now',
      dropoffEtaMin: 'Dropoff in {{ minutes }} min',
      stopsBeforeDropoff: '{{ numberOfStops }} stops before your dropoff',
      onYourWay: "You're on your way",
      thanksForRiding: 'Thanks for Riding',
      cancelButtonTitle: 'Cancel Ride',
      noDriversAvailable: 'Sorry, no drivers were available to fulfill your trip',
      loading: "We're connecting you with your ride",
      connectingWithYourRide: 'Connecting with your ride',
      ridersInfo: {
        ridersLabel: 'Riders',
        editLabel: 'Edit',
      },
      accessibilityInfo: {
        accessibilityLabel: 'Accessibility',
        noRequirements: 'No requirements',
        editLabel: 'Edit',
      },
      paymentInfo: {
        paymentLabel: 'Payment',
        authorized: 'Authorized',
        paid: 'Paid',
      },
      aboutRide: {
        heading: 'About this ride',
        description: 'This ride is provided by {{ provider }} on behalf of {{ serviceName }}',
      },
      lyftRide: {
        providerMsg: 'This ride is provided by Lyft. Look for the Lyft logo when meeting your driver.',
        btnTitle: 'View ride history in Lyft',
        contactDriver: 'Contact driver',
        viewRideBtn: 'View this ride in Lyft',
        accountHistoryMsg: 'Only rides taken after your Lyft account was created will appear in the ride history.',
      },
    },
    confirmLocation: {
      confirmStartLocation: "Confirm your trip's start location",
      dragMap: 'Drag map to adjust location',
      confirmButton: 'Confirm Location',
    },
    rideOptionsCard: {
      accessibilityLabel: 'Choose a ride option and tap Select button',
      headerText: 'Choose a ride option',
      requestButtonTitle: 'Request Ride',
      scheduleButtonTitle: 'Schedule Ride',
      nextAvailableButtonSubtitleAround: 'Pickup {{pickupDate}} in around {{pickupMinutes}} min',
      nextAvailableButtonSubtitleRange: 'Pickup {{pickupDate}} in {{pickupMinutesLow}} - {{pickupMinutesHigh}} min',
      scheduledButtonSubtitleAround: 'Pickup {{pickupDate}} at around {{pickupTime}}',
      scheduledButtonSubtitleRange: 'Pickup {{pickupDate}} at {{pickupTimeLow}} - {{pickupTimeHigh}}',
      loading: "We're searching for rides near you",
      calculatingDropoff: 'Calculating dropoff...',
      selectAService: 'Select a service',
      selectAServiceMore: 'Select a service, or pull up for more',
      pullUpForMore: 'Pull up for more',
      next: 'Next',
      selectServiceButton: 'Select {{serviceName}}',
      selectMixedButton: 'Select Mixed',
      selectTransitButton: 'Select Transit',
      selectWalk: 'Select Walk',
      noRidesAvailable: 'No rides available',
      onDemandOptions: 'On-Demand Options',
      transitOptions: 'Transit Options',
      mixedOptions: 'Mixed Options',
      otherOptions: 'Other Options',
      noEstimateForJourneyTitle: 'Could not find a ride',
      noEstimateForJourneyMessage:
        "Sorry, we couldn't find a ride for your request at this time. Please try a different ride option and try again.",
    },
    refreshDetailsBanner: {
      transitOptionsOutOfDate: 'Transit options are out of date',
      refreshButton: 'Refresh',
    },
    estimatesCardNoTimes: {
      outsideServiceHoursTitle: 'Outside of service hours',
      outsideServiceHours: 'Your requested time is outside of service hours in this area. Next available service:',
      insideServiceHoursTitle: 'No rides available',
      insideServiceHours: "We're really busy right now! Please try again in a few minutes or with different settings.",
    },
    rideOptionsCardBody: {
      dropoffTimeRange: 'Dropoff {{dropoffTimeLow}} – {{dropoffTimeHigh}}',
      dropoffTime: 'Dropoff {{ dropoffTime }}',
      dropoffTimeAround: 'Dropoff around {{ dropoffTime}}',
      dropoffTimeExact: 'Dropoff at {{ dropoffTime}}',
      pickupTimeRange: 'Pickup {{pickupTimeLow}} – {{pickupTimeHigh}}',
      pickupTimeAround: 'Pickup around {{pickupTime}}',
      pickupTimeExact: 'Pickup at {{pickupTime}}',
      filter: 'Filter',
      accessibility: 'Accessibility',
      accessibilityRequirement: 'Requirement',
      accessibilityRequirement_plural: 'Requirements',
      scheduleDefault: 'Schedule',
      leaveTime: 'Leave {{time}}',
      leaveTime_leave: 'Leave {{time}} at',
      leaveTime_arrive: 'Arrive {{time}} at',
      leaveTime_date: 'Leave {{ date }} at {{ time }}',
      arriveTime_date: 'Arrive {{ date }} at {{ time }}',
      rider: 'Rider',
      rider_plural: 'Riders',
      accessibilityLabelRiders: 'Number of Riders',
      accessibilityHintRiders: 'Set the number of riders on your trip',
      accessibilityLabelFilter: 'Filter Services',
      accessibilityHintFilter: 'Filter services based on your needs',
      accessibilityLabelSchedule: 'Schedule A Ride',
      accessibilityHintSchedule: 'Set when you want to leave or arrive',
      accessibilityLabelPayment: 'Payment Method',
      accessibilityHintPayment: 'Set how you want to pay for your trip',
      lyftPass: 'Lyft Pass',
    },
    rideOptionsCardHeader: {
      walkingDuration: '{{walkingDuration}} min walk to your pickup location at',
      pickupArea: 'A short walk to your pickup spot. Exact details provided before pickup.',
    },
    timeline: {
      yourTrip: 'Your Trip',
      rideWith: 'Ride with {{ orgName }}',
      pickup: 'Pickup',
      dropoff: 'Dropoff',
      latest: 'latest {{ time }}',
    },
    emissions: {
      emissionsHeader: 'Emissions',
      emissionsSavings: '{{savings}}% Savings',
      learnMore: 'Learn More',
      emissionSavingsMessage: 'You produced {{savings}}% fewer CO₂ emissions on this trip',
    },
    walkingDirections: {
      info: 'Walk {{walkingDuration}} min to',
    },
    editAccessibility: {
      title: 'Edit Accessibility',
      subtitle: 'Accessibility Requirements',
      description: 'For on-demand trips',
      buttonTitle: 'Confirm Accessibility',
    },
    editRiders: {
      title: 'Edit Riders',
      subtitle: 'How many seats do you need?',
      buttonTitle: 'Confirm Riders',
    },
    editRideOptions: {
      buttonTitle: 'Back',
      cannotAccommodate: "Vehicle Can't Accommodate Change",
      notEnoughRoom: 'The vehicle cannot support the change you requested.',
      fareUnchanged: "You'll be charged the same amount",
      fareIncrease: "You'll be charged {{fare}} more",
      fareDecrease: "You'll be charged {{fare}} less",
    },
    counter: {
      counter: 'Counter',
      minimum: 'Minimum',
      maximum: 'Maximum',
      counterLimitMsg: '{{eventMsgContext}} counter value reached',
      accessibilityLabel: '{{itemLabel}} counter, current value {{value}}',
    },
    scheduleEstimate: {
      title: 'Edit Booking Time',
      subtitle: 'When do you want to ride?',
      now: 'Leave now',
      leave: 'Leave at',
      arrive: 'Arrive by',
      buttonTitle: 'Confirm Booking Time',
      header: {
        accessibilityHint: 'Use radio buttons and date picker below to edit booking time',
      },
      dst: {
        springForward: {
          hint: 'Times between {{start}} and {{end}} are unavailable due to the Daylight Saving Time change.',
        },
        fallBack: {
          title: 'Confirm Specific Time',
          subtitle:
            'Due to the Daylight Saving Time change, this time occurs twice. Please specify which one should be used.',
          beforeTimeSetBack: 'Before clocks are set back',
          afterTimeSetBack: 'After clocks are set back',
        },
      },
    },
    noRideOptionsCard: {
      title: 'No trips found',
      info: "We couldn't find a trip between these locations that fits the current settings",
    },
    estimatesServiceHours: {
      info: 'Your request is outside of service hours. Next available service hours:',
    },
    userBannedCard: {
      info: 'Unfortunately, your account has been temporarily banned from riding with this service.',
    },
    accountSettingsFooter: {
      footerLove: 'Made with ❤ in Vancouver, Canada',
    },
    dateTimePicker: {
      time: 'Time',
      date: 'Date',
      select: 'Select',
    },
    brandInfoBar: {
      header: 'Welcome to {{brandName}}! Tap to learn more.',
    },
    reviewInfoBar: {
      rate: 'How was your last trip? Tap to rate',
      rateAndTip: 'How was your last trip? Tap to rate and tip',
    },
    addCard: {
      buttonConfirm: 'Add Card',
      cardNumberTitle: 'Card Number',
      cardExpiryTitle: 'Expiry Date',
      cardCvcTitle: 'CVC/CCV',
      cardCountryTitle: 'Country',
      cardPostalCodeTitle: 'Postal / ZIP Code',
      cardNumberPlaceholder: '1234 5678 1234 5678',
      cardExpiryPlaceholder: 'MM/YY',
      cardCvcPlaceholder: 'CVC',
      cardPostalCodePlaceholder: '22313',
      cardNumberError: 'Card Number is not valid',
      cardExpiryError: 'Expiry must be a future date in MM/YY format',
      cardCvcError: 'CVC must be 3 digits (or 4 for Amex)',
      cardPostalCodeError: 'Invalid postal / ZIP code for country',
      countryNameFilter: 'Enter country name',
    },
    announcementButton: {
      title: '{{announcementCount}} Announcements',
    },
    requestCancellationTagButton: {
      alternativeMethod: 'Taking Alternative',
      noShow: 'No Show',
      lateDriver: 'Late Driver',
      plansChanged: 'Plans Changed',
      other: 'Other',
    },
    confirmPurchase: {
      termsLinkText: 'Terms →',
      buy: 'Buy',
      changePaymentMethod: 'Change',
    },
    journey: {
      startTag: 'Start',
      walkTag: 'Walk',
      boardTag: 'Board',
      exitTag: 'Exit',
      leaveDescription: 'Leave in {{minutes}} min',
      arrivalDescription: 'arrival at {{minTime}} - {{maxTime}}',
      arrivalDescriptionSingle: 'arrival at {{minTime}}',
      payTransitAgency: 'Pay transit agency',
      timeDescription: '{{minutes}} min',
      walkDescription: 'Walk to {{stopName}}',
      walkDetailsDescription: '{{minutes}} min walk',
      waitDetailsDescription: '{{minutes}} min wait',
      boardDescription: 'Board the {{routeName}}',
      exitDescription: 'Exit at {{stopName}}',
      previousStopDescription: 'After {{stopName}}',
      numberOfStopsDescription: 'Ride {{numberOfStops}} stops • {{minutes}} min',
      nonstopDescription: 'Nonstop • {{minutes}} min',
      onDemand: 'On demand',
      walkingRouteInstruction: 'Walk • {{minutes}} min',
      walkingRouteArrival: '{{time}} arrival',
      requestOnDemandDescription: 'Request {{organizationName}}',
      requestRideButtonTitle: 'Find a ride',
      requestRideButtonSubtitle: 'Pickup in around {{minutes}} min',
      rideDurationDescription: 'Ride for {{minutes}} min',
      pickupDescription: 'Pickup at {{pickupLocation}}',
      dropoffDescription: 'Dropoff at {{dropoffLocation}}',
      latestDropoffTime: 'latest {{dropoffTime}}',
      journeyTagAccessibilityLabel: '{{routeName}} {{modeLabel}}',
      walkDurationAccessibilityLabel: 'Walk for {{minutes}} min',
      separatorAccessibilityLabel: 'then',
      continueJourney: 'Continue Trip',
      continueJourneyTo: 'To {{destination}}',
    },
    deleteAccountAlert: {
      title: 'Delete Account?',
      description:
        "Your account and all personal information will be removed from {{organizationNames}}. \n\nYou won't be able to recover your account. Are you sure you want to delete?",
      confirmButton: 'Delete',
    },
    feedbackButton: {
      text: 'Let us know how we can improve!',
    },
    announcementHomeButton: {
      accessibilityHint: 'Opens screen to see more announcements',
    },
    homeFavIconAction: {
      accessibilityHint: 'Chooses saved address as destination',
    },
    organizationItem: {
      testLabel: 'TEST',
    },
  },
  expo: {
    iosSystemTranslations: {
      NSAppleMusicUsageDescription: 'Apple Music may be used for this service',
      NSBluetoothPeripheralUsageDescription: 'Bluetooth peripherals may be enabled for this service',
      NSCalendarsUsageDescription: 'Calendar is used to get current time',
      NSCameraUsageDescription: 'Camera is used to save a profile photo',
      NSLocationAlwaysUsageDescription:
        'Location is used to create trips and show location on the map. Used for showing accurate ETAs to destination consistently even when user leaves app.',
      NSLocationWhenInUseUsageDescription:
        'Location is used to show your position on the map and connect you with your ride.',
      NSPhotoLibraryAddUsageDescription: 'Saving to your photo library will allow you to upload your profile photo.',
      NSPhotoLibraryUsageDescription: 'Photos are used to allow you to upload a profile photo.',
    },
  },
}

// eslint-disable-next-line import/no-default-export
export default en
