import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { ActivityIndicator, Platform, SectionList, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { SettingsListItem } from 'src/components/settings/SettingsListItem'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { ParamsListAccount, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { GroupStore, IGroupStore } from 'src/stores/GroupStore'
import { LoadingStore } from 'src/stores/LoadingStore'
import { ISection } from 'src/types/section'
import { OsType } from 'src/util/types'

const styles = StyleSheet.create({
  list: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  title: {
    fontSize: 14,
    lineHeight: 16,
    color: colors.textMedium,
    paddingTop: 24,
    paddingHorizontal: 16,
    paddingBottom: 8,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  separator: {
    backgroundColor: colors.gray3,
    height: 1,
  },
})

type Props = ParamsListAccount[ScreenName.GroupMemberships] & {
  groupStore: IGroupStore
  handleNavigateJoinGroup: (params: ParamsListAccount[ScreenName.JoinGroup]) => void
  handleNavigateGroupDetails: (params: ParamsListAccount[ScreenName.GroupDetails]) => void
}

@observer
export class GroupMembershipsView extends Component<Props> {
  private readonly loadingStore = new LoadingStore()

  public async UNSAFE_componentWillMount() {
    await this.loadingStore.execute(this.props.groupStore.refreshStoreData())
  }

  public renderJoinSection = () => (
    <SettingsListItem key='join' id='join' title={st.screens.group.joinGroup()} onPressItem={this.showJoinScreen} />
  )

  public renderGroupMembershipsSection = (): ISection[] => {
    const groupMemberships = this.props.groupStore.groupMemberships
    if (groupMemberships.length === 0) {
      return []
    }

    const groupMembershipsListItems: JSX.Element[] = []

    for (const membership of groupMemberships) {
      const joinedDate = moment.unix(membership.createdAt).format('LL')
      const expiryDateTime =
        membership.expiryTs === null ? st.screens.group.noExpiry() : moment.unix(membership.expiryTs).format('ll LT')
      const groupItem = (
        <SettingsListItem
          key={membership.id}
          id={membership.id}
          title={membership.group.name}
          onPressItem={() =>
            this.showGroupDetails({ id: membership.groupId, ...membership.group }, joinedDate, expiryDateTime)
          }
        />
      )
      groupMembershipsListItems.push(groupItem)
    }

    return [
      {
        title: st.screens.group.joinedTitle(),
        data: groupMembershipsListItems,
      },
    ]
  }

  public render() {
    return this.loadingStore.isLoading() ? (
      <ActivityIndicator style={{ marginTop: 32 }} color='black' />
    ) : (
      <View style={{ flex: 1, backgroundColor: colors.gray4 }}>
        <SectionList
          ListHeaderComponent={this.renderJoinSection()}
          ItemSeparatorComponent={Platform.OS === OsType.iOS ? () => <View style={[styles.separator]} /> : null}
          style={styles.list}
          sections={this.renderGroupMembershipsSection()}
          renderItem={({ item }) => item}
          renderSectionHeader={({ section: { title } }) => <Text style={styles.title}>{title}</Text>}
          stickySectionHeadersEnabled={false}
        />
      </View>
    )
  }

  private readonly showJoinScreen = async () => {
    this.props.handleNavigateJoinGroup({ updateGroupsListCallback: this.updateGroupsListCallback })
  }

  private readonly updateGroupsListCallback = () => this.props.groupStore.refreshStoreData()

  private readonly showGroupDetails = async (
    group: { id: string; name: string; description: string | null },
    joinDate: string,
    expiryDateTime: string
  ) => {
    this.props.handleNavigateGroupDetails({
      group,
      joinDate,
      expiryDateTime,
      updateGroupsListCallback: this.updateGroupsListCallback,
    })
  }
}

const groupStore = new GroupStore()

export const GroupMemberships = (props: ScreenPropsAccount<ScreenName.GroupMemberships>) => (
  <GroupMembershipsView
    {...props.route.params}
    groupStore={groupStore}
    handleNavigateJoinGroup={(params) => {
      props.navigation.navigate(ScreenName.JoinGroup, params)
    }}
    handleNavigateGroupDetails={(params) => {
      props.navigation.navigate(ScreenName.GroupDetails, params)
    }}
  />
)
