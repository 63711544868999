import { NavigationProp } from '@react-navigation/native'
import { CustomFieldStatus, ICustomFieldResponse, NativeObjectType } from '@sparelabs/api-client'
import { Formik, FormikConfig, FormikProps } from 'formik'
import { mapValues } from 'lodash'
import React, { useState } from 'react'
import { useAsync } from 'react-async-hook'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ApiClientBuilder } from 'src/api/ApiClientBuilder'
import { FormSubmitButton } from 'src/components/buttons/FormSubmitButton'
import { CustomField } from 'src/components/form/CustomField'
import { IFormFieldProps } from 'src/components/form/FormFieldTypes'
import { FormWrapper } from 'src/components/form/FormWrapper'
import { LoadingShimmer } from 'src/components/LoadingShimmer'
import { CustomFieldHelper, IFormField } from 'src/helpers/CustomFieldHelper'
import { ParamsListRoot } from 'src/navigation'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { LoadingStore } from 'src/stores/LoadingStore'
import yup from 'yup'

interface IRequestCustomFieldFormProps {
  navigation: NavigationProp<ParamsListRoot>
  onSubmit: (value: Record<string, unknown> | null) => Promise<void>
  loadingStore: LoadingStore
  estimateInputStore: IEstimateInputStore
  displayErrors?: boolean
  onUserError?: (verticalFieldPosition: number) => void
}

export const RequestCustomFieldsForm = (props: IRequestCustomFieldFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true)
  const [customFields, setCustomFields] = useState<ICustomFieldResponse[]>([])

  const fetchCustomFields = async (): Promise<void> => {
    const nativeObjectExtensionMap = await ApiClientBuilder.build().customSchemas.getNativeObjectExtensions()
    if (nativeObjectExtensionMap[NativeObjectType.Request]) {
      setCustomFields(
        nativeObjectExtensionMap[NativeObjectType.Request].fields.filter(
          (field) => field.status === CustomFieldStatus.Active && field.isAvailableToRiderInterface
        )
      )
    }
    setLoading(false)
  }

  useAsync(fetchCustomFields, [])

  const renderField = (
    fieldKey: string,
    field: IFormField,
    value: unknown,
    setFieldValue: (key: string, value: unknown) => void,
    setFieldTouched: (field: string, isTouched?: boolean) => void,
    touched?: boolean,
    error?: string
  ) => {
    const { navigation } = props
    const fieldProps: IFormFieldProps<any> = {
      navigation,
      value,
      touched,
      error,
      onChange: (value) => {
        setFieldValue(fieldKey, value)
        setFieldTouched(fieldKey, true)
      },
      fieldKey,
      label: field.label,
      disabled: field.disabled,
      keyboardType: field.keyboardType,
      isRequired: field.customField?.isRequired,
    }
    if (field.customField) {
      return (
        <CustomField
          key={fieldKey}
          {...fieldProps}
          customField={field.customField}
          setFieldTouched={setFieldTouched}
          displayErrors={props.displayErrors}
        />
      )
    }
  }

  const renderForm = (formProps: FormikProps<Record<string, unknown>>) => {
    const fields = buildFields()
    const isFirstErrorFound = false
    return (
      <SafeAreaView edges={['bottom']}>
        <FormWrapper style={{ paddingTop: 10, paddingBottom: 20, paddingHorizontal: 16 }}>
          {Object.entries(fields).map(([key, field]) => {
            const error = formProps.errors[key] as string | undefined
            return (
              <View
                key={key}
                onLayout={(event) => {
                  const layout = event.nativeEvent.layout
                  if (error && !isFirstErrorFound && props.onUserError) {
                    props.onUserError(layout.y)
                  }
                }}
              >
                {renderField(
                  key,
                  field,
                  formProps.values[key],
                  formProps.setFieldValue,
                  formProps.setFieldTouched,
                  formProps.touched[key],
                  error
                )}
              </View>
            )
          })}
          <View style={{ marginTop: 8 }}>
            <FormSubmitButton
              isLoading={Boolean(props.loadingStore.isLoading())}
              testButtonId='customFieldBtn'
              handleSubmit={formProps.handleSubmit as any}
            />
          </View>
        </FormWrapper>
      </SafeAreaView>
    )
  }

  const buildFields = (): Record<string, IFormField> => ({
    ...CustomFieldHelper.buildFields(customFields),
  })

  const buildSchema = (): yup.ObjectSchema<any> => yup.object(mapValues(buildFields(), (field) => field.schema))

  const buildInitialValues = (): Record<string, unknown> => {
    const initialData = { ...props.estimateInputStore.getEstimateMetadataInput() } as Record<string, unknown> | null
    const metadata: Record<string, unknown> = initialData ?? {}
    return { ...CustomFieldHelper.buildInitialValues(metadata, customFields) }
  }

  const onSubmit = async (value: Record<string, unknown>) => {
    await props.onSubmit(value)
  }

  return loading ? (
    <LoadingShimmer />
  ) : (
    <Formik<FormikConfig<Record<string, unknown>>, Record<string, unknown>>
      validationSchema={buildSchema()}
      initialValues={buildInitialValues()}
      onSubmit={onSubmit}
      render={renderForm}
    />
  )
}
