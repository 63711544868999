import { IRequestResponse } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { Images } from 'src/assets/Images'
import { TouchableListItemWrapper } from 'src/components/touchableListItemWrapper/TouchableListItemWrapper'
import { ScheduledRequestPickupText } from 'src/screens/request/ScheduledRequests/ScheduledRequestPickupText'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexBasis: 'auto',
    padding: 16,
  },
  textContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rightArrow: {
    alignSelf: 'center',
    marginLeft: 'auto',
  },
})

interface IProps {
  request: IRequestResponse
  onPress: () => void
}

@observer
export class ScheduledTripsListItem extends Component<IProps> {
  public render() {
    const { request } = this.props
    return (
      <TouchableListItemWrapper style={styles.container} onPress={this.props.onPress}>
        <View style={styles.textContainer}>
          <ScheduledRequestPickupText request={request} />
          <Image style={styles.rightArrow} resizeMode='contain' source={Images.rightArrow} />
        </View>
      </TouchableListItemWrapper>
    )
  }
}
