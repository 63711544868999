import { NavigationProp } from '@react-navigation/native'
import { CustomFieldStatus, ICustomFieldResponse, ISurveyResponse } from '@sparelabs/api-client'
import { Formik, FormikConfig, FormikProps } from 'formik'
import { mapValues } from 'lodash'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { NativeScrollEvent, NativeSyntheticEvent, ScrollView, StyleSheet, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ApiClientBuilder } from 'src/api/ApiClientBuilder'
import { colors } from 'src/assets/colors'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import { IFormFieldProps } from 'src/components/form/FormFieldTypes'
import { FormTextField } from 'src/components/form/FormTextField/FormTextField'
import { ProgressBar } from 'src/components/ProgressBar'
import { CustomSurveyField } from 'src/components/surveyForm/CustomSurveyField'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { CustomFieldHelper, IFormField } from 'src/helpers/CustomFieldHelper'
import { handleError } from 'src/helpers/ErrorHelpers'
import { st } from 'src/locales'
import { ParamsListRoot } from 'src/navigation'
import { LoadingStore } from 'src/stores/LoadingStore'
import { UIStateStore } from 'src/stores/UIStore'
import yup from 'yup'

const FOOTER_HEIGHT = 250
const FOOTER_OFFSET = 100

enum SurveyLoadingKeys {
  Submit = 'submit',
}

const styles = StyleSheet.create({
  formContainer: {
    backgroundColor: colors.blue10,
    marginBottom: FOOTER_OFFSET,
  },
  headerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    paddingBottom: 32,
    marginBottom: 16,
    backgroundColor: colors.white,
  },
  surveyNameContainer: {
    flexDirection: 'row',
    backgroundColor: colors.purple70,
    marginBottom: 16,
    borderRadius: 8,
    alignContent: 'center',
    paddingVertical: 14,
    paddingHorizontal: 18,
  },
  surveyNameText: {
    color: colors.white,
    lineHeight: 20,
    fontSize: 16,
  },
  iconContainer: {
    marginRight: 10,
  },
  descriptionText: {
    color: colors.blueAlt50,
    lineHeight: 20,
    fontSize: 15,
  },
  disclaimerText: {
    color: colors.blueAlt50,
    marginTop: 16,
    lineHeight: 16,
    fontSize: 12,
  },
  footerContainer: {
    width: '100%',
    position: 'absolute',
    marginTop: 16,
    backgroundColor: colors.white,
    padding: 16,
    borderTopWidth: 0.5,
    borderTopColor: colors.gray50,
    bottom: 16,
  },
  progressBarContainer: {
    paddingVertical: 8,
  },
  questionsTotal: {
    color: colors.blueAlt50,
    fontSize: 12,
    lineHeight: 16,
    textAlign: 'right',
  },
})

export interface ISurveyFormProps {
  navigation: NavigationProp<ParamsListRoot>
  onSubmit: () => Promise<void>
  survey: ISurveyResponse
}

export interface ISurveyFormState {
  surveyFields: ICustomFieldResponse[]
  scrollViewContentHeight: number
  scrollPosition: number
}

@observer
export class SurveyForm extends Component<ISurveyFormProps, ISurveyFormState> {
  private readonly loadingStore = new LoadingStore()

  constructor(props: ISurveyFormProps) {
    super(props)
    this.state = {
      surveyFields: [],
      scrollViewContentHeight: 0,
      scrollPosition: 0,
    }
  }

  public async componentDidMount() {
    const surveyFields = await ApiClientBuilder.build().customSchemas.list({
      fieldGroupIds: [this.props.survey.fieldGroupId],
    })
    if (surveyFields) {
      // Reversed because we want to show the survey in the same order as added in the Admin Panel
      this.setState({
        surveyFields: surveyFields.data
          .filter((field) => field.status === CustomFieldStatus.Active && field.isAvailableToRiderInterface)
          .reverse(),
      })
    }
  }

  public render() {
    return (
      <Formik<FormikConfig<{}>, {}>
        validationSchema={this.buildSchema()}
        initialValues={this.buildInitialValues()}
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    )
  }

  private readonly renderForm = (formProps: FormikProps<{}>) => {
    const fields = this.buildFields()
    return (
      <>
        <ScrollView
          style={styles.formContainer}
          onScroll={this.handleScroll}
          scrollEventThrottle={16}
          onContentSizeChange={this.onContentSizeChange}
        >
          {this.renderInfoHeader(this.props.survey.name, this.props.survey.description)}
          {Object.entries(fields).map(([key, field]) =>
            this.renderField(
              key,
              field,
              formProps.values[key],
              formProps.setFieldValue,
              formProps.touched[key],
              formProps.errors[key]
            )
          )}
        </ScrollView>
        <SafeAreaView edges={['bottom']}>
          <View style={styles.footerContainer}>
            <Text style={styles.questionsTotal}>
              {st.screens.surveys.numberOfQuestions({ amount: this.state.surveyFields.length })}
            </Text>
            <View style={styles.progressBarContainer}>
              <ProgressBar
                backgroundColor={colors.purple20}
                barColor={colors.purple50}
                progressPercent={this.getPercentage()}
                barHeight={4}
              />
            </View>
            <PrimaryButton
              loading={Boolean(this.loadingStore.isLoading(SurveyLoadingKeys.Submit))}
              title={st.screens.surveys.completeButton()}
              testID='surveyFormBtn'
              onPress={formProps.handleSubmit as any}
              disabled={
                this.submitFormDisabled(formProps.values) || this.loadingStore.isLoading(SurveyLoadingKeys.Submit)
              }
            />
          </View>
        </SafeAreaView>
      </>
    )
  }

  private readonly renderInfoHeader = (name: string, description?: string | null) => (
    <View style={styles.headerContainer}>
      <View style={styles.surveyNameContainer}>
        <View style={styles.iconContainer}>
          <FontAwesomeIconWrapper color={colors.white} icon='ballot' size={16} />
        </View>
        <Text style={styles.surveyNameText}>{name}</Text>
      </View>
      {description && <Text style={styles.descriptionText}>{description}</Text>}
      <Text style={styles.disclaimerText}>{st.screens.surveys.dataDisclaimer()}</Text>
    </View>
  )

  private readonly renderField = (
    fieldKey: string,
    field: IFormField,
    value: unknown,
    setFieldValue: (key: never, value: unknown) => void,
    touched?: boolean,
    error?: string
  ) => {
    const { navigation } = this.props
    const fieldProps: IFormFieldProps<any> = {
      navigation,
      value,
      touched,
      error,
      onChange: (value) => setFieldValue(fieldKey as never, value),
      fieldKey,
      label: field.label,
      disabled: field.disabled,
      keyboardType: field.keyboardType,
    }
    if (field.customField) {
      return <CustomSurveyField key={fieldKey} {...fieldProps} customField={field.customField} />
    }
    return <FormTextField key={fieldKey} {...fieldProps} />
  }

  private readonly buildFields = (): Record<string, IFormField> => ({
    ...CustomFieldHelper.buildFields(this.state.surveyFields),
  })

  private buildInitialValues(): Record<string, unknown> {
    return {
      ...CustomFieldHelper.buildInitialValues({}, this.state.surveyFields),
    }
  }

  private buildSchema(): yup.ObjectSchema<any> {
    return yup.object(mapValues(this.buildFields(), (field) => field.schema))
  }

  private readonly onSubmit = async (values: Record<string, unknown>) => {
    try {
      const { ...metadata } = values
      if (AuthenticatorHelper.userId) {
        await this.loadingStore.execute(
          Promise.all([
            ApiClientBuilder.build().surveyAnswers.post({
              surveyId: this.props.survey.id,
              metadata,
            }),
            this.props.onSubmit(),
          ]),
          SurveyLoadingKeys.Submit
        )
      }
    } catch (error) {
      handleError({ error: error as Error })
      AlertHelper.alert(st.screens.surveys.connectionError())
    }
  }

  private readonly submitFormDisabled = (values: Record<string, unknown>) => {
    const { ...metadata } = values
    const hasUnfilledField =
      this.state.surveyFields.find((field) => field.isRequired && metadata[field.key] === undefined) !== undefined
    return hasUnfilledField
  }

  private readonly onContentSizeChange = (width: number, height: number) => {
    this.setState({ scrollViewContentHeight: height })
  }

  private readonly handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    this.setState({ scrollPosition: event.nativeEvent.contentOffset.y })
  }

  private readonly getPercentage = () => {
    if (this.state.scrollViewContentHeight === 0) {
      return 0
    }
    return (
      ((this.state.scrollPosition + (UIStateStore.screenHeight - FOOTER_HEIGHT) + FOOTER_OFFSET) /
        this.state.scrollViewContentHeight) *
      100
    )
  }
}
