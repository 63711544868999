import { IJourney, LegMode } from '@sparelabs/api-client'
import { truncate } from 'lodash'
import React from 'react'
import { SecondaryButton } from 'src/components/buttons/SecondaryButton'
import { AnalyticsHelper, EventName } from 'src/helpers/AnalyticsHelper'
import { st } from 'src/locales'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { RouterStore } from 'src/stores/RouterStore'
import { EstimatesUserInputParsed } from 'src/types'
import { Pathname } from 'src/types/homeRoot'

interface IProps {
  journey: IJourney | null
  initialEstimateInputStore: IEstimateInputStore
  journeyEstimateInput: EstimatesUserInputParsed
}

/**
 * Only render this button if:
 * - A journey exists
 * - This is not the final leg of the journey
 */
export const shouldShowContinueJourneyButton = (
  journey: IJourney | null,
  initialEstimateInput: EstimatesUserInputParsed | null,
  journeyEstimateInput: EstimatesUserInputParsed | null
): boolean =>
  journey !== null &&
  journeyEstimateInput !== null &&
  initialEstimateInput !== null &&
  initialEstimateInput.requestedDropoffAddress !== journeyEstimateInput.requestedDropoffAddress &&
  !isLastLeg(journey, journeyEstimateInput)

const isLastLeg = (
  journey: IJourney | null,
  journeyEstimateInput: Pick<EstimatesUserInputParsed, 'requestedDropoffLocation'>
): boolean => {
  if (!journey) {
    return true
  }

  const lastCompletedLegIndex = journey.legs.findIndex(
    (leg) => leg.mode === LegMode.OnDemand && leg.end.location === journeyEstimateInput.requestedDropoffLocation
  )

  // Filter out remaining walking legs, as these don't warrant creating a new estimate
  const remainingLegs = journey.legs.slice(lastCompletedLegIndex).filter((leg) => leg.mode !== LegMode.Walk)

  return remainingLegs.length > 0
}

/**
 * If an on-demand request is part of a multi-modal journey, and there are still legs remaining, then
 * we should show the user a 'Continue Journey' button that takes them back to the estimates screen with
 * an updated pickup location so they can view the remainder of the journey legs.
 */
export const ContinueJourneyButton = ({ initialEstimateInputStore, journeyEstimateInput }: IProps): JSX.Element => {
  const initialEstimateInput = initialEstimateInputStore.getEstimateInput()

  const journeyDestination = initialEstimateInput.requestedDropoffAddress

  const continueJourneyOnPress = async () => {
    AnalyticsHelper.track(EventName.ContinueJourney)

    // Update the initial estimate input to reference the new pickup location, which is now the dropoff location from the completed on-demand leg
    initialEstimateInputStore.updateEstimateInput({
      ...initialEstimateInput,
      requestedPickupAddress: journeyEstimateInput.requestedDropoffAddress,
      requestedPickupLocation: journeyEstimateInput.requestedDropoffLocation,
    })
    await RouterStore.replaceScreen({ pathname: Pathname.RideOptions }) // Show the ride options screen, and re-generate an estimate for the remaining legs
  }

  return (
    <SecondaryButton
      title={st.components.journey.continueJourney()}
      subtitle={st.components.journey.continueJourneyTo({
        destination: truncate(journeyDestination, { length: 40 }),
      })}
      onPress={continueJourneyOnPress}
    />
  )
}
