import { LegMode } from '@sparelabs/api-client'
import { observer, useObserver } from 'mobx-react-lite'
import React, { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { colors } from 'src/assets/colors'
import {
  AccessibleBottomSheetPosition,
  AccessibleBottomSheetView,
} from 'src/components/accessibleBottomSheet/AccessibleBottomSheetView'
import { AccessibleBottomSheetWrapper } from 'src/components/accessibleBottomSheet/AccessibleBottomSheetWrapper'
import { BackButton } from 'src/components/buttons/BackButton'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { isWalkingOnlyJourney } from 'src/components/journey/JourneyHelper'
import { NoRideOptionsCard } from 'src/components/rideOptions/NoRideOptionsCard'
import { RideOptionsJourneysList } from 'src/components/rideOptions/RideOptionsJourneysList'
import { RideOptionsServicesList } from 'src/components/rideOptions/RideOptionsServicesList'
import { ServiceHours } from 'src/components/rideOptions/ServiceHours'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { handleError } from 'src/helpers/ErrorHelpers'
import { areAllEstimatesNull, isLyftPassLinkEnabled } from 'src/helpers/EstimateHelper'
import {
  calculateListContainerHeight,
  FLOATING_COMPONENT_HEIGHT,
  getButtonPadding,
  getNumberOfRideOptions,
  getShowSectionTitles,
  numberOfRideOptionTypes,
  SCROLL_ITEMS_THRESHOLD,
  shouldShowRequestCustomFields,
  toNumberPercent,
  triggerJourneyScreen,
  triggerLyftPassScreen,
  triggerPaymentMethodValidation,
} from 'src/helpers/RideOptionsCardHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { ROUTER_CONTENT_MAX_WIDTH } from 'src/screens/HomeRootHelper'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { IEstimateStore } from 'src/stores/EstimateStore'
import { IJourneyStore } from 'src/stores/JourneyStore'
import { LoadingStore } from 'src/stores/LoadingStore'
import { RouterStore } from 'src/stores/RouterStore'
import { UIStateStore } from 'src/stores/UIStore'
import { ExternalRideOptions } from 'src/types/rideOptions'
import { RideOptionsControls } from './RideOptionsControls'
import { withNetworkDownView } from './WithNetworkDownView'

const getStyles = () =>
  StyleSheet.create({
    backButtonWrapper: {
      position: 'absolute',
      width: '100%',
      marginBottom: -8,
    },
    card: {
      backgroundColor: colors.white,
      borderRadius: 8,
      shadowColor: colors.shadowColor,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowRadius: 3,
      shadowOpacity: 1,
      elevation: 3,
    },
    controlContainer: {
      backgroundColor: colors.white,
      borderTopColor: colors.gray30,
      borderTopWidth: 1,
      paddingTop: 16,
      paddingHorizontal: 8,
      position: 'absolute',
      flex: 1,
      width: '100%',
      bottom: 0,
      zIndex: 2,
    },
    controlContainerWebOverride: {
      maxWidth: ROUTER_CONTENT_MAX_WIDTH,
      borderBottomRightRadius: 8,
      borderBottomLeftRadius: 8,
    },
    buttonWrapper: {
      paddingHorizontal: 8,
    },
    loading: {
      marginTop: 40,
    },
    handleIndicatorStyle: {
      width: 50,
    },
    sectionTitle: {
      fontSize: 14,
      color: colors.gray70,
      textAlign: 'center',
    },
    sectionTitleContainer: {
      borderBottomWidth: 1,
      borderColor: colors.borderBlue,
      paddingBottom: 8,
      paddingHorizontal: 16,
    },
  })

enum RideOptionsCardSnapPoints {
  Initial = '50%',
  Top = '75%',
}
interface IProps {
  selectedRideOptionId: string | null
  shouldShowJourneys: boolean
  confirmServicePathname: string
  setRideOptions: (rideOptionId: string) => void
  handleBackPress: () => void
  handleNavigateSelectPaymentMethod: (props: ParamsListRoot[ScreenName.SelectPaymentMethod]) => void
  handleNavigateEstimateAccessibilityOptions: (params: ParamsListRoot[ScreenName.EstimateAccessibilityOptions]) => void
  handleNavigateEstimateRiderOptions: (params: ParamsListRoot[ScreenName.EstimateRiderOptions]) => void
  handleNavigateScheduleEstimate: (params: ParamsListRoot[ScreenName.ScheduleEstimate]) => void
  handleNavigateRequestCustomFields: (params: ParamsListRoot[ScreenName.RequestCustomFields]) => void
}

interface IStoreProps {
  journeyStore: IJourneyStore | null
  estimateStore: IEstimateStore
  estimateInputStore: IEstimateInputStore
}

const triggerJourneyScreenLoadingStore: LoadingStore = new LoadingStore()

export const RideOptionsCardBody = observer((props: IProps & IStoreProps) => {
  const insets = useSafeAreaInsets()

  const [backButtonHeight, setBackButtonHeight] = useState<string>(RideOptionsCardSnapPoints.Initial)
  const [bottomSheetPosition, setBottomSheetPosition] = useState<AccessibleBottomSheetPosition>(
    AccessibleBottomSheetPosition.Initial
  )

  const styles = getStyles()
  const buttonPadding = getButtonPadding(insets)
  const { journeyStore, estimateStore, estimateInputStore, shouldShowJourneys, confirmServicePathname } = props

  const numberOfRideOptions = () =>
    getNumberOfRideOptions(
      estimateStore.estimateServices,
      estimateStore.estimateResponseMap,
      shouldShowJourneys && journeyStore ? journeyStore.journeys : null
    )

  // Button is disabled when user has not selected a ride option (ie: when there are no rides available)
  // or when they have previously selected a ride option that is no longer valid
  const isButtonDisabled = (): boolean =>
    !journeyStore?.getJourneyFromId(props.selectedRideOptionId) &&
    !estimateStore.getSelectedEstimate(props.selectedRideOptionId) &&
    !isLyftPassLinkEnabled()

  const renderButton = () => (
    <PrimaryButton
      title={getButtonText()}
      disabled={isButtonDisabled()}
      onPress={handleOnPressRequest}
      loading={triggerJourneyScreenLoadingStore.isLoading()}
    />
  )

  const getButtonText = () => {
    const { selectedRideOptionId, estimateStore, journeyStore } = props
    const selectedService = estimateStore.getSelectedEstimate(selectedRideOptionId)
    if (selectedService) {
      return st.components.rideOptionsCard.selectServiceButton({
        serviceName: selectedService.serviceName,
      })
    }

    const selectedJourney = journeyStore?.getJourneyFromId(selectedRideOptionId)
    if (selectedJourney) {
      if (isWalkingOnlyJourney(selectedJourney)) {
        return st.components.rideOptionsCard.selectWalk()
      }

      const isMixedJourney = selectedJourney.legs.find((leg) => leg.mode === LegMode.OnDemand)
      if (isMixedJourney) {
        return st.components.rideOptionsCard.selectMixedButton()
      }

      return st.components.rideOptionsCard.selectTransitButton()
    }
    if (isLyftPassLinkEnabled()) {
      return st.components.rideOptionsCard.selectServiceButton({ serviceName: 'Lyft Pass' })
    }
    return st.components.rideOptionsCard.next()
  }

  const handleOnPressRequest = async () => {
    const { selectedRideOptionId, estimateStore, journeyStore } = props
    const selectedService = props.estimateStore.estimateServices?.find(
      (service) => service.serviceId === selectedRideOptionId
    )
    const selectedJourney = journeyStore?.getJourneyFromId(selectedRideOptionId)

    if (selectedService) {
      const validPaymentMethod = triggerPaymentMethodValidation(
        selectedService,
        estimateStore.estimateResponseMap,
        estimateInputStore.getEstimateInput()
      )
      if (validPaymentMethod) {
        try {
          if (await shouldShowRequestCustomFields()) {
            props.handleNavigateRequestCustomFields({
              estimateInputStore: props.estimateInputStore,
              estimateStore: props.estimateStore,
              confirmServicePathname: props.confirmServicePathname,
            })
          } else {
            await RouterStore.goToScreen({
              pathname: confirmServicePathname,
            })
          }
        } catch (error) {
          handleError({ error: error as Error })
        }
      }
    } else if (shouldShowJourneys && selectedJourney) {
      try {
        const estimateInput = estimateInputStore.getEstimateInput()
        await triggerJourneyScreenLoadingStore.execute(
          triggerJourneyScreen(selectedJourney, estimateInput.requestedDropoffAddress)
        )
      } catch (error) {
        handleError({ error: error as Error, silent: true })
        AlertHelper.alert(
          st.components.rideOptionsCard.noEstimateForJourneyTitle(),
          st.components.rideOptionsCard.noEstimateForJourneyMessage()
        )
      }
    } else if (isLyftPassLinkEnabled() && selectedRideOptionId === ExternalRideOptions.Lyft) {
      await triggerLyftPassScreen()
    }
  }

  const renderRideOptionControls = () =>
    // TODO why are disabling rules of hook here?
    //eslint-disable-next-line react-hooks/rules-of-hooks
    useObserver(() => (
      <View
        style={[styles.controlContainer, UIStateStore.shouldShowLandscapeWeb ? styles.controlContainerWebOverride : {}]}
      >
        <RideOptionsControls
          estimateStore={estimateStore}
          estimateInputStore={estimateInputStore}
          handleNavigateScheduleEstimate={props.handleNavigateScheduleEstimate}
          handleNavigateSelectPaymentMethod={props.handleNavigateSelectPaymentMethod}
          handleNavigateEstimateAccessibilityOptions={props.handleNavigateEstimateAccessibilityOptions}
          handleNavigateEstimateRiderOptions={props.handleNavigateEstimateRiderOptions}
          estimatesUserInput={estimateInputStore.getEstimateInput()}
          selectedService={estimateStore.estimateServices?.find(
            (service) => service.serviceId === props.selectedRideOptionId
          )}
          isJourneySelected={Boolean(journeyStore?.getJourneyFromId(props.selectedRideOptionId))}
          isDisabled={
            props.selectedRideOptionId === ExternalRideOptions.Lyft // disable edit mode if Lyft option is selected
          }
          {...(props.selectedRideOptionId === ExternalRideOptions.Lyft && { paymentMethodTitle: 'Lyft' })}
        />
        <View style={[styles.buttonWrapper, { paddingBottom: buttonPadding }]}>{renderButton()}</View>
      </View>
    ))

  const showServicesList = () =>
    !areAllEstimatesNull(estimateStore.estimateServices || [], estimateStore.estimateResponseMap)

  const renderRideOptions = () => {
    const rideOptionTypes: number = numberOfRideOptionTypes(
      estimateStore.estimateServices,
      estimateStore.estimateResponseMap,
      journeyStore?.mixedJourneys ?? [],
      journeyStore?.transitJourneys ?? [],
      journeyStore?.walkingOnlyJourney ?? []
    )

    if (estimateStore.estimateServices && estimateStore.estimateServices.length > 0 && numberOfRideOptions() < 1) {
      return (
        <ServiceHours
          estimatesUserInput={estimateInputStore.getEstimateInput()}
          estimateServices={estimateStore.estimateServices}
        />
      )
    } else if (rideOptionTypes < 1) {
      return <NoRideOptionsCard />
    }

    const showSectionTitles = getShowSectionTitles(
      estimateStore.estimateServices,
      estimateStore.estimateResponseMap,
      journeyStore?.mixedJourneys ?? [],
      journeyStore?.transitJourneys ?? [],
      journeyStore?.walkingOnlyJourney ?? []
    )

    const scrollEnabled: boolean = Boolean(numberOfRideOptions() >= SCROLL_ITEMS_THRESHOLD)

    const bottomSheetTop = toNumberPercent(RideOptionsCardSnapPoints.Top)
    const screenHeight = calculateListContainerHeight(bottomSheetTop, buttonPadding)

    return (
      <AccessibleBottomSheetWrapper height={screenHeight}>
        <View style={styles.sectionTitleContainer}>
          <Text style={styles.sectionTitle} accessibilityLabel={st.components.rideOptionsCard.accessibilityLabel()}>
            {st.components.rideOptionsCard.headerText()}
          </Text>
        </View>
        <ScrollView
          testID='bottomSheetScrollView'
          style={{ marginBottom: FLOATING_COMPONENT_HEIGHT + buttonPadding }}
          showsVerticalScrollIndicator={false}
          scrollEnabled={bottomSheetPosition === AccessibleBottomSheetPosition.Top && scrollEnabled}
        >
          {showServicesList() && (
            <RideOptionsServicesList
              showSectionTitles={showSectionTitles}
              selectedRideOptionId={props.selectedRideOptionId}
              setRideOptions={props.setRideOptions}
              estimateStore={estimateStore}
              position={bottomSheetPosition}
              estimateInput={estimateInputStore.getEstimateInput()}
              confirmServicePathname={confirmServicePathname}
            />
          )}
          {shouldShowJourneys && journeyStore && (
            <RideOptionsJourneysList
              showSectionTitles={showSectionTitles}
              selectedRideOptionId={props.selectedRideOptionId}
              setRideOptions={props.setRideOptions}
              journeyStore={journeyStore}
            />
          )}
        </ScrollView>
      </AccessibleBottomSheetWrapper>
    )
  }

  const handleOnChangePosition = (position: AccessibleBottomSheetPosition) => {
    setBottomSheetPosition(position)
    setBackButtonHeight(
      position === AccessibleBottomSheetPosition.Top ? RideOptionsCardSnapPoints.Top : RideOptionsCardSnapPoints.Initial
    )
  }

  return (
    <>
      <View
        style={[
          styles.backButtonWrapper,
          {
            bottom: backButtonHeight,
          },
        ]}
        pointerEvents='box-none'
      >
        <BackButton onPress={props.handleBackPress} />
      </View>
      <AccessibleBottomSheetView
        style={[styles.card, UIStateStore.shouldShowLandscapeWeb ? { maxWidth: ROUTER_CONTENT_MAX_WIDTH } : {}]}
        handleIndicatorStyle={[styles.handleIndicatorStyle]}
        snapPoints={[RideOptionsCardSnapPoints.Initial, RideOptionsCardSnapPoints.Top]}
        enableContentPanningGesture={true}
        enableOverDrag={true}
        onChangePosition={handleOnChangePosition}
      >
        {renderRideOptions()}
      </AccessibleBottomSheetView>
      {renderRideOptionControls()}
    </>
  )
})

export const RideOptionsCard = withNetworkDownView<IProps & IStoreProps>(RideOptionsCardBody)
