import { FontAwesomeIconStyle, ILeg, LegMode } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { Image, LayoutChangeEvent, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import {
  getModeIcon,
  getModeLabel,
  getRouteName,
  getTagSize,
  MODE_FONT_SIZE_PT,
  MODE_ICON_SIZE_PX,
  TagSize,
} from 'src/components/journey/JourneyHelper'
import { st } from 'src/locales'
import { UIStateStore } from 'src/stores/UIStore'

const SERVICE_ICON_SIZE = 20

const styles = StyleSheet.create({
  modeContainer: {
    display: 'flex',
    borderRadius: 4,
    padding: 2,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    minWidth: 20,
  },
  modeText: {
    display: 'flex',
    flexShrink: 1,
    fontSize: MODE_FONT_SIZE_PT,
    lineHeight: 16,
    marginLeft: 2,
    fontWeight: 'bold',
  },
  serviceIcon: {
    height: SERVICE_ICON_SIZE,
    width: SERVICE_ICON_SIZE,
    backgroundColor: colors.white,
    borderRadius: SERVICE_ICON_SIZE / 2,
  },
})

interface IProps {
  leg: ILeg
  routeSelected: boolean
  index: number
}

interface IState {
  tagSize: TagSize
  onLayoutCalled: boolean
}

@observer
export class JourneyLegTag extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      tagSize: TagSize.Full,
      onLayoutCalled: false,
    }
  }

  public render() {
    const { leg, index } = this.props
    return (
      <View
        style={{
          ...styles.modeContainer,
          backgroundColor: this.getBackgroundColor(leg),
          flex: index === 0 ? 0 : -1,
          paddingLeft: leg.mode === LegMode.OnDemand || leg.mode === LegMode.Walk ? 0 : 2,
          top: leg.mode === LegMode.OnDemand ? -2 : 0,
        }}
        onLayout={this.onLayout}
        accessibilityLabel={
          leg.mode === LegMode.Walk
            ? st.components.journey.walkDurationAccessibilityLabel({ minutes: getRouteName(leg) })
            : st.components.journey.journeyTagAccessibilityLabel({
                routeName: getRouteName(leg),
                modeLabel: getModeLabel(leg.mode),
              })
        }
      >
        {this.renderModeImage(leg)}
        {this.renderRouteName(leg)}
      </View>
    )
  }

  private readonly renderModeImage = (leg: ILeg) =>
    leg.mode === LegMode.OnDemand ? (
      <Image style={styles.serviceIcon} source={{ uri: leg.serviceBrand.photoUrl }} />
    ) : (
      <FontAwesomeIconWrapper
        icon={[FontAwesomeIconStyle.Solid, getModeIcon(leg.mode)]}
        size={MODE_ICON_SIZE_PX}
        color={this.getTextColor(leg)}
      />
    )

  private readonly renderRouteName = (leg: ILeg) => {
    const routeName = getRouteName(leg)
    if (this.state.tagSize === TagSize.Compact) {
      return null
    }
    return (
      <Text
        numberOfLines={1}
        style={{ ...styles.modeText, color: this.getTextColor(leg), marginLeft: leg.mode === LegMode.OnDemand ? 4 : 2 }}
      >
        {routeName}
      </Text>
    )
  }

  private readonly onLayout = (event: LayoutChangeEvent) => {
    // Ensures that we only re-render the component once, when onLayout is initially called
    if (!this.state.onLayoutCalled) {
      const { width } = event.nativeEvent.layout
      const tagSize = getTagSize(width, UIStateStore.screenWidth, this.props.leg)
      this.setState({ tagSize, onLayoutCalled: true })
    }
  }

  private readonly getBackgroundColor = (leg: ILeg) => {
    if (leg.mode === LegMode.Walk || leg.mode === LegMode.OnDemand) {
      if (this.props.routeSelected) {
        return colors.blue10
      }
      return colors.white
    }

    if (leg.routeColor) {
      return `#${leg.routeColor}`
    }

    return colors.blueAlt50
  }

  private readonly getTextColor = (leg: ILeg) => {
    if (leg.mode === LegMode.Walk || leg.mode === LegMode.OnDemand) {
      return colors.gray90
    }

    if (leg.routeTextColor) {
      return `#${leg.routeTextColor}`
    }

    return colors.white
  }
}
