import { IListResponse, IRequestResponse, RequestStatus } from '@sparelabs/api-client'
import { LegacyApiClient } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { PagedDataStore } from 'src/stores/PagedDataStore'
import { RequestStore } from 'src/stores/RequestStore'

/**
 * Requests with these statuses are considered finished and will not change any more after this.
 */
const FINISHED_REQUEST_STATUS = [RequestStatus.Completed, RequestStatus.NoDriversAvailable, RequestStatus.Cancelled]

export class PastRequestsStore extends PagedDataStore<IRequestResponse> {
  public static fetchPastTrips = async (skip: number, limit: number): Promise<IListResponse<IRequestResponse>> => {
    // If we have token and we haven't finished fetching all requests
    if (AuthenticatorHelper.userOrgToken) {
      const res = await LegacyApiClient.get(AuthenticatorHelper.userOrgToken, 'requests', {
        ...RequestStore.ORDER_BY_LATEST,
        status: FINISHED_REQUEST_STATUS.join('|'),
        limit,
        skip,
      })
      if (res) {
        return res.body
      }
    }
    throw new Error('Failed to fetch past trips')
  }

  constructor() {
    super(PastRequestsStore.fetchPastTrips)
  }
}
