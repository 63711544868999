import { IconName } from '@fortawesome/fontawesome-svg-core'
import React, { Component } from 'react'
import { StyleSheet, Text, TouchableOpacity, TouchableOpacityProps, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from '../FontAwesomeIcon'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 35,
  },
  activeButton: {
    backgroundColor: colors.selectedBlue,
  },
  button: {
    backgroundColor: colors.blue10,
  },
  activeTitle: {
    fontSize: 15,
    color: colors.textLight,
    fontWeight: '400',
  },
  title: {
    fontSize: 15,
    color: colors.gray90,
    fontWeight: '400',
  },
  icon: {
    marginRight: 8,
  },
  iconSelected: {
    color: colors.white,
  },
})

interface IProps extends TouchableOpacityProps {
  onPress?: () => void
  active?: boolean
  icon?: IconName
  text: string
}

export class TagButton extends Component<IProps> {
  public render() {
    return (
      <TouchableOpacity onPress={this.props.onPress} {...this.props}>
        <View style={[styles.container, this.props.active ? styles.activeButton : styles.button]} testID='tag-button'>
          {this.props.icon && (
            <FontAwesomeIconWrapper
              icon={this.props.icon}
              size={16}
              style={this.props.active ? { ...styles.icon, ...styles.iconSelected } : { ...styles.icon }}
            />
          )}
          <Text style={[styles.title, this.props.active ? styles.activeTitle : styles.title]}>{this.props.text}</Text>
        </View>
      </TouchableOpacity>
    )
  }
}
