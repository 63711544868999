import { IOrganizationResponse, IRequestResponse } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { ProfileButton } from 'src/components/buttons/ProfileButton'
import { HomeCardWrapper } from 'src/components/cards/HomeCardWrapper'
import { ActiveRequestInfoBar } from 'src/components/home/ActiveRequestInfoBar'
import { BrandInfoBar } from 'src/components/home/BrandInfoBar'
import { CompletedRequestInfoBar } from 'src/components/home/CompletedRequestInfoBar'
import { HomeTripPlannerCard } from 'src/components/home/HomeTripPlannerCard'
import { NetworkErrorPill } from 'src/components/NetworkErrorPill'
import { PillArea } from 'src/components/PillArea'
import { ActiveRequestCompletionObserver } from 'src/components/request/ActiveRequestCompletionObserver'
import { RequestCompletionAlerts } from 'src/components/request/RequestCompletionAlerts'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { DeepLinkAction, DeepLinkHelper, IDeepLink } from 'src/helpers/DeepLinkHelper'
import { ParamsListReview, ParamsListRoot, ParamsListScheduledTripList, ScreenName } from 'src/navigation'
import { AnnouncementHomeView } from 'src/screens/home/AnnouncementHomeView'
import {
  HomeDeepLinkHandler,
  IEstimateDeepLinkParams,
  ISurveyDeepLinkParams,
} from 'src/screens/home/HomeDeepLinkHandler'
import { EmptyScheduledRequests, HomeScheduledRequestsButton } from 'src/screens/home/HomeScheduledRequestsButton'
import { RequestStore } from 'src/stores/RequestStore'
import { ServiceStore } from 'src/stores/ServiceStore'
import { EstimatesUserInputParsed } from 'src/types'
import { IDeepLinkUrl } from 'src/types/deepLink'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  topButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

interface IProps {
  handleNavigateAccount: () => void
  handleNavigateSurvey: (params: ParamsListRoot[ScreenName.Survey]) => void
  handleNavigateWebView: (params: ParamsListRoot[ScreenName.WebViewModal]) => void
  handleNavigateScheduledTrips: (params: ParamsListScheduledTripList[ScreenName.ScheduledTripsList]) => void
  handleNavigateOnboarding: (params: ParamsListRoot[ScreenName.Onboarding]) => void
  handleNavigateSetFavoriteLocation: (params: ParamsListRoot[ScreenName.SetFavoriteLocation]) => void
  handleNavigateListAnnouncements: () => void
  handleNavigateReview: (params: ParamsListReview[ScreenName.Review]) => void
  handleNavigateTip: (params: ParamsListReview[ScreenName.AddTip]) => void
  handleNavigateAcknowledgeableAnnouncement: (
    params: ParamsListRoot[ScreenName.AcknowledgeableAnnouncementModal]
  ) => void
}

interface IDeepLinkProps {
  handleNavigateEstimate: (estimateUserInput: EstimatesUserInputParsed | null, serviceId: string | null) => void
}

@observer
export class HomeScreen extends Component<IProps & IDeepLinkProps> {
  private static readonly executeOnNDA = async (request: IRequestResponse) => {
    RequestCompletionAlerts.showNDAAlert(request.scheduledPickupTs || request.requestedPickupTs)
  }

  private static readonly executeOnCancelled = (request: IRequestResponse) => {
    if (request.cancellationDetails) {
      RequestCompletionAlerts.showCancelledAlert(
        request.scheduledPickupTs || request.requestedPickupTs,
        request.cancellationDetails.reason
      )
    }
  }

  private deepLinkHelper: DeepLinkHelper | null = null
  public refreshTimer: NodeJS.Timer | null = null

  public async componentDidMount() {
    // Only create a new DeepLinkHelper if there isn't one already, and deep link listening hasn't started yet
    this.deepLinkHelper = new DeepLinkHelper(this.deepLinkAction.bind(this))
    await this.deepLinkHelper.startListening()

    // Initiate refreshing data
    this.refreshTimer = await this.refreshApp()
  }

  public componentWillUnmount() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer)
    }
    if (this.deepLinkHelper) {
      this.deepLinkHelper.stopListening()
    }
  }

  public refreshApp = async () => {
    await AuthenticatorHelper.refreshStoreData()
    return setTimeout(this.refreshApp, 60000)
  }

  public renderInfoBar(nextRequest: IRequestResponse | undefined, organization: IOrganizationResponse) {
    const requestInfoBar = nextRequest ? <ActiveRequestInfoBar key='requestBar' request={nextRequest} /> : null
    const reviewInfoBar = RequestStore.lastCompletedRequest ? (
      <CompletedRequestInfoBar
        handleNavigateReview={this.props.handleNavigateReview}
        handleNavigateTip={this.props.handleNavigateTip}
        key='requestCompletedBar'
        isSingleBar={!requestInfoBar}
        completedRequest={RequestStore.lastCompletedRequest}
      />
    ) : null

    if (RequestStore.isLoaded) {
      if (!RequestStore.lastCompletedRequest && !nextRequest) {
        return <BrandInfoBar handleNavigateWebView={this.props.handleNavigateWebView} organization={organization} />
      }
      return [reviewInfoBar, requestInfoBar]
    }
    return null
  }

  public renderScheduledTripsButton() {
    if (RequestStore.hasScheduledRequests) {
      return (
        <HomeScheduledRequestsButton
          onPress={() => this.props.handleNavigateScheduledTrips({ navigateToRequestId: null })}
        />
      )
    }
    return <EmptyScheduledRequests />
  }

  public async deepLinkAction({ url }: IDeepLinkUrl) {
    const parsedUrl = DeepLinkHelper.parseDeepLink(url)
    if (parsedUrl) {
      await this.handleLink(parsedUrl)
    }
  }

  private async handleLink(parsedUrl: IDeepLink) {
    switch (parsedUrl.url) {
      case DeepLinkAction.Estimate:
        if (AuthenticatorHelper.getUserOrgToken()) {
          const estimate = await HomeDeepLinkHandler.handleEstimateLink(parsedUrl.query as IEstimateDeepLinkParams)
          if (estimate) {
            this.props.handleNavigateEstimate(estimate.estimateInput, estimate.serviceId)
          }
        }
        break
      case DeepLinkAction.Survey:
        if (AuthenticatorHelper.getUserOrgToken()) {
          const survey = await HomeDeepLinkHandler.handleSurveyLink(parsedUrl.query as ISurveyDeepLinkParams)
          if (survey) {
            this.props.handleNavigateSurvey({ survey })
          }
        }
        break
    }
  }

  public renderHomeTripPlannerCard(nextRequest: IRequestResponse | undefined): JSX.Element | null {
    /* We want to render this trip planner card when the user does not have
     * any trips in progress or when scheduling trips is allowed */
    if ((nextRequest && ServiceStore.isSchedulingAllowed) || !nextRequest) {
      return (
        <HomeTripPlannerCard
          handleNavigateOnboarding={this.props.handleNavigateOnboarding}
          handleNavigateSetFavoriteLocation={this.props.handleNavigateSetFavoriteLocation}
        />
      )
    }
    return null
  }

  public render() {
    const organization = AuthenticatorHelper.organization
    if (!organization || !organization.id) {
      return null
    }
    const request: IRequestResponse | null = RequestStore.activeRequest
    return (
      <View key='route-container' style={styles.container} pointerEvents='box-none' testID='homeScreen'>
        <View style={styles.topButtons} pointerEvents='box-none'>
          {AuthenticatorHelper.userOrgToken && <ProfileButton onPress={() => this.props.handleNavigateAccount()} />}
          <AnnouncementHomeView
            handleNavigateListAnnouncements={this.props.handleNavigateListAnnouncements}
            handleNavigateAcknowledgeableAnnouncement={this.props.handleNavigateAcknowledgeableAnnouncement}
          />
          {this.renderScheduledTripsButton()}
        </View>
        <HomeCardWrapper>
          <PillArea>
            <NetworkErrorPill />
          </PillArea>
          <ActiveRequestCompletionObserver
            effectIfCancelled={HomeScreen.executeOnCancelled}
            effectIfNda={HomeScreen.executeOnNDA}
          />
          {organization && this.renderInfoBar(request || undefined, organization)}
          {organization && this.renderHomeTripPlannerCard(request || undefined)}
        </HomeCardWrapper>
      </View>
    )
  }
}
