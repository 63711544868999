// This hot fix is required to address this bug
// https://github.com/storybookjs/react-native/issues/159#issuecomment-852422659
// eslint-disable-next-line import/order
import Bluebird from 'bluebird'
;(global.Promise as any) = Bluebird

import { checkForUpdateAsync, fetchUpdateAsync, reloadAsync } from 'expo-updates'
import React from 'react'
import { View } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { AppChild } from 'src/AppChild'
import { WebAlert } from 'src/components/webAlert/WebAlert'
import { IUpdateHandlers, UpdateState, useUpdateHook } from 'src/hooks/useUpdateHook'
import { ExpoUpdate } from 'src/screens/ExpoUpdate'

const expoUpdateHandlers: IUpdateHandlers = {
  checkUpdate: checkForUpdateAsync,
  fetchUpdate: fetchUpdateAsync,
  reload: reloadAsync,
}

export const InitScreen: React.FC = () => {
  const updateState: UpdateState = useUpdateHook(expoUpdateHandlers)
  if (updateState === UpdateState.Loading) {
    return <View />
  }
  if (updateState === UpdateState.Updating) {
    return <ExpoUpdate />
  }

  return (
    <>
      <WebAlert />
      <AppChild />
    </>
  )
}

export const App: React.FC = () => (
  <GestureHandlerRootView style={{ flex: 1 }}>
    <InitScreen />
  </GestureHandlerRootView>
)
