import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { PaymentMethodList } from 'src/components/payments/PaymentMethodList'
import { PaymentProviderHelper } from 'src/helpers/payments/PaymentProviderHelper'
import { ParamsListAddPaymentMethod, ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'

type Props = ParamsListRoot[ScreenName.SelectPaymentMethod] & {
  handleNavigateAddPaymentMethod: (params: ParamsListAddPaymentMethod[ScreenName.AddPaymentMethod]) => void
  handleDismiss: () => void
}

@observer
export class SelectPaymentMethodView extends Component<Props> {
  public render() {
    const {
      isCashEnabled,
      selectedPaymentMethodId,
      paymentMethodTypeRequired,
      onPaymentMethodPress,
      handleDismiss,
      handleNavigateAddPaymentMethod,
    } = this.props
    const handlePaymentMethodPress = async (paymentMethodId: string | null) => {
      await onPaymentMethodPress(paymentMethodId)
      handleDismiss()
    }
    return (
      <PaymentMethodList
        handleAddPaymentMethod={() => {
          handleNavigateAddPaymentMethod({
            paymentMethodTypeRequired,
            onPaymentMethodSuccessfullyAdded: handlePaymentMethodPress,
            validProviders: PaymentProviderHelper.getValidProviders(paymentMethodTypeRequired),
          })
        }}
        onSelectPaymentMethod={handlePaymentMethodPress}
        showSelected={true}
        isCashEnabled={isCashEnabled}
        selectedPaymentMethodId={selectedPaymentMethodId}
        paymentMethodTypeRequired={paymentMethodTypeRequired}
        selectAfterAdd={true}
      />
    )
  }
}

export const SelectPaymentMethod = (props: ScreenPropsRoot<ScreenName.SelectPaymentMethod>) => (
  <SelectPaymentMethodView
    {...props.route.params}
    handleNavigateAddPaymentMethod={(params) =>
      props.navigation.navigate(ScreenName.GroupAddPaymentMethod, {
        screen: ScreenName.AddPaymentMethod,
        params,
      })
    }
    handleDismiss={() => props.navigation.goBack()}
  />
)
