import { IRequestResponse } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'

const styles = StyleSheet.create({
  dateText: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 20,
    color: colors.gray90,
  },
  timeText: {
    paddingTop: 4,
    fontSize: 16,
    lineHeight: 20,
    color: colors.gray70,
  },
  timeSubtext: {
    paddingTop: 4,
    fontSize: 14,
    lineHeight: 16,
    color: colors.gray70,
  },
})

interface IProps {
  request: IRequestResponse
}

@observer
export class ScheduledRequestPickupText extends Component<IProps> {
  public render() {
    const { request } = this.props
    const estimatedPickupTimeRange = request.estimatedPickupTime
    const showPickupDropoffWindows = request.showPickupDropoffWindows
    return (
      <View style={{ flexDirection: 'column' }}>
        <Text style={styles.dateText}>{moment.unix(estimatedPickupTimeRange.ts).format('ddd, LL')}</Text>
        {this.renderScheduledPickup(
          estimatedPickupTimeRange.ts,
          estimatedPickupTimeRange.maxTs,
          showPickupDropoffWindows
        )}
      </View>
    )
  }

  private renderScheduledPickup(scheduledPickupTs: number, latestPickupTs: number, showPickupDropoffWindows: boolean) {
    return (
      <>
        <Text style={styles.timeText}>
          {st.request.estimatedPickupTime({ time: moment.unix(scheduledPickupTs).format('LT') })}
        </Text>
        {showPickupDropoffWindows && (
          <Text style={styles.timeSubtext}>
            {st.request.latestTime({ time: moment.unix(latestPickupTs).format('LT') })}
          </Text>
        )}
      </>
    )
  }
}
