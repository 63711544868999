import { IAnnouncementResponse } from '@sparelabs/api-client'
import Location from 'expo-location'
import { action, observable } from 'mobx'
import { persist } from 'mobx-persist'
import { getActiveAnnouncements } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError } from 'src/helpers/ErrorHelpers'

class AnnouncementStoreClass {
  public name = 'AnnouncementStore'

  @observable
  public announcements: IAnnouncementResponse[] = []

  /**
   * We use this array to track if rider has seen the announcement on modal
   */
  @persist('list')
  @observable
  private readonly acknowledgedAnnouncements: string[] = []

  @action
  public getAnnouncements = async (location: Location.LocationObject | null) => {
    try {
      if (AuthenticatorHelper.userOrgToken) {
        const latitude = location ? location.coords.latitude : null
        const longitude = location ? location.coords.longitude : null

        const orgToken: string = AuthenticatorHelper.userOrgToken
        const res = await getActiveAnnouncements(orgToken, latitude, longitude)
        if (res) {
          this.announcements = res.body.announcements
        }
      }
    } catch (error) {
      handleError({ error, silent: true })
    }
  }

  @action
  public getFirstAnnouncement = () => this.announcements[0]

  @action
  public acknowledge = (announcementId: string) => {
    this.acknowledgedAnnouncements.push(announcementId)
  }

  public isAcknowledged = (announcementId: string) => this.acknowledgedAnnouncements.includes(announcementId)

  @action
  public clear = () => {
    this.announcements = []
  }
}

export const AnnouncementStore = new AnnouncementStoreClass()
