import { GroupMembershipStatus, IGroupMembershipResponse, Resources } from '@sparelabs/api-client'
import { observable, runInAction } from 'mobx'
import { LegacyApiClient } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError } from 'src/helpers/ErrorHelpers'

export interface IGroupStore {
  groupMemberships: IGroupMembershipResponse[]
  refreshStoreData: () => Promise<void>
}

export class GroupStore {
  public name = 'GroupStore'

  @observable
  public groupMemberships: IGroupMembershipResponse[] = []

  public refreshStoreData = async () => {
    try {
      if (AuthenticatorHelper.userOrgToken) {
        // Get all active group memberships associated with the user
        const res = await LegacyApiClient.get(AuthenticatorHelper.userOrgToken, `${Resources.GroupMemberships}`, {
          userId: AuthenticatorHelper.userId,
          status: GroupMembershipStatus.Active,
        })
        if (res) {
          runInAction(() => {
            this.groupMemberships = res.body.data
          })
        }
      }
    } catch (error) {
      handleError({ error: error as Error })
    }
  }
}
