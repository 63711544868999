import { IFareRedemptionStub } from '@sparelabs/api-client'
import { CurrencyHelper } from '@sparelabs/currency'
import { sortBy } from 'lodash'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { DiscountNameRenderer } from 'src/screens/request/DiscountNameRenderer'

const getStyles = () =>
  StyleSheet.create({
    container: {
      backgroundColor: colors.white,
      paddingHorizontal: 16,
    },
    fareContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    subtitle: {
      color: colors.gray90,
      fontSize: 16,
      paddingVertical: 24,
    },
    text: {
      color: colors.gray90,
      fontSize: 16,
      paddingVertical: 4,
    },
    textBold: {
      fontWeight: '600',
      fontSize: 16,
      paddingVertical: 4,
    },
  })

@observer
export class DiscountDetailsView extends Component<ParamsListRoot[ScreenName.DiscountDetails]> {
  public render() {
    const styles = getStyles()
    const { fare } = this.props
    const sortedFareRedemptions = sortBy(this.props.fareRedemptions, [
      (redemption) => redemption.type, // group all redemptions of the same type together
      (redemption) => -redemption.amount, // within group, show largest amounts first
    ])
    return (
      <View style={styles.container}>
        <Text style={styles.subtitle}>{st.screens.discountDetails.discountApplied()}</Text>
        <View style={styles.fareContainer}>
          <Text style={styles.text}>{st.screens.discountDetails.fullFare()}</Text>
          {fare && <Text style={styles.text}>{CurrencyHelper.format(fare.cost, fare.currency)}</Text>}
        </View>
        {sortedFareRedemptions.map((redemption, i) => this.renderFareRedemption(redemption, i))}
        <View style={styles.fareContainer}>
          <Text style={styles.textBold}>{st.screens.discountDetails.total()}</Text>
          <Text style={styles.textBold}>{CurrencyHelper.format(fare.total, fare.currency)}</Text>
        </View>
      </View>
    )
  }

  private renderFareRedemption(fareRedemption: IFareRedemptionStub, key: number) {
    const styles = getStyles()
    return (
      <View style={styles.fareContainer} key={key}>
        <Text style={styles.text}>{DiscountNameRenderer.getDiscountName(fareRedemption.type)}</Text>
        <Text style={styles.text}>-{CurrencyHelper.format(fareRedemption.amount, fareRedemption.currency)}</Text>
      </View>
    )
  }
}

export const DiscountDetails = (props: ScreenPropsRoot<ScreenName.DiscountDetails>) => (
  <DiscountDetailsView {...props.route.params} />
)
