import { RiderType } from '@sparelabs/domain-logic'
import { GeometryType } from '@sparelabs/geography'
import { ParsedQuery } from 'query-string'
import { ApiClientBuilder } from 'src/api/ApiClientBuilder'
import { Logger } from 'src/helpers/Logger'
import { ParseEstimateHelper } from 'src/helpers/ParseEstimateHelper'

export interface IEstimateDeepLinkParams extends ParsedQuery<string> {
  requestedPickupLatitude: string
  requestedPickupLongitude: string
  requestedDropoffLatitude: string
  requestedDropoffLongitude: string
  requestedPickupTs: string | null
  requestedDropoffTs: string | null
  numRiders: string | null
  serviceId: string | null
}

export interface ISurveyDeepLinkParams extends ParsedQuery<string> {
  id: string
}

class HomeDeepLinkHandlerClass {
  private readonly logger = new Logger('HomeDeepLinkHandler')

  public handleEstimateLink = async (estimateParams: IEstimateDeepLinkParams) => {
    if (this.areRequiredEstimateParamsValid(estimateParams)) {
      // Check validity of all optional params
      const requestedPickupTs =
        estimateParams.requestedPickupTs && isFinite(Number(estimateParams.requestedPickupTs))
          ? Number(estimateParams.requestedPickupTs)
          : null
      const requestedDropoffTs =
        estimateParams.requestedDropoffTs && isFinite(Number(estimateParams.requestedDropoffTs))
          ? Number(estimateParams.requestedDropoffTs)
          : null
      const numRiders =
        estimateParams.numRiders && isFinite(Number(estimateParams.numRiders)) ? Number(estimateParams.numRiders) : null

      // Parse the estimate input with the estimate params
      let estimateInput = await ParseEstimateHelper.parseEstimate({
        requestedPickupLocation: {
          type: GeometryType.Point,
          coordinates: [
            Number(estimateParams.requestedPickupLongitude),
            Number(estimateParams.requestedPickupLatitude),
          ],
        },
        requestedDropoffLocation: {
          type: GeometryType.Point,
          coordinates: [
            Number(estimateParams.requestedDropoffLongitude),
            Number(estimateParams.requestedDropoffLatitude),
          ],
        },
        requestedPickupAddress: null,
        requestedDropoffAddress: null,
      })

      if (estimateInput) {
        estimateInput = {
          ...estimateInput,
          requestedPickupTs: requestedPickupTs ?? null,
          requestedDropoffTs: requestedPickupTs ? null : requestedDropoffTs,
          riders: [{ type: RiderType.Adult, count: numRiders ?? 1 }],
        }
      }

      return { estimateInput, serviceId: estimateParams.serviceId }
    }
  }

  private readonly areRequiredEstimateParamsValid = (estimateParams: IEstimateDeepLinkParams): boolean => {
    // Check required params first
    if (!this.areCoordinatesFinite(estimateParams)) {
      this.logger.debug('One or more coordinates in estimate deep link is not finite')
      return false
    }
    return true
  }

  private readonly areCoordinatesFinite = ({
    requestedPickupLatitude,
    requestedPickupLongitude,
    requestedDropoffLatitude,
    requestedDropoffLongitude,
  }: IEstimateDeepLinkParams) =>
    isFinite(Number(requestedPickupLatitude)) &&
    isFinite(Number(requestedPickupLongitude)) &&
    isFinite(Number(requestedDropoffLatitude)) &&
    isFinite(Number(requestedDropoffLongitude))

  public handleSurveyLink = async (surveyQuery: ISurveyDeepLinkParams) => {
    if (surveyQuery && surveyQuery.id) {
      const survey = await ApiClientBuilder.build().surveys.get(surveyQuery.id)
      return survey
    }
  }
}

export const HomeDeepLinkHandler = new HomeDeepLinkHandlerClass()
