import { FieldLabel, TextInput } from '@sparelabs/web-ui-components'
import React from 'react'
import { View } from 'react-native'
import { ITextField, ITextFieldProps } from 'src/components/textField/TextFieldTypes'

export const TextField: ITextField = ({ value, onChangeText, placeholder, label, disabled }: ITextFieldProps) => (
  <View>
    <FieldLabel>{label}</FieldLabel>
    <TextInput
      disabled={disabled}
      label={label ?? ''}
      onChange={(value: string | undefined) => {
        onChangeText && onChangeText(value ?? '')
      }}
      value={value}
      placeholder={placeholder}
    />
  </View>
)
