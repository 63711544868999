import { Component } from 'react'
import 'react-native'
import { captureError } from 'src/helpers/ErrorHelpers'

export class ErrorBoundary extends Component<{}, { hasError: boolean }> {
  public static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  constructor(props: {}) {
    super(props)
    this.state = { hasError: false }
  }

  public componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    captureError(error)
    if (info && info.componentStack) {
      // eslint-disable-next-line no-console
      console.log(`${error.message}${info.componentStack}`)
    }
  }

  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return null
    }
    return this.props.children
  }
}
