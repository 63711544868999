import { ILastVehicleLocationResponse, IRequestResponse, RequestStatus } from '@sparelabs/api-client'
import { MINUTE } from '@sparelabs/time'
import { autorun, observable } from 'mobx'
import { LegacyApiClient } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { moment } from 'src/helpers/Moment'
import { Poller } from 'src/helpers/Poller'
import { RequestStore } from 'src/stores/RequestStore'

class ActiveRequestVehicleLocationStoreClass {
  /**
   * This is the number of minutes before a pickup time
   * that we want to allow the rider to see the vehicle on a map.
   *
   * Doing this reduces the issues with showing a vehicle too early when it
   * may be going in the opposite direction
   */
  public VEHICLE_DISPLAY_THRESHOLD_MINUTES: number = 8

  /**
   * Request status should be one of these to show VL. Technically this is redundant because
   * vehicle location is not available during other request states from API anyway
   */
  public REQUEST_STATUS_TO_SHOW_VL = [RequestStatus.Accepted, RequestStatus.Arriving, RequestStatus.InProgress]

  @observable
  public lastVL: ILastVehicleLocationResponse | null = null

  /**
   * How often we wish to poll for vehicle location updates
   */
  private readonly VL_UPDATE_INTERVAL_MS = 5000
  // TODO Make this private.
  public readonly poller: Poller

  constructor() {
    this.poller = new Poller(this.updateData)
    autorun(async () => {
      if (RequestStore.activeRequest && RequestStore.activeRequest.vehicleId) {
        if (!this.poller.isRunning()) {
          this.poller.start(this.VL_UPDATE_INTERVAL_MS, true)
        }
      } else {
        this.poller.stop()
        this.clear()
      }
    })
  }

  public clear() {
    this.lastVL = null
  }

  /**
   * We should fetch VL for scheduled trips and those in which the pickup time is within the allowed threshold
   *  and in allowed request state. If the request is already in progress, fetch VL regardless.
   */
  public shouldBeShowingVL = (request: Pick<IRequestResponse, 'scheduledPickupTs' | 'status'>): boolean =>
    (this.REQUEST_STATUS_TO_SHOW_VL.includes(request.status) &&
      this.pickupIsWithinDisplayThreshold(request.scheduledPickupTs)) ||
    request.status === RequestStatus.InProgress

  private readonly pickupIsWithinDisplayThreshold = (scheduledPickupTs: number | null): boolean =>
    scheduledPickupTs !== null && scheduledPickupTs - moment().unix() <= this.VEHICLE_DISPLAY_THRESHOLD_MINUTES * MINUTE

  private readonly updateData = async () => {
    if (RequestStore.activeRequest && this.shouldBeShowingVL(RequestStore.activeRequest)) {
      this.lastVL = await this.fetchVehicleData(RequestStore.activeRequest.id)
    }
  }

  // TODO Make this private.
  public async fetchVehicleData(requestId: string): Promise<ILastVehicleLocationResponse | null> {
    if (AuthenticatorHelper.userOrgToken) {
      const res = await LegacyApiClient.get(AuthenticatorHelper.userOrgToken, `requests/${requestId}/vehicleLocation`)
      if (res) {
        return res.body
      }
    }
    return null
  }
}

export const ActiveRequestVehicleLocationStore = new ActiveRequestVehicleLocationStoreClass()
