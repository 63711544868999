import { LegMode } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { LayoutChangeEvent, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { IconCircle } from 'src/components/IconCircle'
import { getModeIcon } from 'src/components/journey/JourneyHelper'
import { TimelineDash } from 'src/components/journey/TimelineDash'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'

const ICON_CIRCLE_SIZE = 32

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
  leftContainer: {
    flexDirection: 'row',
    flexShrink: 1,
  },
  instructionContainer: {
    flex: -1,
    display: 'flex',
    paddingHorizontal: 8,
    paddingBottom: 40,
  },
  instructionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    flexWrap: 'wrap',
    color: colors.gray90,
  },
  detailsText: {
    fontSize: 14,
    color: colors.gray70,
    paddingTop: 4,
  },
  timelineContainer: {
    flexDirection: 'column',
    left: -4,
  },
})

export interface IWalkingItem {
  destinationAddress: string
  walkMinutes: number
  waitMinutes?: number
  startTimestamp: number
  isLastLeg?: boolean
}

interface IState {
  dottedLineHeight: number
}

@observer
export class JourneyWalkingItem extends Component<IWalkingItem, IState> {
  constructor(props: IWalkingItem) {
    super(props)
    this.state = {
      dottedLineHeight: 0,
    }
  }

  public render() {
    return (
      <View style={styles.container}>
        <View style={styles.leftContainer}>
          {this.renderTimeline()}
          {this.renderInstructions()}
        </View>
        {this.renderRightContainer()}
      </View>
    )
  }

  private readonly renderTimeline = () => (
    <View style={styles.timelineContainer}>
      <IconCircle
        icon={getModeIcon(LegMode.Walk)}
        backgroundColor={colors.gray30}
        color={colors.gray90}
        size={ICON_CIRCLE_SIZE}
        style={{ marginTop: 4 }}
      />
      {!this.props.isLastLeg && <TimelineDash dottedLineHeight={this.state.dottedLineHeight} />}
    </View>
  )

  private readonly renderInstructions = () => (
    <View style={styles.instructionContainer} onLayout={this.onLayout}>
      <Text style={styles.instructionTitle}>
        {st.components.journey.walkDescription({ stopName: this.props.destinationAddress })}
      </Text>
      <Text style={styles.detailsText}>
        {st.components.journey.walkDetailsDescription({ minutes: this.props.walkMinutes.toString() })}
      </Text>
    </View>
  )

  private readonly renderRightContainer = () => {
    const startTime = moment.unix(this.props.startTimestamp).format('LT')
    return <Text style={styles.detailsText}>{startTime}</Text>
  }

  private readonly onLayout = (event: LayoutChangeEvent) => {
    if (this.state.dottedLineHeight === 0) {
      const height = event.nativeEvent.layout.height
      this.setState({ dottedLineHeight: height - ICON_CIRCLE_SIZE })
    }
  }
}
