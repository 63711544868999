import { IRequestResponse } from '@sparelabs/api-client'
import { toJS } from 'mobx'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { ActivityIndicator, FlatList, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { st } from 'src/locales'
import { ParamsListScheduledTripList, ScreenName, ScreenPropsScheduledTripList } from 'src/navigation'
import { ScheduledTripsListItem } from 'src/screens/request/ScheduledRequests/ScheduledTripsListItem'
import { RequestStore } from 'src/stores/RequestStore'
import { ScheduledRequestsStore } from 'src/stores/ScheduledRequestsStore'

const styles = StyleSheet.create({
  list: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  requestTitle: {
    fontSize: 16,
    color: colors.textDark,
  },
  divider: {
    flex: 1,
    borderTopColor: colors.borderGray,
    borderTopWidth: 1,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 50,
  },
  loadingFooter: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 25,
  },
})

type Props = ParamsListScheduledTripList[ScreenName.ScheduledTripsList] & {
  handleNavigateScheduledTrip: (params: ParamsListScheduledTripList[ScreenName.ScheduledTrip]) => void
}

interface IState {
  pageStore: ScheduledRequestsStore
}

@observer
export class ScheduledTripsListView extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      pageStore: new ScheduledRequestsStore(),
    }
  }

  public async UNSAFE_componentWillMount() {
    await this.state.pageStore.fetchMore()

    if (this.props.navigateToRequestId) {
      const navigateToRequest = this.state.pageStore.data.find((d) => d.id === this.props.navigateToRequestId)
      if (navigateToRequest) {
        this.navigateToSingleTripView(navigateToRequest)
      }
    }
  }

  public onEndReached = async () => {
    if (this.state.pageStore.apiHasMoreData && !this.state.pageStore.isLoading()) {
      await this.state.pageStore.fetchMore()
    }
  }

  public renderEmptyList() {
    return (
      <View style={styles.emptyContainer}>
        <Text style={styles.requestTitle}>{st.screens.scheduledTrips.noScheduledTrips()}</Text>
      </View>
    )
  }

  public renderLoadingFooter() {
    if (this.state.pageStore.isLoading()) {
      return (
        <View style={styles.loadingFooter}>
          <ActivityIndicator color='black' />
        </View>
      )
    }
    return null
  }

  public render() {
    return (
      <FlatList
        style={styles.list}
        data={this.getData()}
        keyExtractor={(item) => item.id}
        onEndReached={this.onEndReached}
        onEndReachedThreshold={0.7}
        renderItem={({ item }) => (
          <ScheduledTripsListItem request={item} onPress={() => this.navigateToSingleTripView(item)} />
        )}
        ListEmptyComponent={this.renderEmptyList()}
        ListFooterComponent={this.renderLoadingFooter()}
        ItemSeparatorComponent={() => <View style={styles.divider} />}
      />
    )
  }

  private readonly navigateToSingleTripView = (request: IRequestResponse) => {
    this.props.handleNavigateScheduledTrip({
      request: toJS(request),
      showConfirmation: false,
    })
  }

  private getData(): IRequestResponse[] {
    /**
     * We don't want to show the "active request" in the list of scheduled requests
     * so we can filter it out here
     */
    return this.state.pageStore.data.filter((s) =>
      RequestStore.activeRequest ? s.id !== RequestStore.activeRequest.id : true
    )
  }
}

export const ScheduledTripsList = (props: ScreenPropsScheduledTripList<ScreenName.ScheduledTripsList>) => (
  <ScheduledTripsListView
    {...props.route.params}
    handleNavigateScheduledTrip={(params) => props.navigation.navigate(ScreenName.ScheduledTrip, params)}
  />
)
