import { IPoint } from '@sparelabs/geography'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { Platform } from 'react-native'
import { colors } from 'src/assets/colors'
import { IWalkingPolylineProps } from 'src/components/mapMarkers/MapMarkerProps'
import { Polyline } from 'src/components/mapMarkers/polyline/Polyline'
import { MapMarkerHelper } from 'src/helpers/MapMarkerHelper'
import { OsType } from 'src/util/types'

/**
 * Draws a path connecting two different locations, if:
 * - they both exist
 * - there is no optimized stops radius
 * - they are sufficiently far apart
 */
@observer
export class WalkingPolyline extends Component<IWalkingPolylineProps> {
  public render() {
    const { walkingInfo } = this.props

    if (
      !walkingInfo ||
      !this.shouldRenderPolyline(
        walkingInfo.startLocation,
        walkingInfo.endLocation,
        walkingInfo.walkingDuration,
        walkingInfo.hasRadius
      )
    ) {
      return null
    }

    return (
      <Polyline
        lineDashPattern={[0, 6]}
        strokeWidth={this.getPolylineStrokeWidth()}
        strokeColor={colors.gray90}
        coordinates={walkingInfo.walkingPolyline}
        lineCap='round'
      />
    )
  }

  private shouldRenderPolyline(
    startLocation: IPoint,
    endLocation: IPoint,
    walkingDuration: number,
    hasRadius: boolean
  ) {
    return !MapMarkerHelper.doLocationsOverlap(startLocation, endLocation, walkingDuration) && !hasRadius
  }

  private getPolylineStrokeWidth() {
    /* On Android devices, the polyline width looks too thin, so we increase the width based on the platform */
    return Platform.OS === OsType.Android ? 6 : 3
  }
}
