import { ITermsResponse } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native'
import { getOrganizationTerms } from 'src/api'
import { colors } from 'src/assets/colors'
import { ISettingsListItem, SettingsList, SettingsListItemType } from 'src/components/settings/SettingsList'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { WebViewHelper } from 'src/helpers/WebViewHelper'
import { st } from 'src/locales'
import { ParamsListAccount, ParamsListRoot, ScreenName, ScreenPropsAccount } from 'src/navigation'

interface IState {
  terms: ITermsResponse | null
  initialized: boolean
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    marginTop: 40,
  },
  emptyText: {
    fontSize: 18,
    margin: 16,
    color: colors.textMedium,
  },
})

type Props = ParamsListAccount[ScreenName.Legal] & {
  handleNavigateWebView: (params: ParamsListRoot[ScreenName.WebViewModal]) => void
}

@observer
export class LegalView extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)
    this.state = { initialized: false, terms: null }
  }

  public async componentDidMount() {
    await this.fetchOrganizationTerms()
  }

  public fetchOrganizationTerms = async () => {
    const terms = await getOrganizationTerms(AuthenticatorHelper.getOrganization().id)
    this.setState({ terms, initialized: true })
  }

  public handlePressItem = (id) => {
    if (this.state.terms) {
      const { termsOfUseUrl: termsOfUse, privacyPolicyUrl: privacyPolicy } = this.state.terms

      switch (id) {
        case '1':
          this.props.handleNavigateWebView({ uri: termsOfUse })
          break
        case '2':
          this.props.handleNavigateWebView({ uri: privacyPolicy })
          break
      }
    }
  }

  public renderLegalLinks = () => {
    if (this.state.terms) {
      const settingsListData: ISettingsListItem[] = []
      settingsListData.push({ id: '1', title: st.screens.legal.termsOfUse(), type: SettingsListItemType.List })
      settingsListData.push({ id: '2', title: st.screens.legal.privacyPolicy(), type: SettingsListItemType.List })
      return <SettingsList data={settingsListData} onPressItem={this.handlePressItem} />
    }
    return <Text style={styles.emptyText}>{st.screens.legal.noTerms()}</Text>
  }

  public renderLoading = () => (
    <View style={styles.loadingContainer}>
      <ActivityIndicator color='black' />
    </View>
  )

  public render = () => (!this.state.initialized ? this.renderLoading() : this.renderLegalLinks())
}

export const Legal = (props: ScreenPropsAccount<ScreenName.Legal>) => (
  <LegalView
    {...props.route.params}
    handleNavigateWebView={(params) => WebViewHelper.handleLink(params, props.navigation.navigate.bind(Legal))}
  />
)
