import React from 'react'
import { ActivityIndicator, Image, ImageSourcePropType, SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { Constants } from 'src/consts/Constants'
import { st } from 'src/locales'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  loginContainer: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'white',
    justifyContent: 'space-between',
  },
  bottomContainer: {
    flex: 1,
    margin: 16,
    marginBottom: 80,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  logoContainer: {
    marginTop: 80,
    width: 250,
    height: 200,
  },
  loadingSpinner: {
    width: 27,
    height: 27,
  },
  updateText: {
    marginBottom: 16,
    color: colors.gray90,
    fontSize: 16,
  },
})

const renderLogo = (): ImageSourcePropType =>
  Constants.APP_LOGO_NAME && Images[Constants.APP_LOGO_NAME as keyof typeof Images]
    ? Images[Constants.APP_LOGO_NAME as keyof typeof Images]
    : Images.defaultLogo

export const ExpoUpdate = () => (
  <SafeAreaView style={styles.container}>
    <View style={styles.loginContainer}>
      <Image resizeMode='contain' style={styles.logoContainer} source={renderLogo()} />
      <View style={styles.bottomContainer}>
        <Text style={styles.updateText}>{st.common.updating()}</Text>
        <ActivityIndicator style={styles.loadingSpinner} color={colors.black} size='small' />
      </View>
    </View>
  </SafeAreaView>
)
