import { IRequestResponse } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { RequestHelper } from 'src/helpers/RequestHelper'
import { st } from 'src/locales'
import { Timeline } from './Timeline'

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.gray70,
    paddingBottom: 8,
  },
  currentLocationContainer: {
    position: 'absolute',
    zIndex: 3,
    left: 6,
  },
})

interface IProps {
  request: IRequestResponse
}

@observer
export class TripTimeline extends Component<IProps> {
  public render() {
    const { request } = this.props
    const isPickupDifferentFromStart = request.scheduledPickupAddress !== request.requestedPickupAddress
    const isDropoffDifferentFromEnd = request.scheduledDropoffAddress !== request.requestedDropoffAddress

    return (
      <View style={styles.container}>
        <Text style={styles.title}>{st.components.timeline.yourTrip()}</Text>
        <Timeline
          requestedPickupAddress={request.requestedPickupAddress}
          requestedDropoffAddress={request.requestedDropoffAddress}
          scheduledPickupAddress={request.scheduledPickupAddress}
          scheduledDropoffAddress={request.scheduledDropoffAddress}
          isPickupDifferentFromStart={isPickupDifferentFromStart}
          isDropoffDifferentFromEnd={isDropoffDifferentFromEnd}
          startEta={RequestHelper.getOriginEta(request)}
          endEta={RequestHelper.getDestinationEta(request)}
          pickupEta={RequestHelper.getPickupEta(request)}
          dropoffEta={RequestHelper.getDropoffEta(request)}
          latestDropoffEta={RequestHelper.getLatestDropoff(request)}
          requestStatus={request.status}
        />
      </View>
    )
  }
}
