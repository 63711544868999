import { IRequestResponse, ReviewTag } from '@sparelabs/api-client'
import { delay } from '@sparelabs/time'
import { toJS } from 'mobx'
import { captureError } from 'src/helpers/ErrorHelpers'
import { TipHelper } from 'src/helpers/TipHelper'
import { st } from 'src/locales'
import { ParamsListReview, ScreenName } from 'src/navigation'
import { LoadingStore } from 'src/stores/LoadingStore'
import { ReviewRating, ReviewStore } from 'src/stores/ReviewStore'

const COMMON_REVIEW_TAGS = [
  ReviewTag.AppExperience,
  ReviewTag.Comfort,
  ReviewTag.Driving,
  ReviewTag.Professionalism,
  ReviewTag.StopLocation,
  ReviewTag.Price,
  ReviewTag.TransitConnection,
]

const POSITIVE_REVIEW_TAGS = [ReviewTag.AboveAndBeyond, ReviewTag.OnTime, ...COMMON_REVIEW_TAGS]
const NEGATIVE_REVIEW_TAGS = [
  ReviewTag.Traffic,
  ReviewTag.LateDriver,
  ReviewTag.BusyService,
  ReviewTag.NoShow,
  ...COMMON_REVIEW_TAGS,
]

export const getTags = (rating: ReviewRating | null): ReviewTag[] | null => {
  if (rating === ReviewRating.Positive) {
    return POSITIVE_REVIEW_TAGS
  } else if (rating === ReviewRating.Negative) {
    return NEGATIVE_REVIEW_TAGS
  }
  return null
}

export const handlePostReviewProcess = async (
  request: IRequestResponse,
  canRiderTip: boolean,
  tipPolicyValid: boolean,
  handleNavigateAddTip: (params: ParamsListReview[ScreenName.AddTip]) => void,
  setIsDoneReview: (isDoneReview: boolean) => void,
  handleDismiss: () => void
): Promise<void> => {
  setIsDoneReview(true)
  // if this ride needs to be tipped, then push to next screen, otherwise display animation
  if (tipPolicyValid) {
    handleNavigateAddTip({
      request: toJS(request),
      canRiderTip,
      title: TipHelper.getTipScreenTitle(request, false),
    })
  } else {
    // delay while the animation plays
    await delay(2500)
    handleDismiss()
  }
}

export const handleThumbPress = (thumbsUp: boolean): void => {
  const rating = thumbsUp ? ReviewRating.Positive : ReviewRating.Negative
  ReviewStore.setRating(rating)
}

export const getReviewDescription = (rating: ReviewRating | null): string => {
  if (rating === ReviewRating.Positive) {
    return st.screens.review.positiveDescription()
  } else if (rating === ReviewRating.Negative) {
    return st.screens.review.negativeDescription()
  }

  return st.screens.review.reviewDescription()
}

export const handlePressConfirm = async (
  request: IRequestResponse,
  canRiderTip: boolean,
  tipPolicyValid: boolean,
  handleNavigateAddTip: (params: ParamsListReview[ScreenName.AddTip]) => void,
  setIsDoneReview: (isDoneReview: boolean) => void,
  handleDismiss: () => void,
  invokeAlert: () => void,
  loadingStore: LoadingStore
) => {
  try {
    if (ReviewStore.rating === null) {
      invokeAlert()
    }

    await loadingStore.execute(ReviewStore.submitReview(request))
    await handlePostReviewProcess(
      request,
      canRiderTip,
      tipPolicyValid,
      handleNavigateAddTip,
      setIsDoneReview,
      handleDismiss
    )
  } catch (error) {
    captureError(error)
  }
}
