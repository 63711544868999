import { action, computed, observable } from 'mobx'
import { Dimensions, Platform } from 'react-native'
import { OsType } from 'src/util/types'

class UIStateStoreClass {
  @observable public screenHeight: number = Dimensions.get('window').height
  @observable public screenWidth: number = Dimensions.get('window').width

  constructor() {
    this.setDimensions()
    // Event Listener for orientation changes
    Dimensions.addEventListener('change', () => {
      this.setDimensions()
    })
  }

  @computed
  public get isPortrait(): boolean {
    return this.screenHeight >= this.screenWidth
  }

  // For Rider Web, we want the mobile web version to appear exactly like
  // the mobile app when the experience is in portrait mode (where possible)
  @computed
  public get shouldShowLandscapeWeb(): boolean {
    return !this.isPortrait && Platform.OS === OsType.Web
  }

  @action
  public setDimensions = () => {
    const dimensions = Dimensions.get('window')
    this.screenHeight = dimensions.height
    this.screenWidth = dimensions.width
  }
}

export const UIStateStore = new UIStateStoreClass()
