import { IPromoAllocationResponse } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { ActivityIndicator, FlatList, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { st } from 'src/locales'
import { ParamsListAccount, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { PromoStore } from 'src/stores/PromoStore'
import { PromoEntry } from './PromoEntry'

const styles = StyleSheet.create({
  list: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  title: {
    fontSize: 16,
    marginLeft: 16,
    color: colors.textDark,
    flexWrap: 'wrap',
    flex: 1,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 50,
  },
})

type Props = ParamsListAccount[ScreenName.ListPromo] & {
  handleNavigateAddPromo: () => void
}

@observer
export class ListPromoScreenView extends Component<Props> {
  public async componentDidMount() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    PromoStore.getPromos()
  }

  public renderEmptyList() {
    return (
      <View style={styles.emptyContainer}>
        {PromoStore.loading ? (
          <ActivityIndicator color='black' />
        ) : (
          <Text style={styles.title}>{st.screens.listPromoScreen.noPromos()}</Text>
        )}
      </View>
    )
  }

  public renderPromotion = ({ item }: { item: IPromoAllocationResponse }) => <PromoEntry promo={item} />

  public renderAddPromotion = () => (
    <PrimaryButton
      onPress={() => this.props.handleNavigateAddPromo()}
      title={st.screens.listPromoScreen.addPromoCode()}
      paddingHorizontal={true}
    />
  )

  public render() {
    return (
      <FlatList
        style={styles.list}
        data={PromoStore.promotions}
        refreshing={PromoStore.loading}
        keyExtractor={(item) => item.id}
        renderItem={this.renderPromotion}
        ListEmptyComponent={this.renderEmptyList()}
        ListFooterComponent={this.renderAddPromotion()}
      />
    )
  }
}

export const ListPromoScreen = (props: ScreenPropsAccount<ScreenName.ListPromo>) => (
  <ListPromoScreenView
    {...props.route.params}
    handleNavigateAddPromo={() => props.navigation.navigate(ScreenName.AddPromo, {})}
  />
)
