import { FeatureFlag, IEstimateOutput, IEstimateService } from '@sparelabs/api-client'
import { FareSemantics, PaymentMethodUtils } from '@sparelabs/domain-logic'
import { ObservableMap } from 'mobx'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { colors } from 'src/assets/colors'
import { CardIconAction } from 'src/components/cards/CardIconAction'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { FareRenderer } from 'src/helpers/FareRenderer'
import { PaymentMethodHelper } from 'src/helpers/payments/PaymentMethodHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'
import { EstimatesUserInputParsed, IEstimatesUserInputParsed } from 'src/types'

export interface IProps {
  handleNavigateSelectPaymentMethod: (props: ParamsListRoot[ScreenName.SelectPaymentMethod]) => void
  estimatesUserInput: IEstimatesUserInputParsed
  selectedService: IEstimateService | undefined
  isJourneySelected: boolean
  estimateResponseMap: ObservableMap<string, IEstimateOutput | null>
  estimateInputStore: IEstimateInputStore
  isDisabled?: boolean
  title?: string
}

@observer
export class PaymentControl extends Component<IProps> {
  public render = () => {
    const { title, isAllowedToProceed } = this.getPaymentMethodInfo()
    return (
      <CardIconAction
        testID='paymentMethodIconAction'
        title={this.props.title || title}
        accessibilityLabel={st.components.rideOptionsCardBody.accessibilityLabelPayment()}
        accessibilityHint={st.components.rideOptionsCardBody.accessibilityHintPayment()}
        accessibilityValue={this.props.title || title}
        titleColor={this.props.isDisabled ? colors.gray5 : !isAllowedToProceed ? colors.red70 : colors.blue70}
        icon='credit-card'
        containerStyle={!isAllowedToProceed ? { backgroundColor: colors.red10 } : { backgroundColor: colors.blue10 }}
        iconColor={this.props.isDisabled ? colors.gray5 : !isAllowedToProceed ? colors.red50 : colors.blue70}
        onPress={() =>
          this.props.handleNavigateSelectPaymentMethod({
            selectedPaymentMethodId: this.props.estimateInputStore.getEstimateInput().paymentMethodId ?? null,
            onPaymentMethodPress: this.onPaymentMethodPress,
            isCashEnabled: this.props.selectedService?.serviceIsCashEnabled ?? false,
          })
        }
        isDisabled={this.props.isDisabled}
      />
    )
  }

  public getPaymentMethodInfo = (): { title: string; isAllowedToProceed: boolean } => {
    // If transit journey is selected, then we won't bother checking for payment method
    if (this.props.isJourneySelected) {
      return { title: st.helpers.payment.payInVehicle(), isAllowedToProceed: true }
    }

    const { paymentMethodId } = this.props.estimatesUserInput
    const { selectedService } = this.props
    const paymentMethod = paymentMethodId ? PaymentMethodStore.getPaymentMethodById(paymentMethodId) : undefined

    // TODO - Remove this feature flag once we have a "mobile wallet" feature available
    const allowBookingWithoutPaymentMethod = AuthenticatorHelper.getOrganization().featureFlags.includes(
      FeatureFlag.AllowBookingWithoutPaymentMethod
    )

    const paymentMethodRequired =
      (!allowBookingWithoutPaymentMethod && !selectedService?.serviceIsCashEnabled) ||
      selectedService?.serviceIsTippingEnabled

    // Take the fare from the estimate rather than the service, to account for any updates to the estimate since the initial fetch of estimate services
    const estimate = selectedService ? this.props.estimateResponseMap.get(selectedService.serviceId) : null

    // If there's no cost to ride, regardless of payment method
    if (estimate && estimate.fare.cost === 0) {
      return { title: st.helpers.payment.free(), isAllowedToProceed: true }
    }

    // If payment is needed and there is not a payment method
    if (paymentMethodRequired && !paymentMethod) {
      if (PaymentMethodStore.paymentMethods.length > 0) {
        return { title: st.screens.selectPaymentMethod.select(), isAllowedToProceed: false }
      }
      return { title: st.screens.selectPaymentMethod.missing(), isAllowedToProceed: false }
    }

    // If there is nothing to pay due to discount
    if (estimate && !FareSemantics.requiresPayment(estimate) && FareSemantics.hasDiscount(estimate)) {
      // If we allow booking without payment methods then don't show "Free" or "No charge" as in this
      // case the rider is using a promo as a credit store
      // TODO - Remove this once we have a "mobile wallet" feature available
      if (allowBookingWithoutPaymentMethod) {
        return { title: st.screens.selectPaymentMethod.select(), isAllowedToProceed: true }
      }
      return { title: FareRenderer.renderZeroFare(estimate), isAllowedToProceed: true }
    }

    // If tipping is enabled and the payment method does not support tipping
    if (
      selectedService?.serviceIsTippingEnabled &&
      (!paymentMethod || !PaymentMethodUtils.isTippingSupported(paymentMethod.brand))
    ) {
      return { title: st.screens.paymentMethods.notAvailable(), isAllowedToProceed: false }
    }

    // If a payment method is selected
    if (paymentMethod) {
      return { title: PaymentMethodHelper.getShortPaymentMethodName(paymentMethod), isAllowedToProceed: true }
    }

    return { title: st.helpers.payment.payInVehicle(), isAllowedToProceed: true }
  }

  public onPaymentMethodPress = async (paymentMethodId: string | null) => {
    const data: EstimatesUserInputParsed = {
      ...this.props.estimateInputStore.getEstimateInput(),
      paymentMethodId,
    }
    this.props.estimateInputStore.estimateInput = data
  }
}
