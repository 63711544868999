import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'

const getStyles = () =>
  StyleSheet.create({
    circle: {
      width: 24,
      height: 24,
      borderColor: colors.gray50,
      borderRadius: 24 / 2,
      borderWidth: 2,
    },
  })

interface IProps {
  value: boolean
  onPress?: () => void
}

@observer
export class RadioButton extends Component<IProps, {}> {
  public render() {
    const styles = getStyles()
    return (
      <TouchableOpacity onPress={this.props.onPress}>
        {this.props.value && <FontAwesomeIcon icon={['fas', 'scrubber']} color={colors.blue50} size={24} />}
        {!this.props.value && <View style={styles.circle} />}
      </TouchableOpacity>
    )
  }
}
