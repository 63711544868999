import { IPromoAllocationResponse, PromoAllocationStatus, Resources } from '@sparelabs/api-client'
import { action, observable } from 'mobx'
import { persist } from 'mobx-persist'
import { LegacyApiClient, redeemPromos } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError } from 'src/helpers/ErrorHelpers'

class PromoStoreClass {
  public name = 'PromoStore'

  @observable
  @persist('object')
  public promotions: IPromoAllocationResponse[] = []

  @observable public loading: boolean = false

  @action
  public getPromos = async () => {
    try {
      this.loading = true
      if (AuthenticatorHelper.userOrgToken) {
        const res = await LegacyApiClient.get(AuthenticatorHelper.userOrgToken, Resources.PromoAllocations, {
          orderBy: 'expiryTs',
          orderDirection: 'ASC',
        })
        if (res) {
          const allocations = res.body.data as IPromoAllocationResponse[]
          this.promotions = allocations.filter((allocation) => allocation.status === PromoAllocationStatus.Unused)
        }
      }
    } catch (error) {
      handleError({ error, silent: true })
    } finally {
      this.loading = false
    }
  }

  @action public redeemPromo = async (code: string) => {
    try {
      this.loading = true
      if (AuthenticatorHelper.userOrgToken) {
        const res = await redeemPromos(AuthenticatorHelper.userOrgToken, AuthenticatorHelper.getUser().id, code)
        if (res) {
          const newPromo = res.body as IPromoAllocationResponse
          this.promotions = [...this.promotions, newPromo].sort((a, b) => a.expiryTs - b.expiryTs)
        }
      }
    } finally {
      this.loading = false
    }
  }

  @action
  public clear() {
    this.promotions = []
    this.loading = false
  }
}

export const PromoStore = new PromoStoreClass()
