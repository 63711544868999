import { IFareRedemptionStub, IFareStub, LegMode } from '@sparelabs/api-client'
import { CurrencyHelper } from '@sparelabs/currency'
import { MINUTE } from '@sparelabs/time'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { Image, LayoutChangeEvent, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { EnrouteCircle } from 'src/components/EnrouteCircle'
import { TimelineDash } from 'src/components/journey/TimelineDash'
import { OUTER_CIRCLE_DIAMETER, StopCircle } from 'src/components/StopCircle'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales/TranslationHelper'

const SERVICE_ICON_SIZE = 32
const MIN_PICKUP_ROUTE_HEIGHT = 24
const MIN_REQUEST_LINE_HEIGHT = 24

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingHorizontal: 16,
  },
  sectionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  timelineContainer: {
    flexDirection: 'row',
    flexShrink: 1,
  },
  iconContainer: {
    flexDirection: 'column',
    left: -4,
  },
  routeLine: {
    opacity: 0.4,
    borderLeftWidth: 8,
    zIndex: -2,
    position: 'relative',
    width: 32,
    right: -12,
    borderColor: colors.gray30,
  },
  stopLine: {
    opacity: 0.4,
    borderLeftWidth: 8,
    zIndex: -1,
    position: 'relative',
    minHeight: 41,
    width: 32,
    right: -8,
    borderColor: colors.gray30,
  },
  serviceIcon: {
    height: SERVICE_ICON_SIZE,
    width: SERVICE_ICON_SIZE,
    borderRadius: SERVICE_ICON_SIZE / 2,
  },
  instructionContainer: {
    flexDirection: 'column',
    paddingHorizontal: 8,
    flexShrink: 1,
    flex: -1,
    paddingBottom: 24,
  },
  pickupTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.gray90,
    flexWrap: 'wrap',
    lineHeight: 20,
  },
  pickupSubtitle: {
    fontSize: 14,
    color: colors.gray70,
    lineHeight: 16,
    flexWrap: 'wrap',
    marginTop: 4,
  },
  detailsRightText: {
    fontSize: 14,
    color: colors.gray70,
    textAlign: 'right',
    lineHeight: 16,
  },
  buttonSectionContainer: {
    flexDirection: 'row',
    left: -4,
  },
  buttonContainer: {
    flex: 1,
    right: -8,
    paddingBottom: 24,
  },
  rideInfoText: {
    fontSize: 14,
    color: colors.gray70,
    marginLeft: 8,
    lineHeight: 16,
  },
  timelineDot: {
    position: 'absolute',
    zIndex: 1,
    paddingTop: 4,
    left: 8,
  },
  lineStub: {
    borderColor: colors.gray30,
    opacity: 0.4,
    borderLeftWidth: 8,
    zIndex: -2,
    height: 4,
    width: 32,
    position: 'absolute',
    left: 4,
  },
})

export interface IOnDemandItem {
  legMode: LegMode
  serviceId: string
  serviceName: string
  serviceBrandUrl: string
  organizationName: string
  pickupLocationName: string
  dropoffLocationName: string
  rideDurationMinutes: number
  pickupTimestamp: number
  dropoffTimestamp: number
  latestDropoffTimestamp: number
  fare: IFareStub
  fareRedemptions: IFareRedemptionStub[]
  isLastLeg: boolean
}

interface IProps {
  onDemandDetails: IOnDemandItem
  handleMakeJourneyEstimate: () => Promise<void>
}

interface IState {
  dottedLineHeight: number
  requestRouteLineHeight: number
  pickupRouteLineHeight: number
  dropoffRouteLineHeight: number
}

@observer
export class JourneyOnDemandItem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      dottedLineHeight: 0,
      requestRouteLineHeight: 0,
      pickupRouteLineHeight: 0,
      dropoffRouteLineHeight: 0,
    }
  }

  public render() {
    return (
      <View style={styles.container}>
        <View style={[styles.sectionContainer, { paddingTop: 4 }]}>
          <View style={styles.timelineContainer}>
            {this.renderRequestTimeline()}
            {this.renderRequestDetails()}
          </View>
          {this.renderFarePrice()}
        </View>
        {this.renderRequestButton()}
        <View style={styles.sectionContainer}>
          <View style={styles.timelineContainer}>
            {this.renderPickupTimeline()}
            {this.renderPickupDetails()}
          </View>
          {this.renderPickupTime()}
        </View>
        <View style={styles.timelineContainer}>
          {this.renderRideDetailsTimeline()}
          {this.renderRideDetails()}
        </View>
        <View style={styles.sectionContainer}>
          <View style={styles.timelineContainer}>
            {this.renderDropoffTimeline()}
            {this.renderDropoffDetails()}
          </View>
          {this.renderDropoffTimes()}
        </View>
      </View>
    )
  }

  private readonly renderRequestTimeline = () => {
    const { serviceBrandUrl } = this.props.onDemandDetails
    return (
      <View style={styles.iconContainer}>
        <Image style={styles.serviceIcon} source={{ uri: serviceBrandUrl }} />
        <View
          style={{
            ...styles.routeLine,
            height: this.state.requestRouteLineHeight,
          }}
        />
      </View>
    )
  }

  private readonly renderRequestDetails = () => (
    <View style={{ ...styles.instructionContainer, paddingBottom: 16 }} onLayout={this.setRequestRouteLineHeight}>
      <Text style={styles.pickupTitle}>
        {st.components.journey.requestOnDemandDescription({
          organizationName: this.props.onDemandDetails.organizationName,
        })}
      </Text>
      <Text style={styles.pickupSubtitle}>{this.props.onDemandDetails.serviceName}</Text>
    </View>
  )

  private readonly setRequestRouteLineHeight = (event: LayoutChangeEvent) => {
    if (this.state.requestRouteLineHeight === 0) {
      const height = event.nativeEvent.layout.height
      this.setState({ requestRouteLineHeight: Math.max(height - SERVICE_ICON_SIZE, MIN_REQUEST_LINE_HEIGHT) })
    }
  }

  private readonly setPickupRouteLineHeight = (event: LayoutChangeEvent) => {
    if (this.state.pickupRouteLineHeight === 0) {
      const height = event.nativeEvent.layout.height
      this.setState({ pickupRouteLineHeight: Math.max(height, MIN_PICKUP_ROUTE_HEIGHT) })
    }
  }

  private readonly setDropoffRouteLineHeight = (event: LayoutChangeEvent) => {
    if (this.state.dropoffRouteLineHeight === 0) {
      const height = event.nativeEvent.layout.height
      this.setState({ dropoffRouteLineHeight: height - OUTER_CIRCLE_DIAMETER })
    }
  }

  // TODO: Should we display discount details?
  private readonly renderFarePrice = () => {
    const { fare } = this.props.onDemandDetails
    return (
      <View>
        <Text style={styles.detailsRightText}>{CurrencyHelper.format(fare.total, fare.currency)}</Text>
      </View>
    )
  }

  private readonly renderRequestButton = () => {
    const { pickupTimestamp } = this.props.onDemandDetails
    const minutes = (pickupTimestamp - moment().unix()) / MINUTE
    return (
      <View style={styles.buttonSectionContainer}>
        <View style={styles.routeLine} />
        <View style={styles.buttonContainer}>
          <PrimaryButton
            title={st.components.journey.requestRideButtonTitle()}
            subtitle={st.components.journey.requestRideButtonSubtitle({ minutes: Math.round(minutes).toString() })}
            onPress={this.props.handleMakeJourneyEstimate}
            paddingHorizontal={false}
          />
        </View>
      </View>
    )
  }

  private readonly renderPickupTimeline = () => (
    <View style={{ left: -4 }}>
      <View style={{ right: -8 }}>
        <View style={[styles.lineStub, { top: -1 }]} />
        <StopCircle outerColor={colors.green50} />
        <View style={[styles.lineStub, { top: OUTER_CIRCLE_DIAMETER - 4 }]} />
      </View>
      <View
        style={{
          ...styles.routeLine,
          height: this.state.pickupRouteLineHeight + 16, // Account for padding between request button
        }}
      />
    </View>
  )

  private readonly renderPickupDetails = () => (
    <View style={styles.instructionContainer} onLayout={this.setPickupRouteLineHeight}>
      <Text style={styles.pickupTitle}>
        {st.components.journey.pickupDescription({ pickupLocation: this.props.onDemandDetails.pickupLocationName })}
      </Text>
    </View>
  )

  private readonly renderPickupTime = () => (
    <View>
      <Text style={styles.detailsRightText}>
        {moment.unix(this.props.onDemandDetails.pickupTimestamp).format('LT')}
      </Text>
    </View>
  )

  private readonly renderRideDetailsTimeline = () => (
    <>
      <View style={styles.timelineDot}>
        <EnrouteCircle outerColor={colors.black} />
      </View>
      <View style={styles.stopLine} />
    </>
  )

  private readonly renderRideDetails = () => (
    <View>
      <Text style={styles.rideInfoText}>
        {st.components.journey.rideDurationDescription({
          minutes: this.props.onDemandDetails.rideDurationMinutes.toString(),
        })}
      </Text>
    </View>
  )

  private readonly renderDropoffTimeline = () => (
    <View style={styles.iconContainer}>
      <View style={{ right: -8 }}>
        <View style={[styles.lineStub, { top: 0 }]} />
        <StopCircle outerColor={colors.red50} />
      </View>
      {!this.props.onDemandDetails.isLastLeg && <TimelineDash dottedLineHeight={this.state.dottedLineHeight} />}
    </View>
  )

  private readonly renderDropoffDetails = () => (
    <View
      style={[styles.instructionContainer, { marginLeft: this.props.onDemandDetails.isLastLeg ? 16 : 0 }]}
      onLayout={(event) => {
        this.setDropoffRouteLineHeight(event)
        this.setDottedLineHeight(event)
      }}
    >
      <Text style={styles.pickupTitle}>
        {st.components.journey.dropoffDescription({ dropoffLocation: this.props.onDemandDetails.dropoffLocationName })}
      </Text>
    </View>
  )

  private readonly renderDropoffTimes = () => {
    const { dropoffTimestamp, latestDropoffTimestamp } = this.props.onDemandDetails
    const dropoffTime = moment.unix(dropoffTimestamp).format('LT')
    const latestDropoffTime = moment.unix(latestDropoffTimestamp).format('LT')
    return (
      <View>
        <Text style={styles.detailsRightText}>{dropoffTime}</Text>
        <Text style={styles.detailsRightText}>
          {st.components.journey.latestDropoffTime({ dropoffTime: latestDropoffTime })}
        </Text>
      </View>
    )
  }

  private readonly setDottedLineHeight = (event: LayoutChangeEvent) => {
    if (this.state.dottedLineHeight === 0) {
      const height = event.nativeEvent.layout.height
      this.setState({ dottedLineHeight: height - OUTER_CIRCLE_DIAMETER })
    }
  }
}
