import { IErrorResponse } from '@sparelabs/api-client'
import { SentryErrorFilterer } from '@sparelabs/mobile-utils'
import { AxiosError, AxiosResponse } from 'axios'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { RootNavigation } from 'src/navigation'
import { Response } from 'superagent'
import { Sentry } from './sentry'

interface IErrorTimeMap {
  error: Error
  ts: number
}

export interface IAxiosErrorResponse extends Error {
  response?: Partial<AxiosResponse<IErrorResponse>>
}

export interface IErrorWithResponse extends Error {
  // TODO Sometimes are handling superagent responses and sometimes we are handling axios responses and format is different
  response?: Partial<Response> & Partial<AxiosResponse<IErrorResponse>>
}

const errorStack: IErrorTimeMap[] = []

const t = st.helpers.errorHelpers

const readStatusCode = (error: IErrorWithResponse): number | undefined => error?.response?.status

const readErrorBodyName = (error: IErrorWithResponse): string | undefined =>
  error?.response?.body?.name ?? error?.response?.data?.name

const readErrorBodyMessage = (error: IErrorWithResponse): string | undefined =>
  error?.response?.body?.message ?? error?.response?.data?.message

export const captureError = (error: IErrorWithResponse): void => {
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  const statusCode = readStatusCode(error)
  if (statusCode !== undefined) {
    const message = `HTTP Response Error, Status: ${statusCode}, Name: ${readErrorBodyName(
      error
    )}, Message: ${readErrorBodyMessage(error)}`
    Sentry.captureMessage(message)
  } else if (SentryErrorFilterer.shouldSendError(error)) {
    Sentry.captureException(error)
  }
}

function shouldDisplayError(error: IErrorWithResponse) {
  let ret = false
  if (readStatusCode(error) !== undefined) {
    if (errorStack.length === 0 || moment().diff(moment.unix(errorStack[errorStack.length - 1].ts)) > 2000) {
      ret = true
    }
  }
  errorStack.push({ error, ts: moment().unix() })
  return ret
}

export const displayErrorAlert = (error: IErrorWithResponse) => {
  AlertHelper.alert(
    t.generalAlertTitle(),
    readErrorBodyMessage(error) ?? error.message,
    [{ text: st.common.alertOk() }],
    {
      cancelable: false,
    }
  )
}

export const handleResetToLoginOnAuthError = () => {
  RootNavigation.reset()

  // We need a timeout here otherwise the alert gets lost during the screen transition.
  // We perform the navigation first so that screens do need throw errors in case the user/organization/token is missing
  setTimeout(
    () =>
      AlertHelper.alert(t.authAlertTitle(), t.authAlertContent(), [{ text: st.common.alertOk() }], {
        cancelable: false,
      }),
    200
  )
}

export function handleError({ error, silent = false }: { error: IErrorWithResponse; silent?: boolean }) {
  // eslint-disable-next-line no-console
  console.log('handleError', error)
  // eslint-disable-next-line no-console
  console.log('handleError', JSON.stringify(error, null, 2))
  captureError(error)
  if (shouldDisplayError(error) && !silent) {
    if (readStatusCode(error) === 401 || readStatusCode(error) === 403) {
      handleResetToLoginOnAuthError()
    } else {
      displayErrorAlert(error)
    }
  }
}

export function handleAuthError(error: AxiosError<IErrorResponse>): void {
  if (error.response) {
    const response = error.response
    AlertHelper.alert('Something went wrong', response.data.message, [{ text: 'OK' }], { cancelable: false })
    Sentry.captureException(error.response.data)
  } else if (error.request) {
    AlertHelper.alert(
      'Something went wrong',
      'Please check your network connections or try again later.',
      [{ text: 'OK' }],
      { cancelable: false }
    )
  } else if (error) {
    Sentry.captureException(error)
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
