import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { st } from 'src/locales'
import { ParamsListAccount, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { PaymentDefaultButton } from 'src/screens/payments/buttons/PaymentDefaultButton'

const styles = StyleSheet.create({
  viewContainer: {
    padding: 16,
    backgroundColor: colors.sectionBlue,
    flex: 1,
  },
  walletImage: {
    alignSelf: 'center',
  },
  description: {
    textAlign: 'left',
    fontSize: 17,
    color: colors.darkGrayBlue,
    marginVertical: 24,
  },
  buttonContainer: {
    marginTop: 24,
  },
})

type Props = ParamsListAccount[ScreenName.ViewCash] & {
  handleDismiss: () => void
}

@observer
export class ViewCashView extends Component<Props> {
  public render() {
    return (
      <View style={styles.viewContainer}>
        <Image resizeMode='contain' source={Images.wallet} style={styles.walletImage} />
        <Text style={styles.description}>{st.screens.viewCash.description()}</Text>
        <View style={styles.buttonContainer}>
          <PaymentDefaultButton handleAfterSetDefault={this.props.handleDismiss} paymentMethodId={null} />
        </View>
      </View>
    )
  }
}
export const ViewCash = (props: ScreenPropsAccount<ScreenName.ViewCash>) => (
  <ViewCashView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
)
