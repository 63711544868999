import { Formik, FormikConfig, FormikProps } from 'formik'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { FormTextField } from 'src/components/form/FormTextField/FormTextField'
import { FormWrapper } from 'src/components/form/FormWrapper'
import { st } from 'src/locales'
import {
  IDeveloperReviewerLoginFormValues,
  IDeveloperReviewerProps,
} from 'src/screens/developer/DeveloperReviewerLoginTypes'
import yup from 'yup'

const style = StyleSheet.create({
  formTextField: {
    marginBottom: 20,
  },
})

const schema = yup.object().shape({
  username: yup
    .string()
    .required(st.screens.developerReviewerLogin.usernameWarning())
    .label(st.screens.developerReviewerLogin.username()),
  password: yup
    .string()
    .required(st.screens.developerReviewerLogin.passwordWarning())
    .label(st.screens.developerReviewerLogin.password()),
})

const renderForm = (formProps: FormikProps<IDeveloperReviewerLoginFormValues>, loading: boolean) => (
  <FormWrapper>
    <View style={style.formTextField}>
      <FormTextField
        fieldKey='testUser'
        label={st.screens.developerReviewerLogin.username()}
        value={formProps.values.username}
        onChange={(value) => formProps.setFieldValue('username' as never, value)}
        touched={formProps.touched.username}
        error={formProps.errors.username}
      />
    </View>
    <View style={style.formTextField}>
      <FormTextField
        fieldKey='testPassword'
        label={st.screens.developerReviewerLogin.password()}
        value={formProps.values.password}
        onChange={(value) => formProps.setFieldValue('password' as never, value)}
        secureTextEntry={true}
        touched={formProps.touched.password}
        error={formProps.errors.password}
      />
    </View>
    <PrimaryButton
      testID='developerLogInButton'
      title={st.screens.login.loginLabel()}
      loading={loading}
      onPress={formProps.handleSubmit as any}
    />
  </FormWrapper>
)

export const DeveloperReviewerLoginForm = (props: IDeveloperReviewerProps) => (
  <Formik<FormikConfig<IDeveloperReviewerLoginFormValues>, IDeveloperReviewerLoginFormValues>
    validationSchema={schema}
    initialValues={{ username: '', password: '' }}
    onSubmit={props.handlePressConfirm}
    render={(formProps) => renderForm(formProps, props.isLoading)}
  />
)
