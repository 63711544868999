import { Currency } from '@sparelabs/api-client'
import { CurrencyHelper, StripeCurrencyHelper } from '@sparelabs/currency'
import { TipValidation } from '@sparelabs/domain-logic'
import { isInteger } from 'lodash'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { SafeAreaView, ScrollView, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { SecondaryButton } from 'src/components/buttons/SecondaryButton'
import { TipHelper } from 'src/helpers/TipHelper'
import { st } from 'src/locales'
import { ParamsListReview, ScreenName, ScreenPropsReview } from 'src/navigation'
import { TipInput } from 'src/screens/tip/tipInput/TipInput'

const styles = StyleSheet.create({
  safeAreaContainer: {
    flex: 1,
    backgroundColor: colors.blue10,
  },
  container: {
    flex: 1,
    flexBasis: 'auto',
    backgroundColor: 'white',
    paddingHorizontal: 16,
  },
  title: {
    color: colors.gray90,
    fontSize: 21,
    fontWeight: '700',
    paddingVertical: 16,
  },
  validationContainer: {
    flexDirection: 'column',
    marginTop: 8,
  },
  hint: {
    fontSize: 14,
    color: colors.gray70,
    paddingBottom: 4,
  },
  normal: {
    color: colors.blue10,
    fontSize: 14,
  },
  error: {
    color: colors.red50,
  },
  label: {
    color: colors.blue50,
    fontSize: 16,
    paddingBottom: 4,
  },
  placeholder: {
    fontSize: 16,
    marginRight: 4,
    color: colors.gray90,
  },
  inputContainer: {
    flex: 1,
    paddingBottom: 8,
    flexDirection: 'column',
  },
  input: {
    color: colors.gray90,
    fontSize: 16,
    borderBottomWidth: 1,
    borderBottomColor: colors.gray90,
    flex: 1,
    height: 44,
  },
  tipInputBoxContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  buttonContainer: {
    flex: 1,
    paddingHorizontal: 16,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    paddingBottom: 24,
  },
})

export interface ITipError {
  error: boolean
  message: string | null
}

interface IState {
  inputValue: string | undefined
  tipAmount: number
  touched: boolean
  validation: ITipError
}

type Props = ParamsListReview[ScreenName.CustomTipScreen] & {
  handleDismiss: () => void
}

@observer
export class CustomTipScreenView extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      inputValue: undefined,
      tipAmount: 0,
      touched: false,
      validation: { error: false, message: null },
    }
  }

  public handleOnChange = (value: string) => {
    const regexPattern = new RegExp(/[,.]/)

    // We prevent adding dot or comma for non decimal currencies
    if ([Currency.ISK, Currency.JPY].includes(this.props.fare.currency)) {
      if (value.split(regexPattern).length > 1) {
        return
      }
    }

    // We prevent adding extra dot or comma
    if (value.split(regexPattern).length > 2) {
      return
    }

    const convertedValue = CurrencyHelper.convertAmountToBaseValue(value, this.props.fare.currency)
    this.setState({
      inputValue: value,
      tipAmount: convertedValue,
      validation: this.handleValidation(convertedValue),
    })
  }

  public handleSubmit = async (amount) => {
    this.props.handleAddTip(amount)
    this.props.handleDismiss()
  }

  public handleFocus = () => {
    this.setState({
      touched: true,
    })
  }

  public handleValidation = (amount: number): ITipError => {
    const { fare } = this.props
    if (TipValidation.isAmountBelowMinimum(amount, fare.currency)) {
      return {
        error: true,
        message: st.screens.tip.lowAmountValidation({
          minAmount: CurrencyHelper.format(StripeCurrencyHelper.minimumCharge(fare.currency), fare.currency),
        }),
      }
    }
    return { error: false, message: null }
  }

  public render() {
    const { fare } = this.props
    return (
      <SafeAreaView style={styles.safeAreaContainer}>
        <ScrollView keyboardShouldPersistTaps='always' keyboardDismissMode='on-drag' style={{ flex: 1 }}>
          <View style={styles.container}>
            <Text style={styles.title}>{st.screens.tip.customTipTitle()}</Text>
            <View key='customTip' style={styles.inputContainer}>
              <Text style={[styles.label, this.state.validation.error ? styles.error : null]}>
                {st.screens.tip.tipAmount()}
              </Text>
              <View style={styles.tipInputBoxContainer}>
                <Text style={styles.placeholder}>{CurrencyHelper.getCurrencySymbol(fare.currency)}</Text>
                <TipInput
                  currency={fare.currency}
                  value={this.state.inputValue}
                  touched={this.state.touched}
                  validation={this.state.validation}
                  onChange={this.handleOnChange}
                  onFocus={this.handleFocus}
                />
              </View>
              {this.state.touched && this.state.tipAmount !== 0 && isInteger(this.state.tipAmount) && (
                <View style={styles.validationContainer}>
                  <Text style={styles.hint}>
                    {st.screens.tip.tipPercentage({
                      tipPercentage: TipHelper.tipPercentage(fare.cost, this.state.tipAmount),
                      cost: CurrencyHelper.format(fare.cost, fare.currency),
                    })}
                  </Text>
                  {this.state.validation && <Text style={styles.error}>{this.state.validation.message}</Text>}
                </View>
              )}
            </View>
          </View>

          <View style={styles.buttonContainer}>
            <SecondaryButton
              title={st.screens.tip.addTipButton()}
              onPress={() => this.handleSubmit(this.state.tipAmount)}
              disabled={this.state.touched && this.state.validation.error}
            />
          </View>
        </ScrollView>
      </SafeAreaView>
    )
  }
}

export const CustomTipScreen = (props: ScreenPropsReview<ScreenName.CustomTipScreen>) => (
  <CustomTipScreenView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
)
