import { IPoint } from '@sparelabs/geography'
import { isNumber } from 'lodash'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { View } from 'react-native'
import { ImageMarkerAnimated } from 'src/components/mapMarkers/imageMarkerAnimated/ImageMarkerAnimated'
import { IVehicleMarkerProps } from 'src/components/mapMarkers/MapMarkerProps'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { mapMarkerStyles, VEHICLE_PIN_HEIGHT, VEHICLE_PIN_WIDTH } from 'src/helpers/MapMarkerHelper'
import { ActiveRequestVehicleLocationStore } from 'src/stores/ActiveRequestVehicleLocationStore'
import { IMarker, MarkerTypes } from 'src/types'

@observer
export class VehicleMarker extends Component<IVehicleMarkerProps> {
  public render() {
    const { vehicleLocation, request } = this.props

    if (
      ActiveRequestVehicleLocationStore.shouldBeShowingVL(request) &&
      vehicleLocation &&
      vehicleLocation.location &&
      isNumber(vehicleLocation.bearing)
    ) {
      return <ImageMarkerAnimated {...this.createVehiclePin(vehicleLocation.location, vehicleLocation.bearing)} />
    }
    return null
  }

  private readonly createVehiclePin = (location: IPoint, bearing: number): IMarker => ({
    name: MarkerTypes.VehiclePin,
    coordinate: location,
    imageStyle: mapMarkerStyles.vehiclePin,
    anchor: { x: 0.5, y: 0.5 },
    child: (
      <View style={{ transform: [{ rotate: `${bearing}deg` }] }}>
        <SvgIconWrapper icon={SvgIcon.Vehicle} widthFixed={VEHICLE_PIN_WIDTH} heightFixed={VEHICLE_PIN_HEIGHT} />
      </View>
    ),
  })
}
