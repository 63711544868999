import { AccessibilityFeature } from '@sparelabs/api-client'
import { isEqual } from 'lodash'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { multimodalEnabled } from 'src/helpers/RideOptionsCardHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { EstimateOptions } from 'src/screens/rideOptions/EstimateOptions'
import { EstimatesUserInputParsed } from 'src/types'

type Props = ParamsListRoot[ScreenName.EstimateAccessibilityOptions] & {
  handleDismiss: () => void
}
export type AccessibilityCountMap = Partial<Record<AccessibilityFeature, number>>

@observer
export class EstimateAccessibilityOptionsView extends Component<Props> {
  public handleSubmit = async (counterValues: Map<string, number>) => {
    const { estimateInputStore } = this.props
    const updatedFeatures: AccessibilityCountMap = {}

    for (const [type, count] of counterValues) {
      if (count > 0) {
        updatedFeatures[type as AccessibilityFeature] = count
      }
    }

    if (!isEqual(updatedFeatures, estimateInputStore.getEstimateInput().accessibilityFeatures)) {
      const data: EstimatesUserInputParsed = {
        ...estimateInputStore.getEstimateInput(),
        accessibilityFeatures: updatedFeatures,
      }
      estimateInputStore.updateEstimateInput(data)
    }

    this.props.handleDismiss()
  }

  public buildAccessibilityMap(props: Props = this.props): Map<AccessibilityFeature, number> {
    const accessibilityFeatures: Map<AccessibilityFeature, number> = new Map()
    props.serviceAccessibilityFeatures.map((accessibilityFeature) => {
      const feature = props.accessibilityFeatures?.find((a) => a.type === accessibilityFeature)
      if (feature) {
        accessibilityFeatures.set(accessibilityFeature, feature.count)
      } else {
        accessibilityFeatures.set(accessibilityFeature, 0)
      }
    })
    return accessibilityFeatures
  }

  public render() {
    return (
      <EstimateOptions
        estimateInputStore={this.props.estimateInputStore}
        counterValues={this.buildAccessibilityMap(this.props)}
        buttonTitle={st.components.editAccessibility.buttonTitle()}
        sectionTitle={st.components.editAccessibility.subtitle()}
        sectionDescription={multimodalEnabled() ? st.components.editAccessibility.description() : undefined}
        translationFunction={st.enums.accessibilityFeature}
        onSubmit={this.handleSubmit}
        allowZeroTotal={true}
      />
    )
  }
}

export const EstimateAccessibilityOptions = (props: ScreenPropsRoot<ScreenName.EstimateAccessibilityOptions>) => (
  <EstimateAccessibilityOptionsView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
)
