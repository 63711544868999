import { IRequestPatchBody, IRequestPostBody, IRequestResponse, RequestStatus } from '@sparelabs/api-client'
import { MINUTE } from '@sparelabs/time'
import Lock from 'async-lock'
import { maxBy, minBy } from 'lodash'
import { autorun, computed, observable, ObservableMap, runInAction, set } from 'mobx'
import { LegacyApiClient } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { moment } from 'src/helpers/Moment'
import { Poller } from 'src/helpers/Poller'

export interface IRequestStore {
  activeRequest: IRequestResponse | null
  requestMap: Map<string, IRequestResponse>
  updateRequest: (id: string, body: IRequestPatchBody) => Promise<IRequestResponse | undefined>
}

/**
 * This defines how often we should be refreshing data about the request when it is the active one
 */
const ACTIVE_REQUEST_REFRESH_INTERVAL_MS = 5000

const OPERATION_LOCK_KEY = 'RequestStore'
/**
 * Even though we don't directly utilize the data, we need to keep track of 5 requests
 * so that if a user has multiple scheduled requests and they cancel one of them
 * this store will still be able to indicate that user "has scheduled trips"
 */
const ONGOING_REQUEST_LIMIT = 5
/**
 * Requests with this statuses are considered "ongoing" which means they are not finished yet
 * Both Active & Scheduled requests share the same statuses
 */
const ONGOING_REQUEST_STATUS = [
  RequestStatus.Accepted,
  RequestStatus.Arriving,
  RequestStatus.InProgress,
  RequestStatus.Processing,
  RequestStatus.ServiceDisruption,
]
/**
 * Requests that can be edited by the rider
 */
export const PRE_PICKUP_REQUEST_STATUSES = [RequestStatus.Accepted, RequestStatus.Arriving]

/**
 * This is what determines the difference of a purely "ongoing request" and an "active request".
 * An "active request" needs to be occurring within the the 30 minutes, otherwise it will be considered scheduled
 */
const ACTIVE_REQUEST_BOUNDARY_FROM_NOW = 30 * MINUTE

class RequestStoreClass implements IRequestStore {
  public ORDER_BY_EARLIEST = {
    orderBy: 'requestedPickupTs',
    orderDirection: 'ASC',
  }

  public ORDER_BY_LATEST = {
    orderBy: 'requestedPickupTs',
    orderDirection: 'DESC',
  }

  /**
   * This class is designed to store data for requests that the app needs to keep track of in the background at all times.
   * Currently it is used by the Home screen and the Request screens. This class should not store further pages of data and
   * it should not store requests that are needed for pages where we have the ability to show a loading indicator.
   *
   * IMPORTANT NOTE:
   *  This class performs API calls separately and stitches the data back using the same filters on the client side.
   *  We do this so that we can actively modify the request data locally without having to refetch everything from the API
   *  Mobx Computed values take care of making sure we are always defined our request categories correctly.
   *
   *  We have 4 categories of requests we keep track of for different purposes:
   *    * "active request"
   *    * "scheduled request"
   *    * "last completed request"
   *
   *  This classes makes use of local locking to avoid different API calls / data updates from fighting each other.
   */

  /**
   * "active request" is defined as the primary requests the user cares about. There can only be one of these so other
   * requests that satisfy all the same criteria will be considered "scheduled requests"
   */
  @computed
  public get activeRequest(): IRequestResponse | null {
    return (
      minBy(
        Array.from(this.requestMap.values()).filter(
          (r) =>
            ONGOING_REQUEST_STATUS.includes(r.status) &&
            r.requestedPickupTs <= moment().unix() + ACTIVE_REQUEST_BOUNDARY_FROM_NOW
        ),
        (r) => r.estimatedPickupTime.ts
      ) || null
    )
  }

  /**
   * "last completed request" is defined as a completed request that has the latest possible pickup time
   * TODO ideally here we would base our decision on the latest possible dropoff completion time but
   * this data is not available from the API
   */
  @computed
  public get lastCompletedRequest(): IRequestResponse | null {
    return (
      maxBy(
        Array.from(this.requestMap.values()).filter((r) => r.status === RequestStatus.Completed),
        (r) => r.estimatedPickupTime.ts
      ) || null
    )
  }

  /**
   * "scheduled requests" are any ongoing requests that are NOT the "active request".
   * We don't need to expose the requests themselves because we only need to whether to draw the scheduled requests button
   */
  @computed
  public get hasScheduledRequests(): boolean {
    const scheduled = Array.from(this.requestMap.values()).filter((r) => {
      /* Note: a "scheduled request" cannot also be the "active request" at the same time */
      if (this.activeRequest && this.activeRequest.id === r.id) {
        return false
      }
      return ONGOING_REQUEST_STATUS.includes(r.status)
    })
    return scheduled.length > 0
  }

  @observable
  public isLoaded: boolean = false

  /**
   * Stores all requests. Code should acquire the operation lock before modifying this data
   */
  @observable
  public requestMap: ObservableMap<string, IRequestResponse> = observable.map()

  /**
   * Different parts of the code attempt to modify data in an async manner.
   * To prevent conflicts are will ask each of those code paths to acquire this lock
   */
  private readonly operationLock = new Lock()
  private readonly activeRequestPoller: Poller

  constructor() {
    this.activeRequestPoller = new Poller(this.refreshActiveRequest)
    /**
     * We will automatically run this block of code to make sure that
     * our poller is running and is stopped at the right time. We only
     * want the poller running when there is an active request.
     */
    autorun(() => {
      if (this.activeRequest) {
        if (!this.activeRequestPoller.isRunning()) {
          this.activeRequestPoller.start(ACTIVE_REQUEST_REFRESH_INTERVAL_MS)
        }
      } else {
        this.activeRequestPoller.stop()
      }
    })
  }

  public refreshData = async (): Promise<void> => {
    await this.runInLock(async () => {
      const newRequests = (
        await Promise.all([
          /* Refresh will always fetch currently active request to make sure that any observers of this specific request will be able to access results
           * even if it turns out that this request is no longer active (possibly became cancelled or NDA since the last fetch) */
          this.fetchCurrentlyActiveRequest(),
          this.fetchLastCompletedRequest(),
          this.fetchOngoingRequests(),
        ])
      )
        .flat()
        .filter((r): r is IRequestResponse => r !== null)
      runInAction(() => {
        this.requestMap.replace(new Map(newRequests.map((r) => [r.id, r])))
        this.isLoaded = true
      })
    })
  }

  public clear() {
    this.requestMap.clear()
  }

  public getOngoingRequestQueryParams() {
    return {
      ...RequestStore.ORDER_BY_EARLIEST,
      status: ONGOING_REQUEST_STATUS.join('|'),
    }
  }

  public async updateRequestOptimistically(id: string, data: Partial<IRequestResponse>) {
    await this.runInLock(async () => {
      const request = this.requestMap.get(id)
      if (request) {
        set(request, data)
      }
    })
  }

  public async createRequest(body: IRequestPostBody): Promise<IRequestResponse | undefined> {
    return this.runInLock(async () => {
      const res = await LegacyApiClient.post(AuthenticatorHelper.getUserOrgToken(), 'requests', body, true)
      if (!res) {
        return
      }
      const request: IRequestResponse = res.body
      this.requestMap.set(request.id, request)
      return request
    })
  }

  public updateRequest = (id: string, body: IRequestPatchBody): Promise<IRequestResponse | undefined> =>
    this.runInLock(async () => {
      if (!this.requestMap.get(id)) {
        return undefined
      }
      const res = await LegacyApiClient.patch(AuthenticatorHelper.getUserOrgToken(), `requests/${id}`, body, true)
      if (!res) {
        return
      }
      const request: IRequestResponse = res.body
      this.requestMap.set(request.id, request)
      return request
    })

  private runInLock<T>(fn: () => Promise<T>): Promise<T> {
    return this.operationLock.acquire(OPERATION_LOCK_KEY, fn)
  }

  private readonly refreshActiveRequest = async () => {
    await this.runInLock(async () => {
      const newRequestData = await this.fetchCurrentlyActiveRequest()
      if (newRequestData) {
        const existingRequestEntry = this.requestMap.get(newRequestData.id)
        if (existingRequestEntry) {
          set(existingRequestEntry, newRequestData)
        }
      }
    })
  }

  private getCompletedRequestQueryParams() {
    return {
      ...RequestStore.ORDER_BY_LATEST,
      status: RequestStatus.Completed,
    }
  }

  private async fetchCurrentlyActiveRequest(): Promise<IRequestResponse | null> {
    if (this.activeRequest) {
      const res = await LegacyApiClient.get(AuthenticatorHelper.getUserOrgToken(), `requests/${this.activeRequest.id}`)
      if (res) {
        return res.body
      }
    }
    return null
  }

  private async fetchLastCompletedRequest(): Promise<IRequestResponse | null> {
    const res = await LegacyApiClient.get(AuthenticatorHelper.getUserOrgToken(), 'requests', {
      ...this.getCompletedRequestQueryParams(),
      limit: 1,
    })
    if (res && res.body.data.length > 0) {
      return res.body.data[0]
    }
    return null
  }

  private async fetchOngoingRequests(): Promise<IRequestResponse[]> {
    const res = await LegacyApiClient.get(AuthenticatorHelper.getUserOrgToken(), 'requests', {
      ...this.getOngoingRequestQueryParams(),
      limit: ONGOING_REQUEST_LIMIT,
    })
    if (res) {
      return res.body.data
    }
    return []
  }
}

export const RequestStore = new RequestStoreClass()
