import { PhoneNumberInput } from '@sparelabs/web-ui-components'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { st } from 'src/locales'
import { IPhoneInput, IPhoneInputProps } from './PhoneInputTypes'

const getStyles = () =>
  StyleSheet.create({
    container: {
      marginBottom: 20,
      zIndex: 1,
    },
    inputLabel: {
      fontSize: 15,
      color: colors.blueAlt50,
      marginBottom: 4,
    },
  })

export const PhoneInput: IPhoneInput = (props: IPhoneInputProps) => (
  <View style={getStyles().container}>
    <Text style={getStyles().inputLabel}>{st.screens.setPhoneNumber.phoneNumberLabel()}</Text>
    <PhoneNumberInput value={props.phoneNumber} onChange={props.handleChangePhoneNumber} />
  </View>
)
