import { IRequestResponse, RequestStatus } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { moment } from 'src/helpers/Moment'
import { RequestHelper } from 'src/helpers/RequestHelper'
import { st } from 'src/locales'

const styles = StyleSheet.create({
  driverStatusContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 8,
    borderBottomWidth: 1,
    borderBottomColor: colors.borderGray,
  },
  driverStatusText: {
    color: colors.gray90,
    fontWeight: '600',
    fontSize: 14,
    textAlign: 'center',
    textTransform: 'uppercase',
    paddingVertical: 16,
  },
  arrivedText: {
    color: colors.selectedBlue,
    fontWeight: '600',
    fontSize: 14,
    textAlign: 'center',
    textTransform: 'uppercase',
    paddingVertical: 16,
  },
})

interface IRequestCardHeaderProps {
  request: IRequestResponse
}

@observer
export class RequestCardHeader extends Component<IRequestCardHeaderProps> {
  public renderInProgressStatusText(request: IRequestResponse) {
    return <Text style={styles.driverStatusText}>{RequestHelper.getInProgressStatusText(request)}</Text>
  }

  public renderDriverStatusText(text: string) {
    return <Text style={styles.driverStatusText}>{text}</Text>
  }

  public renderDriverArrivedStatus(request: IRequestResponse) {
    if (request.pickupEta && request.pickupEta < moment().unix()) {
      return <Text style={styles.arrivedText}>{st.components.requestCard.driverIsHere()}</Text>
    }
    return <Text style={styles.driverStatusText}>{st.components.requestCard.driverArriving()}</Text>
  }

  public renderDriverStatus(request: IRequestResponse) {
    switch (request.status) {
      case RequestStatus.Accepted:
        return this.renderDriverStatusText(RequestHelper.getAcceptedStatusText(request))
      case RequestStatus.Arriving:
        return this.renderDriverArrivedStatus(request)
      case RequestStatus.InProgress:
        return this.renderInProgressStatusText(request)
      case RequestStatus.ServiceDisruption:
        return this.renderDriverStatusText(st.components.requestCard.connectingWithYourRide())
      case RequestStatus.Completed:
        return this.renderDriverStatusText(st.components.requestCard.thanksForRiding())
      case RequestStatus.Cancelled:
      case RequestStatus.NoDriversAvailable:
      case RequestStatus.Processing:
      default:
        return null
    }
  }

  public render() {
    return <View style={styles.driverStatusContainer}>{this.renderDriverStatus(this.props.request)}</View>
  }
}
