import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { CardIconAction } from 'src/components/cards/CardIconAction'
import { moment } from 'src/helpers/Moment'
import { multimodalEnabled } from 'src/helpers/RideOptionsCardHelper'
import { getDateFormatted, getMonthDateFromString } from 'src/helpers/TimeHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { IEstimateStore } from 'src/stores/EstimateStore'
import { IEstimatesUserInputParsed } from 'src/types'

export interface IProps {
  handleNavigateScheduleEstimate: (params: ParamsListRoot[ScreenName.ScheduleEstimate]) => void
  estimatesUserInput: IEstimatesUserInputParsed
  canScheduleEstimate: boolean
  estimateStore: IEstimateStore
  estimateInputStore: IEstimateInputStore
  isDisabled?: boolean
}

const t = st.components.rideOptionsCardBody

@observer
export class ScheduleControl extends Component<IProps> {
  public render = () => {
    if (!this.props.canScheduleEstimate && !multimodalEnabled()) {
      return null
    }
    return (
      <CardIconAction
        testID='scheduleRequestIconAction'
        icon='clock'
        accessibilityLabel={t.accessibilityLabelSchedule()}
        accessibilityHint={t.accessibilityHintSchedule()}
        title={this.getScheduleIconTitle()}
        isDisabled={this.props.isDisabled}
        onPress={() =>
          this.props.handleNavigateScheduleEstimate({
            estimateInputStore: this.props.estimateInputStore,
            estimateStore: this.props.estimateStore,
          })
        }
      />
    )
  }

  public getScheduleIconTitle = (): string => {
    const { requestedDropoffTs } = this.props.estimatesUserInput
    const requestedTs = this.getRequestedTs(this.props.estimatesUserInput)

    if (requestedTs) {
      const formattedTimestamp: string = getMonthDateFromString(getDateFormatted(requestedTs))
      return `${t.leaveTime({
        time: formattedTimestamp,
        context: requestedDropoffTs ? 'arrive' : 'leave',
      })} ${moment.unix(requestedTs).format('LT')}`
    }
    return t.scheduleDefault()
  }

  public getRequestedTs = (estimatesUserInput: IEstimatesUserInputParsed): number | null | undefined => {
    const { requestedPickupTs, requestedDropoffTs } = estimatesUserInput
    return requestedDropoffTs || requestedPickupTs
  }
}
