import { IPaymentMethodResponse } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { CardView } from 'react-native-credit-card-input'
import { Images } from 'src/assets/Images'
import { PaymentMethodHelper } from 'src/helpers/payments/PaymentMethodHelper'

const styles = StyleSheet.create({
  cardContainer: {
    alignItems: 'center',
    marginBottom: 16,
  },
})

const FALLBACK_BRAND = 'placeholder'

interface IProps {
  paymentMethod: IPaymentMethodResponse
}

@observer
export class PaymentCardVisual extends Component<IProps> {
  public render() {
    const { paymentMethod } = this.props
    return (
      <View style={styles.cardContainer}>
        <CardView
          name=' '
          brand={PaymentMethodHelper.getCardViewIconKey(paymentMethod) ?? FALLBACK_BRAND}
          number={`**** **** **** ${paymentMethod.last4Digits}`}
          expiry={this.getExpiryDate()}
          imageFront={Images.creditCardFront}
        />
      </View>
    )
  }

  private getExpiryDate(): string {
    const { paymentMethod } = this.props
    if (paymentMethod.expiryMonth && paymentMethod.expiryYear) {
      return `${
        String(paymentMethod.expiryMonth).length > 1
          ? String(paymentMethod.expiryMonth)
          : `0${paymentMethod.expiryMonth}`
      }/${String(paymentMethod.expiryYear).substring(2, 4)}`
    }
    return ''
  }
}
