import { IPoint } from '@sparelabs/geography'
import { autorun, IReactionDisposer } from 'mobx'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { ErrorBoundary } from 'src/components/error/ErrorBoundary'
import { MapServiceAreas } from 'src/components/mapMarkers/MapServiceAreas'
import { SearchLocationMarker } from 'src/components/mapMarkers/SearchLocationMarker'
import { LocationStore } from 'src/stores/LocationStore'
import { MapStore } from 'src/stores/MapStore'

interface IProps {
  searchLocation?: IPoint | null
}

interface IState {
  location: IPoint | null
}

@observer
export class HomeMap extends Component<IProps, IState> {
  public mapAutorun: IReactionDisposer | null = null
  constructor(props: IProps) {
    super(props)
    this.state = {
      location: null,
    }
  }

  public componentDidMount() {
    // Set the location if it was previously null
    this.mapAutorun = autorun(() => {
      if (this.state.location === null) {
        this.setMapLocation()
      }
    })

    MapStore.setShowUserLocation(true)
  }

  public fitMapToCurrentLocation = () => {
    if (this.state.location) {
      const [longitude, latitude] = this.state.location.coordinates
      MapStore.fitMapToCurrentPosition(latitude, longitude)
    }
  }

  public render() {
    return (
      <>
        <ErrorBoundary>
          <MapServiceAreas hideStops={true} mapRegion={MapStore.mapRegion} />
        </ErrorBoundary>
        <ErrorBoundary>
          {this.props.searchLocation ? <SearchLocationMarker location={this.props.searchLocation} /> : null}
        </ErrorBoundary>
      </>
    )
  }

  private setMapLocation() {
    const location = this.props.searchLocation
      ? this.props.searchLocation
      : LocationStore.getCurrentLocationPointWithPermission()

    if (location) {
      const [longitude, latitude] = location.coordinates
      if (longitude !== this.state.location?.coordinates[0] || latitude !== this.state.location?.coordinates[1]) {
        this.setState({ location }, this.fitMapToCurrentLocation)
      }
    }
  }
}
