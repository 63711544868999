import {
  IRequestCancellationResponse,
  RequestCancellationReason,
  RequestCancelledBy,
  RequestStatus,
  Resources,
} from '@sparelabs/api-client'
import { action, observable } from 'mobx'
import { LegacyApiClient } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { captureError, displayErrorAlert } from 'src/helpers/ErrorHelpers'
import { RequestStore } from 'src/stores/RequestStore'

class RequestCancellationStoreClass {
  @observable
  public reason: null | RequestCancellationReason = null

  @observable
  public notes: string | null = null

  @observable public isLoading: boolean = false

  @action public setNotes = (notes: string) => {
    this.notes = notes
  }

  @action public setReason = (reason: RequestCancellationReason) => {
    if (reason === this.reason) {
      this.reason = null
    } else {
      this.reason = reason
    }
  }

  @action public submit = async (requestId: string, onSuccessCallback: () => void) => {
    if (AuthenticatorHelper.userOrgToken && this.reason) {
      try {
        this.isLoading = true
        const res = await LegacyApiClient.post(
          AuthenticatorHelper.userOrgToken,
          `${Resources.Requests}/${requestId}/cancellation`,
          {
            cancelledBy: RequestCancelledBy.Rider,
            notes: this.notes,
            reason: this.reason,
          },
          true
        )
        if (res) {
          const response: IRequestCancellationResponse = res.body
          // Since we have already cancelled, we can optimistically take note of that to avoid refreshing data
          await RequestStore.updateRequestOptimistically(requestId, {
            status: RequestStatus.Cancelled,
            cancellationDetails: response,
          })
          this.clear()
          onSuccessCallback()
        }
      } catch (error) {
        captureError(error)
        displayErrorAlert(error)
      } finally {
        this.isLoading = false
      }
    }
  }

  @action public clear = () => {
    this.notes = null
    this.reason = null
  }
}

export const RequestCancellationStore = new RequestCancellationStoreClass()
