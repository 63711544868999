import { action, observable } from 'mobx'
import { AppState } from 'react-native'
import { request } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { moment } from 'src/helpers/Moment'

class ApiStoreClass {
  public name = 'ApiStore'

  @observable public connected: boolean = true
  @observable public secondsUntilRetry: number = 0
  private readonly retryInterval: number = 5

  @action
  public handleNetworkSuccess = () => {
    this.secondsUntilRetry = 0
    this.connected = true
  }

  @action
  public handleNetworkError = (_: Error) => {
    if (this.connected && AppState.currentState === 'active') {
      this.connected = false
      this.scheduleRetry()
    }
  }

  private readonly scheduleRetry = () => {
    this.secondsUntilRetry = this.retryInterval
    setTimeout(this.tickRetry, 1000)
  }

  private readonly tickRetry = async () => {
    this.secondsUntilRetry = Math.max(0, this.secondsUntilRetry - 1)
    if (this.secondsUntilRetry === 0) {
      await this.checkConnection()
    } else {
      setTimeout(this.tickRetry, 1000)
    }
  }

  private readonly checkConnection = async () => {
    try {
      await request(AuthenticatorHelper.getUserOrgToken())
        .get(AuthenticatorHelper.getRegionalHost())
        .query({ unique_key: moment().unix() }) // Need unique key so results don't get cached
      this.handleNetworkSuccess()
    } catch (error) {
      this.scheduleRetry()
    }
  }
}

export const ApiStore = new ApiStoreClass()
