import React, { Component } from 'react'
import { SafeAreaView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  radioLabel: {
    marginLeft: 16,
    fontSize: 16,
    color: colors.gray90,
    flex: 1,
  },
  radioLabelContainer: {
    flexDirection: 'row',
    padding: 16,
    borderTopWidth: 1,
    borderColor: colors.borderBlue,
  },
})

type RadioKey = string | number

export interface IRadioOption {
  key: RadioKey
  label: string
}

interface IProps {
  onHandleSelected: (selected: RadioKey) => void
  options: IRadioOption[]
  selected: RadioKey
}

export class RadioButtonGroup extends Component<IProps> {
  public onSelect = (option: IRadioOption) => {
    this.props.onHandleSelected(option.key)
  }

  public renderRadioButtons() {
    return this.props.options.map((option, index) => {
      const selected = this.props.selected === option.key
      const lastItem = index === this.props.options.length - 1
      const radioContainerStyle = lastItem
        ? [styles.radioLabelContainer, { borderBottomWidth: 1 }]
        : styles.radioLabelContainer
      return (
        <TouchableOpacity
          key={option.key}
          onPress={() => this.onSelect(option)}
          accessibilityRole='radio'
          accessibilityState={{ selected }}
        >
          <View style={radioContainerStyle}>
            <RadioButton key={1} selected={selected} />
            <Text style={styles.radioLabel}>{option.label}</Text>
          </View>
        </TouchableOpacity>
      )
    })
  }

  public render() {
    return (
      <SafeAreaView style={styles.container} accessibilityRole='radiogroup'>
        {this.renderRadioButtons()}
      </SafeAreaView>
    )
  }
}

interface IRadioButtonProps {
  selected: boolean
}

const radioDiameter = 14

const radioStyles = StyleSheet.create({
  radioUnfilled: {
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.radioBorder,
    width: radioDiameter + 6,
    height: radioDiameter + 6,
    borderRadius: (radioDiameter + 6) / 2,
    borderWidth: 1,
  },
  radioFilled: {
    backgroundColor: colors.selectedBlue,
    width: radioDiameter,
    height: radioDiameter,
    borderRadius: radioDiameter / 2,
  },
})

export class RadioButton extends Component<IRadioButtonProps> {
  public render() {
    return (
      <View style={[radioStyles.radioUnfilled, this.props.selected && { borderColor: colors.blue30 }]}>
        {this.props.selected && <View style={radioStyles.radioFilled} />}
      </View>
    )
  }
}
