import React from 'react'
import { Platform, StyleProp, StyleSheet, Switch, Text, TextStyle, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FieldError } from 'src/components/form/FieldShared'
import { IFormFieldProps } from 'src/components/form/FormFieldTypes'
import { OsType } from 'src/util/types'

const styles = StyleSheet.create({
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    flex: 1,
    color: colors.blueAlt50,
    fontSize: 16,
  },
  // TODO: remove this styling for different platforms once we finish design system for native for consistency on all platform
  containerWeb: {
    lineHeight: 38,
    paddingBottom: 16,
  },
  containerNative: {
    paddingTop: 16,
  },
})

interface IToggleProps extends IFormFieldProps<boolean> {
  labelStyle?: StyleProp<TextStyle>
}

export const ToggleField = ({
  label,
  value,
  onChange,
  touched,
  error,
  disabled,
  hint,
  isRequired,
  labelStyle,
}: IToggleProps) => (
  <View style={[styles.row, Platform.OS === OsType.Web ? styles.containerWeb : styles.containerNative]}>
    <Text style={labelStyle ?? styles.label}>
      {label}
      {isRequired && <Text style={[styles.label, { color: colors.red50 }]}> *</Text>}
    </Text>
    <Switch
      onValueChange={onChange}
      value={value}
      disabled={disabled}
      thumbColor='white'
      trackColor={{ false: colors.gray2, true: colors.selectedBlue }}
    />
    <View style={{ justifyContent: 'center', marginLeft: 8 }}>
      {hint && <Text style={{ color: colors.gray50 }}>{hint}</Text>}
      <FieldError touched={touched} error={error} />
    </View>
  </View>
)
