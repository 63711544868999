import {
  IErrorResponse,
  IEstimateNextAvailableQueryParams,
  IEstimateOutput,
  IEstimateScheduledQueryParams,
  IEstimateService,
  IServiceAccessibilityFeature,
  RiderType,
} from '@sparelabs/api-client'
import { isNumber, maxBy, union } from 'lodash'
import { action, computed, observable, ObservableMap } from 'mobx'
import { estimateNextAvailable, estimateScheduled, estimateServices, fetchLyftEstimates } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError } from 'src/helpers/ErrorHelpers'
import {
  areAllEstimatesNull,
  getEstimateQuery,
  isLyftPassLinkEnabled,
  isNextAvailablePickupOverTwoHours,
} from 'src/helpers/EstimateHelper'
import {
  EstimatesUserInputParsed,
  INextAvailableEstimatesUserInputParsed,
  IScheduledEstimatesUserInputParsed,
} from 'src/types'
import { ILyftEstimate } from 'src/types/lyftTypes'
import { LoadingStore } from './LoadingStore'

export interface IEstimateStore {
  estimateServices: IEstimateService[] | null
  estimateResponseMap: ObservableMap<string, IEstimateOutput | null>
  loadingStore: LoadingStore
  estimatesAreReady: boolean
  estimateError: IErrorResponse | null
  estimateErrorMap: ObservableMap<string, IErrorResponse>
  lyftEstimates: ILyftEstimate[]

  servicesMaxRiders: number
  servicesAllowScheduling: boolean
  servicesAccessibilityFeatures: IServiceAccessibilityFeature[]
  servicesRiderTypes: RiderType[]

  getSelectedEstimate: (selectedId: string | null) => IEstimateOutput | null
  getEstimateServiceById: (serviceId: string | null) => IEstimateService | null
  fetchEstimates: (estimateInput: EstimatesUserInputParsed) => Promise<void>
  clearEstimates: () => void
  fetchEstimateServices: (estimateInput: EstimatesUserInputParsed) => Promise<void>
  getSelectedEstimateThrows: (selectedServiceId: string | null) => IEstimateOutput
}

export enum EstimateServicesLoading {
  EstimateServices = 'estimate-services',
  Estimates = 'estimates',
}

/**
 * This class is designed to store data for the user input, the estimate services available and the selected estimate that'll be used to
 * create a new request ride.
 * It is used in all parent and children components that handles the estimate services.
 *
 * Estimates can be either available now (next available) or a schedule estimate. Based on its type, we then make an API call to fetch the services
 * in a given organization based on the parsed input from the rider.
 *
 */
export class EstimateStore implements IEstimateStore {
  public name = 'EstimateStore'

  @observable
  public estimateServices: IEstimateService[] | null = null
  // TODO: turn this into map, with serviceId

  /**
   * Stores estimates keyed by serviceId. A null entry in this map means we received a response
   * from the API but no estimate is available for this service
   */
  @observable
  public estimateResponseMap: ObservableMap<string, IEstimateOutput | null> = observable.map()

  @observable
  public estimateError: IErrorResponse | null = null

  @observable
  public estimateErrorMap: ObservableMap<string, IErrorResponse> = observable.map()

  @observable
  public loadingStore = new LoadingStore()

  // Estimates are only ready for rendering once all services and estimates are loaded and sorted
  @observable
  public estimatesAreReady: boolean = false

  @observable
  public lyftEstimates: ILyftEstimate[] = []

  @action
  public refetchEstimates = async (estimateInput: EstimatesUserInputParsed) => {
    if (estimateInput) {
      this.clearEstimates()
      await this.fetchEstimates(estimateInput)
    }
  }

  public getEstimateServiceById(serviceId: string | null): IEstimateService | null {
    if (serviceId) {
      return this.estimateServices?.find((s) => s.serviceId === serviceId) ?? null
    }

    return null
  }

  @computed
  public get servicesAllowScheduling(): boolean {
    if (!this.estimateServices) {
      // We return true so we can have the Schedule button enabled on the card initially
      return true
    }

    // We check if any of the services allow scheduling
    return this.estimateServices.filter(
      (s) => s.serviceAllowScheduledLeaveAtBooking || s.serviceAllowScheduledArriveByBooking
    ).length >= 1
      ? true
      : false
  }

  @computed
  public get servicesRiderTypes(): RiderType[] {
    if (!this.estimateServices) {
      return [RiderType.Adult]
    }

    const riderTypes = this.estimateServices.map((service) => service.serviceRiderTypes)
    return union(...riderTypes)
  }

  @computed
  public get servicesAccessibilityFeatures(): IServiceAccessibilityFeature[] {
    if (!this.estimateServices) {
      return []
    }

    const accessibilityFeatures = this.estimateServices.map((service) => service.serviceAccessibilityFeatures)
    return union(...accessibilityFeatures)
  }

  @computed
  public get servicesMaxRiders(): number {
    const serviceWithLargestMaxRiders = maxBy(this.estimateServices, (service) => service.serviceMaxRiders || Infinity)
    return serviceWithLargestMaxRiders?.serviceMaxRiders || Infinity
  }

  @computed
  public get estimatesAreAvailable(): boolean {
    return Boolean(this.estimateServices && !areAllEstimatesNull(this.estimateServices, this.estimateResponseMap))
  }

  public getSelectedEstimate = (selectedServiceId: string | null): IEstimateOutput | null => {
    if (!selectedServiceId) {
      return null
    }

    const estimate = this.estimateResponseMap.get(selectedServiceId)
    if (!estimate) {
      return null
    }
    return estimate
  }

  public getSelectedEstimateThrows(selectedServiceId: string | null) {
    if (!selectedServiceId) {
      throw new Error('Estimate ServiceId not available')
    }

    const estimate = this.estimateResponseMap.get(selectedServiceId)
    if (!estimate) {
      throw new Error('Estimate not found in map')
    }
    return estimate
  }

  // Create a new estimate by parsing user input to fill in missing addresses
  public fetchEstimateServices = async (estimateInput: EstimatesUserInputParsed) => {
    this.clear()

    try {
      await this.loadingStore.execute(this.getEstimateServices(estimateInput), EstimateServicesLoading.EstimateServices)
    } catch (error) {
      handleError({ error: error as Error })
    }
  }

  @action
  public fetchEstimates = async (estimateInput: EstimatesUserInputParsed) => {
    if (this.isScheduledEstimate(estimateInput)) {
      await this.loadingStore.execute(this.getScheduledEstimates(estimateInput), EstimateServicesLoading.Estimates)
    } else {
      await this.loadingStore.execute(this.getNextAvailableEstimates(estimateInput), EstimateServicesLoading.Estimates)
    }
  }

  public clearEstimates() {
    this.estimateResponseMap.clear()
    this.estimateErrorMap.clear()
    this.estimatesAreReady = false
  }

  public clear() {
    this.estimateServices = null
    this.clearEstimates()
  }

  public getLyftEstimates = async (estimateInput: EstimatesUserInputParsed): Promise<void> => {
    try {
      const [startLng, startLat] = estimateInput.requestedPickupLocation.coordinates
      const [endLng, endLat] = estimateInput.requestedDropoffLocation.coordinates
      const lyftResponse = await fetchLyftEstimates({
        start_lat: startLat,
        start_lng: startLng,
        end_lat: endLat,
        end_lng: endLng,
      })
      this.lyftEstimates = lyftResponse.body.cost_estimates
    } catch (error) {
      this.lyftEstimates = []
      handleError({ error: error as IErrorResponse, silent: true })
    }
  }

  @action
  public getEstimateServices = async (estimateInput: EstimatesUserInputParsed) => {
    const query = getEstimateQuery(estimateInput)
    if (isLyftPassLinkEnabled()) {
      await this.getLyftEstimates(estimateInput)
    }
    try {
      const response = await estimateServices(AuthenticatorHelper.getUserOrgToken(), query)
      if (response) {
        this.estimateServices = response.body.services
      }
    } catch (error) {
      if (error.response) {
        // If there was a 4xx or 5xx, we set services as empty array to represent no services
        this.estimateServices = []
      }
      // eslint-disable-next-line no-console
      console.log(error)
      handleError({ error, silent: true })
    } finally {
      // If there are no estimate services, we consider the estimates to be ready
      if (!this.estimateServices || this.estimateServices.length < 1) {
        this.estimatesAreReady = true
      }
    }
  }

  private readonly getNextAvailableEstimates = async (estimateUserInput: INextAvailableEstimatesUserInputParsed) => {
    const rider = AuthenticatorHelper.getUser()
    if (this.estimateServices && this.estimateServices.length > 0) {
      for (const estimateService of this.estimateServices) {
        const query: IEstimateNextAvailableQueryParams = {
          ...getEstimateQuery(estimateUserInput),
          serviceId: estimateService.serviceId,
          riderId: rider.id,
          deepSearch: true,
        }

        try {
          const res = await this.loadingStore.execute(
            estimateNextAvailable(AuthenticatorHelper.getUserOrgToken(), query),
            query.serviceId
          )

          // If estimate errors or pickup was over two hours, set estimate to null
          if (res && !isNextAvailablePickupOverTwoHours(res.body.scheduledPickupTs)) {
            this.estimateResponseMap.set(estimateService.serviceId, res.body)
          } else {
            this.estimateResponseMap.set(estimateService.serviceId, null)
          }
        } catch (error) {
          const errorMessage = error.response ? error.response.body : null
          // eslint-disable-next-line no-console
          console.log(error)
          this.handleFailedEstimate(errorMessage)
          handleError({ error, silent: true })
          this.estimateResponseMap.set(estimateService.serviceId, null)
          this.estimateErrorMap.set(estimateService.serviceId, errorMessage)
        }
      }

      // Defaults to sorting by lowest fare
      this.sortByPickupEta()
      this.estimatesAreReady = true
    } else {
      this.handleFailedEstimate(null)
    }
  }

  public getScheduledEstimates = async (estimateUserInput: IScheduledEstimatesUserInputParsed) => {
    const rider = AuthenticatorHelper.getUser()
    if (this.estimateServices && this.estimateServices.length > 0) {
      for (const estimateService of this.estimateServices) {
        const scheduledQuery: IEstimateScheduledQueryParams = {
          ...getEstimateQuery(estimateUserInput),
          serviceId: estimateService.serviceId,
          riderId: rider.id,
          deepSearch: true,
        }
        if (estimateUserInput.requestedPickupTs !== null) {
          scheduledQuery.requestedPickupTs = estimateUserInput.requestedPickupTs
          scheduledQuery.requestedDropoffTs = undefined
        } else if (estimateUserInput.requestedDropoffTs !== null) {
          scheduledQuery.requestedPickupTs = undefined
          scheduledQuery.requestedDropoffTs = estimateUserInput.requestedDropoffTs
        }

        // If scheduling is not valid for this service then set estimate to null
        if (
          !this.isValidScheduling(scheduledQuery.requestedPickupTs, scheduledQuery.requestedDropoffTs, estimateService)
        ) {
          this.estimateResponseMap.set(estimateService.serviceId, null)
        } else {
          try {
            const res = await this.loadingStore.execute(
              estimateScheduled(AuthenticatorHelper.getUserOrgToken(), scheduledQuery),
              scheduledQuery.serviceId
            )
            // If estimate errors, set estimate to null
            if (res) {
              this.estimateResponseMap.set(estimateService.serviceId, res.body)
            } else {
              this.estimateResponseMap.set(estimateService.serviceId, null)
            }
          } catch (error) {
            const errorMessage = error.response ? error.response.body : null
            this.handleFailedEstimate(errorMessage)
            // eslint-disable-next-line no-console
            console.log(error)
            handleError({ error, silent: true })
            this.estimateResponseMap.set(estimateService.serviceId, null)
            this.estimateErrorMap.set(estimateService.serviceId, errorMessage)
          }
        }
      }

      this.sortByPickupEta()
    } else {
      this.handleFailedEstimate(null)
    }

    // Estimates are ready regardless of whether the call was successful or not
    this.estimatesAreReady = true
  }

  private isValidScheduling(
    requestedPickupTs: number | undefined,
    requestedDropoffTs: number | undefined,
    estimateService: IEstimateService
  ): boolean {
    return (
      (isNumber(requestedPickupTs) &&
        requestedDropoffTs === undefined &&
        estimateService.serviceAllowScheduledLeaveAtBooking) ||
      (isNumber(requestedDropoffTs) &&
        requestedPickupTs === undefined &&
        estimateService.serviceAllowScheduledArriveByBooking)
    )
  }

  public isScheduledEstimate(
    estimateInput: IScheduledEstimatesUserInputParsed | INextAvailableEstimatesUserInputParsed
  ): estimateInput is IScheduledEstimatesUserInputParsed {
    return Boolean(estimateInput.requestedPickupTs) || Boolean(estimateInput.requestedDropoffTs)
  }

  @action
  public readonly sortByPickupEta = (): void => {
    if (this.estimateServices && this.estimatesAreAvailable) {
      this.estimateServices = this.estimateServices.slice().sort((serviceA, serviceB) => {
        const estimateA = this.estimateResponseMap.get(serviceA.serviceId)
        const estimateB = this.estimateResponseMap.get(serviceB.serviceId)
        if (!estimateA || !estimateA.estimatedPickupTime) {
          return 1
        } else if (!estimateB || !estimateB.estimatedPickupTime) {
          return -1
        }
        return estimateA.estimatedPickupTime.ts - estimateB.estimatedPickupTime.ts
      })
    }
  }

  private readonly handleFailedEstimate = (error: IErrorResponse | null) => {
    this.estimateError = error
  }
}
