import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { colors } from 'src/assets/colors'
import { IMapServiceAreasProps } from 'src/components/mapMarkers/MapMarkerProps'
import { Polygon } from 'src/components/mapMarkers/polygon/Polygon'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { mapMarkerStyles, STOP_PIN_SIZE } from 'src/helpers/MapMarkerHelper'
import { ServiceStore } from 'src/stores/ServiceStore'
import { IMarker, MarkerTypes } from 'src/types'
import { areIndividualMarkersVisible } from '../../helpers/MapHelper'
import { ImageMarker } from './ImageMarker'

@observer
export class MapServiceAreas extends Component<IMapServiceAreasProps> {
  public render() {
    return (
      <>
        {this.renderZones()}
        {this.renderStops()}
      </>
    )
  }

  private renderZones() {
    return (
      <Polygon
        strokeWidth={3}
        fillColor={colors.zoneFill}
        strokeColor={colors.zoneBorder}
        zoneMap={ServiceStore.zonesMap}
      />
    )
  }

  private renderStops() {
    if (this.props.mapRegion && areIndividualMarkersVisible(this.props.mapRegion) && !this.props.hideStops) {
      return ServiceStore.stops.map((stop) => {
        const marker: IMarker = {
          name: `stop-pin-${stop.id}` as MarkerTypes, // TODO: Investigate
          coordinate: stop.location,
          imageStyle: mapMarkerStyles.stopPin,
          child: <SvgIconWrapper icon={SvgIcon.StopPin} widthFixed={STOP_PIN_SIZE} heightFixed={STOP_PIN_SIZE} />,
        }
        return <ImageMarker key={stop.id} {...marker} />
      })
    }
    return null
  }
}
