import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { ParamsListAccount, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { PaymentCardVisual } from 'src/screens/payments/PaymentCardVisual'
import { PaymentMethodControls } from 'src/screens/payments/PaymentMethodControls'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'

const styles = StyleSheet.create({
  viewContainer: {
    padding: 16,
    backgroundColor: colors.white,
    flex: 1,
  },
})

type Props = ParamsListAccount[ScreenName.ViewCreditCard] & {
  handleDismiss: () => void
}

@observer
export class ViewCreditCardView extends Component<Props> {
  public render(): JSX.Element | null {
    const { paymentMethodId } = this.props
    const paymentMethod = PaymentMethodStore.getPaymentMethodById(paymentMethodId)
    if (paymentMethod) {
      return (
        <View style={styles.viewContainer}>
          <PaymentCardVisual paymentMethod={paymentMethod} />
          <PaymentMethodControls handlePopScreen={() => this.props.handleDismiss()} paymentMethodId={paymentMethodId} />
        </View>
      )
    }
    return null
  }
}

export const ViewCreditCard = (props: ScreenPropsAccount<ScreenName.ViewCreditCard>) => (
  <ViewCreditCardView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
)
