import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import {
  AccessibilityActionEvent,
  AccessibilityInfo,
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import { st } from 'src/locales'
import { LoadingStore } from 'src/stores/LoadingStore'

const getStyles = () =>
  StyleSheet.create({
    counterRow: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    buttonCircle: {
      padding: 10,
      borderRadius: 36,
      height: 48,
      width: 48,
      backgroundColor: colors.blue10,
      alignItems: 'center',
      justifyContent: 'center',
    },
    textCounter: {
      width: 48,
      textAlign: 'center',
      fontWeight: '400',
      fontSize: 24,
      color: colors.gray90,
      marginHorizontal: 10,
    },
  })

interface ICounterProps {
  config: ICounterConfig
  value: number
  onChange: (value: number) => Promise<void>
  disabled?: boolean
  disableIncrement?: boolean
  disableDecrement?: boolean
  itemLabel: string
}

export interface ICounterConfig {
  min: number
  max: number
}

enum CounterLoadingKeys {
  Increment = 'increment',
  Decrement = 'decrement',
}

export const Counter = observer(
  ({ config, value, onChange, disabled, disableIncrement, disableDecrement, itemLabel }: ICounterProps) => {
    const styles = getStyles()
    const loadingStore: LoadingStore = new LoadingStore()

    useEffect(() => {
      AccessibilityInfo.announceForAccessibility(value.toString())
    }, [value])

    const decrement = async () => {
      await loadingStore.execute(onChange(Math.max(value - 1, config.min)), CounterLoadingKeys.Decrement)
    }

    const increment = async () => {
      await loadingStore.execute(onChange(Math.min(value + 1, config.max)), CounterLoadingKeys.Increment)
    }

    const onAccessibilityAction = async (event: AccessibilityActionEvent) => {
      const eventMsgContext =
        event.nativeEvent.actionName === CounterLoadingKeys.Decrement
          ? st.components.counter.minimum()
          : st.components.counter.maximum()
      const stepperLimitMsg = st.components.counter.counterLimitMsg({ eventMsgContext })
      await accessibilityActionHandler(event.nativeEvent.actionName as CounterLoadingKeys, stepperLimitMsg)
    }

    const accessibilityActionHandler = async (actionType: CounterLoadingKeys, actionLimitMsg: string) => {
      if (allowStepChange(actionType)) {
        await handleStepChange(actionType)
      } else {
        AccessibilityInfo.announceForAccessibility(actionLimitMsg)
      }
    }

    const allowStepChange = (actionType: CounterLoadingKeys) =>
      actionType === CounterLoadingKeys.Increment ? !disableIncrement : !disableDecrement

    const handleStepChange = async (actionType: CounterLoadingKeys) => {
      if (actionType === CounterLoadingKeys.Increment) {
        await increment()
      } else {
        await decrement()
      }
    }

    return (
      <View
        style={styles.counterRow}
        accessible={true}
        accessibilityRole='adjustable'
        accessibilityLabel={
          itemLabel ? st.components.counter.accessibilityLabel({ itemLabel, value }) : st.components.counter.counter()
        }
        accessibilityActions={[{ name: CounterLoadingKeys.Decrement }, { name: CounterLoadingKeys.Increment }]}
        onAccessibilityAction={onAccessibilityAction}
      >
        <TouchableOpacity
          style={styles.buttonCircle}
          onPress={decrement}
          disabled={disabled || disableDecrement}
          testID='decreaseButton'
        >
          {loadingStore.isLoading(CounterLoadingKeys.Decrement) ? (
            <ActivityIndicator color={colors.blue50} />
          ) : (
            <FontAwesomeIconWrapper icon='minus' color={disableDecrement ? colors.blue30 : colors.blue50} size={16} />
          )}
        </TouchableOpacity>
        <Text style={styles.textCounter} accessible={false}>
          {value}
        </Text>
        <TouchableOpacity
          style={styles.buttonCircle}
          onPress={increment}
          disabled={disabled || disableIncrement}
          testID='increaseButton'
        >
          {loadingStore.isLoading(CounterLoadingKeys.Increment) ? (
            <ActivityIndicator color={colors.blue50} />
          ) : (
            <FontAwesomeIconWrapper icon='plus' color={disableIncrement ? colors.blue30 : colors.blue50} size={16} />
          )}
        </TouchableOpacity>
      </View>
    )
  }
)
