import { NavigationProp } from '@react-navigation/native'
import { CustomFieldStatus, ICustomFieldResponse, IRiderMePatchBody, NativeObjectType } from '@sparelabs/api-client'
import { Formik, FormikConfig, FormikProps } from 'formik'
import { mapValues } from 'lodash'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ApiClientBuilder } from 'src/api/ApiClientBuilder'
import { FormSubmitButton } from 'src/components/buttons/FormSubmitButton'
import { CustomField } from 'src/components/form/CustomField'
import { IFormFieldProps } from 'src/components/form/FormFieldTypes'
import { FormTextField } from 'src/components/form/FormTextField/FormTextField'
import { FormWrapper } from 'src/components/form/FormWrapper'
import { LoadingShimmer } from 'src/components/LoadingShimmer'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { CustomFieldHelper, IFormField } from 'src/helpers/CustomFieldHelper'
import { st } from 'src/locales'
import { ParamsListRoot } from 'src/navigation'
import { LoadingStore } from 'src/stores/LoadingStore'
import yup from 'yup'

interface IUserFormProps {
  navigation: NavigationProp<ParamsListRoot>
  onSubmit: (data: IRiderMePatchBody) => Promise<void>
  loadingStore: LoadingStore
  loadingKey: string
  displayErrors?: boolean
  onUserError?: (verticalFieldPosition: number) => void
}

interface IUserFormState {
  isLoading: boolean
  customFields: ICustomFieldResponse[]
}

export interface IStaticUserFormValues {
  firstName: string | null
  lastName: string | null
  email: string | null
  phoneNumber: string | null | undefined
}

const staticUserFields: Record<string, IFormField> = {
  firstName: {
    label: st.screens.setProfile.firstNameLabel(),
    schema: yup
      .string()
      .required(st.screens.setProfile.firstNameWarning())
      .label(st.screens.setProfile.firstNameLabel())
      .max(32),
  },
  lastName: {
    label: st.screens.setProfile.lastNameLabel(),
    schema: yup
      .string()
      .required(st.screens.setProfile.lastNameWarning())
      .label(st.screens.setProfile.lastNameLabel())
      .max(32),
  },
  email: {
    label: st.screens.setProfile.emailLabel(),
    keyboardType: 'email-address',
    schema: yup
      .string()
      .required(st.screens.setProfile.emailWarning())
      .email(st.screens.setProfile.emailValid())
      .label(st.screens.setProfile.emailLabel())
      .max(64),
  },
  phoneNumber: {
    label: st.screens.setProfile.phoneNumberLabel(),
    disabled: true,
    schema: yup.string().label(st.screens.setProfile.phoneNumberLabel()),
  },
}

@observer
export class UserForm extends Component<IUserFormProps, IUserFormState> {
  constructor(props: IUserFormProps) {
    super(props)
    this.state = { isLoading: true, customFields: [] }
  }

  public async componentDidMount() {
    // Fetch up-to-date user info
    await AuthenticatorHelper.fetchUserData()
    // Fetch custom fields
    const nativeObjectExtensionMap = await ApiClientBuilder.build().customSchemas.getNativeObjectExtensions()
    if (nativeObjectExtensionMap[NativeObjectType.Rider]) {
      this.setState({
        customFields: nativeObjectExtensionMap[NativeObjectType.Rider].fields.filter(
          (field) => field.status === CustomFieldStatus.Active && field.isAvailableToRiderInterface
        ),
      })
    }
    this.setState({ isLoading: false })
  }

  public render() {
    if (this.state.isLoading) {
      return <LoadingShimmer />
    }
    return (
      <Formik<FormikConfig<IStaticUserFormValues>, IStaticUserFormValues>
        validationSchema={this.buildSchema()}
        initialValues={this.buildInitialValues()}
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    )
  }

  private readonly renderForm = (formProps: FormikProps<IStaticUserFormValues>) => {
    const fields = this.buildFields()
    const isFirstErrorFound = false
    return (
      <SafeAreaView edges={['bottom']}>
        <FormWrapper style={{ paddingTop: 10, paddingBottom: 20, paddingHorizontal: 16 }}>
          {Object.entries(fields).map(([key, field]) => {
            const error: string = formProps.errors[key]
            return (
              <View
                key={key}
                onLayout={(event) => {
                  const layout = event.nativeEvent.layout
                  if (error && !isFirstErrorFound && this.props.onUserError) {
                    this.props.onUserError(layout.y)
                  }
                }}
              >
                {this.renderField(
                  key,
                  field,
                  formProps.values[key],
                  formProps.setFieldValue,
                  formProps.setFieldTouched,
                  formProps.touched[key],
                  error
                )}
              </View>
            )
          })}
          <View style={{ marginTop: 8 }}>
            <FormSubmitButton
              isLoading={Boolean(this.props.loadingStore.isLoading(this.props.loadingKey))}
              testButtonId='setProfileBtn'
              handleSubmit={formProps.handleSubmit as any}
            />
          </View>
        </FormWrapper>
      </SafeAreaView>
    )
  }

  private readonly renderField = (
    fieldKey: string,
    field: IFormField,
    value: unknown,
    setFieldValue: (key: never, value: unknown) => void,
    setFieldTouched: (field: never, isTouched?: boolean) => void,
    touched?: boolean,
    error?: string
  ) => {
    const { navigation } = this.props
    const fieldProps: IFormFieldProps<any> = {
      navigation,
      value,
      touched,
      error,
      onChange: (value) => {
        setFieldValue(fieldKey as never, value)
        setFieldTouched(fieldKey as never, true)
      },
      fieldKey,
      label: field.label,
      disabled: field.disabled,
      keyboardType: field.keyboardType,
      isRequired: field.customField?.isRequired,
    }
    if (field.customField) {
      return (
        <CustomField
          key={fieldKey}
          {...fieldProps}
          customField={field.customField}
          setFieldTouched={setFieldTouched}
          displayErrors={this.props.displayErrors}
        />
      )
    }
    return <FormTextField key={fieldKey} {...fieldProps} />
  }

  private buildInitialValues(): IStaticUserFormValues & Record<string, unknown> {
    const user = AuthenticatorHelper.getUser()
    const rider = AuthenticatorHelper.getRider()
    return {
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      email: user.email ?? '',
      phoneNumber: user.phoneNumber ?? '',
      ...(rider.metadata ? CustomFieldHelper.buildInitialValues(rider.metadata, this.state.customFields) : {}),
    }
  }

  private readonly buildFields = (): Record<string, IFormField> => ({
    ...staticUserFields,
    ...CustomFieldHelper.buildFields(this.state.customFields),
  })

  private buildSchema(): yup.ObjectSchema<any> {
    return yup.object(mapValues(this.buildFields(), (field) => field.schema))
  }

  private readonly onSubmit = async (values: IStaticUserFormValues) => {
    // We filter out the phoneNumber because it should not be patched.
    const { firstName, lastName, email, phoneNumber, ...metadata } = values
    await this.props.onSubmit({ firstName, lastName, email, metadata })
  }
}
