import { action } from 'mobx'
import { RouterStore as RouterStoreClass } from 'mobx-react-router'
import { AnalyticsHelper } from 'src/helpers/AnalyticsHelper'
import { Pathname } from 'src/types/homeRoot'

class RiderRouterStore extends RouterStoreClass {
  public name = 'RiderRouterStore'

  @action
  public returnHome = async () => {
    await AnalyticsHelper.setScreen(Pathname.Home)
    this.go(-10)
  }

  @action
  public goToScreenIndex = async (index: number) => {
    this.go(index)
    const pathname = this.history.location.pathname
    await AnalyticsHelper.setScreen(pathname)
  }

  @action
  public replaceScreen = async ({ pathname, state }: { pathname: string; state?: any }) => {
    if (state) {
      this.replace({ pathname, state })
    } else {
      this.replace(pathname)
    }
    await AnalyticsHelper.setScreen(pathname)
  }

  @action
  public goToScreen = async ({ pathname, state }: { pathname: string; state?: any }) => {
    if (state) {
      this.push({ pathname, state })
    } else {
      this.push(pathname)
    }
    await AnalyticsHelper.setScreen(pathname)
  }

  @action
  public returnToPreviousScreen = async () => {
    this.goBack()
    const pathname = this.history.location.pathname
    await AnalyticsHelper.setScreen(pathname)
  }
}

export const RouterStore = new RiderRouterStore()
