import { IVehicleResponse } from '@sparelabs/api-client'
import { autorun, observable } from 'mobx'
import { LegacyApiClient } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { RequestStore } from 'src/stores/RequestStore'

class ActiveRequestVehicleStoreClass {
  @observable
  public vehicle: IVehicleResponse | null = null

  constructor() {
    autorun(async () => {
      if (RequestStore.activeRequest && RequestStore.activeRequest.vehicleId) {
        this.vehicle = await this.fetchVehicleData(RequestStore.activeRequest.id)
      } else {
        this.vehicle = null
      }
    })
  }

  public clear() {
    ActiveRequestVehicleStore.vehicle = null
  }

  private readonly fetchVehicleData = async (requestId: string): Promise<IVehicleResponse | null> => {
    if (AuthenticatorHelper.userOrgToken) {
      const res = await LegacyApiClient.get(AuthenticatorHelper.userOrgToken, `requests/${requestId}/vehicle`)
      if (res) {
        return res.body
      }
    }
    return null
  }
}

export const ActiveRequestVehicleStore = new ActiveRequestVehicleStoreClass()
