import { IListResponse } from '@sparelabs/api-client'
import { LegacyApiClient } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'

/**
 * Use this helper to get data from the API, the data is fetched in pages and returned in a single list
 * This is useful for fetching a large number of rows
 */
export class PagedDataHelper {
  public static PAGE_LIMIT: number = 50

  private static async getData<T>(resource: string, token: string, params?: object): Promise<T | void> {
    const res = await LegacyApiClient.get(token, resource, params, true)

    if (res) {
      return res.body
    }
  }

  public static async getDataWithPagination<T>(resource: string, params: object): Promise<T[]> {
    let numToSkip = 0
    let numReturned = 0
    let data: T[] = []

    if (AuthenticatorHelper.userOrgToken) {
      do {
        const res = await this.getData<IListResponse<T>>(resource, AuthenticatorHelper.userOrgToken, {
          ...params,
          limit: PagedDataHelper.PAGE_LIMIT,
          skip: numToSkip,
        })

        if (res) {
          data = data.concat(res.data)
          numReturned = res.data.length
          numToSkip += PagedDataHelper.PAGE_LIMIT
        }
      } while (numReturned === PagedDataHelper.PAGE_LIMIT)
    }

    return data
  }
}
