import { IPostRequestFareEstimateResponse, IRequestRiderType, RiderType } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { getAuthedApi } from 'src/api'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { handleError } from 'src/helpers/ErrorHelpers'
import { PurchaseInput } from 'src/helpers/payments/PaymentFlowHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { RequestOptions } from 'src/screens/request/RequestOptions'
import { IRequestStore, PRE_PICKUP_REQUEST_STATUSES, RequestStore } from 'src/stores/RequestStore'
import { IServiceStore, ServiceStore } from 'src/stores/ServiceStore'

type Props = ParamsListRoot[ScreenName.RequestRiderOptions] & {
  requestStore: IRequestStore
  serviceStore: IServiceStore
  handleDismiss: () => void
  handleNavigateDiscountDetails: (params: ParamsListRoot[ScreenName.DiscountDetails]) => void
  getFareEstimate: (updatedValues: IRequestRiderType[]) => Promise<IPostRequestFareEstimateResponse>
}

@observer
export class RequestRiderOptionsView extends Component<Props> {
  public UNSAFE_componentWillMount = () => {
    const { handleDismiss, serviceId, requestId, requestStore, serviceStore } = this.props
    const request = requestStore.requestMap.get(requestId)
    const service = serviceStore.servicesMap.get(serviceId)
    if (!request || !service || !PRE_PICKUP_REQUEST_STATUSES.includes(request.status)) {
      handleDismiss()
    }
  }

  public handleConfirmUpdate = async (updatedValues: IRequestRiderType[], purchaseInput?: PurchaseInput) => {
    const { requestId, handleDismiss, requestStore } = this.props
    try {
      await requestStore.updateRequest(requestId, {
        riders: updatedValues,
        ...purchaseInput,
      })
      handleDismiss()
    } catch (error) {
      handleError({ error, silent: true })

      AlertHelper.alert(
        st.payments.paymentErrorTitle(),
        // TODO - Use the actual payment error from the API
        st.payments.paymentErrorPaymentMethod(),
        [
          {
            text: st.common.alertOk(),
          },
        ],
        { cancelable: false }
      )
    }
  }

  public render() {
    const { serviceId, requestId, handleNavigateDiscountDetails, requestStore, serviceStore } = this.props
    const service = serviceStore.servicesMap.get(serviceId)
    const request = requestStore.requestMap.get(requestId)
    if (request && service) {
      return (
        <RequestOptions<RiderType>
          handleNavigateDiscountDetails={handleNavigateDiscountDetails}
          requestStore={requestStore}
          requestId={request.id}
          initialFare={request?.fare}
          showFareChange={true}
          getFareEstimate={this.props.getFareEstimate}
          handleConfirmUpdate={this.handleConfirmUpdate}
          initialRequestOptionsCount={request?.riders ?? []}
          availableServiceOptions={service?.riderTypes ?? []}
          question={st.components.editRiders.subtitle()}
          buttonTitle={st.components.editRiders.buttonTitle()}
          translationFunction={st.enums.riderType}
          allowZeroTotal={false}
          maxTotal={service?.maxRiders}
        />
      )
    }
    return null
  }
}

export const RequestRiderOptions = (props: ScreenPropsRoot<ScreenName.RequestRiderOptions>) => (
  <RequestRiderOptionsView
    {...props.route.params}
    requestStore={RequestStore}
    serviceStore={ServiceStore}
    handleDismiss={() => props.navigation.goBack()}
    handleNavigateDiscountDetails={(params) => props.navigation.navigate(ScreenName.DiscountDetails, params)}
    getFareEstimate={(updatedValues) =>
      getAuthedApi().requests.createFareEstimate(props.route.params.requestId, { riders: updatedValues })
    }
  />
)
