export const Images = {
  /* eslint-disable @typescript-eslint/no-require-imports */
  // images that can't be swapped to SVG's
  creditCardFront: require('../../assets/img/credit-card-front.png'),
  creditCardBack: require('../../assets/img/credit-card-back.png'),
  searchingRides: require('../../assets/img/searching-rides.png'),
  wallet: require('../../assets/img/wallet.png'),

  // TODO: remove once we swap these for FontAwesomeIcons
  // https://sparelabs.atlassian.net/browse/ENDUSER-25
  user: require('../../assets/img/user.png'),
  searchLocationMarker: require('../../assets/img/map-marker-alt-solid.png'),
  globeAmericas: require('../../assets/img/fa-globe-americas.png'),
  close: require('../../assets/img/close.png'),
  payments: require('../../assets/img/fa-dollar.png'),
  rightArrow: require('../../assets/img/right_arrow.png'),
  help: require('../../assets/img/fa-question.png'),
  legal: require('../../assets/img/fa-gavel.png'),
  back: require('../../assets/img/back.png'),
  floatingLabelArrow: require('../../assets/img/floatingLabelArrow.png'),
  question: require('../../assets/img/question.png'),
  thumbsUp: require('../../assets/img/thumbs-up.png'),
  thumbsDown: require('../../assets/img/thumbs-down.png'),
  creditCard: require('../../assets/img/credit-card.png'),
  magnifyingGlass: require('../../assets/img/fa-search.png'),
  homeUser: require('../../assets/img/fa-user.png'),
  pastTrips: require('../../assets/img/fa-past.png'),
  groupMemberships: require('../../assets/img/fa-users.png'),
  arrowRight: require('../../assets/img/fa-white-arrow-right.png'),
  promoIcon: require('../../assets/img/badge-percent.png'),
  iconChevronLeftScaled: require('../../assets/img/icon-chevron-left-custom.png'),
  lyftLogo: require('../../assets/img/lyftLogo.png'),
  lyftLogoNoBg: require('../../assets/img/lyftLogoNoBg.png'),
  externalLink: require('../../assets/img/externalLink.png'),
  lyftLogoPink: require('../../assets/img/lyftLogoPink.png'),

  // TODO: Remove brand logos for brands that do not have a white-label app when logoUrl gets merged
  logolessLogo: require('../../assets/img/logoless_org.png'),
  ufsnapLogo: require('../../assets/img/ufsnapLogo.png'),
  redirideLogo: require('../../assets/img/redirideLogo.png'),
  rossmoorLogo: require('../../assets/img/rossmoorLogo.png'),
  dartrapLogo: require('../../assets/img/dartrapLogo.png'),
  defaultLogo: require('../../assets/img/default_logo.png'),
  emtPalma: require('../../assets/img/emt_palma.png'),
  dartLogo: require('../../assets/img/gopool-logo.png'),
  dctaLogo: require('../../assets/img/dcta-logo.png'),
  maasJVLogo: require('../../assets/img/maasJV-logo.jpg'),
  rvtdLogo: require('../../assets/img/rvtdLogo.png'),
  noOrgLogo: require('../../assets/img/noOrgLogo.png'),
  starTransitLogo: require('../../assets/img/starTransitLogo.png'),
  paraPickupLogo: require('../../assets/img/paraPickupLogo.png'),
  ruterLogo: require('../../assets/img/ruterLogo.png'),
  starTranLogo: require('../../assets/img/starTranLogo.png'),
  southwestPrimeLogo: require('../../assets/img/southwestPrimeLogo.png'),
  ruapehuLogo: require('../../assets/img/logo-ruapehu.png'),
  knowrouteLogo: require('../../assets/img/knowroute-logo.png'),
  atbLogo: require('../../assets/img/atbLogo.png'),
  durhamLogo: require('../../assets/img/durhamLogo.png'),
  whistleLogo: require('../../assets/img/whistleLogo.png'),
  citibusLogo: require('../../assets/img/citibusLogo.png'),
  medicinehatLogo: require('../../assets/img/medicinehatLogo.png'),
  bellevueLogo: require('../../assets/img/bellevueLogo.png'),
  powellRiverLogo: require('../../assets/img/powellRiverLogo.png'),
  ctpLogo: require('../../assets/img/ctpLogo.png'),
  rtcLogo: require('../../assets/img/rtcLogo.png'),
  emiratesLogo: require('../../assets/img/emiratesLogo.png'),
  coastalLogo: require('../../assets/img/coastalLogo.png'),
  powellRiverTaxiLogo: require('../../assets/img/powellRiverTaxiLogo.png'),
  grabACabLogo: require('../../assets/img/grabACabLogo.png'),
  gatraLogo: require('../../assets/img/gatraLogo.png'),
  wrtaLogo: require('../../assets/img/wrtaLogo.png'),
  rnvLogo: require('../../assets/img/rnvLogo.png'),
  pantLogo: require('../../assets/img/pantLogo.png'),
  zipRidzLogo: require('../../assets/img/zipRidzLogo.png'),
  seatLogo: require('../../assets/img/seatLogo.png'),
  miltonLogo: require('../../assets/img/miltonLogo.png'),
  kingCountyLogo: require('../../assets/img/kingCountyLogo.png'),
  nobinaLogo: require('../../assets/img/nobinaLogo.png'),
  laWawaLogo: require('../../assets/img/laWawaLogo.png'),
  sierraCarLogo: require('../../assets/img/sierraCarLogo.png'),
  sunTranLogo: require('../../assets/img/sunTranLogo.png'),
  btsNetworkLogo: require('../../assets/img/btsNetworkLogo.png'),
  merrimackValleyLogo: require('../../assets/img/merrimackValleyLogo.png'),
  emtPalmaLogo: require('../../assets/img/emtPalmaLogo.png'),
  mountainLineLogo: require('../../assets/img/mountainLineLogo.png'),
  skuttleLogo: require('../../assets/img/skuttleLogo.png'),
  kariLogo: require('../../assets/img/kariLogo.png'),
  varmlandstrafikLogo: require('../../assets/img/varmlandstrafikLogo.png'),
  hamiltonRailwayLogo: require('../../assets/img/hamiltonRailwayLogo.png'),
  ramRideLogo: require('../../assets/img/ramRideLogo.png'),
  moxieLogo: require('../../assets/img/moxieLogo.png'),
  perthCountyLogo: require('../../assets/img/perthCountyLogo.png'),
  citySquareLogo: require('../../assets/img/citySquareLogo.png'),
  mojaRideLogo: require('../../assets/img/mojaRideLogo.png'),
  rideKCLogo: require('../../assets/img/rideKCLogo.png'),
  middlesexLogo: require('../../assets/img/middlesexLogo.png'),
  burlingtonLogo: require('../../assets/img/burlingtonLogo.png'),
  elevatedLogo: require('../../assets/img/elevatedLogo.png'),
  rideckLogo: require('../../assets/img/rideckLogo.jpg'),
  calaverasLogo: require('../../assets/img/calaverasLogo.png'),
  theCurrentLogo: require('../../assets/img/theCurrentLogo.png'),
  capitalTransitLogo: require('../../assets/img/capitalTransitLogo.png'),
  cpacsLogo: require('../../assets/img/cpacsLogo.png'),
  truvoLogo: require('../../assets/img/truvoLogo.png'),
  mvtaLogo: require('../../assets/img/mvtaLogo.png'),
  dartShuttleLogo: require('../../assets/img/dartShuttleLogo.png'),
  dalliLogo: require('../../assets/img/dalliLogo.png'),
  bookerridesLogo: require('../../assets/img/bookerridesLogo.png'),
  niceminiLogo: require('../../assets/img/niceminiLogo.png'),
  grtflexLogo: require('../../assets/img/grtflexLogo.png'),
  // TODO: change default to another logo to represent a testing app
  testingLogo: require('../../assets/img/default_logo.png'),
  saskatoonTransitLogo: require('../../assets/img/saskatoonTransitLogo.png'),
  strathconacountyLogo: require('../../assets/img/strathconacountyLogo.png'),
  capitalMetroLogo: require('../../assets/img/capitalMetroLogo.png'),
  twinTransit: require('../../assets/img/twinTransitLogo.png'),
  pstaaccessLogo: require('../../assets/img/pstaaccessLogo.png'),
  clovisTransitLogo: require('../../assets/img/clovisTransitLogo.png'),
  kansasuniLogo: require('../../assets/img/kansasuniLogo.png'),
  cunightrideLogo: require('../../assets/img/cunightrideLogo.png'),
  saintjohntransitLogo: require('../../assets/img/saintjohntransitLogo.png'),
  shonankamakuraLogo: require('../../assets/img/shonankamakuraLogo.png'),
  skyssLogo: require('../../assets/img/skyssLogo.png'),
  cambusLogo: require('../../assets/img/cambusLogo.png'),
  goSouthPlacerLogo: require('../../assets/img/goSouthPlacerLogo.png'),
  flexondemandLogo: require('../../assets/img/flexondemandLogo.png'),
  brakarLogo: require('../../assets/img/brakarLogo.png'),
  modLogo: require('../../assets/img/modLogo.png'),
  kentuckyparatransitLogo: require('../../assets/img/kentuckyparatransitLogo.png'),
  /* eslint-enable @typescript-eslint/no-require-imports */
}

export const pinImageStyle = { width: 20, height: 20 }
export const pinImageAnchor = { x: 0.5, y: 1 }
export const pinImageCenterOffset = { x: 0, y: -19 }

export const circlePinImageStyle = { width: 20, height: 20 }
export const circlePinCenterOffset = { x: 0, y: -6 }
export const circlePinAnchor = { x: 0.5, y: 0.5 }
