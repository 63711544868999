import { RequestCancellationReason } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { ScrollView, StyleSheet, Text, TextInput, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { PrimaryDanger } from 'src/components/buttons/PrimaryDanger'
import { RequestCancellationTagButton } from 'src/components/request/RequestCancellationTagButton'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { RequestCancellationStore } from 'src/stores/RequestCancellationStore'
import { AlertButtonStyle } from 'src/util/types'

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    backgroundColor: colors.textLight,
  },
  scrollContainer: {
    flex: 0.5,
  },
  container: {
    flex: 1,
    backgroundColor: colors.textLight,
    flexDirection: 'column',
  },
  description: {
    paddingTop: 24,
    paddingHorizontal: 20,
    paddingBottom: 16,
    color: colors.gray90,
    fontSize: 21,
    fontWeight: 'bold',
  },
  buttonContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'stretch',
    paddingHorizontal: 16,
    paddingBottom: 16,
    width: '100%',
  },
  textBox: {
    paddingTop: 20,
    flex: 1,
    marginHorizontal: 16,
  },
  textInput: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.gray2,
    paddingHorizontal: 8,
    fontSize: 15,
    height: 100,
    flex: 1,
  },
  tagListContainer: {
    flex: 1,
    paddingHorizontal: 16,
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingBottom: 20,
  },
  bottomContainer: {
    flex: 0.2,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.textLight,
  },
  tagContainer: {
    paddingHorizontal: 4,
    paddingVertical: 5,
  },
})

type Props = ParamsListRoot[ScreenName.RequestCancellation] & {
  handleDismiss: () => void
}

@observer
export class RequestCancellationView extends Component<Props> {
  public handlePressConfirm = async () => {
    const { requestId } = this.props
    if (RequestCancellationStore.reason === null) {
      AlertHelper.alert(
        st.screens.requestCancellation.alertTitle(),
        st.screens.requestCancellation.alertContent(),
        [{ text: st.common.alertOk(), style: AlertButtonStyle.Cancel }],
        { cancelable: false }
      )
    } else {
      await RequestCancellationStore.submit(requestId, async () => {
        this.props.handleDismiss()
        this.props.onSuccessCallback()
      })
    }
  }

  public renderReasonTags() {
    const applicableReasons: RequestCancellationReason[] = [
      RequestCancellationReason.AlternativeMethod,
      RequestCancellationReason.LateDriver,
      RequestCancellationReason.ScheduleChange,
      RequestCancellationReason.Other,
    ]
    const reasonTags = applicableReasons.map((reason) => (
      <View testID={`cancellationReason-${reason}`} key={reason} style={styles.tagContainer}>
        <RequestCancellationTagButton
          reason={reason}
          active={RequestCancellationStore.reason === reason}
          onPress={() => RequestCancellationStore.setReason(reason)}
          accessibilityRole='radio'
          accessibilityState={{ selected: RequestCancellationStore.reason === reason }}
        />
      </View>
    ))
    return <View style={styles.tagListContainer}>{reasonTags}</View>
  }

  public render() {
    return (
      <View style={styles.outerContainer}>
        <ScrollView style={styles.scrollContainer}>
          <View style={styles.container}>
            <Text style={styles.description}>{st.screens.requestCancellation.description()}</Text>
            {this.renderReasonTags()}
            <View style={styles.textBox}>
              <TextInput
                style={styles.textInput}
                multiline={true}
                editable={true}
                underlineColorAndroid='transparent'
                placeholder={st.screens.requestCancellation.commentPlaceholder({
                  organization: AuthenticatorHelper.getOrganization().name,
                })}
                numberOfLines={2}
                onChangeText={(notes) => RequestCancellationStore.setNotes(notes)}
                value={RequestCancellationStore.notes || ''}
                textAlignVertical='top'
                accessibilityLabel={st.common.inputText()}
              />
            </View>
          </View>
        </ScrollView>
        <View style={styles.bottomContainer}>
          <View style={styles.buttonContainer}>
            <PrimaryDanger
              testID='cancellationConfirm'
              loading={RequestCancellationStore.isLoading}
              title={st.screens.requestCancellation.confirmCancellation()}
              onPress={this.handlePressConfirm}
            />
          </View>
        </View>
      </View>
    )
  }
}

export const RequestCancellation = (props: ScreenPropsRoot<ScreenName.RequestCancellation>) => (
  <RequestCancellationView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
)
