import { IJourney, IJourneyEstimateQueryParams, LegMode } from '@sparelabs/api-client'
import { action, computed, observable } from 'mobx'
import { getJourneys } from 'src/api'
import { isWalkingOnlyJourney } from 'src/components/journey/JourneyHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError } from 'src/helpers/ErrorHelpers'
import { getCreatedInterface, parseAccessibilityFeatureMapToArray } from 'src/helpers/EstimateHelper'
import { moment } from 'src/helpers/Moment'
import { LoadingStore } from 'src/stores/LoadingStore'
import { EstimatesUserInputParsed } from 'src/types'
import { IJourneyTs } from 'src/types/journey'

export interface IJourneyStore {
  journeys: IJourney[] | null
  journeyInput: IJourneyEstimateQueryParams | null
  loadingStore: LoadingStore
  lastRefreshTime: number
  mixedJourneys: IJourney[]
  transitJourneys: IJourney[]
  walkingOnlyJourney: IJourney[]

  fetchJourneys: (estimateInput: EstimatesUserInputParsed) => Promise<void>
  getJourneyFromId: (id: string | null) => IJourney | null
}

/**
 * This class is designed to store data for the journeys available based on the estimate stored in EstimateInputStore.
 * It is used in all parent and children components that handle displaying journeys.
 */

export class JourneyStore implements IJourneyStore {
  public name = 'JourneyStore'

  @observable
  public journeyInput: IJourneyEstimateQueryParams | null = null

  @observable
  public journeys: IJourney[] | null = null

  @observable
  public loadingStore: LoadingStore = new LoadingStore()

  @observable
  public lastRefreshTime: number = 0

  @computed
  public get mixedJourneys(): IJourney[] {
    return this.journeys
      ? this.journeys.filter((journey) => Boolean(journey.legs.find((leg) => leg.mode === LegMode.OnDemand)))
      : []
  }

  @computed
  public get transitJourneys(): IJourney[] {
    return this.journeys
      ? this.journeys.filter(
          (journey) =>
            Boolean(!journey.legs.find((leg) => leg.mode === LegMode.OnDemand)) && !isWalkingOnlyJourney(journey)
        )
      : []
  }

  // There is only one walking journey option, but returning as a list for simplifying rendering logic
  @computed
  public get walkingOnlyJourney(): IJourney[] {
    const walkingOnlyJourney = this.journeys?.find((journey) => isWalkingOnlyJourney(journey))
    return walkingOnlyJourney ? [walkingOnlyJourney] : []
  }

  @action
  public parseJourneyInput = (estimateInput: EstimatesUserInputParsed) => {
    const { ts, arriveBy } = this.parseJourneyTs(estimateInput)
    this.journeyInput = {
      ts,
      arriveBy,
      startLongitude: estimateInput.requestedPickupLocation.coordinates[0],
      startLatitude: estimateInput.requestedPickupLocation.coordinates[1],
      endLongitude: estimateInput.requestedDropoffLocation.coordinates[0],
      endLatitude: estimateInput.requestedDropoffLocation.coordinates[1],
      legModes: Object.values(LegMode),
      accessibilityFeatures: parseAccessibilityFeatureMapToArray(estimateInput.accessibilityFeatures).map((feature) =>
        JSON.stringify(feature)
      ),
      riderId: AuthenticatorHelper.userId || undefined,
      createdInterface: getCreatedInterface(),
      numRiders: estimateInput.numRiders,
      includeMixedOptions: true,
    }
  }

  @action
  public parseEstimateAndFetchJourneys = async (estimateInput: EstimatesUserInputParsed) => {
    this.journeys = null
    await this.fetchJourneys(estimateInput)
  }

  @action
  public fetchJourneys = async (estimateInput: EstimatesUserInputParsed) => {
    try {
      this.parseJourneyInput(estimateInput)
      if (this.journeyInput) {
        const journeys = await this.loadingStore.execute(getJourneys(this.journeyInput))
        this.journeys = this.sortByStartTs(journeys)
        this.lastRefreshTime = moment().unix()
      } else {
        throw new Error('Journey Estimate Input not found')
      }
    } catch (error) {
      handleError({ error: error as Error })
    }
  }

  /**
   * Parses the correct Ts to use from EstimatesUserInputParsed, which is required for IJourneyEstimateQueryParams
   * IJourneyEstimateQueryParams also know needs to know if this Ts is a pickup Ts or a dropoff Ts
   */
  @action
  public parseJourneyTs = (estimateInput: EstimatesUserInputParsed): IJourneyTs => {
    if (estimateInput.requestedPickupTs) {
      return { ts: estimateInput.requestedPickupTs, arriveBy: false }
    } else if (estimateInput.requestedDropoffTs) {
      return { ts: estimateInput.requestedDropoffTs, arriveBy: true }
    }
    // Default to making request with current time
    return { ts: moment().unix(), arriveBy: false }
  }

  @action
  public clear = () => {
    this.journeyInput = null
    this.journeys = null
  }

  public getJourneyFromId = (journeyId: string | null): IJourney | null =>
    this.journeys?.find((journey) => journeyId === journey.id) ?? null

  @action
  public readonly sortByStartTs = (journeys: IJourney[]): IJourney[] =>
    journeys.slice().sort((journeyA, journeyB) => journeyA.startTime.ts - journeyB.startTime.ts)
}
