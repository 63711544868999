import { IAnnouncementResponse } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ButtonWrapper } from 'src/components/buttons/ButtonWrapper'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { WebViewHelper } from 'src/helpers/WebViewHelper'
import { useNavigationPreventBackButton } from 'src/hooks/useNavigationPreventBackButton'
import { st } from 'src/locales'
import { ScreenName, ScreenPropsRoot } from 'src/navigation'
import { AnnouncementStore } from 'src/stores/AnnouncementStore'
import { AnnouncementItem } from './AnnouncementItem'

const getStyles = () =>
  StyleSheet.create({
    safeAreaContainer: {
      flex: 1,
      backgroundColor: 'white',
    },
  })

interface IProps {
  handleAcknowledgePress: () => void
  handleAnnouncementPress: (uri: string | null) => void
  announcement: IAnnouncementResponse
}

@observer
export class AcknowledgeableAnnouncementView extends Component<IProps> {
  public renderButton() {
    return (
      <ButtonWrapper>
        <PrimaryButton title={st.common.acceptButton()} onPress={() => this.props.handleAcknowledgePress()} />
      </ButtonWrapper>
    )
  }

  public render() {
    const styles = getStyles()
    return (
      <SafeAreaView edges={['bottom']} style={styles.safeAreaContainer}>
        <ScrollView>
          <AnnouncementItem
            announcement={this.props.announcement}
            handleActionPress={this.props.handleAnnouncementPress}
          />
        </ScrollView>
        {this.renderButton()}
      </SafeAreaView>
    )
  }
}

export const AcknowledgeableAnnouncementModal = (
  props: ScreenPropsRoot<ScreenName.AcknowledgeableAnnouncementModal>
) => {
  useNavigationPreventBackButton(() => true, props.navigation, [])
  return (
    <AcknowledgeableAnnouncementView
      announcement={props.route.params.announcement}
      handleAnnouncementPress={(uri: string | null) => {
        if (uri) {
          WebViewHelper.handleLink({ uri }, props.navigation.navigate.bind(AcknowledgeableAnnouncementModal))
        }
      }}
      handleAcknowledgePress={() => {
        props.navigation.navigate(ScreenName.RootHome, {})
        AnnouncementStore.acknowledge(props.route.params.announcement.id)
        props.route.params.onAcknowledge()
      }}
    />
  )
}
