import React, { Component } from 'react'
import { Animated, StyleSheet, View, ViewStyle } from 'react-native'

interface IProps {
  backgroundColor: string
  barColor: string
  progressPercent: number
  barHeight?: number
}

const styles = StyleSheet.create({
  progressBarContainer: {
    borderRadius: 100,
    overflow: 'hidden',
    height: 8,
    flex: 1,
    width: '100%',
  },
})

export class ProgressBar extends Component<IProps> {
  public render() {
    const progressAnimation: ViewStyle = {
      width: `${this.props.progressPercent}%`,
      bottom: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
    }

    return (
      <View
        style={[
          styles.progressBarContainer,
          { backgroundColor: this.props.backgroundColor },
          { height: this.props.barHeight ?? 8 },
        ]}
      >
        <Animated.View style={[progressAnimation, { backgroundColor: this.props.barColor }]} />
      </View>
    )
  }
}
