import { FontAwesomeIconStyle } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { Pill } from 'src/components/PillArea'
import { st } from 'src/locales'
import { ApiStore } from 'src/stores/ApiStore'

@observer
export class NetworkErrorPill extends Component {
  public render() {
    if (ApiStore.connected) {
      return null
    }
    return (
      <Pill
        backgroundColor='rgba(0, 0, 0, 0.5)'
        textColor='white'
        title={st.errors.noConnection()}
        icon={[FontAwesomeIconStyle.Solid, 'info-circle']}
      />
    )
  }
}
