import { Vancouver } from '@sparelabs/places'
import { action, observable } from 'mobx'
import { persist } from 'mobx-persist'
import { EdgePadding, Region } from 'react-native-maps'
import { getLatitudeLongitudeDelta } from 'src/helpers/MapHelper'
import { IMapOptions, IMapState, IMarkerCoordinates } from 'src/types'

const defaultMapRegion = {
  longitude: Vancouver.telusWorldOfScience.location.coordinates[0],
  latitude: Vancouver.telusWorldOfScience.location.coordinates[1],
  ...getLatitudeLongitudeDelta(),
}

const defaultLatitudeDeltaValue: number = 0.02

export class MapStoreClass {
  public name = 'MapStore'
  @observable
  @persist('object')
  public mapRegion: Region

  @observable
  @persist('object')
  public mapState: IMapState

  @observable
  public showUserLocation: boolean = true

  @observable
  public isPanning: boolean = false

  @observable
  public disableMovement: boolean = false

  @observable
  public mapPadding: EdgePadding = { top: 0, bottom: 0, left: 0, right: 0 }

  @observable
  public latitudeDeltaValue: number = defaultLatitudeDeltaValue

  constructor() {
    this.mapRegion = defaultMapRegion

    this.mapState = {
      coordinates: [],
      options: {},
    }
  }

  @action
  public setIsPanning(isPanning: boolean) {
    this.isPanning = isPanning
  }

  @action
  public setMapRegion(region: Region) {
    this.mapRegion = region
  }

  @action
  public setShowUserLocation(showUser: boolean) {
    this.showUserLocation = showUser
  }

  @action
  public fitMapToCoordinates = (coordinates: IMarkerCoordinates[], options: IMapOptions) => {
    this.mapState = {
      coordinates,
      options,
    }
  }

  @action
  public fitMapToCurrentPosition = (latitude: number, longitude: number) => {
    this.fitMapToCoordinates(
      [
        {
          latitude,
          longitude,
        },
      ],
      { animated: true }
    )
  }

  @action
  public resetMap = () => {
    this.resetMapPadding()
    this.latitudeDeltaValue = defaultLatitudeDeltaValue
    this.disableMovement = false
  }

  @action
  public resetMapPadding = () => {
    this.mapPadding = { top: 0, bottom: 0, left: 0, right: 0 }
  }

  @action
  public clear() {
    this.mapRegion = defaultMapRegion
    this.mapState = { coordinates: [], options: {} }
    this.resetMap()
  }
}

export const MapStore = new MapStoreClass()
