import { ITipPostBody, ITipResponse } from '@sparelabs/api-client'
import { action, observable, ObservableMap } from 'mobx'
import { persist } from 'mobx-persist'
import { createTip, retrieveTip } from 'src/api'
import { handleError } from 'src/helpers/ErrorHelpers'

export interface ITipStore {
  getTipResponse: (requestId: string) => Promise<ITipResponse | null>
  createTip: (requestId: string, body: ITipPostBody) => Promise<ITipResponse>
}

class TipStoreClass implements ITipStore {
  public name = 'TipStore'

  @persist('map')
  @observable
  private requestTipsMap: ObservableMap<string, ITipResponse | null> = observable.map()

  public getTipResponse = async (requestId: string): Promise<ITipResponse | null> => {
    const tipResponse = this.requestTipsMap.get(requestId)

    if (tipResponse) {
      return tipResponse
    }

    let tip: ITipResponse | null = null
    try {
      tip = await retrieveTip(requestId)
      if (tip) {
        this.requestTipsMap.set(requestId, tip)
      }
    } catch (error) {
      // 404 errors means the rider didn't tip
      if (error && error.response.status === 404) {
        this.requestTipsMap.set(requestId, null)
      } else {
        handleError({ error, silent: true })
      }
    }
    return tip
  }

  public createTip = async (requestId: string, body: ITipPostBody): Promise<ITipResponse> => {
    const res = await createTip(requestId, body)
    TipStore.updateRequestTipsMap(requestId, res)
    return res
  }

  @action
  public updateRequestTipsMap = (requestId: string, tipBody: ITipResponse) => {
    this.requestTipsMap.set(requestId, tipBody)
  }

  public getTipFromMap = (requestId: string) => this.requestTipsMap.get(requestId)

  @action
  public clear() {
    this.requestTipsMap = observable.map()
  }
}

export const TipStore = new TipStoreClass()
