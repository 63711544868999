import { NavigationProp } from '@react-navigation/native'
import { FeatureFlag } from '@sparelabs/api-client'
import { observer } from 'mobx-react/native'
import React, { Component } from 'react'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { WebViewHelper } from 'src/helpers/WebViewHelper'
import { st } from 'src/locales'
import { ParamsListAccount, ParamsListRoot, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { NavigationStateHelper } from 'src/navigation/consts/NavigationStateHelper'
import { RequestStore } from 'src/stores/RequestStore'
import { AlertButtonStyle } from 'src/util/types'
import { ApiClientBuilder } from '../api/ApiClientBuilder'
import { AccountHeader } from '../components/AccountHeader'
import { AccountSettingsFooter } from '../components/AccountSettingsFooter'
import { ISettingsListItem, SettingsList, SettingsListItemType } from '../components/settings/SettingsList'

const t = st.screens.account

export enum AccountListItem {
  Organization = 'Organization',
  ScheduledTrips = 'ScheduledTrips',
  PastTrips = 'PastTrips',
  FarePasses = 'FarePasses',
  Promos = 'Promos',
  Payments = 'Payments',
  Favorites = 'Favorites',
  GroupMemberships = 'GroupMemberships',
  FleetAgreements = 'FleetAgreements',
  Help = 'Help',
  Legal = 'Legal',
  SignOut = 'SignOut',
  Delete = 'Delete',
}

type Props = ParamsListAccount[ScreenName.Account] & {
  handleAccountSettingsItem: (accountSettingsItem: Exclude<AccountListItem, AccountListItem.Delete>) => void
  handleNavigateSetProfile: () => void
  handleNavigateRootLogin: () => void
  handleNavigateDeveloperMenu: () => void
}

@observer
export class AccountView extends Component<Props> {
  public getSettingsListData = () => {
    const settingsList: ISettingsListItem[] = []
    const organizationName: string = AuthenticatorHelper.organization ? AuthenticatorHelper.organization.name : ''
    if (AuthenticatorHelper.globalApp && AuthenticatorHelper.globalApp?.linkedOrganizations.length > 1) {
      settingsList.push({
        id: AccountListItem.Organization,
        title: t.organization({ organizationName }),
        image: Images.globeAmericas,
        type: SettingsListItemType.List,
      })
    }

    if (RequestStore.hasScheduledRequests) {
      settingsList.push({
        id: AccountListItem.ScheduledTrips,
        title: t.scheduledTrips(),
        iconComponent: <FontAwesomeIconWrapper icon='calendar' size={16} color={colors.textDark} />,
        type: SettingsListItemType.List,
      })
    }

    settingsList.push({
      id: AccountListItem.PastTrips,
      title: t.pastTrips(),
      image: Images.pastTrips,
      type: SettingsListItemType.List,
    })

    if (AuthenticatorHelper.getOrganization().featureFlags.find((flag) => flag === FeatureFlag.FarePasses)) {
      settingsList.push({
        id: AccountListItem.FarePasses,
        title: t.farePasses(),
        iconComponent: <FontAwesomeIconWrapper icon='ticket' size={16} color={colors.textDark} />,
        type: SettingsListItemType.List,
      })
    }

    if (AuthenticatorHelper.getOrganization().featureFlags.find((flag) => flag === FeatureFlag.Promos)) {
      settingsList.push({
        id: AccountListItem.Promos,
        title: st.screens.listPromoScreen.menuTitle(),
        image: Images.promoIcon,
        type: SettingsListItemType.List,
      })
    }

    settingsList.push({
      id: AccountListItem.Payments,
      title: t.payments(),
      image: Images.payments,
      type: SettingsListItemType.List,
    })

    settingsList.push({
      id: AccountListItem.Favorites,
      title: t.favoriteLocations(),
      iconComponent: <FontAwesomeIconWrapper icon='heart' size={16} color={colors.textDark} />,
      type: SettingsListItemType.List,
    })

    settingsList.push({
      id: AccountListItem.GroupMemberships,
      title: t.groupMemberships(),
      image: Images.groupMemberships,
      type: SettingsListItemType.List,
    })

    settingsList.push({
      id: AccountListItem.FleetAgreements,
      title: t.fleetAgreements(),
      iconComponent: <FontAwesomeIconWrapper icon='car-bus' size={16} color={colors.textDark} />,
      type: SettingsListItemType.List,
    })

    if (AuthenticatorHelper.organization && AuthenticatorHelper.organization.faqUrl) {
      settingsList.push({
        id: AccountListItem.Help,
        title: t.help(),
        image: Images.help,
        type: SettingsListItemType.List,
      })
    }

    settingsList.push(
      { id: AccountListItem.Legal, title: t.legal(), image: Images.legal, type: SettingsListItemType.List },
      {
        id: AccountListItem.SignOut,
        title: t.signout(),
        type: SettingsListItemType.Action,
        withHeader: true,
        color: colors.red50,
      },
      {
        id: AccountListItem.Delete,
        title: t.deleteAccount(),
        type: SettingsListItemType.Action,
        withHeader: true,
        color: colors.red50,
      }
    )

    return settingsList
  }

  public handlePressItem = async (accountSettingsItem: AccountListItem) => {
    if (accountSettingsItem === AccountListItem.Delete) {
      await this.handleDeleteAccount()
    } else {
      this.props.handleAccountSettingsItem(accountSettingsItem)
    }
  }

  public renderListHeaderComponent = () => <AccountHeader onPress={this.props.handleNavigateSetProfile} />

  public hasUserAndOrganization = (): boolean => {
    const user = AuthenticatorHelper.user
    return Boolean(AuthenticatorHelper.organization && user && user.id)
  }

  public render() {
    return (
      /* 
        We don't want to render the account if no org or user is set to avoid unexpected crashes
        such as when logging out and the org is removed causing a re-render of Account with no org set
      */
      this.hasUserAndOrganization() && (
        <SettingsList<AccountListItem>
          data={this.getSettingsListData()}
          onPressItem={this.handlePressItem}
          listHeaderComponent={this.renderListHeaderComponent}
          listFooterComponent={
            <AccountSettingsFooter handleNavigateDeveloperMenu={this.props.handleNavigateDeveloperMenu} />
          }
        />
      )
    )
  }

  private readonly handleDeleteAccount = async () => {
    const organization = AuthenticatorHelper.organization
    if (organization) {
      AlertHelper.alert(
        st.components.deleteAccountAlert.title(),
        st.components.deleteAccountAlert.description({
          organizationNames: organization.name,
        }),
        [
          {
            style: AlertButtonStyle.Cancel,
            text: st.common.buttonCancel(),
          },
          {
            style: AlertButtonStyle.Destructive,
            text: st.components.deleteAccountAlert.confirmButton(),
            onPress: async () => {
              await ApiClientBuilder.build().users.deleteMe({ organizationIds: [organization.id] })
              AuthenticatorHelper.removeCurrentOrgLoginData()
              await AuthenticatorHelper.logout()
              this.props.handleNavigateRootLogin()
            },
          },
        ],
        {
          cancelable: true,
        }
      )
    }
  }
}

export const Account = (props: ScreenPropsAccount<ScreenName.Account>) => (
  <AccountView
    {...props.route.params}
    handleNavigateRootLogin={() => {
      props.navigation
        .getParent<NavigationProp<ParamsListRoot>>()
        .reset({ routes: [NavigationStateHelper.getRootLoginRoutes()] })
    }}
    handleNavigateSetProfile={() => {
      props.navigation.navigate(ScreenName.SetProfile, { isOnboarding: false })
    }}
    handleNavigateDeveloperMenu={() =>
      props.navigation.navigate(ScreenName.GroupDeveloperMenu, {
        screen: ScreenName.DeveloperMenu,
        params: {},
      })
    }
    handleAccountSettingsItem={async (accountSettingsItem: AccountListItem) => {
      switch (accountSettingsItem) {
        case AccountListItem.Organization:
          await AuthenticatorHelper.logout()
          props.navigation
            .getParent<NavigationProp<ParamsListRoot>>()
            .reset({ routes: [NavigationStateHelper.getRootLoginRoutes()] })
          break
        case AccountListItem.FarePasses:
          props.navigation.navigate(ScreenName.FarePassList, {})
          break
        case AccountListItem.Favorites:
          props.navigation.navigate(ScreenName.FavoritesListScreen, {})
          break
        case AccountListItem.Payments:
          props.navigation.navigate(ScreenName.PaymentMethods, {})
          break
        case AccountListItem.Promos:
          props.navigation.navigate(ScreenName.ListPromo, {})
          break
        case AccountListItem.PastTrips:
          props.navigation.navigate(ScreenName.PastTripsList, {})
          break
        case AccountListItem.ScheduledTrips:
          props.navigation.navigate(ScreenName.GroupScheduledTripsList, {
            screen: ScreenName.ScheduledTripsList,
            params: {
              navigateToRequestId: null,
            },
          })
          break
        case AccountListItem.GroupMemberships:
          props.navigation.navigate(ScreenName.GroupMemberships, {})
          break
        case AccountListItem.FleetAgreements:
          props.navigation.navigate(ScreenName.FleetAgreementList, {})
          break
        case AccountListItem.Help: {
          const faqUrl = AuthenticatorHelper.getOrganization().faqUrl
          if (faqUrl) {
            WebViewHelper.handleLink({ uri: faqUrl }, props.navigation.navigate.bind(Account))
          }
          break
        }
        case AccountListItem.Legal:
          props.navigation.navigate(ScreenName.Legal, {})
          break
        case AccountListItem.SignOut:
          // We have to use this method of switching to login screen so that other
          // screens do not continue being mounted
          AuthenticatorHelper.removeCurrentOrgLoginData()
          await AuthenticatorHelper.logout()
          props.navigation
            .getParent<NavigationProp<ParamsListRoot>>()
            .reset({ routes: [NavigationStateHelper.getRootLoginRoutes()] })
          break
      }
    }}
  />
)
